import { Box } from '@mui/material';
import React, { useState } from 'react';
import SearchFilters from './components/SearchFilters';
import AssignBroker from '../../AssignBroker';
import { useAssignUserBroker } from '../../../api/user';
import useToastNotification from '../../../hooks/useToastNotification';

const Filters = ({
  filterTag,
  handleChange,
  tags,
  MenuProps,
  control,
  debouncedHandleSearchChange,
  setRowSelection,
  originalSelectedRows,
  brokers,
}) => {
  const { mutate, isSuccess, isLoading } = useAssignUserBroker();
  const [selectedBroker, setSelectedBroker] = useState([]);

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'successfuly_updated_leads_broker',
    cb: () => {
      setSelectedBroker(undefined);
      setRowSelection({});
    },
    variant: 'success',
  });

  return (
    <Box display="flex" gap="20px" alignItems="center">
      {originalSelectedRows && originalSelectedRows.length > 0 ? (
        <AssignBroker
          MenuProps={MenuProps}
          brokers={brokers}
          selectedBroker={selectedBroker}
          setSelectedBroker={setSelectedBroker}
          isBrokerSelected={selectedBroker?.length}
          onMutate={() => {
            if (selectedBroker.length) {
              mutate({ data: { brokerId: selectedBroker, users: originalSelectedRows?.map((row) => row?.id) } });
            }
          }}
        />
      ) : (
        <SearchFilters
          MenuProps={MenuProps}
          control={control}
          debouncedHandleSearchChange={debouncedHandleSearchChange}
          filterTag={filterTag}
          handleChange={handleChange}
          tags={tags}
        />
      )}
    </Box>
  );
};

export default Filters;
