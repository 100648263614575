import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StepperCards = ({ variant = false, label, desc, index }) => {
  const { t } = useTranslation();
  const variantStyle = variant
    ? {
        box: {
          color: 'white',
          background: '#187D7E',
        },
        circle: {
          border: '1px solid white',
        },
      }
    : {
        box: {
          background: '#f4f4f7',
        },
        circle: {
          border: '1px solid #187D7E',
          color: '#187D7E',
        },
      };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '25px',
        gap: '16px',
        background: '#f4f4f7',
        borderRadius: ' 15px',

        ...variantStyle.box,
      }}
    >
      <Box display="flex" gap="24px">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: 0,
            gap: '10px',
            userSelect: 'none',
            width: '44px',
            height: '44px',
            borderRadius: '100%',
            ...variantStyle.circle,
          }}
        >
          <Typography sx={{ fontSize: '20px' }}>{index}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap="16px">
          <Typography
            variant="h4"
            sx={{
              fontWeight: 400,
              lineHeight: '150% !important',
              letterSpacing: '-0.96px',
            }}
          >
            {t(label)}
          </Typography>
          <Typography
            sx={{ fontSize: '18px', fontWeight: 200, lineHeight: '150% !important', letterSpacing: '-0.36px' }}
          >
            {t(desc)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default StepperCards;
