import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { ArrowBack, ArrowUpwardRounded, ExpandMoreRounded } from '@mui/icons-material';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import DocumentManager from '../dashboardComponents/DocumentManager';
import { startCase } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import DocumentTab from '../DocumentTab';
import FinishedDocumentsTab from './FinishedDocumentsTab';

const DocumentTabs = ({ taskStatus, suffix, isLoading, files, tabs, archives, archive }) => {
  const [activeTabs, setActiveTabs] = useState([]);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { currentUser } = useSelector((state) => state.auth);
  const finishedTrial = currentUser?.info?.finishedTrial;
  const [expanded, setExpanded] = useState(false);
  const { t, i18n } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const lang = i18n.language;

  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} overflow="hidden" maxWidth="100%" minHeight="100vh">
      <Box display="flex" flexDirection="column" alignItems="center" paddingX={1} gap="3px" pt={1}>
        {isMobile ? (
          <Accordion expanded={expanded} sx={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreRounded />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => setExpanded(!expanded)}
            >
              <Typography>{params?.fileType ? startCase(params.fileType) : t('options')}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                flexDirection: 'column',
                display: 'flex',
                gap: '10px',
              }}
            >
              {tabs
                ?.filter((tab) => (finishedTrial ? true : !tab.auth))
                ?.map((tab, idx) => (
                  <DocumentTab
                    key={idx}
                    suffix={suffix}
                    lang={lang}
                    tab={tab}
                    isLoading={isLoading}
                    onLastTabClick={(lastTab) => {
                      navigate(`${suffix}/${lastTab?.[`name_${lang}`]}`, { state: suffix });
                    }}
                    activeTabs={activeTabs}
                    setActiveTabs={setActiveTabs}
                    isActive={params?.fileType === tab?.[`name_${lang}`]}
                    isMenu
                  />
                ))}
            </AccordionDetails>
          </Accordion>
        ) : (
          tabs
            ?.filter((tab) => (finishedTrial ? true : !tab.auth))
            ?.map((tab, index) => (
              <DocumentTab
                key={index}
                suffix={suffix}
                lang={lang}
                tab={tab}
                isLoading={isLoading}
                onLastTabClick={(lastTab) => {
                  navigate(`${suffix}/${lastTab?.[`name_${lang}`]}`, { state: suffix });
                }}
                activeTabs={activeTabs}
                setActiveTabs={setActiveTabs}
                isActive={params?.fileType === tab?.[`name_${lang}`]}
                isMenu
              />
            ))
        )}
        <FinishedDocumentsTab setActiveTabs={setActiveTabs} activeTabs={activeTabs} suffix={suffix} />
      </Box>
      <Divider orientation="vertical" sx={{ height: '100%' }} />
      {params?.fileType ? (
        <Box display="flex" width="100%" flexDirection="column" p={2}>
          <DocumentManager
            taskStatus={taskStatus}
            archives={archives}
            files={files}
            fileType={params?.fileType}
            archive={archive}
          />
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="fit-content"
          width="100%"
          paddingX={3}
          pt={3}
        >
          {!isMobile ? <ArrowBack /> : <ArrowUpwardRounded />} {t('selectCategory')}
        </Box>
      )}
    </Box>
  );
};

export default DocumentTabs;
