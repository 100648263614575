import { useMutation, useQueryClient } from 'react-query';
import { createTerminSlotsLocalization } from '../api/termins';

const useCreateTerminSlotLocals = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createTerminSlotsLocalization(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['termin-slots'] });
    },
  });
};

export default useCreateTerminSlotLocals;
