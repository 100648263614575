import { Box, Typography } from '@mui/material';
import React from 'react';

const StatusBox = ({ color, children }) => {
  return (
    <Box sx={{ paddingY: '8px', flexGrow: 1, paddingX: '12px', backgroundColor: color, borderRadius: '5px' }}>
      <Typography variant="body1" fontWeight={700} sx={{ color: 'white' }} textAlign="center">
        {children}
      </Typography>
    </Box>
  );
};

export default StatusBox;
