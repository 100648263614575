import { useMutation, useQuery, useQueryClient } from 'react-query';
import { customInstance } from './client';

const suffix = '/v1/family-members';
const suffix2 = '/v1/family-relations';
const suffix3 = '/family-relation-locals';

//GET Family Relations
const getAllFamilyRelations = () => {
  return customInstance({
    url: `${suffix2}`,
    method: 'get',
  });
};

export const useGetAllFamilyRelations = () => {
  return useQuery(['family', 'relations'], async () => {
    const data = await getAllFamilyRelations();
    return data;
  });
};

//POST Family Members
const createFamilyMember = (data) => {
  return customInstance({
    url: `${suffix}`,
    method: 'post',
    data,
  });
};

export const useCreateFamilyMember = () => {
  const queryClient = useQueryClient();
  return useMutation(({ data }) => createFamilyMember(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['family', 'members'] });
    },
  });
};

//POST Family Relations

const createFamilyRelation = (data) => {
  return customInstance({
    url: `${suffix2}`,
    method: 'post',
    data,
  });
};

export const useCreateFamilyRelation = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createFamilyRelation(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['family', 'relations'] });
    },
  });
};

// DELETE Family Member

const deleteFamilyMember = async (id) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'delete',
  });
};

export const useDeleteFamilyMember = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id }) => deleteFamilyMember(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['family', 'members'] });
    },
  });
};

// GET Family Member by ID

const getFamilyMemberById = (id) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'get',
  });
};

export const useGetFamilyMemberById = (id) => {
  return useQuery(['family-member', id], () => getFamilyMemberById(id), { enabled: !!id });
};

// PUT Family Member

const updateFamilyMember = (id, data) => {
  return customInstance({
    url: `/v1/family-members/${id}`,
    method: 'put',
    data: data,
  });
};

export const useEditFamMember = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateFamilyMember(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['family', 'members'] });
    },
  });
};

//GET Family Member By User ID

const getFamilyMemberByUserId = (id) => {
  return customInstance({
    url: `/v1/family-members/user/${id}`,
    method: 'get',
  });
};

export const useGetFamilyMemberByUserId = (id) => {
  return useQuery(['family', 'members', id], () => getFamilyMemberByUserId(id), { enabled: !!id });
};

//GET Family Relation By ID

const getFamilyRelationById = (id) => {
  return customInstance({
    url: `${suffix2}/${id}`,
    method: 'get',
  });
};

export const useGetFamilyRelationById = (id) => {
  return useQuery(['family-relation', id], () => getFamilyRelationById(id), { enabled: !!id });
};

//PUT Family Relation By ID

const updateFamilyRelation = (id, data) => {
  return customInstance({
    url: `${suffix2}/${id}`,
    method: 'put',
    data: data,
  });
};

export const useUpdateFamilyRelation = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateFamilyRelation(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['family', 'relations'] });
    },
  });
};

const createFamilyRelationLocalization = (data) => {
  return customInstance({
    url: `${suffix3}`,
    method: 'post',
    data,
  });
};

export const useCreateFamilyRelationLocalization = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createFamilyRelationLocalization(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['family', 'relations'] });
    },
  });
};

const updateFamilyRelationLocalization = (id, data) => {
  return customInstance({
    url: `${suffix2}/${id}`,
    method: 'put',
    data: data,
  });
};

export const useUpdateFamilyRelationLocalization = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateFamilyRelationLocalization(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['family', 'relations'] });
    },
  });
};
