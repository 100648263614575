import { Box, Divider, IconButton, Typography } from '@mui/material';
import React from 'react';
import ArticleEditorForm from './ArticleEditorForm';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useGetAllLanguages } from '../../../../api/articles';
import { useGetArchiveByType } from '../../../../api/archive';

const ArticleEditor = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: languages } = useGetAllLanguages();
  const { data: archives } = useGetArchiveByType('articles');
  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" px={1} py={2.45}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" fontWeight={700} px={3}>
            {t('articleEditor')}
          </Typography>
        </Box>
        <Divider />
        <Box p={5}>
          <Box display="flex" flexDirection="column">
            <ArticleEditorForm languages={languages} archives={archives} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ArticleEditor;
