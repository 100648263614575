import { createColumnHelper } from '@tanstack/react-table';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useBrokersColumns = () => {
  const columnHelper = createColumnHelper();
  const { t } = useTranslation();
  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          #
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.firstName, {
      id: 'firstName',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('firstname')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.lastName, {
      id: 'lastName',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('lastname')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.email, {
      id: 'email',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('email')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.phoneNumber, {
      id: 'phoneNumber',
      cell: (info) => <Typography>{info.getValue()}</Typography>,

      header: () => <Typography fontWeight={700}>{t('phone.number')}</Typography>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.zipCode, {
      id: 'zipCode',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => <Typography fontWeight={700}>{t('zip')}</Typography>,
      footer: (info) => info.column.id,
    }),
  ];
  return columns;
};

export default useBrokersColumns;
