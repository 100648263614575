import { useTranslation } from 'react-i18next';
import { useCreateLeadType } from '../api/leadTypes';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useToastNotification from './useToastNotification';

export const useLeadTypes = (handleDrawerClose) => {
  const { t } = useTranslation();
  const { mutate, isSuccess, isLoading, isError } = useCreateLeadType();
  const schema = yup
    .object()
    .shape({
      locals: yup.object().shape({
        eng: yup.string().required(t('required_field', { field: t('english_label') })),
        de: yup.string().required(t('required_field', { field: t('deutsch_label') })),
      }),
    })
    .required();

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      type: '',
      locals: {
        eng: '',
        de: '',
      },
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessfullyUsingLeadTypes',
    variant: 'success',
    cb: () => {
      reset();
      handleDrawerClose();
    },
  });
  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorUsingLeadTypes',
    variant: 'error',
  });

  const onSubmit = async (data) => {
    mutate({ data: { type: data.locals.de, locals: data.locals } });
  };
  return {
    control,
    handleSubmit,
    onSubmit,
  };
};
