import { createSlice, current } from '@reduxjs/toolkit';
import { login, logout, setUser, updateUser, updateUserData, updateUserFiles } from './authActions';

const initialState = {
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  isLoading: false,
  error: null,
  roles: [],
  currentUser: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.currentUser = action.payload.currentUser;
        state.roles = action.payload.currentUser.roles;
        state.error = null;
        state.isLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isAuthenticated = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
        state.isAuthenticated = false;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.currentUser = { ...action.payload };
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isAuthenticated = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
        state.isAuthenticated = false;
      })
      .addCase(logout, (state) => {
        state.currentUser = null;
        state.accessToken = null;
        state.refreshToken = null;
      })
      .addCase(updateUserFiles, (state, action) => {
        state.currentUser.files = [...current(state.currentUser.files), action.payload];
      })
      .addCase(updateUserData, (state, action) => {
        const newUser = { ...state.currentUser, ...action.payload };
        state.currentUser = newUser;
      })
      .addCase(setUser, (state, action) => {
        state.accessToken = action.payload.accessToken;
        state.currentUser = action.payload;
        state.roles = action.payload.roles;
        state.error = null;
        state.isLoading = false;
        state.isAuthenticated = true;
      });
  },
  reducers: {},
});

const { reducer } = authSlice;
export default reducer;
