/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Box from '@mui/material/Box';
import { useDeleteFamilyMember, useGetFamilyMemberByUserId } from '../../api/familyMembers';
import TableComponent from '../Table/Table';
import useFamilyMemberColumn from '../../hooks/AllUsers/useFamilyMemeberColumns';
import { IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import FamilyMembers from './FamilyMembers';
import CustomDrawer from '../modals/CustomDrawer';
import EditFamilyMembers from './EditFamilyMember';
import DeleteModal from '../modals/DeleteModal';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useTranslation } from 'react-i18next';
import VerifyLoader from '../../components/verifyLoader/VerifyLoader';
import useToastNotification from '../../hooks/useToastNotification';
import SubmitButton from '../commonComponents/SubmitButton';

const FamilyMemberTable = () => {
  const { t } = useTranslation();
  const { id } = useCurrentUser();
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetFamilyMemberByUserId(id);
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [editFamilyMemberId, setEditFamilyMemberId] = useState(null);
  const { mutate: deleteFamilyMember, isSuccess, isLoading, isError } = useDeleteFamilyMember();

  const handleOpen = () => {
    setOpenAddDrawer(!openAddDrawer);
  };
  const handleDeleteModal = (id) => {
    setOpenDelete(!openDelete);
    if (typeof id === 'string') {
      setEditFamilyMemberId(id);
    } else {
      setEditFamilyMemberId(null);
    }
  };

  const handleOpenEdit = (id) => {
    setOpenEditDrawer(!openEditDrawer);
    if (typeof id === 'string') {
      setEditFamilyMemberId(id);
    } else {
      setEditFamilyMemberId(null);
    }
  };
  const handleDelete = (id) => {
    deleteFamilyMember({ id });
    setOpenDelete(!openDelete);
  };

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'Successfully Deleted Family Member',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorDeletingFamilyMember',
    variant: 'error',
  });

  const columns = useFamilyMemberColumn(handleOpenEdit, handleDeleteModal);
  return (
    <>
      <Box width="100%">
        {isLoadingData || isFetchingData ? (
          <Box width="100%" display="flex" justifyContent="center">
            <VerifyLoader />
          </Box>
        ) : (
          data && (
            <TableComponent data={data} columns={columns} hasAboveStuff>
              <SubmitButton onClick={handleOpen} buttonText={t('AddNew')} backgroundColor="#31B099" color="inherit" />
            </TableComponent>
          )
        )}
      </Box>
      {/* Add Drawer */}
      <CustomDrawer open={openAddDrawer} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddFamilyMember')}
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <FamilyMembers handleDrawerClose={handleOpen} />
      </CustomDrawer>

      {/* Edit Drawer */}
      <CustomDrawer open={openEditDrawer} handleOpen={handleOpenEdit}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('EditFamilyMember')}
          </Typography>
          <IconButton onClick={handleOpenEdit}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <EditFamilyMembers id={editFamilyMemberId} handleDrawerClose={handleOpenEdit} />
      </CustomDrawer>

      {/* Delete Modal */}
      <DeleteModal
        handleDeleteModal={handleDeleteModal}
        openDelete={openDelete}
        headingText={t('DeleteFamilyMembers')}
        bodyText={t('AreYouSureFamilyMember')}
        handleDelete={() => handleDelete(editFamilyMemberId)}
      />
    </>
  );
};

export default FamilyMemberTable;
