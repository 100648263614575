import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentCard from '../dashboardComponents/DocumentCard';
import { downloadFilesAsZip } from '../../features/ZipDownloadUtil';
import { format } from 'date-fns';
import SmallButton from '../commonComponents/SmallButton';
import TableContainer from '../Table/TableContainer';

const BrokerUserFiles = ({ username, files }) => {
  const { t } = useTranslation();
  const handleDownload = () => {
    const fileUrls = files.map((file) => file.url);
    downloadFilesAsZip(fileUrls, username, format(new Date(), 'dd.MM.yyyy'));
  };
  return (
    <TableContainer sx={{ p: 2 }}>
      <Typography variant="h6">{t('UploadedDocuments')}</Typography>
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" gap="16px">
        <Box>
          <SmallButton
            iconUrl="https://taxofficestorage.blob.core.windows.net/media/arrow-down.svg"
            onClick={handleDownload}
            altText="Download Icon"
          />
        </Box>
      </Box>

      {files?.length > 0 &&
        files?.map((file) => {
          return (
            <Box key={file.id} width="100%">
              <DocumentCard {...file} />
            </Box>
          );
        })}
    </TableContainer>
  );
};

export default BrokerUserFiles;
