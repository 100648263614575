import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAddTermins } from '../../../../hooks/useAddTermins';
import SubmitButton from '../../../commonComponents/SubmitButton';
import CustomFormInput from '../../../commonComponents/CustomFormInput';
import CustomSelect from '../../../commonComponents/CustomSelect';
import TableContainer from '../../../Table/TableContainer';

const Termins = ({ id, defaultBrokerValue, isAdmin, brokers }) => {
  const { t } = useTranslation();

  const { handleSubmit, control, onSubmit } = useAddTermins(id, defaultBrokerValue);

  return (
    <TableContainer sx={{ p: 2 }}>
      <Typography variant="h6">{t('Appointments')}</Typography>
      <Box display="flex" gap="20px">
        <CustomFormInput name="from" type="datetime-local" control={control} label={t('From')} />
        <CustomFormInput name="interest" label={t('Interest')} control={control} />
      </Box>
      {isAdmin && (
        <CustomSelect
          control={control}
          name="broker"
          label={t('Broker')}
          data={brokers?.results}
          valueField={(item) => item.id}
          displayField={(item) => `${item.firstName} ${item.lastName}`}
        />
      )}
      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </TableContainer>
  );
};

export default Termins;
