import React, { useState } from 'react';
import TableComponent from '../../../Table/Table';
import Header from './components/Header';

const TaskTable = ({
  tasks,
  columns,
  allTasks,
  handleNextPage,
  handlePrevPage,
  handleLimit,
  setPage,
  isLoading,
  isFetching,
  filterTag,
  handleChange,
  MenuProps,
  tags,
  control,
  debouncedHandleSearchChange,
  setLimit,
  onRowClick,
  cursor,
  brokers,
  rowSelection,
  setRowSelection,
}) => {
  const [originalSelectedRows, setOriginalSelectedRows] = useState();
  return (
    <TableComponent
      hasAboveStuff
      data={tasks}
      columns={columns}
      hasPagination
      rowsPerPage={allTasks?.limit}
      page={allTasks?.currentPage - 1}
      handleNextPage={() => handleNextPage(setPage)}
      handlePrevPage={() => handlePrevPage(setPage)}
      hasNextPage={allTasks?.hasNextPage}
      hasPreviousPage={allTasks?.hasPreviousPage}
      onPageChange={(lim) => handleLimit(lim, setLimit)}
      setPage={setPage}
      isLoading={isLoading}
      isFetching={isFetching}
      total={allTasks?.total}
      cursor={cursor}
      onRowClick={onRowClick}
      getOriginalSelectedRows={setOriginalSelectedRows}
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
    >
      <Header
        setRowSelection={setRowSelection}
        originalSelectedRows={originalSelectedRows}
        brokers={brokers}
        MenuProps={MenuProps}
        control={control}
        debouncedHandleSearchChange={debouncedHandleSearchChange}
        filterTag={filterTag}
        handleChange={handleChange}
        tags={tags}
      />
    </TableComponent>
  );
};

export default TaskTable;
