const findLocalsOrUseFirst = (locals, code) => {
  const foundIndex = locals?.findIndex((local) => local.language === code);
  if (foundIndex < 0) {
    if (locals.length > 0) {
      return locals[0];
    }
  }
  return locals?.[foundIndex];
};

export default findLocalsOrUseFirst;
