import {
  Box,
  Button,
  Divider,
  ListItemText,
  OutlinedInput,
  FormControl,
  MenuItem,
  Select,
  Typography,
  InputLabel,
  IconButton,
} from '@mui/material';
import React from 'react';
import TableComponent from '../components/Table/Table';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBrokerUsers } from '../api/user';
import useChangeEffect from '../hooks/useChangeEffect';
import CustomTextField from '../components/commonComponents/CustomTextField';
import { useNavigate } from 'react-router-dom';
import CustomDrawer from '../components/modals/CustomDrawer';
import CloseIcon from '@mui/icons-material/Close';
import RegistrationForm from '../components/registrationLayout/registrationForm';
import { debounce } from 'lodash-es';
import { tags } from '../components/adminDashboard/users/tags';
import { handleLimit, handleNextPage, handlePrevPage } from '../utils/paginationFunction';
import LanguageSelect from '../components/selectTranslation/translateSelect';
import { useSelector } from 'react-redux';
import useBrokerUsersColumns from '../hooks/AllUsers/useBrokerUsersColumns';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BrokerUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.auth?.currentUser);

  const [keyword, setKeyword] = useState('');
  const [filterTag, setFilterTag] = useState(['search']);
  const [filterTagData, setFilterTagData] = useState({});
  const [openAddDrawer, setOpenAddDrawer] = useState(false);

  const handleOpen = () => {
    setOpenAddDrawer(!openAddDrawer);
  };

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);

  const { data: allUsers, isLoading, isFetching } = useBrokerUsers(user?.id, { page, limit, ...filterTagData });
  const users = allUsers?.data?.results;

  const columns = useBrokerUsersColumns();

  const { control } = useForm({
    mode: 'onSubmit',

    reValidateMode: 'onChange',
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterTag(typeof value === 'string' ? value.split(',') : value);
  };

  const handleRowClick = (row) => {
    navigate(`/broker/users/${row?.id}`);
  };

  const updateFilterTagData = () => {
    const dynamicFilterTag = filterTag;
    const dynamicKeyword = keyword;

    setFilterTagData({ [dynamicFilterTag]: dynamicKeyword });
  };

  useChangeEffect(() => {
    updateFilterTagData();
  }, [filterTag, keyword]);

  const debouncedHandleSearchChange = debounce((value) => {
    setKeyword(value);
    setPage(0);
  }, 300);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center" px={4}>
            <Typography variant="h6" fontWeight={700} px={5} py={2.45} sx={{ marginLeft: -4 }}>
              {t('AllUsers')}
            </Typography>
            <LanguageSelect />
          </Box>
          <Divider />

          <Box m={5}>
            {users && (
              <TableComponent
                hasAboveStuff
                data={users}
                columns={columns}
                hasPagination
                rowsPerPage={allUsers?.data?.limit}
                page={allUsers?.data?.currentPage - 1}
                handleNextPage={() => handleNextPage(setPage)}
                handlePrevPage={() => handlePrevPage(setPage)}
                hasNextPage={allUsers?.data?.hasNextPage}
                hasPreviousPage={allUsers?.data?.hasPreviousPage}
                onPageChange={(lim) => handleLimit(lim, setLimit)}
                setPage={setPage}
                isLoading={isLoading}
                isFetching={isFetching}
                total={allUsers?.data?.total}
                onRowClick={handleRowClick}
                cursor="pointer"
              >
                <Box display="flex" alignItems="center">
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: '#31B099',
                      '&:hover': {
                        backgroundColor: '#0F5D66',
                      },
                      textTransform: 'none',
                      borderRadius: '25px',
                      width: 'fit-content',
                      paddingY: '10px',
                      paddingX: '25px',
                      display: 'flex',
                      gap: '10px',
                    }}
                    onClick={handleOpen}
                  >
                    <Typography fontWeight={700}>{t('AddNew')}</Typography>
                    <img alt="Menu Icon" src={'https://taxofficestorage.blob.core.windows.net/media/add.svg'}></img>
                  </Button>
                </Box>
                <Box display="flex" gap="20px" alignItems="center">
                  <FormControl sx={{ width: 200 }}>
                    <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                    <Select
                      labelid="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      value={filterTag}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" sx={{ border: '1px solid #DCE4E8', borderRadius: '15px' }} />}
                      renderValue={(selected) => {
                        const selectedTag = tags.find((tag) => tag.value === selected[0]);
                        return selectedTag ? selectedTag.label : '';
                      }}
                      MenuProps={MenuProps}
                    >
                      {tags.map((tag) => (
                        <MenuItem key={tag.value} value={tag.value}>
                          <ListItemText primary={tag.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Controller
                    name="search"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Box display="flex" flexDirection="column">
                        <CustomTextField
                          id="search"
                          value={value ? value : ''}
                          label="Search"
                          onChange={(event) => {
                            onChange(event);
                            debouncedHandleSearchChange(event.target.value);
                          }}
                        />
                      </Box>
                    )}
                  />

                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: '#fff',
                      color: '#000',
                      fontWeight: 700,
                      textTransform: 'none',
                      borderRadius: '25px',
                      width: 'fit-content',
                      paddingY: '16px',
                      paddingX: '25px',
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <img alt="Menu Icon" src={'https://taxofficestorage.blob.core.windows.net/media/export.svg'}></img>
                    <Typography fontWeight={700}>{t('Export')}</Typography>
                  </Button>
                </Box>
              </TableComponent>
            )}
          </Box>
        </Box>
      </Box>

      {/* Add Drawer */}
      <CustomDrawer open={openAddDrawer} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddUser')}
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <RegistrationForm source="AddUserForm" handleDrawerClose={handleOpen} />
      </CustomDrawer>
    </>
  );
};

export default BrokerUsers;
