import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import PhoneInput from './PhoneInput/PhoneInput';

const CorePhoneInput = forwardRef((props, ref) => {
  const { name = 'phone', control } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <PhoneInput onChange={onChange} value={value} error={error} name={name} />
        </>
      )}
    />
  );
});

export default CorePhoneInput;
