import { Button, Modal, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DeleteModal = ({ openDelete, handleDeleteModal, headingText, bodyText, handleDelete }) => {
  const { t } = useTranslation();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px',
    p: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
    width: '500px',
  };
  return (
    <Modal
      open={openDelete}
      onClose={handleDeleteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center',
            bgcolor: '#C65468',
            borderRadius: '200px',
            width: '100px',
            height: '100px',
            boxShadow: '0px 24px 34px -10px #852E3D4D',

            '& img': {
              filter: 'brightness(1000)',
              width: '50px',
              height: '50px',
            },
          }}
        >
          <img alt="Tab Icon" src={'https://taxofficestorage.blob.core.windows.net/media/trashfill.svg'}></img>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" gap="10px">
          <Typography variant="h5">{headingText}</Typography>
          <Typography variant="body2" fontWeight={600} color="#6C7278" textAlign="center">
            {bodyText}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" flexDirection="row" width="100%" gap="25px">
          <Button
            variant="contained"
            sx={{
              bgcolor: '#fff',
              color: '#187D7E',
              textTransform: 'none',
              paddingY: '10px',
              paddingX: '25px',
              border: '1px solid #DCE4E8',
              borderRadius: ' 14px',
              width: '100%',
              '&:hover': {
                bgcolor: 'rgba(255,255,255,0.7)',
              },
            }}
            onClick={handleDeleteModal}
          >
            <Typography variant="button" color="#000" fontWeight={700}>
              {t('Cancel')}
            </Typography>
          </Button>
          <Button
            variant="contained"
            sx={{
              bgcolor: '#31B099',
              color: '#fff',
              textTransform: 'none',
              paddingY: '15px',
              paddingX: '25px',
              border: '1px solid #31B099',
              borderRadius: ' 14px',
              width: '100%',

              '&:hover': {
                bgcolor: '#31B09999',
              },
            }}
            onClick={handleDelete}
          >
            <Typography variant="button" color="#fff" fontWeight={700}>
              {t('Yes')}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
