import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const downloadFilesAsZip = async (fileUrls, zipFileName, date) => {
  const zip = new JSZip();
  const folder = zip.folder(`${zipFileName}_${date}`);

  await Promise.all(
    fileUrls.map(async (fileUrl) => {
      const response = await fetch(fileUrl);
      const data = await response.blob();
      const fileName = getFilenameFromUrl(fileUrl);
      folder.file(fileName, data);
    }),
  );
  const zipData = await zip.generateAsync({ type: 'blob' });

  saveAs(zipData, `${zipFileName}_${date}.zip`);
};

const getFilenameFromUrl = (url) => {
  const parts = url.split('/');
  return parts[parts.length - 1];
};
