import { Box, Button } from '@mui/material';
import React from 'react';

const ButtonPicker = ({ options, value, onChange, isMulti }) => {
  return (
    <Box display="flex" gap={1} flexWrap="wrap">
      {options.map((option) => {
        const isActive = isMulti ? !!value.find((opt) => opt.value === option.value) : value === option.value;
        return (
          <Button
            key={option.value}
            sx={{
              padding: '12px 24px',
              outline: isActive ? '1px solid #31B099' : '1px solid #DCE4E8',
              borderRadius: '14px',
              color: isActive ? '#fff' : 'rgb(0,0,0)',
              backgroundColor: isActive ? '#31B099' : '#',
              '&:focus; &:hover': {
                outline: '2px solid #31B099',
                color: '#31B099',
              },
            }}
            variant="outlined"
            onClick={() => {
              if (onChange) {
                if (isMulti) {
                  if (value.find((opt) => opt.value === option.value)) {
                    onChange(value.filter((opt) => opt.value !== option.value));
                  } else {
                    onChange([...value, option]);
                  }
                } else {
                  onChange(option);
                }
              }
            }}
          >
            {option.label}
          </Button>
        );
      })}
    </Box>
  );
};

export default ButtonPicker;
