import { useSnackbar } from 'notistack';

export const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  const show = ({ message, variant, anchorOrigin }) => {
    enqueueSnackbar(message, { variant, anchorOrigin, autoHideDuration: 2000  });
  };

  return {
    toast: show,
  };
};
