import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './auth';
import dashboardReducer from './dashboard/dashboardSlice';

const persistConf = {
  key: 'root',
  storage,
  blacklist: ['auth'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
});

export default persistReducer(persistConf, rootReducer);
