import React, { useState } from 'react';
import { Chip, Menu } from '@mui/material';
import findLocalsOrUseFirst from '../../utils/findLocalsOrUseFirst';

const StatusMenu = ({ data, statuses, i18n, mutate }) => {
  const getTextColor = (backgroundColor) => {
    const hexColor = backgroundColor.slice(1);
    const r = parseInt(hexColor.slice(0, 2), 16);
    const g = parseInt(hexColor.slice(2, 4), 16);
    const b = parseInt(hexColor.slice(4, 6), 16);

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness > 128 ? '#364c5c' : '#fff';
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Chip
        label={data && findLocalsOrUseFirst(data?.status?.locals, i18n?.language)?.name}
        sx={{
          bgcolor: data?.status?.color,
          color: '#fff',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            borderRadius: '15px',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          },
        }}
      >
        {statuses?.map((status, index) => (
          <Chip
            key={index}
            label={findLocalsOrUseFirst(status?.locals, i18n.language)?.name}
            sx={{
              bgcolor: status.color,
              color: getTextColor(status.color),
            }}
            onClick={() => {
              mutate({ id: data?.id, statusId: status?.id });
              handleClose();
            }}
          />
        ))}
      </Menu>
    </>
  );
};

export default StatusMenu;
