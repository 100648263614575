/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useCreateFamilyMember, useGetAllFamilyRelations } from '../api/familyMembers';
import useToastNotification from './useToastNotification';

export const useFamilyMember = (handleDrawerClose) => {
  const { t } = useTranslation();
  const { mutate, isSuccess, isLoading, isError } = useCreateFamilyMember();
  const { data } = useGetAllFamilyRelations();
  const famRelationArr = data?.map((arType) => arType.id);

  const addFamilyRelationSchema = yup
    .object()
    .shape({
      firstName: yup.string().required(t('firstName.required')),
      lastName: yup.string().required(t('lastName.required')),
      birthDate: yup.date().required(t('birthDate.required')),
      familyRelation: famRelationArr && yup.string().oneOf(famRelationArr).required(t('familyRelation.required')),
    })
    .required();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      familyRelation: '',
      birthDate: '',
    },
    resolver: yupResolver(addFamilyRelationSchema),
  });

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessFamilyMember',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorFamilyMember',
    variant: 'error',
  });

  const onSubmit = async (data) => {
    handleDrawerClose();
    mutate({ data });
    reset();
  };

  return {
    handleSubmit,
    onSubmit,
    control,
    errors,
  };
};
