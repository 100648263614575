import { Box, Typography } from '@mui/material';
import SuccessIcon from '../../../../../assets/icons/check.png';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../commonComponents/CustomButton';

const IconContainer = ({ t }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: 300,
        bgcolor: 'white',
        zIndex: '1001',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '32px',
        gap: '32px',
        borderRadius: { md: '15px' },
        boxShadow: '0px 34px 114px rgba(0, 0, 0, 0.08)',
        width: { xs: '100%', md: '480px' },
        flex: { xs: 1 },
      }}
    >
      <Typography textAlign="center" color="#31B099" variant="h6" fontWeight="bold" fontFamily="Megante">
        {t('thank_you_for_your_submission_info')}
      </Typography>
      <Box
        sx={{
          backgroundImage: `url(${SuccessIcon})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover', // This stretches the background to fill the container
          height: 256,
          width: 256,
        }}
      />
      <CustomButton
        onClick={() => {
          navigate('/');
        }}
      >
        www.digitialesbuero.ch
      </CustomButton>
    </Box>
  );
};

export default IconContainer;
