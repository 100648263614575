export const handleLimit = (lim, setLimit) => {
  setLimit(lim);
};

export const handleNextPage = (setPage) => {
  setPage((prev) => prev + 1);
};

export const handlePrevPage = (setPage) => {
  setPage((prev) => prev - 1);
};
