import React from 'react';
import InfoField from './InfoField';
import { Box } from '@mui/material';

const WorkAndTransportInfo = ({ data }) => {
  return (
    <>
      <Box display="flex" flexDirection="row" width="100%" gap="10px">
        <InfoField labelKey="ManWorkAddress" value={data?.info.manWorkAddress ? data?.info.manWorkAddress : '/'} />
        <InfoField
          labelKey="HusbandTransport"
          value={data?.info.husbandTransport ? data?.info.husbandTransport : '/'}
        />
      </Box>
      <Box display="flex" flexDirection="row" width="100%" gap="10px">
        <InfoField labelKey="WifeWorkAddress" value={data?.info.wifeWorkAddress ? data?.info.wifeWorkAddress : '/'} />
        <InfoField labelKey="WifeTransport" value={data?.info.wifeTransport ? data?.info.wifeTransport : '/'} />
      </Box>
    </>
  );
};

export default WorkAndTransportInfo;
