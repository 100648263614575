import { useMutation, useQuery, useQueryClient } from 'react-query';
import { customInstance } from './client';

const suffix = '/v1/archive';
const suffix2 = '/v1/archivetypes';

//GET Archives
export const getAllArchives = () => {
  return customInstance({
    url: `${suffix}`,
    method: 'get',
  });
};

export const useGetAllArchives = () => {
  return useQuery(['archives'], async () => {
    const { data } = await getAllArchives();
    return data;
  });
};

//GET Archive Types
export const getAllArchiveTypes = () => {
  return customInstance({
    url: `${suffix2}`,
    method: 'get',
  });
};

export const useGetAllArchiveTypes = (enabled = true) => {
  return useQuery(
    ['archive-types'],
    async () => {
      const { data } = await getAllArchiveTypes();
      return data;
    },
    { enabled, refetchOnWindowFocus: false },
  );
};

//POST Archive
export const createArchive = (data) => {
  return customInstance({
    url: `${suffix}`,
    method: 'post',
    data,
  });
};

export const useCreateArchive = () => {
  const queryClient = useQueryClient();
  return useMutation(({ data }) => createArchive(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['archives'] });
    },
  });
};

//POST Archive Type

export const createArchiveType = async (data) => {
  return customInstance({
    url: `${suffix2}`,
    method: 'post',
    data,
  });
};

export const useCreateArchiveType = () => {
  const queryClient = useQueryClient();
  return useMutation(({ data }) => createArchiveType(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['archive-types'] });
    },
  });
};

//GET By ID

export const getArchiveById = (id) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'get',
  });
};

export const useGetArchiveById = (id) => {
  return useQuery(['archive', id], () => getArchiveById(id), { enabled: !!id });
};

//PUT Archive

export const updateArchive = (id, data) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'put',
    data: data,
  });
};

export const useUpdateArchive = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateArchive(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['archives'] });
    },
  });
};

//GET Archive Type By Id

export const getArchiveTypeById = (id) => {
  return customInstance({
    url: `${suffix2}/${id}`,
    method: 'get',
  });
};

export const useGetArchiveTypeById = (id) => {
  return useQuery(['archive-type', id], () => getArchiveTypeById(id), { enabled: !!id });
};

//PUT Archive Type

export const updateArchiveType = (id, data) => {
  return customInstance({
    url: `${suffix2}/${id}`,
    method: 'put',
    data: data,
  });
};

export const useUpdateArchiveType = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateArchiveType(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['archive-types'] });
    },
  });
};

//GET Archive By Type

export const getArchiveByType = (archiveType) => {
  return customInstance({
    url: `${suffix}/type/${archiveType}`,
    method: 'get',
  });
};

export const useGetArchiveByType = (archiveType) => {
  return useQuery(['archive', archiveType], () => getArchiveByType(archiveType), {
    enabled: !!archiveType,
    refetchOnWindowFocus: false,
  });
};

// CREATE Archive Localizations

export const createArchiveLocalization = (data) => {
  return customInstance({ url: '/archive-localizations', method: 'post', data });
};

export const createArchiveTypesLocals = (data) =>
  customInstance({
    url: '/archive-type-locals',
    method: 'post',
    data,
  });

export const useCreateArchiveTypeLocal = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createArchiveTypesLocals(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['archive-types'] });
    },
  });
};
