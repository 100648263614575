import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import MenuDropdown from '../../components/adminDashboard/MenuDropdown';
import { format, setMinutes } from 'date-fns';

const useTerminSlotsColumns = (handleDeleteModal, handleEdit) => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper();
  const menuItems = [
    {
      label: t('Edit'),
      icon: 'https://taxofficestorage.blob.core.windows.net/media/edit-2.svg',
      onClick: (row) => {
        const labelEng = row.locals?.find((val) => val.language === 'eng');
        const labelDe = row.locals?.find((val) => val.language === 'de');
        handleEdit({ ...row, label: { eng: labelEng?.label, de: labelDe?.label } });
      },
    },
    {
      label: t('Delete'),
      icon: 'https://taxofficestorage.blob.core.windows.net/media/trash.svg',
      onClick: (row) => handleDeleteModal(row.id),
    },
  ];
  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          #
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.locals, {
      id: 'locals.eng',
      cell: (info) => {
        const label = info.getValue()?.find((val) => val.language === 'eng');
        return <Typography>{label ? label?.label : t('missing')}</Typography>;
      },
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('English')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.locals, {
      id: 'locals.de',
      cell: (info) => {
        const label = info.getValue()?.find((val) => val.language === 'de');
        return <Typography>{label ? label?.label : t('missing')}</Typography>;
      },
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Deutsch')}
        </Typography>
      ),
    }),
    columnHelper.accessor(
      (row) => ({
        fromHour: row.from.hour,
        fromMinute: row.from.minute,
      }),
      {
        id: 'from',
        cell: (info) => {
          const { fromHour, fromMinute } = info.getValue();
          return <Typography>{format(setMinutes(new Date().setHours(fromHour), fromMinute), 'HH:mm')}</Typography>;
        },
        header: () => (
          <Typography variant="body1" fontWeight={700}>
            {t('FromHourMinute')}
          </Typography>
        ),
      },
    ),
    columnHelper.accessor(
      (row) => ({
        toHour: row.to.hour,
        toMinute: row.to.minute,
      }),
      {
        id: 'to',
        cell: (info) => {
          const { toHour, toMinute } = info.getValue();
          return <Typography>{format(setMinutes(new Date().setHours(toHour), toMinute), 'HH:mm')}</Typography>;
        },
        header: () => (
          <Typography variant="body1" fontWeight={700}>
            {t('ToHourMinute')}
          </Typography>
        ),
      },
    ),

    columnHelper.accessor('action', {
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Action')}
        </Typography>
      ),
      cell: (info) => {
        return (
          <MenuDropdown
            id={info.row.original.id}
            menuItems={menuItems.map((item) => ({ ...item, onClick: () => item.onClick(info.row.original) }))}
          />
        );
      },
      footer: (info) => info.column.id,
    }),
  ];
  return columns;
};

export default useTerminSlotsColumns;
