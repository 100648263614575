import { Box, Button, CircularProgress, FormHelperText, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useVerifyToken } from '../api/auth';
import { useToast } from '../hooks/useToast';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../store/auth/authActions';
import useUrlQueryParams from '../hooks/useUrlQueryParams';
import { useTranslation } from 'react-i18next';
import LogoLink from '../components/commonComponents/LogoLink';

const Verify = () => {
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const urlParams = useUrlQueryParams();
  const redirect = urlParams.get('redirect');
  const [otp, setOtp] = useState('');
  const { state } = useLocation();
  const { email, password } = state;
  const { mutate, isError, error, isLoading, isSuccess } = useVerifyToken();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      otp: '',
    },
  });

  const onSubmit = ({ otp }) => {
    setOtp(otp);
    mutate({ email, otp });
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(login({ email, password, otp }));
    }
  }, [dispatch, email, isLoading, isSuccess, otp, password]);

  useEffect(() => {
    if (user.accessToken) {
      if (redirect) {
        navigate(redirect, { replace: true });
      } else {
        navigate(`/dashboard`, { replace: true });
      }
    }
  }, [navigate, redirect, user.accessToken]);

  useEffect(() => {
    if (isError || user.error) {
      const errorMessage =
        error?.response?.data?.message ?? user.error ?? 'Something went wrong! Please try again later.';
      toast({
        variant: 'danger',
        message: errorMessage,
      });
    }
  }, [error?.response?.data?.message, isError, toast, user.error]);

  return (
    <Box pt={10} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      {isLoading ? (
        <Box minHeight="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <LogoLink src="https://taxofficestorage.blob.core.windows.net/media/greenLogo.svg" alt="Your logo" />
          <Typography variant="h6" fontWeight={700} textAlign="center" paddingY={5}>
            {t('otpCodeMsg')}
          </Typography>
          <Box display="flex" justifyContent="center" alignItems="center" py={5}>
            <Controller
              name="otp"
              control={control}
              render={({ field, fieldState }) => (
                <Box textAlign="center" maxWidth={500}>
                  <MuiOtpInput sx={{ gap: 1 }} {...field} length={6} onComplete={handleSubmit(onSubmit)} />
                  {fieldState.invalid ? <FormHelperText error>OTP invalid</FormHelperText> : null}
                </Box>
              )}
            />
          </Box>
          <Box display="flex" w="100%" justifyContent="center">
            <Button onClick={() => navigate('/auth')}>
              <Typography variant="h6" sx={{ borderBottom: '1px solid rgba(0,0,0,0.7)' }} color="rgba(0,0,0,0.7)">
                {t('goBack')}
              </Typography>
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Verify;
