import { Button, styled } from '@mui/material';
import React from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const UploadButton = ({ onFileChange }) => {
  return (
    <Button
      component="label"
      variant="contained"
      color="inherit"
      sx={{
        color: '#fff',
        bgcolor: '#31B099',
        '&:hover': {
          backgroundColor: '#0F5D66',
        },
        textTransform: 'none',
        borderRadius: '15px',
        width: 'fit-content',
        paddingY: '10px',
        paddingX: '25px',
        marginTop: '10px',
      }}
      startIcon={<CloudUploadIcon />}
    >
      Upload file
      <VisuallyHiddenInput type="file" onChange={onFileChange} />
    </Button>
  );
};

export default UploadButton;
