import React from 'react';
import PageBackground from '../components/LeadPage/PageBackground';
import Card from '../components/ThankYou/Card';
import { useTranslation } from 'react-i18next';

const ThankYou = () => {
  window.dataLayer.push({
    event: 'pageview',
  });
  const { t } = useTranslation();
  return (
    <PageBackground>
      <Card t={t} />
    </PageBackground>
  );
};

export default ThankYou;
