/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCreateNote } from '../api/notes';
import useToastNotification from './useToastNotification';

export const useAddNotes = (id, handleDrawerClose) => {
  const { mutate, isSuccess, isLoading, isError } = useCreateNote();
  const schema = yup.object().shape({
    note: yup.string().required('Notes'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      note: '',
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'successAddedNotes',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'errorAddingNotes',
    variant: 'error',
  });

  const onSubmit = async (data) => {
    mutate({ lead: id, note: data.note });
    reset();
    handleDrawerClose();
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    isLoading,
    errors,
  };
};
