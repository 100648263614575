import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React from 'react';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import './footer.css';
import SocialIcons from './SocialIcons/SocialIcons';

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flex: 1,
    marginY: 3,
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: ' 80px 48px',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flex: 1,
    marginY: 3,
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    paddingBottom: '50px',
    paddingTop: '50px',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    flex: 1,
    marginY: 3,
    justifyContent: 'space-evenly',

    flexDirection: 'row',
  },
  [theme.breakpoints.up('xl')]: {
    display: 'flex',
    flex: 1,
    marginY: 3,
    justifyContent: 'space-evenly',

    flexDirection: 'row',
  },
  [theme.breakpoints.up('xxl')]: {
    display: 'flex',
    flex: 1,
    marginY: 3,
    flexDirection: 'row',
  },
}));

const Footer = () => {
  const { t } = useTranslation();
  const today = new Date();
  return (
    <>
      <Container maxWidth="xxl" disableGutters sx={{ backgroundColor: '#1A1C1E' }}>
        <StyledBox>
          <Box
            display="flex"
            flexDirection="column"
            gap="40px"
            justifyContent="center"
            alignItems="center"
            flexGrow={2}
          >
            <Box
              component="img"
              sx={{
                height: 50,
                objectFit: 'contain',
              }}
              alt="Your logo."
              src={'https://taxofficestorage.blob.core.windows.net/media/whiteLogo.svg'}
            ></Box>
            <SocialIcons />
          </Box>
          <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            alignItems={'flex-start'}
            gap={{ xs: '5px', md: '10px' }}
            width={{ xs: '100%', md: 'auto' }}
          >
            <Typography variant="h6" color="#fff" paddingTop={3}>
              {t('Useful.links')}
            </Typography>

            <Link to="/auth/login" style={{ textDecoration: 'none' }}>
              <Typography variant="body1" color="#fff" lineHeight="24px">
                {t('Login')}
              </Typography>
            </Link>

            <Link to="/auth/register" style={{ textDecoration: 'none' }}>
              <Typography variant="body1" color="#fff" lineHeight="24px">
                {t('Register')}
              </Typography>
            </Link>

            <Link to="/legal" style={{ textDecoration: 'none' }}>
              <Typography variant="body1" color="#fff" lineHeight="24px">
                {t('Legal')}
              </Typography>
            </Link>
            <Link to="/tac" style={{ textDecoration: 'none' }}>
              <Typography variant="body1" color="#fff" lineHeight="24px">
                {t('Terms.conditions')}
              </Typography>
            </Link>
            <Link to="/impressum" style={{ textDecoration: 'none' }}>
              <Typography variant="body1" color="#fff" lineHeight="24px">
                {t('Impressum')}
              </Typography>
            </Link>
          </Box>

          <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={{ xs: '5px', md: '10px' }}
            width={{ xs: '100%', md: 'auto' }}
          >
            <Typography variant="h6" color="#fff" paddingTop={3}>
              {t('Contact')}
            </Typography>

            <Typography variant="body1" color="#fff">
              Digitalesbüro.ch GmbH
            </Typography>
            <Typography variant="body1" color="#fff">
              Rorschacher Strasse 304
            </Typography>
            <Typography variant="body1" color="#fff">
              9016 St. Gallen
            </Typography>
            <Typography
              variant="body1"
              color="#fff"
              display="flex"
              alignItems="center"
              sx={{ justifyContent: 'flex-start' }}
            >
              <PhoneIcon />
              &emsp;Tel: 071 554 60 75
            </Typography>
            <Typography
              variant="body1"
              color="#fff"
              display="flex"
              alignItems="center"
              sx={{ justifyContent: 'flex-start' }}
            >
              <PhoneIcon />
              &emsp;Tel & Whatsapp: 076 460 05 55
            </Typography>
            <Typography
              variant="body1"
              color="#fff"
              display="flex"
              alignItems="center"
              sx={{ justifyContent: 'flex-start' }}
            >
              <EmailIcon />
              &emsp;info@digitalesbuero.ch
            </Typography>
          </Box>
        </StyledBox>
      </Container>
      <Box bgcolor="#fff">
        <Typography variant="subtitle1" color="#0f5d66" textAlign="center" paddingY="10px">
          &copy; {today.getFullYear()} Digitalesbüro.ch -{' '}
          <Link style={{ borderBottom: '1px solid', textDecoration: 'none', color: '#037A68' }} to={'/impressum'}>
            {t('Impressum')}
          </Link>
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
