import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import CropSquareRoundedIcon from '@mui/icons-material/CropSquareRounded';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

const ControlledMultipleCheckbox = ({ label, options, disabled }) => {
  return (
    <FormControl component="fieldset" disabled={disabled}>
      <FormGroup sx={{ display: 'flex', flexDirection: 'column' }}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                sx={{
                  color: '#0F5D66',
                  '&.Mui-checked': {
                    color: '#0F5D66',
                  },
                  '^.MuiFormControlLabel-label': {
                    color: '#1A1C1E',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '21px',
                    letterSpacing: '-0.02em',
                    textAlign: 'left',
                  },
                }}
                icon={<CropSquareRoundedIcon />}
                checkedIcon={<SquareRoundedIcon />}
                checked={Array.isArray(option.checkedValues) && option.checkedValues.includes(option.value)}
                onChange={option.onChange}
              />
            }
            label={label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default ControlledMultipleCheckbox;
