import React from 'react';
import InputMask from 'react-input-mask';
import CustomTextField from '../commonComponents/CustomTextField';
import { Controller } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

function DateInputComponent({ name, control, label, labelIcon }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box display="flex" flexDirection="column" width="100%">
          <Typography variant="body2" fontSize="12px" color="#ACB5BB">
            {label}
            {labelIcon}
          </Typography>
          <InputMask
            mask="99.99.9999"
            value={value}
            onChange={(e) => {
              onChange(e?.target?.value);
            }}
            maskChar={null}
          >
            {(inputProps) => (
              <CustomTextField
                {...inputProps}
                fullWidth
                placeholder="dd.MM.yyyy"
                inputProps={{ maxLength: 10, inputMode: 'numeric' }}
              />
            )}
          </InputMask>
          {error && (
            <Typography variant="caption" color="error">
              {error.message}
            </Typography>
          )}
        </Box>
      )}
    />
  );
}

export default DateInputComponent;
