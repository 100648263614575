import { Box } from '@mui/material';
import React from 'react';
import '../verifyLoader/verifyloader.css';
const VerifyLoader = () => {
  return (
    <Box display="flex" marginTop={30} flexDirection="column" alignItems="center" justifyContent="center">
      <div className="loader">
        <div className="cell d-0"></div>
        <div className="cell d-1"></div>
        <div className="cell d-2"></div>

        <div className="cell d-1"></div>
        <div className="cell d-2"></div>

        <div className="cell d-2"></div>
        <div className="cell d-3"></div>

        <div className="cell d-3"></div>
        <div className="cell d-4"></div>
      </div>
    </Box>
  );
};

export default VerifyLoader;
