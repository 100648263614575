import { Box, Button, Divider, Typography, IconButton } from '@mui/material';
import React from 'react';
import useColumns from '../hooks/AllUsers/useColumns';
import TableComponent from '../components/Table/Table';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllBrokers, useGetAllUsers } from '../api/user';
import useChangeEffect from '../hooks/useChangeEffect';
import CustomDrawer from '../components/modals/CustomDrawer';
import CloseIcon from '@mui/icons-material/Close';
import RegistrationForm from '../components/registrationLayout/registrationForm';
import { debounce } from 'lodash-es';
import { tags } from '../components/adminDashboard/users/tags';
import { handleLimit, handleNextPage, handlePrevPage } from '../utils/paginationFunction';
import LanguageSelect from '../components/selectTranslation/translateSelect';
import Filters from '../components/AllUsers/Filters';
import { useSortItems } from '../hooks/useSortItems';
import { useGetStatusByEntity } from '../api/status';
import { useNavigate } from 'react-router-dom';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AllUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState('');
  const [filterTag, setFilterTag] = useState(['search']);
  const [filterTagData, setFilterTagData] = useState({});
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [originalSelectedRows, setOriginalSelectedRows] = useState();

  const handleOpen = () => {
    setOpenAddDrawer(!openAddDrawer);
  };
  const { data: statuses } = useGetStatusByEntity('users');
  const { data: brokers } = useGetAllBrokers();
  const { setSortItem, sortIcon, sortBy } = useSortItems();
  const columns = useColumns({ brokers, setSortItem, sortIcon, statuses });

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);

  const { data: allUsers, isLoading, isFetching } = useGetAllUsers({ page, limit, ...filterTagData, sortBy });
  const users = allUsers?.results;
  const { control } = useForm({
    mode: 'onSubmit',

    reValidateMode: 'onChange',
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterTag(typeof value === 'string' ? value.split(',') : value);
  };

  const handleRowClick = (row) => {
    navigate(`/admin/users/${row?.id}`);
  };

  const updateFilterTagData = () => {
    const dynamicFilterTag = filterTag;
    const dynamicKeyword = keyword;

    setFilterTagData({ [dynamicFilterTag]: dynamicKeyword });
  };

  useChangeEffect(() => {
    updateFilterTagData();
  }, [filterTag, keyword]);

  const debouncedHandleSearchChange = debounce((value) => {
    setKeyword(value);
    setPage(0);
  }, 300);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center" px={4}>
            <Typography variant="h6" fontWeight={700} px={5} py={2.45} sx={{ marginLeft: -4 }}>
              {t('AllUsers')}
            </Typography>
            <LanguageSelect />
          </Box>
          <Divider />

          <Box m={5}>
            {users && (
              <TableComponent
                hasAboveStuff
                data={users}
                columns={columns}
                hasPagination
                rowsPerPage={allUsers?.limit}
                page={allUsers?.currentPage - 1}
                handleNextPage={() => handleNextPage(setPage)}
                handlePrevPage={() => handlePrevPage(setPage)}
                hasNextPage={allUsers?.hasNextPage}
                hasPreviousPage={allUsers?.hasPreviousPage}
                onPageChange={(lim) => handleLimit(lim, setLimit)}
                setPage={setPage}
                isLoading={isLoading}
                isFetching={isFetching}
                total={allUsers?.total}
                onRowClick={handleRowClick}
                cursor="pointer"
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                getOriginalSelectedRows={setOriginalSelectedRows}
              >
                <Box display="flex" alignItems="center">
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: '#31B099',
                      '&:hover': {
                        backgroundColor: '#0F5D66',
                      },
                      textTransform: 'none',
                      borderRadius: '25px',
                      width: 'fit-content',
                      paddingY: '10px',
                      paddingX: '25px',
                      display: 'flex',
                      gap: '10px',
                    }}
                    onClick={handleOpen}
                  >
                    <Typography fontWeight={700}>{t('AddNew')}</Typography>
                    <img alt="Menu Icon" src={'https://taxofficestorage.blob.core.windows.net/media/add.svg'}></img>
                  </Button>
                </Box>
                <Filters
                  MenuProps={MenuProps}
                  control={control}
                  debouncedHandleSearchChange={debouncedHandleSearchChange}
                  filterTag={filterTag}
                  handleChange={handleChange}
                  tags={tags}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  originalSelectedRows={originalSelectedRows}
                  brokers={brokers?.results}
                />
              </TableComponent>
            )}
          </Box>
        </Box>
      </Box>

      {/* Add Drawer */}
      <CustomDrawer open={openAddDrawer} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddUser')}
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <RegistrationForm source="AddUserForm" handleDrawerClose={handleOpen} />
      </CustomDrawer>
    </>
  );
};

export default AllUsers;
