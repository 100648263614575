import React from 'react';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';

const CounterContainer = ({ children }) => {
  return (
    <Card elevation={2} sx={{ borderRadius: '15px', mx: 5, my: 1 }}>
      <CardContent>
        <Box width="100%">
          <Typography variant="h6" fontWeight={900}>
            Overview
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box display="flex" justifyContent="space-between">
          {children}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CounterContainer;
