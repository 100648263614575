import * as yup from 'yup';
import { useGetAllIcons, useUpdateFileType } from '../api/fileTypes';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import useToastNotification from './useToastNotification';

export const useEditFileType = (id, fileType, handleDrawerClose, isChild) => {
  const { data: icons } = useGetAllIcons();
  const { mutate, isSuccess, isLoading, isError } = useUpdateFileType();
  const [defaultValuesLoaded, setDefaultValuesLoaded] = useState(false);
  let defaultValues = {};
  const iconsArr = icons?.map((icon) => icon.url);

  const editFileTypeSchema = yup.object().shape({
    name_eng: yup.string().required(),
    name_de: yup.string().required(),
    description_eng: yup.string().required(),
    description_de: yup.string().required(),
    icon: isChild ? yup.string().notRequired() : iconsArr && yup.string().oneOf(iconsArr).required(),
    is_active: yup.boolean(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: defaultValuesLoaded ? defaultValues : {},
    resolver: yupResolver(editFileTypeSchema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (fileType) {
      const defaultValues = {
        name_eng: fileType?.name_eng || '',
        name_de: fileType?.name_de || '',
        description_eng: fileType?.description_eng || '',
        description_de: fileType?.description_de || '',
        icon: fileType?.icon || '',
        is_active: fileType?.is_active,
      };
      reset(defaultValues);
      setDefaultValuesLoaded(true);
    }
  }, [fileType, reset]);

  useEffect(() => {
    if (fileType) {
      setDefaultValuesLoaded(true);
    }
  }, [fileType]);

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'successEditFileType',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'errorEditFileType',
    variant: 'error',
  });

  const onSubmit = async (data) => {
    mutate({ id, data });
    handleDrawerClose();
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    isLoading,
    defaultValuesLoaded,
    errors,
  };
};
