import { Box, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import MenuDropdown from '../../components/adminDashboard/MenuDropdown';
import { useAssignBrokerToTask, useUpdateTaskStatus } from '../../api/tasks';
import findLocalsOrUseFirst from '../../utils/findLocalsOrUseFirst';
import useToastNotification from '../useToastNotification';
import { useState } from 'react';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox';
import StatusBox from '../../components/StatusBox';

const useTaskColumns = ({ brokers, statuses }) => {
  const [sortBy, setSortBy] = useState(undefined);
  const { mutate, isError, isSuccess, isLoading } = useAssignBrokerToTask();
  const { mutate: updateStatus } = useUpdateTaskStatus();

  const { t, i18n } = useTranslation();

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessfullyUpdatedTask',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorUpdatingTask',
    variant: 'error',
  });

  const setSortItem = (item) => {
    setSortBy((prev) => {
      let [key, value] = prev?.split(':') || [];
      if (key !== item) {
        value = undefined;
      }
      if (value === '' || !value) {
        return `${item}:asc`;
      } else if (value === 'asc') {
        return `${item}:desc`;
      } else if (value === 'desc') {
        return undefined;
      }
    });
  };

  const sortIcon = (item) => {
    const [key, state] = sortBy?.split(':') || [];
    if (item === key) {
      switch (state) {
        case 'desc':
          return <ArrowDropDown color="gray" />;
        case 'asc':
          return <ArrowDropUp color="gray" />;
        default:
          return;
      }
    }
  };

  const columnHelper = createColumnHelper();
  const columns = [
    {
      id: 'select',
      header: ({ table }) => {
        return (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        );
      },
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    columnHelper.accessor(
      (row) => ({
        firstName: row.user?.firstName,
        lastName: row.user?.lastName,
      }),
      {
        id: 'user',
        cell: (info) => (
          <Typography>
            {info.getValue().firstName} {info.getValue().lastName}
          </Typography>
        ),
        header: () => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 1,
              cursor: 'pointer',
            }}
            onClick={() => {
              setSortItem('user');
            }}
          >
            <Typography variant="body1" fontWeight={700}>
              {t('Username')}
            </Typography>
            {sortIcon('user')}
          </Box>
        ),
      },
    ),
    columnHelper.accessor((row) => row.archive?.name, {
      id: 'archive',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setSortItem('archive');
          }}
        >
          <Typography variant="body1" fontWeight={700}>
            {t('archive')}
          </Typography>
          {sortIcon('archive')}
        </Box>
      ),
    }),
    columnHelper.accessor((row) => row.status, {
      id: 'status',
      cell: (info) => {
        const localData = findLocalsOrUseFirst(info?.getValue()?.locals, i18n.language);
        const taskId = info.row.original.id;
        const statusOptions = statuses?.map((status) => ({
          label: findLocalsOrUseFirst(status?.locals, i18n.language)?.name,
          value: status?.id,
          onClick: () => {
            updateStatus({ id: taskId, statusId: status?.id });
          },
        }));

        return (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <StatusBox color={info?.getValue()?.color}>{localData?.name || info.getValue()?.name}</StatusBox>{' '}
            <MenuDropdown
              isEditBroker={true}
              id={taskId}
              menuItems={statusOptions}
              hasIcon={false}
              selectedBrokerId={info?.getValue()?.id}
            />
          </Box>
        );
      },
      header: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setSortItem('status');
          }}
        >
          <Typography variant="body1" fontWeight={700}>
            {t('status')}
          </Typography>
          {sortIcon('status')}
        </Box>
      ),
    }),
    columnHelper.accessor((row) => row.createdAt, {
      id: 'createdAt',
      cell: (info) => <Typography>{format(new Date(info.getValue()), 'dd.MM.yyyy')}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Created At')}
        </Typography>
      ),
    }),
    columnHelper.accessor(
      (row) => ({
        firstName: row.broker?.firstName,
        lastName: row.broker?.lastName,
        id: row.broker?.id,
      }),
      {
        id: 'fullName',
        cell: (info) => {
          const brokerData = info.getValue();
          const isBrokerAssigned = brokerData.firstName || brokerData.lastName;
          const taskId = info.row.original.id;

          const brokerOptions = brokers?.results.map((broker) => ({
            label: `${broker.firstName} ${broker.lastName}`,
            value: broker.id,
            onClick: () => {
              mutate({ id: taskId, data: { brokerId: broker.id } });
            },
          }));

          return (
            <Typography>
              {isBrokerAssigned ? (
                <Box display="flex" alignItems="center">
                  <Typography>
                    {brokerData.firstName} {brokerData.lastName}{' '}
                  </Typography>
                  <MenuDropdown
                    id={taskId}
                    menuItems={brokerOptions}
                    selectedBrokerId={brokerData.id}
                    hasIcon={false}
                    isEditBroker={true}
                  />
                </Box>
              ) : (
                <MenuDropdown id={taskId} menuItems={brokerOptions} hasIcon={false} />
              )}
            </Typography>
          );
        },
        header: () => (
          <Typography variant="body1" fontWeight={700}>
            {t('Brokers')}
          </Typography>
        ),
      },
    ),
  ];
  return [columns, sortBy];
};

export default useTaskColumns;
