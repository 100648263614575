import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TableContainer from '../../../Table/TableContainer';
import ContactInfo from './components/ContactInfo';
import AddressInfo from './components/AddressInfo';
import QuestionsField from '../../users/PersonalInfo/components/QuestionsField';

const LeadInfo = ({ data }) => {
  const { t } = useTranslation();
  return (
    <TableContainer sx={{ p: 2 }}>
      <Typography variant="h6">{t('personalInfo')}</Typography>
      <Box display="flex" flexDirection="column">
        <ContactInfo data={data} />
        <AddressInfo data={data} />
        <Box display="flex" gap="32px" justifyContent="space-between">
          <QuestionsField labelKey="Estate" status={data?.estate} />
          <QuestionsField labelKey="married" status={data?.married} />
        </Box>
      </Box>
    </TableContainer>
  );
};

export default LeadInfo;
