import {
  Box,
  Button,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomTextField from '../../../commonComponents/CustomTextField';

const SearchFilters = ({ filterTag, handleChange, tags, MenuProps, control, debouncedHandleSearchChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <FormControl sx={{ width: 200 }}>
        <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          value={filterTag}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" sx={{ border: '1px solid #DCE4E8', borderRadius: '15px' }} />}
          renderValue={(selected) => {
            const selectedTag = tags.find((tag) => tag.value === selected[0]);
            return selectedTag ? selectedTag.label : '';
          }}
          MenuProps={MenuProps}
        >
          {tags.map((tag) => (
            <MenuItem key={tag.value} value={tag.value}>
              <ListItemText primary={tag.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Controller
        name="search"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Box display="flex" flexDirection="column">
            <CustomTextField
              id="search"
              value={value ? value : ''}
              label="Search"
              onChange={(event) => {
                onChange(event);
                debouncedHandleSearchChange(event.target.value);
              }}
            />
          </Box>
        )}
      />

      <Button
        variant="contained"
        sx={{
          bgcolor: '#fff',
          color: '#000',
          fontWeight: 700,
          textTransform: 'none',
          borderRadius: '25px',
          width: 'fit-content',
          paddingY: '16px',
          paddingX: '25px',
          display: 'flex',
          gap: '10px',
        }}
      >
        <img alt="Menu Icon" src={'https://taxofficestorage.blob.core.windows.net/media/export.svg'}></img>
        <Typography fontWeight={700}>{t('Export')}</Typography>
      </Button>
    </>
  );
};

export default SearchFilters;
