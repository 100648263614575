import { Button } from '@mui/material';
import React from 'react';

const TaxSavingButton = ({ variant, textKey, onClick, isPresent, t }) => {
  if (!isPresent) {
    return null;
  }
  return (
    <Button
      variant={variant}
      sx={{
        bgcolor: variant === 'contained' ? '#0F5D66' : '#fff',
        color: variant === 'contained' ? '#fff' : '#0F5D66',
        textTransform: 'none',
        borderRadius: '15px',
        borderColor: variant === 'contained' ? '' : 'rgba(15, 93, 102, 0.4)',
        fontWeight: '700',
        padding: '8px 12px',
        '&:hover': {
          color: variant === 'contained' ? 'rgba(255, 255, 255,0.9)' : 'rgba(15, 93, 102,1)',
          bgcolor: variant === 'contained' ? 'rgba(15, 93, 102,0.9)' : 'rgba(255, 255, 255,1)',
          borderColor: variant === 'contained' ? '' : '#0F5D66',
        },
        flex: 1,
      }}
      onClick={onClick}
    >
      {t(textKey)}
    </Button>
  );
};

export default TaxSavingButton;
