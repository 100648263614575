import { Container, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../components/homeComponent/Footer';
import NavBarHome from '../components/navBar/navBarHome';

const TermsAndConditions = () => {
  const { t } = useTranslation();
  return (
    <>
      <NavBarHome />
      <Box display="flex" justifyContent="center">
        <>
          <Container maxWidth="xxl" fixed disableGutters sx={{ paddingY: 10, paddingX: 3 }}>
            <Typography variant="h4" color={'#037A68'} textAlign={'center'} py="30px">
              {t('Tittle.Terms')}
            </Typography>

            <>
              <Typography variant="h6">{t('Scope.1')}</Typography>
              <Typography>{t('Scope.1.1')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.2')}</Typography>
              <Typography>{t('Scope.2.1')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.3')}</Typography>
              <Typography>{t('Scope.3.1')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.4')}</Typography>
              <Typography>{t('Scope.4.1')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.5')}</Typography>
              <Typography>{t('Scope.5.1')}</Typography>
              <Typography>{t('Scope.5.2')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.6')}</Typography>
              <Typography>{t('Scope.6.1')}</Typography>
              <Typography>{t('Scope.6.2')}</Typography>
              <Typography>{t('Scope.6.3')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.7')}</Typography>
              <Typography>{t('Scope.7.1')}</Typography>
              <Typography>{t('Scope.7.2')}</Typography>
              <Typography>{t('Scope.7.3')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.8')}</Typography>
              <Typography>{t('Scope.8.1')}</Typography>
              <Typography>{t('Scope.8.2')}</Typography>
              <Typography>{t('Scope.8.3')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.9')}</Typography>
              <Typography>{t('Scope.9.1')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.10')}</Typography>
              <Typography>{t('Scope.10.1')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.11')}</Typography>
              <Typography>{t('Scope.11.1')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.12')}</Typography>
              <Typography>{t('Scope.12.1')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.13')}</Typography>
              <Typography>{t('Scope.13.1')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.14')}</Typography>
              <Typography>{t('Scope.14.1')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.15')}</Typography>
              <Typography>{t('Scope.15.1')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.16')}</Typography>
              <Typography>{t('Scope.16.1')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.17')}</Typography>
              <Typography>{t('Scope.17.1')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.18')}</Typography>
              <Typography>{t('Scope.18.1')}</Typography>
            </>
            <>
              <Typography variant="h6">{t('Scope.19')}</Typography>
              <Typography>{t('Scope.19.1')}</Typography>
            </>
          </Container>
        </>
      </Box>

      <Footer />
    </>
  );
};
export default TermsAndConditions;
//added
