import { useSelector } from 'react-redux';

export const useAuth = () => {
  const state = useSelector((state) => state.auth);

  return {
    id: state?.currentUser?.id,
    ...state,
  };
};
