import { Box, Breadcrumbs, Divider, Typography } from '@mui/material';
import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetLeadById } from '../../../api/leads';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';
import Notes from './Notes';
import Termins from './Termins';
import Journal from './Journal';
import LeadInfo from './LeadInfo';
import { useSelector } from 'react-redux';
import { useGetAllBrokers } from '../../../api/user';

const LeadsView = () => {
  const { id } = useParams();
  //leeds is not a typo is on purpose please dont change, thanks for ur understanding
  const { data: leedsData } = useGetLeadById(id);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth);
  const isAdmin = user.roles?.includes('admin');
  const defaultBrokerValue = isAdmin ? '' : user?.currentUser?.id;
  const { data: brokers } = useGetAllBrokers(undefined, isAdmin);

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Typography textAlign={{ xs: 'center', sm: 'left' }} variant="h6" fontWeight={700} px={5} py={2.45}>
          {leedsData?.name}
        </Typography>
        <Divider />
        <Box display="flex" flexDirection="column" gap="32px" m={3}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link onClick={() => navigate(-1)} style={{ textDecoration: 'none' }}>
              <Typography variant="body1" color="#1A1C1E">
                {t('Leads')}
              </Typography>
            </Link>
            <Typography variant="body1" color="#1A1C1E">
              {leedsData?.name}
            </Typography>
          </Breadcrumbs>
          <Box display="flex" flexDirection="row" gap="20px">
            <Box display="flex" width="50%" flexDirection="column" gap="32px">
              <LeadInfo data={leedsData} />
            </Box>
            <Box display="flex" flexDirection="column" gap="32px" width="50%">
              <Termins id={id} defaultBrokerValue={defaultBrokerValue} isAdmin={isAdmin} brokers={brokers} />
              <Notes id={id} />
            </Box>
          </Box>
          <Box>
            <Journal id={id} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LeadsView;
