import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

export default function NavBarRegister() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <>
      {!isMobile && (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            sx={{
              background: 'transparent',
              boxShadow: 'none',
              padding: { xs: '24px 44px', md: '34px 90px' },
            }}
          >
            <Toolbar
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Link to={'/'}>
                <Box
                  component="img"
                  sx={{
                    height: { xs: '28px', md: '35px' },
                    width: { xs: '168px', md: '205px' },
                    objectFit: 'contain',
                  }}
                  alt="Your logo."
                  src={'https://taxofficestorage.blob.core.windows.net/media/whiteLogo.svg'}
                ></Box>
              </Link>
            </Toolbar>
          </AppBar>
        </Box>
      )}
    </>
  );
}
