import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import InfoCard from '../components/dashboardComponents/InfoCard';
import { useTranslation } from 'react-i18next';
import UserInfoForm from '../components/userInfo/userInfoForm';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from '../components/modals/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import TerminForm from '../components/commonComponents/TerminForm/TerminForm';
import findLocalsOrUseFirst from '../utils/findLocalsOrUseFirst';
import useTimeOfDay from '../utils/useTimeOfTheDay';
import SeeMoreCard from '../components/dashboardComponents/SeeMoreCard';
import { useTaskByArchive } from '../api/tasks';
import TaxPotential from '../components/dashboardComponents/TaxPotential';
import { useGetTaxPotential } from '../api/taxPotential';
import { trim } from 'lodash-es';
import { useGetAllArchives } from '../api/archive';
import banner from '../assets/banners/tax-potential.jpeg';
import { useGetFinishedDocuments } from '../api/finishedDocuments';
import TopTabs from '../components/dashboardComponents/TopTabs/TopTabs';
import { useNavigate } from 'react-router-dom';
import { setActiveTab } from '../store/dashboard/dashboardSlice';
import LanguageSelect from '../components/selectTranslation/translateSelect';

const NewDashboard = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.dashboard.activeTab);
  const { t, i18n } = useTranslation();
  const { currentUser } = useSelector((state) => state.auth);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const TABS = [
    {
      onClick: () => {
        dispatch(setActiveTab((currentYear - 2).toString()));
        navigate('/my_documents/taxyear');
      },
      label: t('previous'),
    },
    {
      onClick: () => {
        dispatch(setActiveTab((currentYear - 1).toString()));
        navigate('/my_documents/taxyear');
      },
      label: t('current_year'),
    },
    {
      onClick: () => {
        dispatch(setActiveTab(currentYear.toString()));
        navigate('/my_documents/taxyear');
      },
      label: t('next'),
    },
  ];
  const info = currentUser?.info;
  const firstName = currentUser?.firstName;
  const finishedTrial = info?.finishedTrial;
  const timeOfDay = useTimeOfDay();
  const { data: archives } = useGetAllArchives();
  const [taxYear, setTaxYear] = useState();
  const { data: taxPotential } = useGetTaxPotential({ user: currentUser?.id, archive: taxYear });
  const { data: finishedDocuments } = useGetFinishedDocuments({
    user: currentUser?.id,
    archive: taxYear,
    enabled: !!taxYear,
  });
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [formView] = useState(false);
  const prevYear = new Date().getFullYear() - 1;
  const { data } = useTaskByArchive(prevYear.toString());

  const handleOpen = () => {
    setOpenAddModal(!openAddModal);
  };

  const handleWidgetClick = (title) => {
    setSelectedWidget(title);
    handleOpen();
  };

  useEffect(() => {
    setTaxYear(archives?.find((archive) => trim(archive?.name) === (currentYear - 1).toString())?.id);
  }, [archives, currentYear]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center" px={2}>
            <Typography variant="h6" fontWeight={700} px={5} py={2}>
              {t('Greeting', { timeOfDay: t(timeOfDay) })}, {firstName}
            </Typography>
            <LanguageSelect />
          </Box>
          <TopTabs tabs={TABS} activeTab={activeTab} />

          <Box mx={3} pt="25px">
            <Grid container spacing={3}>
              {finishedTrial && (
                <>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    {!formView && (
                      <InfoCard
                        status={data && findLocalsOrUseFirst(data?.status?.locals, i18n.language)?.name}
                        statusColor={data?.status?.color}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <TaxPotential
                      file={taxPotential?.file?.url}
                      image={banner}
                      onOpenDrawer={() =>
                        handleWidgetClick(taxPotential ? taxPotential?.archive?.name : (currentYear - 1).toString())
                      }
                      finishedDocuments={finishedDocuments?.file?.url}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <SeeMoreCard />
                  </Grid>
                </>
              )}
            </Grid>

            {!finishedTrial && !formView && (
              <>
                <Box
                  sx={{
                    padding: '20px',
                    bgcolor: '#fff',
                    border: '2px solid #DCE4E8',
                    boxShadow: '0px 34px 184px -10px rgba(0, 0, 0, 0.06)',
                    borderRadius: '24px',
                  }}
                >
                  <UserInfoForm />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {/* Add Drawer */}
      <CustomModal open={openAddModal} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('MakeAnappointment')}
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <TerminForm interest={selectedWidget} handleModalClose={handleOpen} />
      </CustomModal>
    </>
  );
};

export default NewDashboard;
