import React from 'react';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import { useTranslation } from 'react-i18next';

const QuestionsField = ({ labelKey, status }) => {
  const { t } = useTranslation();

  return (
    <Typography variant="body1" display="flex" alignItems="center">
      <span style={{ fontWeight: 700 }}>{t(labelKey)}:</span>
      {status ? <CheckCircleOutlineSharpIcon color="success" /> : <HighlightOffSharpIcon color="error" />}
    </Typography>
  );
};

export default QuestionsField;
