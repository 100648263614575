import React from 'react';
import PageBackground from '../components/LeadPage/PageBackground';
import LeadForm2 from '../components/LeadsForms/LeadForm2/LeadForm2';

const LeadUpdatePage = () => {
  window.dataLayer.push({
    event: 'pageview',
  });
  return (
    <PageBackground>
      <LeadForm2 />
    </PageBackground>
  );
};

export default LeadUpdatePage;
