import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import findLocalsOrUseFirst from '../../utils/findLocalsOrUseFirst';

const useFamilyMemberForUserColumn = () => {
  const { t, i18n } = useTranslation();

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          #
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.firstName, {
      id: 'firstName',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('firstname')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.lastName, {
      id: 'name',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('lastname')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.birthDate, {
      id: 'birthDate',
      cell: (info) => <Typography>{format(new Date(info.getValue()), 'dd.MM.yyyy')}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('birthdate')}
        </Typography>
      ),
    }),
    columnHelper.accessor(
      (row) => {
        const localData = findLocalsOrUseFirst(row.familyRelation.locals, i18n.language);
        return localData.name;
      },
      {
        id: 'familyRelation',
        cell: (info) => <Typography>{info.getValue()}</Typography>,
        header: () => (
          <Typography variant="body1" fontWeight={700}>
            {t('FamilyRelation')}
          </Typography>
        ),
      },
    ),
  ];
  return columns;
};
export default useFamilyMemberForUserColumn;
