import { createColumnHelper } from '@tanstack/react-table';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MenuDropdown from '../../components/adminDashboard/MenuDropdown';
import { useAssignUserBroker, useUpdateUserStatus } from '../../api/user';
import useToastNotification from '../useToastNotification';
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox';
import SortableHeader from '../../components/SortableHeader/SortableHeader';
import { formatIfValid } from '../../utils/formatIfValid';
import findLocalsOrUseFirst from '../../utils/findLocalsOrUseFirst';
import StatusBox from '../../components/StatusBox';

const useColumns = ({ brokers, setSortItem, sortIcon, statuses }) => {
  const columnHelper = createColumnHelper();
  const { t, i18n } = useTranslation();
  const { mutate, isSuccess, isLoading, isError } = useAssignUserBroker();
  const { mutate: updateStatus } = useUpdateUserStatus();

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessfullyUpdatedUser',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorUpdatingUser',
    variant: 'error',
  });

  const columns = [
    {
      id: 'select',
      header: ({ table }) => {
        return (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        );
      },
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    columnHelper.accessor((row) => row.firstName, {
      id: 'firstName',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <SortableHeader header={t('firstname')} itemName="firstName" setSortItem={setSortItem} sortIcon={sortIcon} />
      ),
    }),
    columnHelper.accessor((row) => row.lastName, {
      id: 'lastName',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <SortableHeader header={t('lastname')} itemName="lastName" setSortItem={setSortItem} sortIcon={sortIcon} />
      ),
    }),
    columnHelper.accessor((row) => row.email, {
      id: 'email',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <SortableHeader header={t('email')} itemName="email" setSortItem={setSortItem} sortIcon={sortIcon} />
      ),
    }),
    columnHelper.accessor((row) => row.phoneNumber, {
      id: 'phoneNumber',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <SortableHeader
          header={t('phone.number')}
          itemName="phoneNumber"
          setSortItem={setSortItem}
          sortIcon={sortIcon}
        />
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.birthdate, {
      id: 'birthdate',
      header: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setSortItem('birthdate');
          }}
        >
          <Typography sx={{ userSelect: 'none' }} variant="body1" fontWeight={700}>
            {t('birthdate')}
          </Typography>
          {sortIcon('birthdate')}
        </Box>
      ),
      cell: (info) => {
        const formatedDate = formatIfValid(info.getValue(), 'dd.MM.yyyy');
        return <Typography>{formatedDate}</Typography>;
      },
    }),
    columnHelper.accessor((row) => row.zipCode, {
      id: 'zipCode',
      cell: (info) => <Typography>{info.getValue()}</Typography>,

      header: () => (
        <SortableHeader header={t('zip')} itemName="zipCode" setSortItem={setSortItem} sortIcon={sortIcon} />
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.address, {
      id: 'address',
      cell: (info) => <Typography>{info.getValue()}</Typography>,

      header: () => (
        <SortableHeader header={t('address')} itemName="address" setSortItem={setSortItem} sortIcon={sortIcon} />
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.place, {
      id: 'place',
      cell: (info) => <Typography>{info.getValue()}</Typography>,

      header: () => (
        <SortableHeader header={t('place')} itemName="place" setSortItem={setSortItem} sortIcon={sortIcon} />
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.createdAt, {
      id: 'createdAt',
      header: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setSortItem('createdAt');
          }}
        >
          <Typography sx={{ userSelect: 'none' }}>{t('createdAt')}</Typography>
          {sortIcon('createdAt')}
        </Box>
      ),
      cell: (info) => {
        const formatedDate = formatIfValid(info.getValue(), 'dd.MM.yyyy');
        return <Typography>{formatedDate}</Typography>;
      },
    }),
    columnHelper.accessor((row) => row.updatedAt, {
      id: 'updatedAt',
      header: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setSortItem('updatedAt');
          }}
        >
          <Typography sx={{ userSelect: 'none' }}>{t('updatedAt')}</Typography>
          {sortIcon('updatedAt')}
        </Box>
      ),
      cell: (info) => {
        const formatedDate = formatIfValid(info.getValue(), 'dd.MM.yyyy');
        return <Typography>{formatedDate}</Typography>;
      },
    }),
    columnHelper.accessor((row) => row.promoCode, {
      id: 'promoCode',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <SortableHeader header={t('promoCode')} itemName="promoCode" setSortItem={setSortItem} sortIcon={sortIcon} />
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor(
      (row) => ({
        firstName: row.broker?.firstName,
        lastName: row.broker?.lastName,
        id: row.broker?.id,
      }),
      {
        id: 'fullName',
        cell: (info) => {
          const brokerData = info.getValue();

          const isBrokerAssigned = brokerData.firstName || brokerData.lastName;
          const userId = info.row.original.id;

          const brokerOptions = brokers?.results.map((broker) => ({
            label: `${broker.firstName} ${broker.lastName}`,
            value: broker.id,
            onClick: () => {
              mutate({ data: { brokerId: broker.id, users: [userId] } });
            },
          }));
          return (
            <Typography>
              {isBrokerAssigned ? (
                <Box display="flex" alignItems="center">
                  <Typography variant="body1">
                    {brokerData.firstName} {brokerData.lastName}{' '}
                  </Typography>
                  <MenuDropdown
                    id={userId}
                    menuItems={brokerOptions}
                    selectedBrokerId={brokerData.id}
                    hasIcon={false}
                    isEditBroker={true}
                  />
                </Box>
              ) : (
                <MenuDropdown id={userId} menuItems={brokerOptions} hasIcon={false} />
              )}
            </Typography>
          );
        },
        header: () => (
          <Typography variant="body1" fontWeight={700}>
            {t('Brokers')}
          </Typography>
        ),
      },
    ),
    columnHelper.accessor((row) => row.status, {
      id: 'status',
      cell: (info) => {
        const localData = findLocalsOrUseFirst(info?.getValue()?.locals, i18n.language);
        const userId = info.row.original.id;
        const statusOptions = statuses?.map((status) => ({
          label: findLocalsOrUseFirst(status?.locals, i18n.language)?.name,
          value: status?.id,
          onClick: () => {
            updateStatus({ id: userId, statusId: status?.id });
          },
        }));

        return (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <StatusBox color={info?.getValue()?.color}>{localData?.name || info.getValue()?.name}</StatusBox>{' '}
            <MenuDropdown
              isEditBroker={true}
              id={userId}
              menuItems={statusOptions}
              hasIcon={false}
              selectedBrokerId={info?.getValue()?.id}
            />
          </Box>
        );
      },
      header: () => (
        <Typography sx={{ userSelect: 'none' }} variant="body1">
          {t('status')}
        </Typography>
      ),
    }),
  ];
  return columns;
};

export default useColumns;
