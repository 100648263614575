import React from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

const CustomSelect = ({ control, name, label, data, valueField, displayField, disabled = false }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ...field } }) => (
        <Box display="flex" flexDirection="column" width="100%">
          <Typography variant="body2" fontSize="12px" color="#ACB5BB">
            {label}
          </Typography>
          <Select
            sx={{ border: '1px solid #DCE4E8', borderRadius: '15px' }}
            {...field}
            value={value || ''}
            onChange={onChange}
            disabled={disabled}
          >
            {data?.map((item, index) => (
              <MenuItem key={index} value={item && valueField ? valueField(item) : ''}>
                {item && typeof displayField === 'function' ? displayField(item) : item?.[displayField]}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
    />
  );
};

export default CustomSelect;
