import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { verifySinginOpt } from '../../api/auth';
import { updateUserInfo } from '../../api/user';

export const login = createAsyncThunk('auth/login', async ({ email, password, otp }, thunkAPI) => {
  try {
    const {
      data: { accessToken, refreshToken, ...data },
    } = await verifySinginOpt({ email, password, otp });
    return { accessToken, refreshToken, currentUser: { ...data } };
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const updateUser = createAsyncThunk('auth/update', async (data, thunkAPI) => {
  try {
    const info = await updateUserInfo(data);
    return info?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const updateUserFiles = createAction('auth/user-files', (data) => {
  return {
    payload: data?.data,
  };
});

export const updateUserData = createAction('auth/updateUserData', (data) => {
  return {
    payload: data,
  };
});

export const setUser = createAction('auth/setUser', (user) => ({ payload: user }));

export const logout = createAction('auth/logout');
