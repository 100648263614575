import React from 'react';
import { Box, Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme, customtextfield }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: customtextfield,
    width: '100%',
    textAlign: '',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flex: 1,
    marginY: '50px',

    flexDirection: customtextfield,
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginY: '50px',
    paddingY: '50px',

    flexDirection: 'row',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    flex: 1,
    marginY: '50px',

    flexDirection: 'row',
  },
  [theme.breakpoints.up('xl')]: {
    display: 'flex',
    flex: 1,
    marginY: '50px',

    flexDirection: 'row',
  },
  [theme.breakpoints.up('xxl')]: {
    display: 'flex',
    flex: 1,
    marginY: '50px',

    flexDirection: 'row',
  },
}));

const Image = ({ src, height = '100%', width = '100%' }) => {
  return (
    <Box
      component="img"
      sx={{
        height,
        width,
      }}
      alt="Your logo."
      src={src}
    />
  );
};

const ArticleCardHero = ({ variant = ['outline'], contentHeight = '100%', imageSrc, children, flexDir }) => {
  return (
    <StyledPaper
      sx={{ justifyContent: 'space-between', flexDirection: 'column', display: 'flex' }}
      elevation={variant.includes('outline') ? 0 : 3}
      square={false}
      customtextfield={flexDir}
      className="HELLO"
    >
      {variant.includes('left') && (
        <Box p={3}>
          <Image src={imageSrc} sx={{ height: { xs: 300, md: 921, lg: 921 }, width: { xs: 300, md: 581, lg: 581 } }} />
        </Box>
      )}
      <Box
        p={3}
        sx={{ justifyContent: 'space-between', flexDirection: 'column', display: 'flex' }}
        height={contentHeight}
        gap="20px"
        maxWidth={{ xs: '100%', md: '50%' }}
      >
        {children}
      </Box>
      {variant.includes('right') && (
        <Box p={3}>
          <Image src={imageSrc} sx={{ height: { xs: 300, md: 921, lg: 921 }, width: { xs: 300, md: 581, lg: 581 } }} />
        </Box>
      )}
    </StyledPaper>
  );
};

export default ArticleCardHero;
