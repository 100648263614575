import React from 'react';
import ArticleCardContent from '../articleCard/cardContent';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { Box, Chip, IconButton, Modal, Typography } from '@mui/material';
import { multilineExtractor } from '../../utils';
import CloseIcon from '@mui/icons-material/Close';
import ArticleCardHero from '../articleCardHero';
import StepperCards from './Cards/StepperCards';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', md: '45%' },
  height: { xs: '90%', md: 'fit-content' },
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const ContentComponent = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);
  const bodyText = multilineExtractor(t('content.second.readmore'));

  return (
    <>
      <Box id="how_to_start" display="flex">
        <ArticleCardHero
          variant={['left', 'outline']}
          imageSrc={'https://taxofficestorage.blob.core.windows.net/media/howItWorks.svg'}
          flexDir={'column'}
        >
          <Chip
            label={t('how.to.start')}
            sx={{
              backgroundColor: '#f4f4f7',
              marginX: '10px',
              paddingX: '10px',
              width: 'fit-content',
              textTransform: 'uppercase',
            }}
          />
          <Typography variant="h2">{t('howItWorks.title')}</Typography>

          <StepperCards
            label={t('stepperComponent.first.label')}
            desc={t('stepperComponent.first.description')}
            index={'1'}
          />
          <StepperCards
            label={t('stepperComponent.second.label')}
            desc={t('stepperComponent.second.description')}
            index={'2'}
          />
          <StepperCards
            label={t('stepperComponent.third.label')}
            desc={t('stepperComponent.third.description')}
            index={'3'}
          />
        </ArticleCardHero>
      </Box>

      <Box id="tax_planing" display="flex">
        <ArticleCardHero
          imageSrc={'https://taxofficestorage.blob.core.windows.net/media/taxPlanning.svg'}
          flexDir={'column-reverse'}
          contentHeight="fit-content"
          variant={['right', 'outline']}
        >
          <Chip
            label={t('About.tax')}
            sx={{
              marginX: '10px',
              backgroundColor: '#f4f4f7',
              width: 'fit-content',
              paddingX: '10px',
              textTransform: 'uppercase',
            }}
          />
          <ArticleCardContent
            gap="10px"
            title={t('content.title')}
            variant="secondary"
            body={t('content.first.text')}
            headingSize="h2"
          />
        </ArticleCardHero>
      </Box>

      <Box id="tips" display="flex">
        <ArticleCardHero
          variant={['left', 'outline']}
          contentHeight="fit-content"
          imageSrc={'https://taxofficestorage.blob.core.windows.net/media/taxReturn.svg'}
          flexDir={'column'}
        >
          <ArticleCardContent
            gap="10px"
            variant="secondary"
            title={t('content.second.title')}
            body={t('content.second.text')}
            headingSize="h2"
          />
          <Button
            variant="contained"
            sx={{
              bgcolor: '#0F5D66',
              textTransform: 'none',
              borderRadius: '15px',
              marginY: '15px',
              width: 'fit-content',
              paddingY: '10px',
              paddingX: '25px',
            }}
            onClick={handleOpen}
          >
            {t('showMore')}
          </Button>
        </ArticleCardHero>
      </Box>
      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" alignItems="flex-start" justifyContent="space-between">
            <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
              {t('content.second.title')}
            </Typography>
            <IconButton onClick={handleOpen}>
              <CloseIcon sx={{ fontSize: '28px' }} />
            </IconButton>
          </Box>
          {bodyText.map((text) => (
            <Typography py="10px" id="modal-modal-description" variant="body1" key={text}>
              {text}
            </Typography>
          ))}
        </Box>
      </Modal>
    </>
  );
};

export default ContentComponent;
