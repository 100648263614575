import { Box, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useEditArchiveType } from '../../../hooks/useEditArchiveType';
import { useTranslation } from 'react-i18next';
import { CustomSwitch } from '../../commonComponents/CustomSwitch';
import SelectIcon from '../../SelectIcon';
import SubmitButton from '../../commonComponents/SubmitButton';
import CustomFormInput from '../../commonComponents/CustomFormInput';

const EditArchiveTypeForm = ({ id, handleDrawerClose, icons }) => {
  const { handleSubmit, control, onSubmit } = useEditArchiveType(id, handleDrawerClose);
  const { t } = useTranslation();
  return (
    <Box component="form" display="flex" flexDirection="column">
      <CustomFormInput name="name_eng" control={control} label={t('english_label')} />
      <CustomFormInput name="name_de" control={control} label={t('deutsch_label')} />
      <Controller
        name="is_active"
        control={control}
        render={({ field }) => (
          <Box display="flex" alignContent="center">
            <Typography>{t('Inactive')}</Typography>
            <CustomSwitch checked={!!field.value} onChange={() => field.onChange(!field.value)} {...field} />
            <Typography>{t('Active')}</Typography>
          </Box>
        )}
      />

      <Controller
        name="icon"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <SelectIcon icons={icons} onClick={(item) => onChange(item.url)} value={value} />
        )}
      />

      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default EditArchiveTypeForm;
