import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { useState } from 'react';

export const useSortItems = () => {
  const [sortBy, setSortBy] = useState(undefined);
  const setSortItem = (item) => {
    setSortBy((prev) => {
      let [key, value] = prev?.split(':') || [];
      if (key !== item) {
        value = undefined;
      }
      if (value === '' || !value) {
        return `${item}:asc`;
      } else if (value === 'asc') {
        return `${item}:desc`;
      } else if (value === 'desc') {
        return undefined;
      }
    });
  };

  const sortIcon = (item) => {
    const [key, state] = sortBy?.split(':') || [];
    if (item === key) {
      switch (state) {
        case 'desc':
          return <ArrowDropDown color="gray" />;
        case 'asc':
          return <ArrowDropUp color="gray" />;
        default:
          return;
      }
    }
  };

  return { setSortItem, sortIcon, sortBy };
};
