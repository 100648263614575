import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const CustomModal = ({ open, handleClose, children }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      ModalProps={{
        keepMounted: true,
      }}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', md: '35%' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          px: 5,
          py: 2,

          boxSizing: 'border-box',
          borderRadius: '15px',
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
