import React from 'react';
import { Box, Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flex: 1,
    marginY: '50px',

    flexDirection: 'column-reverse',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginY: '50px',
    flexDirection: 'column-reverse',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    flex: 1,
    marginY: '50px',

    flexDirection: 'column-reverse',
  },
  [theme.breakpoints.up('xl')]: {
    display: 'flex',
    flex: 1,
    marginY: '50px',

    flexDirection: 'column-reverse',
  },
  [theme.breakpoints.up('xxl')]: {
    display: 'flex',
    flex: 1,
    marginY: '50px',

    flexDirection: 'column-reverse',
  },
}));

const Image = ({ src, height = '100%', width = '100%' }) => {
  return (
    <Box
      component="img"
      sx={{
        height,
        width,
      }}
      alt="Your logo."
      src={src}
    />
  );
};

const ArticleCard = ({ variant = ['outline'], imageSrc, children }) => {
  return (
    <StyledPaper elevation={variant.includes('outline') ? 0 : 3} square={false}>
      <Box p={3}>
        {variant.includes('left') && imageSrc && (
          <Image src={imageSrc} sx={{ height: { xs: 300, md: 300, lg: 500 }, width: { xs: 300, md: 300, lg: 500 } }} />
        )}
      </Box>
      <Box p={3} textAlign="center">
        {children}
      </Box>
      <Box p={3}>
        {variant.includes('right') && imageSrc && (
          <Image src={imageSrc} sx={{ height: { xs: 300, md: 400, lg: 500 }, width: { xs: 300, md: 400, lg: 500 } }} />
        )}
      </Box>
    </StyledPaper>
  );
};

export default ArticleCard;
