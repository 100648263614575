import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateArchive } from '../api/archive';
import { useTranslation } from 'react-i18next';
import useToastNotification from '../hooks/useToastNotification';

export const useAddArchive = (handleDrawerClose, archives) => {
  const { t } = useTranslation();
  const archiveTypeArr = archives?.map((arType) => arType.id);

  const { mutate, isSuccess, isLoading, isError } = useCreateArchive();

  const addArchiveSchema = yup
    .object()
    .shape({
      validFrom: yup.date().typeError(t('date_required')).required(t('validFrom.required')),
      validTo: yup.date().typeError(t('date_required')).required(t('validTo.required')),
      locals: yup.object().shape({
        eng: yup.string().required(t('required_field', { field: t('english_label') })),
        de: yup.string().required(t('required_field', { field: t('deutsch_label') })),
      }),
      archiveTypeId: archiveTypeArr && yup.string().oneOf(archiveTypeArr).required(t('type.required')),
      is_active: yup.boolean().required(),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      validFrom: '',
      validTo: '',
      archiveTypeId: '',
      is_active: true,
    },
    resolver: yupResolver(addArchiveSchema),
    reValidateMode: 'onChange',
  });

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessfullyArchive',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorAddingArchive',
    variant: 'error',
  });

  const onSubmit = (data) => {
    const { de, eng } = data.locals;
    mutate({
      data: {
        ...data,
        name: de,
        locals: [
          { name: de, language: 'de' },
          { name: eng, language: 'eng' },
        ],
      },
    });
    handleDrawerClose();
    reset();
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    isLoading,
    errors,
  };
};
