import React from 'react';
import { format } from 'date-fns';
import { Box } from '@mui/material';
import InfoField from '../../../users/PersonalInfo/components/InfoField';

const ContactInfo = ({ data }) => {
  return (
    <>
      <InfoField labelKey="FullName" value={data?.name ? data.name : '/'} />
      <InfoField labelKey="birthdate" value={data?.birthdate ? format(new Date(data?.birthdate), 'dd.MM.yyyy') : '/'} />
      <Box display="flex" flexDirection="row" width="100%" gap="10px">
        <InfoField labelKey="email" value={data?.email ? data?.email : '/'} />
        <InfoField labelKey="phone.number" value={data?.phoneNumber ? data?.phoneNumber : '/'} />
      </Box>
    </>
  );
};

export default ContactInfo;
