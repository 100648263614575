/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateFinishedDocument, useDeleteFinishedDocument } from '../../../../api/finishedDocuments';
import { trim } from 'lodash-es';
import useFinishedDocumentsColumns from '../../../../hooks/AllUsers/useFinishedDocumentsColumns';
import { Box, Typography } from '@mui/material';
import TableComponent from '../../../Table/Table';
import UploadFileButton from '../../../commonComponents/UploadFileButton/UploadFileButton';
import DeleteModal from '../../../modals/DeleteModal';
import useToastNotification from '../../../../hooks/useToastNotification';
import TaxYearSelector from '../../../commonComponents/TaxYearSelector';
import TableContainer from '../../../Table/TableContainer';

const FinishedDocuments = ({ finishedDocuments, archives, user, setTaxYear, taxYear }) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const { register, control } = useForm();
  const [progress, setProgress] = useState();
  const [docId, setDocId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  const { mutate, isLoading, isSuccess, isError } = useCreateFinishedDocument(setProgress);
  const {
    mutate: deleteFinishedDocument,
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
    isError: isErrorDelete,
  } = useDeleteFinishedDocument();

  const handleDeleteModal = (id) => {
    setOpenDelete(!openDelete);
    if (typeof id === 'string') {
      setDocId(id);
    } else {
      setDocId(null);
    }
  };
  const handleDelete = () => {
    deleteFinishedDocument(docId);
    setOpenDelete(!openDelete);
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setProgress(null);
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    setTaxYear(archives?.find((archive) => trim(archive?.name) === (currentYear - 1).toString())?.id);
  }, [archives]);

  const handleUploadSubmit = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user', user);
    formData.append('archive', taxYear);

    mutate(formData);
  };

  const columns = useFinishedDocumentsColumns(handleDeleteModal);

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessfullyAddedTaxPotential',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorAddingTaxPotential',
    variant: 'error',
  });

  useToastNotification({
    isShow: isSuccessDelete,
    loading: isLoadingDelete,
    messageKey: 'SuccessfullyDeletedTaxPotential',
    variant: 'success',
  });

  useToastNotification({
    isShow: isErrorDelete,
    loading: isLoadingDelete,
    messageKey: 'ErrorDeleteingTaxPotential',
    variant: 'error',
  });

  return (
    <>
      <TableContainer>
        <TableComponent
          hasBoxShadow
          hasAboveStuff
          data={Array.isArray(finishedDocuments) ? finishedDocuments : [finishedDocuments]}
          columns={columns}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" px={1} width="100%">
            <Typography variant="h6">{t('finishedDocuments')}</Typography>
            {taxYear && <TaxYearSelector taxYear={taxYear} setTaxYear={setTaxYear} archives={archives} />}
            <UploadFileButton
              onSubmit={handleUploadSubmit}
              progress={isLoading ? progress : undefined}
              register={register}
              control={control}
              props
            />
          </Box>
        </TableComponent>
      </TableContainer>

      {/* Delete Modal */}
      <DeleteModal
        handleDeleteModal={handleDeleteModal}
        openDelete={openDelete}
        headingText={t('DeleteFinishedDocument')}
        bodyText={t('AreYouSureDeleteFinishedDocument')}
        handleDelete={() => handleDelete(docId)}
      />
    </>
  );
};

export default FinishedDocuments;
