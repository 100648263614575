import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './authSlices';

const persistConf = {
  key: 'auth',
  storage,
  blacklist: ['loading', 'error'],
};

export default persistReducer(persistConf, authReducer);
