import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCreateArchiveType, useCreateArchiveTypeLocal } from '../api/archive';
import { useTranslation } from 'react-i18next';
import useToastNotification from './useToastNotification';

export const useArchiveType = (handleDrawerClose) => {
  const { t } = useTranslation();
  const { mutate, isSuccess, isLoading, isError, data } = useCreateArchiveType();
  const { mutate: createLocals } = useCreateArchiveTypeLocal();
  const addArchiveTypeSchema = yup
    .object()
    .shape({
      name_de: yup.string().required(t('name.required')),
      name_eng: yup.string().required(t('name.required')),
    })
    .required();

  const { handleSubmit, control, reset, getValues } = useForm({
    defaultValues: {
      name_eng: '',
      name_de: '',
      icon: '',
    },
    resolver: yupResolver(addArchiveTypeSchema),
    reValidateMode: 'onChange',
  });

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessfullyAddedArchiveType',
    variant: 'success',
    cb: () => {
      if (data) {
        const formVals = getValues();
        createLocals({ name: formVals.name_eng, language: 'eng', archiveType: data?.data?.id });
        reset();
        handleDrawerClose();
      }
    },
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorAddingArchiveType',
    variant: 'error',
  });

  const onSubmit = async (data) => {
    mutate({ data: { name: data.name_de, language: 'de', is_active: true, icon: data.icon } });
  };
  return {
    control,
    handleSubmit,
    onSubmit,
  };
};
