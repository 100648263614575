import { Box, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import LanguageSelect from '../components/selectTranslation/translateSelect';
import { useTranslation } from 'react-i18next';
import { useGetAllTasks } from '../api/tasks';
import useTaskColumns from '../hooks/AllUsers/useTaskColumns';
import { useGetAllBrokers } from '../api/user';
import { useGetStatusByEntity } from '../api/status';
import { useForm } from 'react-hook-form';
import useChangeEffect from '../hooks/useChangeEffect';
import { debounce } from 'lodash-es';
import { handleLimit, handleNextPage, handlePrevPage } from '../utils/paginationFunction';
import TaskTable from '../components/adminDashboard/tasks/TaskTable';
import { tags } from '../components/adminDashboard/tasks/tags';
import { useNavigate } from 'react-router-dom';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Tasks = () => {
  const { t } = useTranslation();
  const [rowSelection, setRowSelection] = useState({});
  const [keyword, setKeyword] = useState('');
  const [filterTag, setFilterTag] = useState(['search']);
  const [filterTagData, setFilterTagData] = useState({});
  const { data: brokers } = useGetAllBrokers();
  const { data: statuses } = useGetStatusByEntity('tasks');
  const navigation = useNavigate();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [columns, sortBy] = useTaskColumns({
    brokers,
    statuses,
  });
  const { data: allTasks, isLoading, isFetching } = useGetAllTasks({ page, limit, ...filterTagData, sortBy });

  const tasks = allTasks?.results;

  const { control } = useForm({
    mode: 'onSubmit',

    reValidateMode: 'onChange',
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterTag(typeof value === 'string' ? value.split(',') : value);
  };

  const updateFilterTagData = () => {
    const dynamicFilterTag = filterTag;
    const dynamicKeyword = keyword;

    setFilterTagData({ [dynamicFilterTag]: dynamicKeyword });
  };

  useChangeEffect(() => {
    updateFilterTagData();
  }, [filterTag, keyword]);

  const debouncedHandleSearchChange = debounce((value) => {
    setKeyword(value);
    setPage(0);
  }, 300);

  const handleRowClick = (row) => {
    if (row?.id) {
      navigation(row?.id);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center" px={4}>
          <Typography variant="h6" fontWeight={700} px={5} py={2.45} sx={{ marginLeft: -4 }}>
            {t('allTasks')}
          </Typography>
          <LanguageSelect />
        </Box>
        <Divider />

        <Box m={5}>
          {tasks && (
            <TaskTable
              MenuProps={MenuProps}
              allTasks={allTasks}
              columns={columns}
              control={control}
              debouncedHandleSearchChange={debouncedHandleSearchChange}
              filterTag={filterTag}
              handleChange={handleChange}
              handleLimit={handleLimit}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
              isFetching={isFetching}
              isLoading={isLoading}
              setPage={setPage}
              tags={tags}
              tasks={tasks}
              setLimit={setLimit}
              onRowClick={handleRowClick}
              cursor="pointer"
              setRowSelection={setRowSelection}
              rowSelection={rowSelection}
              brokers={brokers?.results}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Tasks;
