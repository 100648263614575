import Box from '@mui/material/Box';
import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { useEditArchive } from '../../../hooks/useEditArchive';
import { CustomSwitch } from '../../commonComponents/CustomSwitch';
import SubmitButton from '../../commonComponents/SubmitButton';
import CustomFormInput from '../../commonComponents/CustomFormInput';
import CustomSelect from '../../commonComponents/CustomSelect';

const EditArchiveForm = ({ id, handleDrawerClose, archiveTypes }) => {
  const { handleSubmit, control, onSubmit } = useEditArchive(id, handleDrawerClose, archiveTypes);

  const { t } = useTranslation();
  return (
    <Box component="form" display="flex" flexDirection="column">
      <CustomSelect
        control={control}
        name="type"
        label={t('ArchiveType')}
        data={archiveTypes}
        valueField={(item) => item.id}
        displayField={(item) => item.name}
      />
      <CustomFormInput name="locals.de" control={control} label={t('deutsch_label')} />
      <CustomFormInput name="locals.eng" control={control} label={t('english_label')} />
      <CustomFormInput name="validFrom" type="date" control={control} label={t('ValidFrom')} />
      <CustomFormInput name="validTo" type="date" control={control} label={t('ValidTo')} />
      <Controller
        name="is_active"
        control={control}
        render={({ field }) => (
          <Box display="flex" alignContent="center">
            <Typography>{t('Inactive')}</Typography>
            <CustomSwitch checked={field.value || false} onChange={() => field.onChange(!field.value)} {...field} />
            <Typography>{t('Active')}</Typography>
          </Box>
        )}
      />

      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default EditArchiveForm;
