import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Box, Card, CardContent, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomBarChart = ({ data, title }) => {
  const { t } = useTranslation();
  let uniqueKeys = [];
  data?.forEach((d) => {
    let keys = Object.keys(d).filter((key) => key !== 'userEmail');
    keys.forEach((key) => {
      if (!uniqueKeys.includes(key)) {
        uniqueKeys.push(key);
      }
    });
  });

  return (
    <Card elevation={2} sx={{ borderRadius: '15px', width: '90%' }}>
      <CardContent>
        <Typography variant="h6" fontWeight={900}>
          {title}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box style={{ height: '400px' }}>
          {data && (
            <ResponsiveBar
              data={data}
              keys={uniqueKeys}
              indexBy="userEmail"
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              padding={0.5}
              layout="vertical"
              colors={['#96B6C5', '#DFCCFB', '#94A684', '#6096B4', '#ECEE81', '#B2A4FF']}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 35,
                legend: t('email'),
                legendPosition: 'middle',
                legendOffset: 70,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 10,
                tickRotation: 0,
                tickValues: 5,
                legend: t('FileCount'),
                legendPosition: 'middle',
                legendOffset: -50,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CustomBarChart;
