import { Box, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import findLocalsOrUseFirst from '../../utils/findLocalsOrUseFirst';

import StatusBox from '../../components/StatusBox';
import OutlinedClose from '../../assets/icons/OutlinedClose';
import { formatIfValid } from '../../utils/formatIfValid';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { useState } from 'react';
import MenuDropdown from '../../components/adminDashboard/MenuDropdown';
import { useUpdateLead } from '../../api/leads';
import useToastNotification from '../useToastNotification';
import FilledCheckIcon from '../../assets/icons/FilledCheckIcon';
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox';

const useLeadsColumns = ({ brokers, statuses }) => {
  const { mutate, isSuccess, isLoading, isError } = useUpdateLead();

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessfullyUpdatedLead',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorUpdatingLead',
    variant: 'error',
  });

  const [sortBy, setSortBy] = useState(undefined);

  const setSortItem = (item) => {
    setSortBy((prev) => {
      let [key, value] = prev?.split(':') || [];
      if (key !== item) {
        value = undefined;
      }
      if (value === '' || !value) {
        return `${item}:asc`;
      } else if (value === 'asc') {
        return `${item}:desc`;
      } else if (value === 'desc') {
        return undefined;
      }
    });
  };

  const sortIcon = (item) => {
    const [key, state] = sortBy?.split(':') || [];
    if (item === key) {
      switch (state) {
        case 'desc':
          return <ArrowDropDown color="gray" />;
        case 'asc':
          return <ArrowDropUp color="gray" />;
        default:
          return;
      }
    }
  };

  const { t, i18n } = useTranslation();
  const columnHelper = createColumnHelper();
  const columns = [
    {
      id: 'select',
      header: ({ table }) => {
        return (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        );
      },
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setSortItem('name');
          }}
        >
          <Typography variant="body1" fontWeight={700} sx={{ userSelect: 'none' }}>
            {t('name')}
          </Typography>
          {sortIcon('name')}
        </Box>
      ),
    }),
    columnHelper.accessor((row) => row.phoneNumber, {
      id: 'phoneNumber',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setSortItem('phoneNumber');
          }}
        >
          <Typography variant="body1" fontWeight={700} sx={{ userSelect: 'none' }}>
            {t('phone.number')}
          </Typography>
          {sortIcon('phoneNumber')}
        </Box>
      ),
    }),
    columnHelper.accessor((row) => row.email, {
      id: 'email',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setSortItem('email');
          }}
        >
          <Typography variant="body1" fontWeight={700} sx={{ userSelect: 'none' }}>
            {t('email')}
          </Typography>
          {sortIcon('email')}
        </Box>
      ),
    }),
    columnHelper.accessor((row) => row.zip, {
      id: 'zip',
      header: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setSortItem('zip');
          }}
        >
          <Typography sx={{ userSelect: 'none' }}>{t('zip')}</Typography>
          {sortIcon('zip')}
        </Box>
      ),
      cell: (info) => <Typography>{info.getValue()}</Typography>,
    }),
    columnHelper.accessor((row) => row.kanton, {
      id: 'kanton',
      header: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setSortItem('kanton');
          }}
        >
          <Typography sx={{ userSelect: 'none' }}>{t('kanton')}</Typography> {sortIcon('kanton')}
        </Box>
      ),
      cell: (info) => <Typography>{info.getValue()}</Typography>,
    }),
    columnHelper.accessor((row) => row.address, {
      id: 'address',
      header: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setSortItem('address');
          }}
        >
          <Typography sx={{ userSelect: 'none' }}>{t('Address')}</Typography>
          {sortIcon('address')}
        </Box>
      ),
      cell: (info) => <Typography>{info.getValue()}</Typography>,
    }),
    columnHelper.accessor((row) => row.birthdate, {
      id: 'birthdate',
      header: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setSortItem('birthdate');
          }}
        >
          <Typography sx={{ userSelect: 'none' }}>{t('birthdate')}</Typography>
          {sortIcon('birthdate')}
        </Box>
      ),
      cell: (info) => {
        const formatedDate = formatIfValid(info.getValue(), 'dd.MM.yyyy');
        return <Typography>{formatedDate}</Typography>;
      },
    }),
    columnHelper.accessor((row) => row.married, {
      id: 'married',
      header: () => <Typography sx={{ userSelect: 'none' }}>{t('married')}</Typography>,
      cell: (info) => (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {info.getValue() ? <FilledCheckIcon fill="green" /> : <OutlinedClose fill="red" />}
        </Box>
      ),
    }),
    columnHelper.accessor((row) => row.estate, {
      id: 'estate',
      header: () => <Typography sx={{ userSelect: 'none' }}>{t('estate')}</Typography>,
      cell: (info) => (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {info.getValue() ? <FilledCheckIcon fill="green" /> : <OutlinedClose fill="red" />}
        </Box>
      ),
    }),
    columnHelper.accessor((row) => row.status, {
      id: 'status',
      cell: (info) => {
        const localData = findLocalsOrUseFirst(info?.getValue()?.locals, i18n.language);
        const taskId = info.row.original.id;
        const statusOptions = statuses?.map((status) => ({
          label: findLocalsOrUseFirst(status?.locals, i18n.language)?.name,
          value: status?.id,
          onClick: () => {
            mutate({ id: taskId, data: { status: status?.id } });
          },
        }));

        return (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <StatusBox color={info?.getValue()?.color}>{localData?.name || info.getValue()?.name}</StatusBox>{' '}
            <MenuDropdown
              isEditBroker={true}
              id={taskId}
              menuItems={statusOptions}
              hasIcon={false}
              selectedBrokerId={info?.getValue()?.id}
            />
          </Box>
        );
      },
      header: () => (
        <Typography sx={{ userSelect: 'none' }} variant="body1" fontWeight={700}>
          {t('status')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.createdAt, {
      id: 'createdAt',
      header: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setSortItem('createdAt');
          }}
        >
          <Typography sx={{ userSelect: 'none' }}>{t('createdAt')}</Typography>
          {sortIcon('createdAt')}
        </Box>
      ),
      cell: (info) => {
        const formatedDate = formatIfValid(info.getValue(), 'dd.MM.yyyy');
        return <Typography>{formatedDate}</Typography>;
      },
    }),
    columnHelper.accessor((row) => row.updatedAt, {
      id: 'updatedAt',
      header: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setSortItem('updatedAt');
          }}
        >
          <Typography sx={{ userSelect: 'none' }}>{t('updatedAt')}</Typography>
          {sortIcon('updatedAt')}
        </Box>
      ),
      cell: (info) => {
        const formatedDate = formatIfValid(info.getValue(), 'dd.MM.yyyy');
        return <Typography>{formatedDate}</Typography>;
      },
    }),
    columnHelper.accessor(
      (row) => ({
        firstName: row.broker?.firstName,
        lastName: row.broker?.lastName,
        id: row.broker?.id,
      }),
      {
        id: 'fullName',
        cell: (info) => {
          const brokerData = info.getValue();
          const isBrokerAssigned = brokerData.firstName || brokerData.lastName;
          const taskId = info.row.original.id;

          const brokerOptions = brokers?.results.map((broker) => ({
            label: `${broker.firstName} ${broker.lastName}`,
            value: broker.id,
            onClick: () => {
              mutate({ id: taskId, data: { broker: broker.id } });
            },
          }));

          return (
            <Typography>
              {isBrokerAssigned ? (
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" fontWeight={700}>
                    {brokerData.firstName} {brokerData.lastName}{' '}
                  </Typography>
                  <MenuDropdown
                    id={taskId}
                    menuItems={brokerOptions}
                    selectedBrokerId={brokerData.id}
                    hasIcon={false}
                    isEditBroker={true}
                  />
                </Box>
              ) : (
                <MenuDropdown id={taskId} menuItems={brokerOptions} hasIcon={false} />
              )}
            </Typography>
          );
        },
        header: () => (
          <Typography variant="body1" fontWeight={700}>
            {t('Brokers')}
          </Typography>
        ),
      },
    ),
  ];
  return [columns, sortBy];
};
export default useLeadsColumns;
