import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';

const CustomPieChart = ({ data, title }) => {
  const mappedData = data?.map((item) => ({
    id: item.fileTypeName,
    value: parseInt(item.fileCount, 10),
  }));

  return (
    <Card elevation={2} sx={{ borderRadius: '15px', width: '90%' }}>
      <CardContent>
        <Typography variant="h6" fontWeight={900}>
          {title}
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box style={{ height: '400px' }}>
          {data && (
            <ResponsivePie
              data={mappedData}
              colors={['#96B6C5', '#DFCCFB', '#94A684', '#6096B4', '#ECEE81', '#B2A4FF']}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.7}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 1]],
              }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor="#333333"
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: 'color',
                modifiers: [['darker', 2]],
              }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CustomPieChart;
