import React, { useState } from 'react';
import useColumns from './hooks/useColumns';
import TableComponent from '../../../Table/Table';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../../commonComponents/SubmitButton';
import CustomDrawer from '../../../modals/CustomDrawer';
import CloseIcon from '@mui/icons-material/Close';
import TerminsForm from './components/TerminsForm';
import TableContainer from '../../../Table/TableContainer';

const Journal = ({ id, data, isLoading, isFetching }) => {
  const { t } = useTranslation();
  const colums = useColumns();
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const handleOpen = () => {
    setOpenAddDrawer(!openAddDrawer);
  };
  return (
    <>
      <TableContainer>
        <TableComponent
          hasBoxShadow
          hasAboveStuff
          data={data || []}
          columns={colums}
          isLoading={isLoading}
          isFetching={isFetching}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h6" fontWeight={700}>
              {t('appointments')}
            </Typography>
          </Box>
          <Box display="flex" gap="20px" alignItems="center">
            <SubmitButton onClick={handleOpen} buttonText={t('AddNew')} backgroundColor="#31B099" color="inherit" />
          </Box>
        </TableComponent>
      </TableContainer>
      {/* Add Drawer */}
      <CustomDrawer open={openAddDrawer} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddAppointment')}
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <TerminsForm handleDrawerClose={handleOpen} id={id} />
      </CustomDrawer>
    </>
  );
};

export default Journal;
