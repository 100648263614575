import React from 'react';
import ArticleCard from '../articleCard';
import ArticleCardContent from '../articleCard/cardContent';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import VideoPlayer from './VideoPlayer';
import AppButtons from '../dashboardComponents/AppButtons';

const Hero = ({ heroRef }) => {
  const { t } = useTranslation();
  return (
    <Box id="hero" ref={heroRef}>
      <ArticleCard variant={['left', 'outline']} flexDir={'column-reverse'}>
        <ArticleCardContent
          headingSize={'title'}
          alignTitle={'center'}
          variant="secondary"
          title={t('hero.title')}
          body={t('hero.text')}
        />
        <Box display="flex" justifyContent="center" paddingTop={3}>
          <AppButtons />
        </Box>
        <VideoPlayer src={'https://taxofficestorage.blob.core.windows.net/media/vid_compressed.mp4'} />
      </ArticleCard>
    </Box>
  );
};

export default Hero;
