import React from 'react';
import InfoField from '../../../users/PersonalInfo/components/InfoField';

const AddressInfo = ({ data }) => {
  return (
    <>
      <InfoField labelKey="zip" value={data?.zip ? data?.zip : '/'} />
      <InfoField labelKey="Address" value={data?.address ? data?.address : '/'} />
      <InfoField labelKey="Place" value={data?.kanton ? data?.kanton : '/'} />
    </>
  );
};

export default AddressInfo;
