import { Box, Typography } from '@mui/material';
import React from 'react';
import FormHeader from './components/FormHeader';
import Form from './components/Form';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../../selectTranslation/translateSelect';

const LeadForm2 = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        width: { xs: '100%', md: '480px' },
        py: '24px',
        borderRadius: { xs: '0px', md: '16px' },
        px: '32px',
        zIndex: 2,
        minHeight: { xs: '100vh', md: '100%' },
      }}
    >
      <FormHeader />
      <Form />
      <Typography variant="body1" fontWeight={700} textAlign="center">
        {t('this_information_allows_us_to_process_your_tax_return_almost_twice_as_quickly')}
      </Typography>
      <Box display="flex" justifyContent="center" pt={1}>
        <LanguageSelect />
      </Box>
    </Box>
  );
};

export default LeadForm2;
