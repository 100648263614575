import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
export default function AdditionalFeesCard({ caption, options }) {
  const { t } = useTranslation();
  return (
    <Paper
      elevation={3}
      sx={{
        display: 'flex',
        m: 1,
        flexDirection: 'column',
        gap: '20px',
        justifyContent: 'space-around',
        textAlign: 'center',
        padding: '32px',
        width: '95%',
        height: '95%',
        borderRadius: '16px',
      }}
    >
      <Typography variant="h5">{t(`${caption}`)}</Typography>

      <Grid container spacing={2}>
        {options?.map(({ title, img, price }, i) => (
          <Grid item xs={6} key={i}>
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              gap={2}
              alignItems="center"
              justifyContent="space-around"
            >
              <Typography>{t(`${title}`)}</Typography>
              <img src={img} alt="house" />
              <Typography variant="h6" color="#23978D">
                +
              </Typography>
              <Typography variant="h6" color="#23978D">
                {price}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}
