import React from 'react';
import Box from '@mui/material/Box';
import CardSub from './cardSubComponent';
import AdditionalFeesCard from './additonalFees';
import { useTranslation } from 'react-i18next';
import { Chip, Grid } from '@mui/material';
export default function SimplePaper({ priceRef }) {
  const { t } = useTranslation();
  return (
    <Box
      id="pricing"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexWrap="wrap"
      paddingTop="10px"
      paddingY="40px"
      gap="20px"
    >
      <Chip label={t('pricing')} sx={{ textTransform: 'uppercase', backgroundColor: '#f4f4f7', paddingX: '10px' }} />
      <Grid container>
        <Grid item xs={12} sm={6} lg={3}>
          <CardSub
            planName={'Student'}
            img={'https://taxofficestorage.blob.core.windows.net/media/studentHat.svg'}
            price={'50 CHF'}
            options={[
              { name: 'TaxDeclaration' },
              { name: 'ConsultationAndDiscussion' },
              { name: 'DeadLine' },
              { name: 'SavingPotentialCalculation' },
            ]}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <CardSub
            planName={'Single'}
            img={'https://taxofficestorage.blob.core.windows.net/media/personSingle.svg'}
            price={'99 CHF'}
            options={[
              { name: 'TaxDeclaration' },
              { name: 'ConsultationAndDiscussion' },
              { name: 'DeadLine' },
              { name: 'SavingPotentialCalculation' },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <CardSub
            planName={'Married'}
            img={'https://taxofficestorage.blob.core.windows.net/media/familyRoof.svg'}
            price={'149 CHF'}
            options={[
              { name: 'TaxDeclaration' },
              { name: 'ConsultationAndDiscussion' },
              { name: 'DeadLine' },
              { name: 'SavingPotentialCalculation' },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <AdditionalFeesCard
            caption={'Additional.Fees'}
            options={[
              {
                title: 'ForEach',
                img: 'https://taxofficestorage.blob.core.windows.net/media/houseTrees.svg',
                price: '50 CHF',
              },
              {
                title: 'InvestmentDepot',
                img: 'https://taxofficestorage.blob.core.windows.net/media/bank.svg',
                price: '50 CHF',
              },
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
