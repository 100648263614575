import { Box } from '@mui/material';
import SelectFilterField from '../../../leads/LeadsTable/components/Header/components/Filters/components/SelectFilterField';
import SearchInput from '../../../leads/LeadsTable/components/Header/components/Filters/components/SearchInput';
import ExportButton from '../../../leads/LeadsTable/components/Header/components/Filters/components/ExportButton';

const Filters = ({ filterTag, handleChange, MenuProps, tags, control, debouncedHandleSearchChange }) => {
  return (
    <Box display="flex" gap="20px" alignItems="center">
      <SelectFilterField MenuProps={MenuProps} filterTag={filterTag} handleChange={handleChange} tags={tags} />
      <SearchInput control={control} debouncedHandleSearchChange={debouncedHandleSearchChange} />
      <ExportButton />
    </Box>
  );
};

export default Filters;
