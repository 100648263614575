import React from 'react';
import PageBackground from '../components/LeadPage/PageBackground';
import LeadForm from '../components/LeadsForms/LeadForm';
import { Helmet } from 'react-helmet';

const LeadPage = () => {
  window.dataLayer.push({
    event: 'pageview',
  });
  return (
    <>
      <Helmet>
        <script>fbq('track', 'Lead');</script>
      </Helmet>
      <PageBackground>
        <LeadForm />
      </PageBackground>
    </>
  );
};

export default LeadPage;
