import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const VideoPlayer = ({ src }) => {
  const videoWrapperStyle = {
    position: 'relative',
    width: '100%',
    paddingTop: '56.25%',
  };

  const videoStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
      <Paper sx={{ overflow: 'hidden', width: '100%', maxWidth: '900px', borderRadius: '16px' }}>
        <div style={videoWrapperStyle}>
          <video src={src} autoPlay loop controls style={videoStyle}>
            Your browser does not support the video tag.
          </video>
        </div>
      </Paper>
    </Box>
  );
};

export default VideoPlayer;
