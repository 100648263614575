import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetCurrentUser } from '../api/user';
import { setCurrentUser, clearUser } from '../store/currentUserSlice';
import useLocalStorage from './useLocalStorage';
import jwt from 'jwt-decode';
import { useAuth } from './useAuth';

const useCurrentUser = () => {
  const state = useSelector((state) => state.auth.currentUser);

  return {
    ...state,
  };
};

export default useCurrentUser;

// WARNING: DO NOT REMOVE THE UNUSED IMPORTS. FOR SOME REASON IT CRASHES THE APP. TRIED TO REMOVE THEM MULTIPLE TIMES, WITHOUT ANY SUCCESS
// PLEASE DON'T TRY THIS UNDER ANY CIRCUMSTANCE
