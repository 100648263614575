import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useForgotPass } from '../../hooks/useForgotPass';
import CustomFormInput from '../commonComponents/CustomFormInput';
import SubmitButton from '../commonComponents/SubmitButton';

export default function ForgotPassLayout() {
  const { handleSubmit, onSubmit, control } = useForgotPass();
  const { t } = useTranslation();

  return (
    <Box component="form" display="flex" width="100%" flexDirection="column" justifyContent="space-evenly" gap="32px">
      <CustomFormInput name="email" control={control} label={t('email')} />
      <SubmitButton
        onClick={handleSubmit(onSubmit)}
        buttonText={t('SendEmail')}
        backgroundColor="#0F5D66"
        color="white"
      />
    </Box>
  );
}
