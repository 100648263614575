import { Divider, IconButton, Typography, Box } from '@mui/material';
import React from 'react';
import ArchiveTable from './ArchiveTable';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useGetAllArchiveTypes, useGetAllArchives } from '../../../api/archive';
import LanguageSelect from '../../selectTranslation/translateSelect';

const ArchivesSettingsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useGetAllArchiveTypes();
  const { data: archives, isLoading, isFetching } = useGetAllArchives();

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center" px={4}>
          <IconButton onClick={() => navigate(-1)} sx={{ marginLeft: -2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" fontWeight={700} px={5} py={2.45} sx={{ marginLeft: -4, flexGrow: 1 }}>
            {t('ArchivesSettings')}
          </Typography>
          <LanguageSelect />
        </Box>
        <Divider />
        <Box p={5}>
          <Box display="flex" flexDirection="column">
            <ArchiveTable archiveTypes={data} data={archives} isFetching={isFetching} isLoading={isLoading} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ArchivesSettingsPage;
