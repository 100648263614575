import React from 'react';
import InfoField from './InfoField';

const AddressInfo = ({ data }) => {
  return (
    <>
      <InfoField labelKey="zip" value={data?.zipCode ? data?.zipCode : '/'} />
      <InfoField labelKey="Address" value={data?.address ? data?.address : '/'} />
      <InfoField labelKey="Place" value={data?.place ? data?.place : '/'} />
    </>
  );
};

export default AddressInfo;
