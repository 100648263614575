import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import rootReducer from './rootReducer';
import { verifyAccessToken } from '../api/auth';
import { logout } from './auth/authActions';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store, null, () => {
  const token = store.getState().auth.accessToken;
  if (token) {
    verifyAccessToken(token).catch(() => {
      store.dispatch(logout());
    });
  }
});
