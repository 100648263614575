import { Box, Button, Card, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import image from '../../assets/banners/see-more.png';

const SeeMoreCard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        m: 1,
        mx: { xs: 0, sm: 1 },
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        border: '0.5px solid #DCE4E8',
        borderRadius: '24px',
        '&:hover': {
          boxShadow: '0px 2px 4px -5px rgba(0, 0, 0, 0.01)',
        },
      }}
    >
      <CardMedia
        component="img"
        height="200px"
        image={image}
        sx={{
          objectFit: 'contain',
          width: '100%',
        }}
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 1,
          padding: '15px',
          gap: '10px',
        }}
      >
        <Box>
          <Typography sx={{ my: 2 }} variant="subtitle1">
            {t('knowledge_center')}
          </Typography>
          <Typography sx={{ my: 2 }} gutterBottom variant="h6" fontWeight={900} color="#0F5D66">
            {t('benefit_contact')}
          </Typography>
        </Box>
        <Box display="flex">
          <Button
            variant="outlined"
            sx={{
              bgcolor: '#fff',
              color: '#0F5D66',
              textTransform: 'none',
              borderRadius: '15px',
              borderColor: 'rgba(15, 93, 102, 0.4)',
              fontWeight: '700',
              padding: '8px 12px',
              '&:hover': {
                color: 'rgba(15, 93, 102,1)',
                bgcolor: 'rgba(255, 255, 255,1)',
                borderColor: '#0F5D66',
              },
              flex: 1,
              height: 60,
            }}
            onClick={() => {
              navigate('/articles');
            }}
          >
            {t('see_more')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SeeMoreCard;
