import { Box } from '@mui/material';
import React from 'react';
import { useAddNotes } from '../../../../hooks/useAddNotes';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../../commonComponents/SubmitButton';
import CustomFormInput from '../../../commonComponents/CustomFormInput';

const AddNotesForm = ({ id, handleDrawerClose }) => {
  const { t } = useTranslation();
  const { handleSubmit, control, onSubmit } = useAddNotes(id, handleDrawerClose);
  return (
    <Box component="form" display="flex" flexDirection="column">
      <CustomFormInput name="note" control={control} label={t('Notes')} />
      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default AddNotesForm;
