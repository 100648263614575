import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../../commonComponents/SubmitButton';
import CustomFormInput from '../../../commonComponents/CustomFormInput';

const AppointmentSlotsForm = ({ handleSubmit, control, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Box component="form" display="flex" flexDirection="column">
      <Typography>{t('time')}</Typography>
      <Box my={1} display="flex" gap="20px">
        <CustomFormInput name="from.hour" control={control} label={t('FromHour')} />
        <CustomFormInput name="from.minute" control={control} label={t('FromMinute')} />
      </Box>
      <Box my={1} display="flex" gap="20px">
        <CustomFormInput name="to.hour" control={control} label={t('ToHour')} />
        <CustomFormInput name="to.minute" control={control} label={t('ToMinute')} />
      </Box>
      <Typography>{t('localization')}</Typography>
      <CustomFormInput name="label.de" control={control} label={t('deutsch_label')} />
      <CustomFormInput name="label.eng" control={control} label={t('english_label')} />
      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default AppointmentSlotsForm;
