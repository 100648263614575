import { Box, Collapse, Popover, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import findDeepType from '../../utils/findDeep';
import AddIconButton from './components/AddIconButton';
import InfoIconButton from './components/InfoIconButton';

const DesktopDocumentTab = ({
  isLoading,
  suffix,
  tab,
  lang,
  isSub,
  onLastTabClick,
  activeTabs,
  setActiveTabs,
  isActive,
  onAddButtonClick,
  isMenu = false,
}) => {
  const params = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    const foundTab = findDeepType(tab, params?.fileType, lang);
    if (foundTab) {
      setActiveTabs([foundTab.id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.fileType, tab]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        sx={{
          my: '2px',
          background: isMenu && isActive ? '#0F5D66' : '#E8ECEE',
          borderRadius: '16px',
          boxShadow: 'none',
          width: '100%',
          padding: '5px',
          fontWeight: '400',
          '&:hover': isMenu && {
            bgcolor: !isActive ? 'rgba(237, 241, 243,1)' : '#0F5D66',
            color: !isActive ? '#000' : undefined,
          },
          '&:active': isMenu && { bgcolor: '#0F5D66', color: '#fff' },
          color: isMenu && isActive ? '#fff' : '#1A1C1E',
          bgcolor: isMenu && isActive ? '#0F5D66' : '#',
          cursor: 'pointer',
        }}
        variant="contained"
        disabled={!isActive && isLoading}
        onClick={() => {
          if (tab.subTypes?.length) {
            if (isSub) {
              setActiveTabs((prev) => {
                if (prev?.includes(tab.id)) {
                  return [...prev?.filter((p) => p !== tab.id)];
                }
                return [...prev, tab.id];
              });
            } else {
              setActiveTabs((prev) => {
                if (prev?.includes(tab.id)) {
                  return [];
                }
                return [tab.id];
              });
            }
          } else {
            if (onLastTabClick) {
              onLastTabClick(tab);
            }
          }
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" paddingLeft={isSub ? 5 : 0}>
          {!isSub && tab.icon && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: isMenu && isActive ? '#fff' : '#EDF1F3',
                overflow: 'visible',
                borderRadius: '200px',
                paddingLeft: '10px',
                width: '20px',
                height: '20px',
                '& img': {
                  filter: !isActive
                    ? 'brightness(0) saturate(100%) invert(27%) sepia(25%) saturate(2578%) hue-rotate(148deg) brightness(89%) contrast(89%)'
                    : 'brightness(0) saturate(100%)',
                },
              }}
            >
              <img alt="Tab Icon" src={tab.icon}></img>
            </Box>
          )}

          <Box
            fontWeight={isActive ? 700 : 400}
            whiteSpace={'pre-wrap'}
            sx={{
              wordBreak: 'break-word',
              userSelect: 'none',
            }}
            textAlign="left"
            ml={2}
            px={0}
            width={160}
          >
            {tab?.[`name_${lang}`]}
            <InfoIconButton onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} isActive={isActive} />
          </Box>
          <Box color={isMenu && isActive ? '#fff' : '#ACB5BB'}>
            {tab?.subTypes?.length > 0 ? (
              <Box display="flex" alignItems="center">
                <ArrowForwardIosIcon
                  sx={{
                    transform:
                      activeTabs.includes(tab.id) || findDeepType(tab, params?.fileType, lang)
                        ? 'rotate(90deg)'
                        : 'rotate(0deg)',
                    transition: 'transform 0.2s ease-in-out',
                  }}
                />
              </Box>
            ) : (
              isMenu && (
                <AddIconButton isActive={isActive} onAddButtonClick={onAddButtonClick} tab={tab} isMenu={isMenu} />
              )
            )}
          </Box>
        </Box>
      </Box>
      <Collapse in={!!(activeTabs?.includes(tab.id) || findDeepType(tab, params?.fileType, lang))}>
        {tab.subTypes?.map((subTab) => (
          <DesktopDocumentTab
            isSub
            isLoading={isLoading}
            lang={lang}
            key={subTab.id}
            onLastTabClick={onLastTabClick}
            suffix={suffix}
            tab={subTab}
            activeTabs={activeTabs}
            setActiveTabs={setActiveTabs}
            onAddButtonClick={onAddButtonClick}
            isActive={activeTabs.includes(subTab.id)}
            isMenu={isMenu}
          />
        ))}
      </Collapse>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>{tab?.[`description_${lang}`]}</Typography>
      </Popover>
    </>
  );
};

export default DesktopDocumentTab;
