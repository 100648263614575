import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetAllIcons, useGetFileTypeById } from '../../../api/fileTypes';
import { useEditFileType } from '../../../hooks/useEditFileType';
import { CustomSwitch } from '../../commonComponents/CustomSwitch';
import SubmitButton from '../../commonComponents/SubmitButton';
import CustomFormInput from '../../commonComponents/CustomFormInput';

const EditFileTypeForm = ({ id, handleDrawerClose }) => {
  const { data: fileType } = useGetFileTypeById(id);

  const { t } = useTranslation();
  const { handleSubmit, onSubmit, control, defaultValuesLoaded } = useEditFileType(
    id,
    fileType,
    handleDrawerClose,
    !(fileType && !fileType?.parentType),
  );
  const { data: icons } = useGetAllIcons();
  const [selectedIcon, setSelectedIcon] = useState('');

  useEffect(() => {
    if (icons) {
      setSelectedIcon(fileType?.icon);
    }
  }, [icons, setSelectedIcon, fileType]);
  if (!defaultValuesLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Box component="form" display="flex" flexDirection="column">
      <CustomFormInput name="name_eng" control={control} label={t('NameEnglish')} />
      <CustomFormInput name="name_de" control={control} label={t('NameGerman')} />
      <CustomFormInput name="description_eng" control={control} label={t('DescEnglish')} />
      <CustomFormInput name="description_de" control={control} label={t('DescGerman')} />
      {fileType && !fileType?.parentType && (
        <Controller
          name="icon"
          control={control}
          render={({ field }) => (
            <Box display="flex" my={1} flexDirection="column" width="100%">
              <Typography variant="body2" fontSize="12px">
                {t('Icon')}
              </Typography>
              <Grid container spacing={2}>
                {icons?.map((icon) => (
                  <Grid key={icon.url} item>
                    <Box
                      sx={{
                        border: selectedIcon === icon.url ? '2px solid #31B099' : 'none',
                        borderRadius: '15px',
                        paddingX: 1,
                        paddingTop: 1,
                      }}
                      onClick={() => {
                        setSelectedIcon(icon.url);
                        field.onChange(icon.url);
                      }}
                    >
                      <img
                        src={icon.url}
                        alt={icon.name}
                        style={{
                          width: '44px',
                        }}
                      ></img>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        />
      )}
      <Controller
        name="is_active"
        control={control}
        render={({ field }) => (
          <Box display="flex" alignContent="center">
            <Typography>{t('Inactive')}</Typography>
            <CustomSwitch checked={field.value} onChange={() => field.onChange(!field.value)} {...field} />
            <Typography>{t('Active')}</Typography>
          </Box>
        )}
      />
      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default EditFileTypeForm;
