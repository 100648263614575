import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Controller } from 'react-hook-form';
import DotProgress from '../../dashboardComponents/DotProgress';
import { useTranslation } from 'react-i18next';

const UploadButton = ({ onSubmit, progress, register, control, props, multiple, disabled }) => {
  const { t } = useTranslation();
  const handleFileChange = (event) => {
    if (multiple) {
      // Handle multiple files
      const files = event.target.files;
      if (files.length) {
        onSubmit(files);
      }
    } else {
      // Handle single file
      const file = event.target.files[0];
      if (file) {
        onSubmit(file);
      }
    }
  };

  return (
    <IconButton
      variant="contained"
      component="label"
      color="inherit"
      disabled={progress ? true : false || disabled}
      sx={{
        width: '240px',
        height: '54px',
        color: '#fff',
        backgroundColor: props.variant !== 'outlined' && '#31B099',
        borderRadius: '14px',
        '&:hover': {
          backgroundColor: '#31B09999',
        },
        '&.Mui-disabled': {
          bgcolor: '#0f5d6688',
          border: '2px solid #0f5d6688',
          color: '#fff',
        },
        display: 'flex',
        position: 'relative',
        gap: '8px',
        overflow: 'hidden',
      }}
    >
      <Box position="absolute" bgcolor="#0f5d66" height="54px" width={`${progress * 2.4}px`} left={0}></Box>
      <Box display="flex" position="relative" zIndex={2} justifyContent="center">
        <Box display="flex" alignItems="center" gap="10px">
          <Typography variant="body2" color="inherit" fontFamily="Manrope" fontWeight={600} display="flex">
            {progress ? t('adding_document') : t('add_document')}
          </Typography>
          {progress && (
            <Typography width="30px" color="inherit" textAlign="left">
              <DotProgress />
            </Typography>
          )}
          {!progress && <AddIcon sx={{ fontSize: 20, fontWeight: 600, color: '#ffffff' }} />}
        </Box>
        <Controller
          control={control}
          name={'fileType'}
          render={({ field }) => (
            <input
              type="file"
              ref={register}
              hidden
              accept="application/pdf, image/*"
              {...field}
              onChange={handleFileChange}
              multiple
            />
          )}
        />
      </Box>
    </IconButton>
  );
};

export default UploadButton;
