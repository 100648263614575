import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StatusSelect = ({ value, onChange, error }) => {
  const { t } = useTranslation();
  const isSingle = value === 'single';
  const isMarried = value === 'married';
  const isDivorced = value === 'divorced';
  const istKaputt = value === 'widowed';
  return (
    <>
      <Box display="flex" gap={1} flexWrap="wrap">
        <Button
          sx={{
            padding: '12px 24px',
            outline: isSingle ? '1px solid #31B099' : '1px solid #DCE4E8',
            borderRadius: '14px',
            color: isSingle ? '#fff' : 'rgb(0,0,0)',
            backgroundColor: isSingle ? '#31B099' : '#',
            '&:focus; &:hover': {
              outline: '2px solid #31B099',
              color: '#31B099',
            },
          }}
          variant="outlined"
          onClick={() => {
            if (onChange) {
              onChange('single');
            }
          }}
        >
          {t('single')}
        </Button>
        <Button
          sx={{
            padding: '12px 24px',
            outline: isMarried ? '1px solid #31B099' : '1px solid #DCE4E8',
            borderRadius: '14px',
            color: isMarried ? '#fff' : 'rgb(0,0,0)',
            backgroundColor: isMarried ? '#31B099' : '#',
            '&:focus; &:hover': {
              outline: '2px solid #31B099',
              color: '#31B099',
            },
          }}
          variant="outlined"
          onClick={() => {
            if (onChange) {
              onChange('married');
            }
          }}
        >
          {t('married')}
        </Button>
        <Button
          sx={{
            padding: '12px 24px',
            outline: isDivorced ? '1px solid #31B099' : '1px solid #DCE4E8',
            borderRadius: '14px',
            color: isDivorced ? '#fff' : 'rgb(0,0,0)',
            backgroundColor: isDivorced ? '#31B099' : '#',
            '&:focus; &:hover': {
              outline: '2px solid #31B099',
              color: '#31B099',
            },
          }}
          variant="outlined"
          onClick={() => {
            if (onChange) {
              onChange('divorced');
            }
          }}
        >
          {t('divorced')}
        </Button>
        <Button
          sx={{
            padding: '12px 24px',
            outline: istKaputt ? '1px solid #31B099' : '1px solid #DCE4E8',
            borderRadius: '14px',
            color: istKaputt ? '#fff' : 'rgb(0,0,0)',
            backgroundColor: istKaputt ? '#31B099' : '#',
            '&:focus; &:hover': {
              outline: '2px solid #31B099',
              color: '#31B099',
            },
          }}
          variant="outlined"
          onClick={() => {
            if (onChange) {
              onChange('widowed');
            }
          }}
        >
          {t('widowed')}
        </Button>
      </Box>
      {error && (
        <Typography variant="caption" color="error">
          {error.message}
        </Typography>
      )}
    </>
  );
};

export default StatusSelect;
