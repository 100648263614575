import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useGetAllFamilyRelations } from '../../api/familyMembers';
import { useEditFamilyMember } from '../../hooks/useEditFamilyMember';
import CustomFormInput from '../commonComponents/CustomFormInput';
import SubmitButton from '../commonComponents/SubmitButton';
import CustomSelect from '../commonComponents/CustomSelect';
import findLocalsOrUseFirst from '../../utils/findLocalsOrUseFirst';

const EditFamilyMembers = ({ id, handleDrawerClose }) => {
  const { t, i18n } = useTranslation();
  const { onSubmit, handleSubmit, control, defaultValuesLoaded } = useEditFamilyMember(id, handleDrawerClose);
  const { data } = useGetAllFamilyRelations();
  const filteredFamilyRelations = data?.map((item) => {
    const localData = findLocalsOrUseFirst(item.locals, i18n.language);
    return {
      id: item.id,
      localData: localData,
    };
  });
  if (!defaultValuesLoaded) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Box display="flex" flexDirection="column" component="form" gap="30px" width="100%">
        <CustomFormInput name="firstName" control={control} label={t('firstname')} />
        <CustomFormInput name="lastName" control={control} label={t('lastname')} />
        <CustomSelect
          control={control}
          name="familyRelation"
          label={t('FamilyRelation')}
          data={filteredFamilyRelations}
          valueField={(item) => item.id}
          displayField={(item) => item?.localData?.name}
        />
        <CustomFormInput name="birthDate" type="date" control={control} label={'Date'} />
        <SubmitButton
          onClick={handleSubmit(onSubmit)}
          buttonText={t('Edit')}
          backgroundColor="#31B099"
          color="inherit"
        />
      </Box>
    </>
  );
};

export default EditFamilyMembers;
