import { useUpdateAppointment } from '../api/termins';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import useToastNotification from './useToastNotification';
import { format } from 'date-fns';
export const useEditAppointment = (id, selectedAppointment) => {
  const { mutate, isSuccess, isLoading } = useUpdateAppointment();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    from: yup.date().required(t('From')),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      from: selectedAppointment?.from ? format(new Date(selectedAppointment?.from), "yyyy-MM-dd'T'HH:mm") : '',
      broker: '',
      user: '',
      interest: '',
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'successfullyAddedStatus',
    variant: 'success',
  });

  const onSubmit = (data) => {
    mutate({
      id: selectedAppointment?.id,
      data: {
        from: new Date(data.from).getTime(),
        lead: selectedAppointment?.lead?.id,
        broker: id,
      },
    });

    reset();
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    isLoading,
    errors,
    reset,
  };
};
