import { Box } from '@mui/system';
import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../footer.css';

const SocialIcons = () => {
  return (
    <Box>
      <ul className="socialList">
        <li>
          <a
            className="facebook"
            target="_blank"
            href="https://www.facebook.com/profile.php?id=100089510193782"
            rel="noreferrer"
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <FacebookIcon />
          </a>
        </li>
        <li>
          <a
            className="instagram"
            target="_blank"
            href="https://www.instagram.com/digitalesbuero.ch/?next=%2f"
            rel="noreferrer"
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <InstagramIcon />
          </a>
        </li>
        <li>
          <a
            className="linkedin"
            target="_blank"
            href="https://www.linkedin.com/in/digitalesb%C3%BCro-ch-1018462b0/"
            rel="noreferrer"
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <LinkedInIcon />
          </a>
        </li>
      </ul>
    </Box>
  );
};

export default SocialIcons;
