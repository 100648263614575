import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import isISODateValid from '../../utils/isISODateValid';
import { format } from 'date-fns';

const useUserNotesColumns = () => {
  const { t } = useTranslation();

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          #
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row?.createdAt, {
      id: 'createdAt',
      cell: (info) => (
        <Typography>{isISODateValid(info.getValue()) && format(new Date(info.getValue()), 'dd.MM.yyyy')}</Typography>
      ),
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Date')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row?.note, {
      id: 'note',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Note')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row?.createdBy, {
      id: 'createdByFullName',
      cell: (info) => {
        const createdBy = info.getValue();
        return createdBy ? (
          <Typography>{`${createdBy.firstName} ${createdBy.lastName}`}</Typography>
        ) : (
          <Typography>-</Typography>
        );
      },
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Created By')}
        </Typography>
      ),
    }),
  ];
  return columns;
};
export default useUserNotesColumns;
