import { useQuery } from 'react-query';
import { customInstance } from './client';

const suffix = 'v1/stats';

//GET Task Count
const getTaskCount = () => {
  return customInstance({
    url: `${suffix}/tasks`,
    method: 'get',
  });
};

export const useGetTaskCount = () => {
  return useQuery(['tasks-count'], async () => {
    const data = await getTaskCount();
    return data;
  });
};

//GET File Count
const getFileCount = () => {
  return customInstance({
    url: `${suffix}/files`,
    method: 'get',
  });
};

export const useGetFileCount = () => {
  return useQuery(['file-count'], async () => {
    const data = await getFileCount();
    return data;
  });
};

//GET User Count
const getUserCount = () => {
  return customInstance({
    url: `${suffix}/users`,
    method: 'get',
  });
};

export const useGetUserCount = () => {
  return useQuery(['user-count'], async () => {
    const data = await getUserCount();
    return data;
  });
};

const getTopTen = () => {
  return customInstance({
    url: `${suffix}/topten`,
    method: 'get',
  });
};

export const useGetTopTen = () => {
  return useQuery(['top-ten-count'], async () => {
    const data = await getTopTen();

    const transformData = (data) => {
      return data.map((item) => {
        const transformedItem = { userEmail: item.userEmail };

        item.files.forEach((file) => {
          transformedItem[file.archiveName.trim()] = file.fileCount;
        });

        return transformedItem;
      });
    };

    const transformedData = transformData(data);

    return transformedData;
  });
};

const getFileTypeCount = () => {
  return customInstance({
    url: `${suffix}/file-type-count`,
    method: 'get',
  });
};

export const useGetFileTypeCount = () => {
  return useQuery(['file-type-count'], async () => {
    const data = await getFileTypeCount();
    return data;
  });
};

const getBrokerTaskCount = (id) => {
  return customInstance({
    url: `${suffix}/broker-tasks/${id}`,
    method: 'get',
  });
};

export const useGetBrokerTaskCount = (id) => {
  return useQuery(['tasks-count', id], () => getBrokerTaskCount(id));
};

const getNextTerminForBroker = (id) => {
  return customInstance({
    url: `${suffix}/next-appointment/${id}`,
    method: 'get',
  });
};

export const useGetNextTerminForBroker = (id) => {
  return useQuery(['next-termin', id], () => getNextTerminForBroker(id));
};

const getBrokerTerminCount = (id) => {
  return customInstance({
    url: `${suffix}/termins/${id}`,
    method: 'get',
  });
};

export const useGetBrokerTerminCount = (id) => {
  return useQuery(['termins-count', id], () => getBrokerTerminCount(id));
};

const getCompletedTerminsCount = (id) => {
  return customInstance({
    url: `${suffix}/completed-termins/${id}`,
    method: 'get',
  });
};

export const useGetBrokerCompletedTerminCount = (id) => {
  return useQuery(['completed-termins', id], () => getCompletedTerminsCount(id));
};
