import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import MenuDropdown from '../../components/adminDashboard/MenuDropdown';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import findLocalsOrUseFirst from '../../utils/findLocalsOrUseFirst';

const useFamilyMemberColumn = (handleOpenEdit, handleDeleteModal) => {
  const { t, i18n } = useTranslation();

  const menuItems = [
    {
      label: t('Edit'),
      icon: 'https://taxofficestorage.blob.core.windows.net/media/edit-2.svg',
      onClick: (id) => handleOpenEdit(id),
    },
    {
      label: t('Delete'),
      icon: 'https://taxofficestorage.blob.core.windows.net/media/trash.svg',
      onClick: (id) => handleDeleteModal(id),
    },
  ];
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          #
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.firstName, {
      id: 'firstName',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('firstname')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.lastName, {
      id: 'name',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('lastname')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.birthDate, {
      id: 'birthDate',
      cell: (info) => <Typography>{format(new Date(info.getValue()), 'dd.MM.yyyy')}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('birthdate')}
        </Typography>
      ),
    }),
    columnHelper.accessor(
      (row) => {
        const localData = findLocalsOrUseFirst(row.familyRelation.locals, i18n.language);
        return localData.name;
      },
      {
        id: 'familyRelation',
        cell: (info) => <Typography>{info.getValue()}</Typography>,
        header: () => (
          <Typography variant="body1" fontWeight={700}>
            {t('FamilyRelation')}
          </Typography>
        ),
      },
    ),

    columnHelper.accessor('action', {
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Action')}
        </Typography>
      ),
      cell: (info) => {
        return <MenuDropdown id={info.row.original.id} menuItems={menuItems} />;
      },
      footer: (info) => info.column.id,
    }),
  ];
  return columns;
};
export default useFamilyMemberColumn;
