import { Box } from '@mui/material';
import React from 'react';
import TableComponent from '../../Table/Table';
import useArchiveColumns from '../../../hooks/AllUsers/useArchiveColumns';
import { IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import CustomDrawer from '../../modals/CustomDrawer';
import CloseIcon from '@mui/icons-material/Close';
import ArchiveSettings from './ArchiveSettings';
import EditArchiveForm from './EditArchiveForm';
import { useTranslation } from 'react-i18next';
import VerifyLoader from '../../verifyLoader/VerifyLoader';
import SubmitButton from '../../commonComponents/SubmitButton';

const ArchiveTable = ({ archiveTypes, data, isLoading, isFetching }) => {
  const { t } = useTranslation();
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [archiveId, setArchiveId] = useState(null);

  const handleOpen = () => {
    setOpenAddDrawer(!openAddDrawer);
  };

  const handleOpenEdit = (id) => {
    setOpenEditDrawer(!openEditDrawer);
    if (typeof id === 'string') {
      setArchiveId(id);
    } else {
      setArchiveId(null);
    }
  };

  const columns = useArchiveColumns(handleOpenEdit);

  return (
    <>
      <Box m={5}>
        <Box width="100%">
          {isLoading || isFetching ? (
            <Box width="100%" display="flex" justifyContent="center">
              <VerifyLoader />
            </Box>
          ) : (
            data && (
              <TableComponent hasAboveStuff data={data} columns={columns}>
                <SubmitButton onClick={handleOpen} buttonText={t('AddNew')} backgroundColor="#31B099" color="inherit" />
              </TableComponent>
            )
          )}
        </Box>
      </Box>
      {/* Add Drawer */}
      <CustomDrawer open={openAddDrawer} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddArchive')}
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <ArchiveSettings data={archiveTypes} handleDrawerClose={handleOpen} />
      </CustomDrawer>

      {/* Edit Drawer */}
      <CustomDrawer open={openEditDrawer} handleOpen={handleOpenEdit}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('EditArchive')}
          </Typography>
          <IconButton onClick={handleOpenEdit}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <EditArchiveForm id={archiveId} handleDrawerClose={handleOpenEdit} archiveTypes={archiveTypes} />
      </CustomDrawer>
    </>
  );
};

export default ArchiveTable;
