import axios from 'axios';
import { store } from '../store';
import { logout } from '../store/auth/authActions';
import { useToast } from '../hooks/useToast';

const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL : 'http://localhost:5001';
export const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en-GB',
  },
});

const customInstanceFactory = (axiosBuilder) => {
  return function (config) {
    const controller = new AbortController();
    const promise = axiosBuilder({
      ...config,
      signal: controller.signal,
    }).then(({ data }) => data);

    promise.cancel = () => {
      controller.abort('Query was cancelled by React Query');
    };

    promise.catch((error) => {
      controller.abort('Query was cancelled by React Query');
      console.debug('API error:', error);
    });

    return promise;
  };
};

export const customInstance = (args) => {
  const accessToken = store.getState()?.auth?.accessToken;
  return customInstanceFactory(axiosClient)({
    headers: {
      'Accept-Language': 'en-GB',
      Authorization: `Bearer ${accessToken}`,
      ...args.headers,
    },
    ...args,
  });
};

export const configureInitialInterceptors = (appStore) => {
  axiosClient.interceptors.request.use((conf) => {
    const state = appStore.getState();
    const updatedConf = conf;
    const token = state?.auth?.accessToken;
    if (!updatedConf.headers['Authorization']) {
      updatedConf.headers['Authorization'] = `Bearer ${token}`;
    }
    return updatedConf;
  });

  axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
      const prevRequest = error?.config;
      if (error?.response?.status === 401 && prevRequest?.url !== '/auth/sign-in') {
        store.dispatch(logout());
        useToast('Session expired, please login again', 'error');
      }
      return Promise.reject(error);
    },
  );
};
