import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAddFileType } from '../../../hooks/useAddFileType';
import { useGetAllIcons } from '../../../api/fileTypes';
import SubmitButton from '../../commonComponents/SubmitButton';
import CustomFormInput from '../../commonComponents/CustomFormInput';
import CustomSelect from '../../commonComponents/CustomSelect';

const FileTypeForm = ({ handleDrawerClose, parentType, archiveTypes }) => {
  const { t, i18n } = useTranslation();
  const { handleSubmit, onSubmit, control } = useAddFileType(handleDrawerClose, parentType);
  const { data: icons } = useGetAllIcons();
  const [selectedIcon, setSelectedIcon] = useState('');

  return (
    <Box component="form" display="flex" flexDirection="column">
      {!parentType && (
        <CustomSelect
          control={control}
          name="archiveType"
          label={t('ArchiveType')}
          data={archiveTypes}
          valueField={(item) => item.id}
          displayField={(item) => item?.locals?.find((local) => local?.language === i18n.language)?.name || item?.name}
        />
      )}
      <CustomFormInput name="name_eng" control={control} label={t('NameEnglish')} />
      <CustomFormInput name="name_de" control={control} label={t('NameGerman')} />
      <CustomFormInput name="description_eng" control={control} label={t('DescEnglish')} />
      <CustomFormInput name="description_de" control={control} label={t('DescGerman')} />
      {!parentType && (
        <Controller
          name="icon"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Box display="flex" my={1} flexDirection="column" width="100%">
              <Typography variant="body2" fontSize="12px">
                {t('Icon')}
              </Typography>
              <Grid container spacing={1}>
                {icons?.map((item) => (
                  <Grid key={item.url} item>
                    <Box
                      sx={{
                        border: selectedIcon === item.name ? '2px solid #31B099' : 'none',
                        borderRadius: '15px',
                        paddingX: 1,
                        paddingTop: 1,
                      }}
                      onClick={() => {
                        setSelectedIcon(item.name);
                        field.onChange(item.url);
                      }}
                    >
                      <img
                        src={item.url}
                        alt={item.name}
                        style={{
                          width: '44px',
                        }}
                      ></img>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              {error && (
                <Typography variant="caption" color="error">
                  {t('SelectIcon')}
                </Typography>
              )}
            </Box>
          )}
        />
      )}
      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default FileTypeForm;
