import { useMutation, useQuery, useQueryClient } from 'react-query';
import { customInstance } from './client';

const suffix = '/document-widgets';
const suffix2 = '/widget-localizations';

const getAllDocumentWidgets = () => {
  return customInstance({
    url: `${suffix}`,
    method: 'get',
  });
};

export const useGetAllDocumentWidgets = () => {
  const query = useQuery(['document-widgets'], () => getAllDocumentWidgets());
  return query;
};

const createDocumentWidget = (data) => {
  return customInstance({
    url: `${suffix}`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const useCreateDocumentWidget = () => {
  const queryClient = useQueryClient();
  return useMutation(({ data }) => createDocumentWidget(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['document-widgets'] });
    },
  });
};

const updateDocumentWidget = (id, data) => {
  return customInstance({
    url: `${suffix2}/${id}`,
    method: 'put',
    data: data,
  });
};

export const useUpdateDocumentWidget = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateDocumentWidget(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['document-widgets'] });
    },
  });
};

const deleteDocumentWidget = (id) => {
  return customInstance({
    url: `${suffix2}/${id}`,
    method: 'delete',
  });
};

export const useDeleteDocumentWidget = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => deleteDocumentWidget(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['document-widgets'] });
    },
  });
};

const getDocumentWidgetByName = (name) => {
  return customInstance({
    url: `${suffix}/${name}`,
    method: 'get',
  });
};

export const useGetDocumentWidgetByName = () => {
  const query = useQuery(['document-widgets'], (name) => getDocumentWidgetByName(name));
  return query;
};

const createDocumentWidgetLocalization = (data) => {
  return customInstance({
    url: `${suffix2}`,
    method: 'post',
    data,
  });
};

export const useCreateDocumentWidgetLocalization = () => {
  const queryClient = useQueryClient();
  return useMutation(({ data }) => createDocumentWidgetLocalization(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['document-widgets'] });
    },
  });
};
