import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useToast } from './useToast';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateUser } from '../store/auth/authActions';
import { format } from 'date-fns';

const useUserInfo = (info) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState({ ...info });

  const registerSchema = yup
    .object()
    .shape({
      estate: yup.boolean(),
      insurance: yup.boolean(),
      receivedAlimente: yup.boolean(),
      payedAlimente: yup.boolean(),
      credit: yup.boolean(),
      bonds: yup.boolean(),
      manWorkAddress: yup.string().max(128),
      wifeWorkAddress: yup.string().max(128),
      familyStatus: yup.string().oneOf(['married', 'single', 'widowed', 'divorced'], 'Invalid family status'),
      moved: yup.boolean(),
      oldAddress: yup.string().test('oldAddress-conditional', 'Old Address is required', function (value) {
        const { moved } = this.parent;
        if (moved) {
          return value != null && value.trim() !== '';
        }
        return true;
      }),
      livedFrom: yup
        .date()
        .nullable()
        .test('livedFrom-conditional', 'Lived From date is required', function (value) {
          const { moved } = this.parent;
          if (moved) {
            return !!value;
          }
          return true;
        }),
      livedTo: yup
        .date()
        .nullable()
        .test('livedTo-conditional', 'Lived To date is required', function (value) {
          const { moved } = this.parent;
          if (moved) {
            return !!value;
          }
          return true;
        }),
      husbandTransport: yup.string().nullable(),
      wifeTransport: yup.string().nullable(),
      receivedInheritance: yup.boolean(),
      assets: yup.boolean(),
      religion: yup.boolean(),
      taxExtension: yup.boolean(),
      furtherEducation: yup.boolean(),
    })
    .required();

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues: { ...userInfo },
    resolver: yupResolver(registerSchema),
  });

  useEffect(() => {
    setUserInfo({ ...info });
  }, [info]);

  useEffect(() => {
    const formattedInfo = {
      ...info,
    };

    if (info?.livedFrom) {
      formattedInfo.livedFrom = format(new Date(info?.livedFrom), 'yyyy-MM-dd');
    }

    if (info?.livedTo) {
      formattedInfo.livedTo = format(new Date(info?.livedTo), 'yyyy-MM-dd');
    }

    // Reset form with formatted date values
    reset(formattedInfo);
  }, [info, reset]);

  const onSubmit = async (data) => {
    try {
      if (!data.moved) {
        data.oldAddress = '';
        data.livedFrom = null;
        data.livedTo = null;
      }
      dispatch(updateUser(data));
      toast({
        message: 'User Info Updated',
        variant: 'success',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
      });
      navigate('/dashboard');
    } catch (err) {
      clearErrors();
      switch (err?.response?.status) {
        case 409:
          toast({
            message: t('user'),
            variant: 'error',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
          break;
        default:
          break;
      }
    }
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    setValue,
    errors,
    watch,
  };
};

export default useUserInfo;
