import { Box, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import findLocalsOrUseFirst from '../../../../../utils/findLocalsOrUseFirst';
import { useTranslation } from 'react-i18next';
import StatusBox from '../../../../../components/StatusBox';
import isISODateValid from '../../../../../utils/isISODateValid';
import MenuDropdown from '../../../MenuDropdown';
import { useAssignBrokerToTask, useUpdateTaskStatus } from '../../../../../api/tasks';
import useToastNotification from '../../../../../hooks/useToastNotification';

const useColumns = ({ brokers, statuses }) => {
  const { t, i18n } = useTranslation();
  const { mutate, isError, isSuccess, isLoading } = useAssignBrokerToTask();
  const { mutate: updateStatus } = useUpdateTaskStatus();

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessfullyUpdatedTask',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorUpdatingTask',
    variant: 'error',
  });

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => <Typography variant="body1">#</Typography>,
    }),
    columnHelper.accessor((row) => row.archive?.name, {
      id: 'archive',
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
        >
          <Typography variant="body1">{t('archive')}</Typography>
        </Box>
      ),
    }),
    columnHelper.accessor((row) => row?.createdAt, {
      id: 'createdAt',
      cell: (info) => <span>{isISODateValid(info.getValue()) && format(new Date(info.getValue()), 'dd.MM.yyyy')}</span>,
      header: () => <Typography variant="body1">{t('Date')}</Typography>,
    }),
    columnHelper.accessor((row) => row.status, {
      id: 'status',
      cell: (info) => {
        const localData = findLocalsOrUseFirst(info?.getValue()?.locals, i18n.language);
        const taskId = info.row.original.id;
        const statusOptions = statuses?.map((status) => ({
          label: findLocalsOrUseFirst(status?.locals, i18n.language)?.name,
          value: status?.id,
          onClick: () => {
            updateStatus({ id: taskId, statusId: status?.id });
          },
        }));

        return (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <StatusBox color={info?.getValue()?.color}>{localData?.name || info.getValue()?.name}</StatusBox>{' '}
            <MenuDropdown
              isEditBroker={true}
              id={taskId}
              menuItems={statusOptions}
              hasIcon={false}
              selectedBrokerId={info?.getValue()?.id}
            />
          </Box>
        );
      },
      header: () => <Typography variant="body1">{t('status')}</Typography>,
    }),
    columnHelper.accessor(
      (row) => ({
        firstName: row.broker?.firstName,
        lastName: row.broker?.lastName,
        id: row.broker?.id,
      }),
      {
        id: 'fullName',
        cell: (info) => {
          const brokerData = info.getValue();
          const isBrokerAssigned = brokerData.firstName || brokerData.lastName;
          const taskId = info.row.original.id;

          const brokerOptions = brokers?.results.map((broker) => ({
            label: `${broker.firstName} ${broker.lastName}`,
            value: broker.id,
            onClick: () => {
              mutate({ id: taskId, data: { brokerId: broker.id } });
            },
          }));

          return (
            <span>
              {isBrokerAssigned ? (
                <Box display="flex" alignItems="center">
                  <Typography variant="body1">
                    {brokerData.firstName} {brokerData.lastName}{' '}
                  </Typography>
                  <MenuDropdown
                    id={taskId}
                    menuItems={brokerOptions}
                    selectedBrokerId={brokerData.id}
                    hasIcon={false}
                    isEditBroker={true}
                  />
                </Box>
              ) : (
                <MenuDropdown id={taskId} menuItems={brokerOptions} hasIcon={false} />
              )}
            </span>
          );
        },
        header: () => <Typography variant="body1">{t('Brokers')}</Typography>,
      },
    ),
  ];
  return columns;
};
export default useColumns;
