import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import 'react-phone-input-2/lib/style.css';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { Checkbox, FormControlLabel, IconButton, Typography } from '@mui/material';
import { useRegister } from '../../hooks/useRegister';
import CorePhoneInput from '../phoneInput';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { multilineExtractor } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { VisibilityOffOutlined } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useSwissGeoDataFromZip from '../../hooks/useSwissGeoDataFromZip';
import CustomSelect from '../commonComponents/CustomSelect';

export default function RegistrationForm({ source, handleDrawerClose }) {
  const { handleSubmit, onSubmit, control, loading, watch } = useRegister(source, handleDrawerClose);
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [placeOptions, setPlaceOptions] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const { plan } = useParams();
  const TAC = multilineExtractor(t('termsAndConditionsWarning'));

  const pass = watch('password');
  const pass1 = watch('passwordConfirmation');
  const navigate = useNavigate();
  const zipCode = watch('zipCode');
  const { data: geoData } = useSwissGeoDataFromZip(zipCode, zipCode?.length === 4);

  useEffect(() => {
    const options = [];
    geoData?.forEach((d) => {
      d.gem_name?.forEach((name) => {
        const labelName = `${d.plz6_name[0]} (${name})`;
        options.push({ label: labelName, value: labelName });
      });
    });
    setPlaceOptions(options);
  }, [geoData]);
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      display="flex"
      width="100%"
      flexDirection="column"
      justifyContent="space-evenly"
      gap={'1rem'}
    >
      <Controller
        name="plan"
        control={control}
        render={({ field }) => <input name="plan" value={plan} hidden {...field} />}
      />
      <CorePhoneInput name="phoneNumber" control={control} />
      <Controller
        name="firstName"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <TextField color="success" label={t('name')} variant="standard" error={!!error} {...field} />
            {error && (
              <Typography variant="caption" color="error">
                {error.message}
              </Typography>
            )}
          </>
        )}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <TextField color="success" label={t('lastname')} variant="standard" error={!!error} {...field} />
            {error && (
              <Typography variant="caption" color="error">
                {error.message}
              </Typography>
            )}
          </>
        )}
      />
      <Controller
        name="birthdate"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <Typography variant="body2" fontSize="12px">
              {t('birthdate')}
            </Typography>
            <TextField color="success" variant="standard" type="date" error={!!error} {...field} />
            {error && (
              <Typography variant="caption" color="error">
                {error.message}
              </Typography>
            )}
          </>
        )}
      />

      <Controller
        name="email"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <TextField color="success" type="email" label={t('email')} variant="standard" error={!!error} {...field} />
            {error && (
              <Typography variant="caption" color="error">
                {error.message}
              </Typography>
            )}
          </>
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <TextField
              label={t('Password')}
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              variant="standard"
              color="success"
              error={!!error}
              {...field}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlinedIcon />}
                  </IconButton>
                ),
              }}
            />
            {error && (
              <Typography variant="caption" color="error">
                {error.message}
              </Typography>
            )}
          </>
        )}
      />
      <Controller
        name="passwordConfirmation"
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <TextField
                label={t('passwordConfirmation')}
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                variant="standard"
                color="success"
                error={!!error}
                {...field}
                InputProps={{
                  endAdornment:
                    !error && pass === pass1 ? (
                      <IconButton>
                        <CheckCircleOutlineIcon color="success" />
                      </IconButton>
                    ) : (
                      pass1.length > 0 &&
                      error && (
                        <IconButton>
                          <CloseIcon color="error" />
                        </IconButton>
                      )
                    ),
                }}
              />
              {error && (
                <Typography variant="caption" color="error">
                  {error ? error.message : t('passwordsDoNotMatch')}
                </Typography>
              )}
            </>
          );
        }}
      />
      <Controller
        name="address"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <TextField color="success" label={t('Address')} variant="standard" error={!!error} {...field} />
            {error && (
              <Typography variant="caption" color="error">
                {error.message}
              </Typography>
            )}
          </>
        )}
      />
      <Controller
        name="zipCode"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <TextField
              color="success"
              label={t('zip')}
              variant="standard"
              error={!!error}
              {...field}
              inputProps={{ maxLength: 4 }}
            />
            {error && (
              <Typography variant="caption" color="error">
                {error.message}
              </Typography>
            )}
          </>
        )}
      />
      <CustomSelect
        control={control}
        name="place"
        label={t('Place')}
        data={placeOptions}
        valueField={(item) => item.value}
        displayField={(item) => item.label}
      />
      <Controller
        name="promoCode"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <TextField color="success" label={t('promoCode')} variant="standard" error={!!error} {...field} />
            {error && (
              <Typography variant="caption" color="error">
                {error.message}
              </Typography>
            )}
          </>
        )}
      />
      <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="start" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                color: '#037A68',
                '&.Mui-checked': {
                  color: '#037A68',
                },
              }}
              onClick={() => setChecked(!checked)}
            />
          }
        />
        <Typography textAlign="start" variant="body2">
          {TAC[0]}
        </Typography>
        <Typography variant="body2" sx={{ cursor: 'pointer' }} fontWeight={600} px={0.5} color="primary">
          <a href="/tac" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
            {TAC[1]}
          </a>
        </Typography>
      </Box>

      <LoadingButton
        loading={loading}
        variant="contained"
        disabled={!checked}
        size="large"
        type="submit"
        fullWidth
        sx={{
          bgcolor: '#0F5D66',
          '&:hover': {
            backgroundColor: '#187D7E',
          },
          marginTop: '10px',
          color: 'white',
        }}
      >
        {t('signup')}
      </LoadingButton>
      <Box
        onClick={() => navigate('/')}
        sx={{
          textDecoration: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <ArrowBackIcon />
        <Typography variant="body2" color="#1A1C1E">
          {t('goBack')}
        </Typography>
      </Box>
    </Box>
  );
}
