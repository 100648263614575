import Typography from '@mui/material/Typography';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import MenuDropdown from '../../components/adminDashboard/MenuDropdown';

const useFamilyRelationsColumns = (handleEdit) => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper();

  const menuItems = [
    {
      label: t('Edit'),
      icon: 'https://taxofficestorage.blob.core.windows.net/media/edit-2.svg',
      onClick: (row) => {
        const nameEng = row.locals?.find((val) => val.language === 'eng');
        const nameDe = row.locals?.find((val) => val.language === 'de');
        handleEdit({ ...row, name: { eng: nameEng?.name, de: nameDe?.name } });
      },
    },
  ];

  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          #
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.locals, {
      id: 'locals.eng',
      cell: (info) => {
        const name = info.getValue()?.find((val) => val.language === 'eng');
        return <Typography>{name ? name?.name : t('missing')}</Typography>;
      },
      header: () => (
        <Typography variant="body1" fontWeight={700} d>
          {t('English')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.locals, {
      id: 'locals.de',
      cell: (info) => {
        const name = info.getValue()?.find((val) => val.language === 'de');
        return <Typography>{name ? name?.name : t('missing')}</Typography>;
      },
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Deutsch')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.is_active, {
      id: 'is_active',
      cell: (info) => <Typography>{info.row.original.is_active ? 'Active' : 'Inactive'}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('IsActive')}
        </Typography>
      ),
    }),
    columnHelper.accessor('action', {
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Action')}
        </Typography>
      ),
      cell: (info) => {
        return (
          <MenuDropdown
            id={info.row.original.id}
            menuItems={menuItems.map((item) => ({ ...item, onClick: () => item.onClick(info.row.original) }))}
          />
        );
      },
      footer: (info) => info.column.id,
    }),
  ];
  return columns;
};

export default useFamilyRelationsColumns;
