import React from 'react';
import AppRoutes from './routes/appRoutes';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { I18nextProvider } from 'react-i18next';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { persistor, store } from './store';
import i18n from './config/i18n';
import ScrollToTop from './components/scrollToTop';
import { configureInitialInterceptors } from './api/client';
import ReactGA from 'react-ga4';
import TrackPageView from './routes/appRoutes/TrackPageView';
import TagManager from 'react-gtm-module';
configureInitialInterceptors(store);

const queryClient = new QueryClient();
const theme = createTheme({
  palette: {},
  typography: {
    fontFamily: ['Plus Jakarta Sans', 'sans-serif'].join(','),
  },
});

theme.typography.h1 = {
  fontSize: '38px',
  fontWeight: '900',
  lineHeight: '48px',
  [theme.breakpoints.up('md')]: {
    fontSize: '72px',
    lineHeight: '94px',
  },
};

theme.typography.title = {
  fontSize: '38px',
  fontWeight: '900',
  letterSpacing: '-2.16px',
  fontFamily: 'Plus Jakarta Sans',
  fontVariantLigatures: 'common-ligatures',
  lineHeight: '48px',
  [theme.breakpoints.up('md')]: {
    fontSize: '72px',
    lineHeight: '94px',
  },
};
theme.typography.h2 = {
  fontSize: '38px',
  fontWeight: '700',
  lineHeight: '48px',
  [theme.breakpoints.up('md')]: {
    fontSize: '56px',
    lineHeight: '72px',
  },
};

theme.typography.h3 = {
  fontSize: '1.75rem',
  fontWeight: '700',
  lineHeight: '78px',
  letterSpacing: '-0.03em',
  [theme.breakpoints.up('md')]: {
    fontSize: '56px',
    lineHeight: '48px',
  },
};

theme.typography.h4 = {
  fontSize: '1.75rem',
  fontWeight: '700',
  lineHeight: '27px',
  letterSpacing: '-0.03em',
  color: '#1A1C1E',
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
    lineHeight: '48px',
  },
};
theme.typography.h5 = {
  fontSize: '1.50rem',
  fontWeight: '700',
  lineHeight: '48px',
  color: '#1A1C1E',
  [theme.breakpoints.up('md')]: {
    fontSize: '28px',
  },
};
theme.typography.h6 = {
  fontSize: '1.50rem',
  fontWeight: '700',
  lineHeight: '36px',
  letterSpacing: '-0.03em',
  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
  },
};
//body1
theme.typography.body1 = {
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '27px',
  letterSpacing: '-0.02em',
  [theme.breakpoints.up('md')]: {
    letterSpacing: '-0.02em',
    fontSize: '14px',
    lineHeight: '27px',
  },
};
//secondary
theme.typography.secondary = {
  fontWeight: '400',
  fontSize: '16px',
  color: '#6C7278',
  lineHeight: '150% !important',
  letterSpacing: '-0.02em',
  [theme.breakpoints.up('md')]: {
    letterSpacing: '-0.02em',
    fontSize: '18px',
  },
};
//body2
theme.typography.body2 = {
  color: '#1A1C1E',
  fontSize: '16px',
  fontWeight: '400',
  letterSpacing: '-0.02em',
};
//subtitle1
theme.typography.subtitle1 = {
  color: '#6C7278',
  fontSize: '14px',
  fontWeight: '400',
};
theme.typography.button = {
  fontFamily: 'Manrope',
  fontSize: '16px',
  fontWeight: '400',
  letterSpacing: '-0.02em',
  color: '#1A1C1E',
};

ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
};

TagManager.initialize(tagManagerArgs);

function App() {
  window.dataLayer.push({
    event: 'pageview',
  });
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider>
                <BrowserRouter>
                  <ScrollToTop>
                    <div className="App">
                      <CssBaseline />
                      <TrackPageView />
                      <AppRoutes />
                    </div>
                  </ScrollToTop>
                </BrowserRouter>
              </SnackbarProvider>
            </ThemeProvider>
          </I18nextProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
