import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createFile, deleteFile, getFileTypes, getFileTypesByArchiveType, getOwnFiles } from '../services/files';

const useOwnFiles = (fileType) => {
  return useQuery(['files'], () => getOwnFiles(fileType), {
    enabled: !!fileType,
  });
};

export const useFileTypes = (params) => {
  const query = useQuery(['files-types'], () => getFileTypes(params));
  return query;
};

export const useFileTypesByArchiveType = (archiveType) =>
  useQuery(['files', 'types', archiveType], () => getFileTypesByArchiveType(archiveType), {
    enabled: !!archiveType,
    refetchOnWindowFocus: false,
  });

export const useCreateFile = (trackProgress) => {
  const queryClient = useQueryClient();
  return useMutation((data) => createFile(data, trackProgress), {
    onSuccess: () => {
      queryClient.invalidateQueries(['files']);
    },
  });
};

export const useDeleteFile = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(['files']);
    },
  });
};

export default useOwnFiles;
