import { Box, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import MenuDropdown from '../../components/adminDashboard/MenuDropdown';
import isISODateValid from '../../utils/isISODateValid';
import findLocalsOrUseFirst from '../../utils/findLocalsOrUseFirst';
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox';
import { useUpdateAppointment } from '../../api/termins';
import useToastNotification from '../useToastNotification';

const useUserTerminsColumns = ({ brokers }) => {
  const { t, i18n } = useTranslation();
  const { mutate, isSuccess, isLoading, isError } = useUpdateAppointment();

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessfullyUpdatedTermin',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorUpdatedTermin',
    variant: 'error',
  });

  const columnHelper = createColumnHelper();

  const columns = [
    {
      id: 'select',
      header: ({ table }) => {
        return (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        );
      },
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    columnHelper.accessor((row) => row.user?.firstName, {
      id: 'name',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('name')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.user?.zipCode, {
      id: 'zip',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('zip')}
        </Typography>
      ),
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor((row) => row.interest, {
      id: 'interest',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('interest')}
        </Typography>
      ),
    }),

    columnHelper.accessor((row) => row.user?.status, {
      id: 'status',
      cell: (info) => {
        const localizedStatus = findLocalsOrUseFirst(info.getValue()?.locals, i18n.language);
        return <Typography>{localizedStatus?.name || info.getValue()?.name}</Typography>;
      },

      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Status')}
        </Typography>
      ),
    }),

    columnHelper.accessor((row) => row.from, {
      id: 'createdAt',
      cell: (info) => (
        <Typography>{isISODateValid(info.getValue()) && format(new Date(info.getValue()), 'dd.MM.yyyy')}</Typography>
      ),
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Date')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => ({ from: row.from, to: row.to }), {
      id: 'createdTime',
      cell: (info) => {
        const { from } = info.getValue();
        return <Typography>{`${isISODateValid(from) && format(new Date(from), 'hh:mm')}`}</Typography>;
      },
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('fromTime')}
        </Typography>
      ),
    }),
    columnHelper.accessor(
      (row) => ({
        firstName: row.broker?.firstName,
        lastName: row.broker?.lastName,
        id: row.broker?.id,
      }),
      {
        id: 'fullName',
        cell: (info) => {
          const brokerData = info.getValue();
          const isBrokerAssigned = brokerData.firstName || brokerData.lastName;
          const taskId = info.row.original.id;

          const brokerOptions = brokers?.results.map((broker) => ({
            label: `${broker.firstName} ${broker.lastName}`,
            value: broker.id,
            onClick: () => {
              mutate({ id: taskId, data: { broker: broker.id } });
            },
          }));

          return (
            <Typography>
              {isBrokerAssigned ? (
                <Box display="flex" alignItems="center">
                  <Typography variant="body1">
                    {brokerData.firstName} {brokerData.lastName}{' '}
                  </Typography>
                  <MenuDropdown
                    id={taskId}
                    menuItems={brokerOptions}
                    selectedBrokerId={brokerData.id}
                    hasIcon={false}
                    isEditBroker={true}
                  />
                </Box>
              ) : (
                <MenuDropdown id={taskId} menuItems={brokerOptions} hasIcon={false} />
              )}
            </Typography>
          );
        },
        header: () => (
          <Typography variant="body1" fontWeight={700}>
            {t('Brokers')}
          </Typography>
        ),
      },
    ),
  ];
  return columns;
};
export default useUserTerminsColumns;
