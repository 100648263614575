import { isValid } from 'date-fns';

const chDateFormatToJsDateFormat = ({ chFormat, onInvalidDate, onValidDate }) => {
  const formattedString = chFormat.split('.').reverse().join('-');
  if (isValid(new Date(formattedString))) {
    if (onValidDate) {
      onValidDate();
    }
    return new Date(formattedString);
  }
  if (onInvalidDate) {
    onInvalidDate();
  }
  return formattedString;
};

export default chDateFormatToJsDateFormat;
