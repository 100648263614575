/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import DocumentCard from '../../dashboardComponents/DocumentCard';
import { useDeleteDocumentWidget } from '../../../api/documentWidgets';
import CustomDrawer from '../../modals/CustomDrawer';
import DocumentWidgetsForm from './DocumentWidgetsForm';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DeleteModal from '../../modals/DeleteModal';
import DocumentWidgetsEditForm from './DocumentWidgetsEditForm';
import DocumentWidgetsLocalizationForm from './DocumentWidgetLocalizationForm';
import useToastNotification from '../../../hooks/useToastNotification';
import SubmitButton from '../../commonComponents/SubmitButton';

const DocumentWidgetsTable = ({ data, languages }) => {
  const { t } = useTranslation();
  const { mutate: deleteWidget, isSuccess, isLoading: isWidgetLoading, isError } = useDeleteDocumentWidget();
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [openLocalizeDrawer, setOpenLocalizeDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [widgetId, setWidgetId] = useState(null);
  const [selectedWidget, setSelectedWidget] = useState(null);

  const handleDeleteModal = (id) => {
    setOpenDelete(!openDelete);
    if (typeof id === 'string') {
      setWidgetId(id);
    } else {
      setWidgetId(null);
    }
  };

  useToastNotification({
    isShow: isSuccess,
    loading: isWidgetLoading,
    messageKey: 'SuccessDocumentWidgetDelete',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isWidgetLoading,
    messageKey: 'DocumentWidgetDeleteError',
    variant: 'error',
  });

  const handleDelete = () => {
    deleteWidget(widgetId);
    setOpenDelete(!openDelete);
  };

  const handleDeleteRequest = (localId) => {
    setOpenDelete(true);
    setWidgetId(localId);
  };

  const handleEditWidget = (local) => {
    setSelectedWidget(local);
    setOpenEditDrawer(true);
  };

  const handleOpen = () => {
    setOpenAddDrawer(!openAddDrawer);
  };
  const handleOpenEdit = () => {
    setOpenEditDrawer(!openEditDrawer);
  };

  const handleOpenLocalize = (item) => {
    setSelectedWidget(item?.id);
    setOpenLocalizeDrawer(!openLocalizeDrawer);
  };

  const mapDataToDocumentCardProps = (item, local) => {
    return {
      id: local.id,
      originalName: local.title,
      uploadedAt: local.subtitle,
      url: item.image,
      fileUrl: item.file,
      showImg: false,
      deleteWidget,
      onEdit: () => handleEditWidget(item, local),
      onDelete: () => handleDeleteRequest(local.id),
      onLocalization: () => handleOpenLocalize(item),
    };
  };
  return (
    <>
      <SubmitButton onClick={handleOpen} buttonText={t('AddNew')} backgroundColor="#31B099" color="inherit" />
      {data?.map((item) =>
        item?.locals?.map((local) => (
          <Box key={local.id} width="100%" my={1}>
            <DocumentCard {...mapDataToDocumentCardProps(item, local)} />
          </Box>
        )),
      )}

      {/* Add Drawer */}
      <CustomDrawer open={openAddDrawer} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddDocument')}
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <DocumentWidgetsForm handleDrawerClose={handleOpen} selectedWidget={selectedWidget} languages={languages} />
      </CustomDrawer>

      {/* Edit Drawer */}
      <CustomDrawer open={openEditDrawer} handleOpen={handleOpenEdit}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('EditDocumentWidget')}
          </Typography>
          <IconButton onClick={handleOpenEdit}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <DocumentWidgetsEditForm handleDrawerClose={handleOpenEdit} selectedWidget={selectedWidget} />
      </CustomDrawer>

      {/* Localize Drawer */}
      <CustomDrawer open={openLocalizeDrawer} handleOpen={handleOpenLocalize}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddLocalization')}
          </Typography>
          <IconButton onClick={handleOpenLocalize}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <DocumentWidgetsLocalizationForm
          handleDrawerClose={handleOpenLocalize}
          selectedWidget={selectedWidget}
          languages={languages}
        />
      </CustomDrawer>

      {/* Delete Modal */}
      <DeleteModal
        handleDeleteModal={handleDeleteModal}
        openDelete={openDelete}
        headingText={t('DeleteDocument')}
        bodyText={t('AreYouSureDocument')}
        handleDelete={() => handleDelete(widgetId)}
      />
    </>
  );
};

export default DocumentWidgetsTable;
