const USER_OPTIONS = {
  dashboard: {
    link: '/dashboard',
    topArr: [
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/home-2.svg',
        label: 'Dashboard',
        navigateTo: 'dashboard',
        auth: true,
      },
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/people.svg',
        label: 'Family Members',
        navigateTo: 'family-members',
        auth: true,
      },
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/user.svg',
        label: 'tax_profile',
        navigateTo: 'tax-profile',
        auth: true,
      },
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/article.svg',
        label: 'Articles',
        navigateTo: 'articles',
        auth: true,
      },
    ],
    bottomArr: [
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/settings.svg',
        label: 'Settings',
        navigateTo: 'settings',
        auth: true,
      },
    ],
  },
  admin: {
    link: '/admin',
    topArr: [
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/dashboard.svg',
        label: 'Dashboard',
        navigateTo: 'admin/dashboard',
        auth: true,
      },
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/profile-2user.svg',
        label: 'Customers',
        navigateTo: 'admin/users',
        auth: true,
      },
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/people.svg',
        label: 'Leads',
        navigateTo: 'admin/leads',
        auth: true,
      },
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/calendar.svg',
        hasdropdown: 1,
        label: 'Appointments',
        dropdownoptions: [
          {
            label: 'Lead Appointments',
            navigateTo: 'admin/lead-appointments',
            auth: true,
          },
          {
            label: 'User Appointments',
            navigateTo: 'admin/user-appointments',
            auth: true,
          },
        ],
      },
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/task-square.svg',
        label: 'Tasks',
        navigateTo: 'admin/tasks',
        auth: true,
      },
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/task-square.svg',
        label: 'Articles',
        navigateTo: 'admin/articles',
        auth: true,
      },
    ],
    bottomArr: [
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/settings.svg',
        label: 'Settings',
        navigateTo: 'admin/settings',
        auth: true,
      },
    ],
  },
  broker: {
    link: '/broker',
    topArr: [
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/dashboard.svg',
        label: 'Dashboard',
        navigateTo: 'broker/dashboard',
        auth: true,
      },
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/people.svg',
        label: 'Leads',
        navigateTo: 'broker/leads',
        auth: true,
      },

      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/calendar.svg',
        label: 'Appointments',
        navigateTo: 'broker/appointments',
        auth: true,
      },
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/profile-2user.svg',
        label: 'Customers',
        navigateTo: 'broker/customers',
        auth: true,
      },
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/task-square.svg',
        label: 'Tasks',
        navigateTo: 'broker/tasks',
        auth: true,
      },
    ],
    bottomArr: [
      {
        imgSrc: 'https://taxofficestorage.blob.core.windows.net/media/settings.svg',
        label: 'Settings',
        navigateTo: 'broker/settings',
        auth: true,
      },
    ],
  },
};

export default USER_OPTIONS;
