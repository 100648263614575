import { useMutation, useQueryClient } from 'react-query';
import { customInstance } from './client';

const suffix = '/v1/auth';

export const signInUser = async (data) => {
  return customInstance({
    url: `${suffix}/create-sign-in-otp`,
    method: 'post',
    data,
  });
};

export const verifySinginOpt = async (data) => {
  return customInstance({
    url: `${suffix}/sign-in`,
    method: 'post',
    data,
  });
};

export const verifyToken = async ({ email, otp }) => {
  return customInstance({
    url: `${suffix}/verify/otp`,
    method: 'post',
    data: {
      email,
      otp,
    },
  });
};

export const forgotPassword = async (data) => {
  return customInstance({
    url: `${suffix}/forgot-password-otp`,
    method: 'post',
    data,
  });
};

export const useForgotPassword = () => {
  return useMutation({
    mutationFn: (data) => forgotPassword(data),
  });
};

export const resetPassword = async ({ email, password, otp }) => {
  return customInstance({
    url: `${suffix}/reset-password`,
    method: 'post',
    data: { email, password, otp },
  });
};

export const logoutToken = async (token) => {
  return customInstance({
    url: `${suffix}/logout/${token}`,
    method: 'delete',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const refreshToken = async ({ refreshToken }) => {
  return customInstance({
    url: `${suffix}/refresh-token`,
    method: 'post',
    data: { refreshToken },
  });
};

// registration

export const signUpUser = async (data) => {
  return customInstance({
    url: `${suffix}/sign-up`,
    method: 'post',
    data,
  });
};

export const useSignupUser = () => {
  return useMutation({
    mutationFn: (data) => signUpUser(data),
  });
};

export const useResetPassToken = () => {
  return useMutation({
    mutationFn: ({ email, password, otp }) => resetPassword({ email, password, otp }),
  });
};

export const useVerifyToken = () => {
  return useMutation({
    mutationFn: ({ email, otp }) => verifyToken({ email, otp }),
  });
};

export const verifyAccessToken = async (token) => {
  return customInstance({
    url: `${suffix}/verify/${token}`,
    method: 'get',
  });
};

//SIGN UP BROKER

export const signUpBroker = async (data) => {
  return customInstance({
    url: `${suffix}/sign-up-broker`,
    method: 'post',
    data,
  });
};

export const useSignupBroker = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => signUpBroker(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['brokers'] });
    },
  });
};
