import { Box, Button, Typography } from '@mui/material';
import { format, setMinutes } from 'date-fns';
import React from 'react';

const TerminSlotComponent = ({ slot, selectedSlot, setSelectedSlot, setValue }) => {
  return (
    <Button
      variant="contained"
      sx={{
        bgcolor: selectedSlot === slot.id ? '#31B099' : '#fff',
        color: selectedSlot === slot.id ? '#fff' : '#0F5D66',
        textTransform: 'none',
        borderRadius: '15px',
        fontWeight: '700',
        padding: '8px 12px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '&:hover': {
          color: 'rgba(15, 93, 102,0.5)',
          bgcolor: 'rgba(255, 255, 255,0.3)',
        },
      }}
      onClick={() => {
        setValue('from.hour', slot.from.hour);
        setValue('from.minute', slot.from.minute);
        setValue('to.hour', slot.to.hour);
        setValue('to.minute', slot.to.minute);
        setSelectedSlot(selectedSlot === slot.id ? undefined : slot.id);
      }}
    >
      <Typography>{slot.label}</Typography>
      <Box display="flex">
        <Typography>{format(setMinutes(new Date().setHours(slot.from.hour), slot.from.minute), 'HH:mm')}</Typography>
        <Typography> - </Typography>
        <Typography>{format(setMinutes(new Date().setHours(slot.to.hour), slot.to.minute), 'HH:mm')}</Typography>
      </Box>
    </Button>
  );
};

export default TerminSlotComponent;
