import React from 'react';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const GetStartedButton = ({ destination, fullWidth }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button
      variant="contained"
      sx={{
        bgcolor: '#187D7E',
        '&:hover': {
          backgroundColor: '#0F5D66',
        },
        display: 'flex',
        borderRadius: '14px',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Manrope',
        height: '54px',
        lineHeight: '150%',
        letterSpacing: '-0.32px',
        padding: '20px 25px',
        justifyContent: 'center',
        alignIitems: 'center',
        gap: '10px',
        width: fullWidth && '100%',
      }}
      onClick={() => navigate(destination)}
      endIcon={<ArrowForwardIcon />}
    >
      {t('getStarted')}
    </Button>
  );
};

export default GetStartedButton;
