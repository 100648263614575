import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function MenuDropdown({ id, menuItems, hasIcon = true, selectedBrokerId, isEditBroker, row }) {
  const handleItemClick = (id, onClick, event) => {
    event.stopPropagation();
    onClick(id, row);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {isEditBroker ? (
          <img alt="Edit Icon" src="https://taxofficestorage.blob.core.windows.net/media/chevron.svg"></img>
        ) : (
          <MoreHorizIcon />
        )}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            borderRadius: '15px',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          },
        }}
      >
        {menuItems?.map((item, index) => (
          <MenuItem
            key={index}
            onClick={(event) => handleItemClick(id, item.onClick, event)}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '10px',
              width: '100%',
              border: '1px solid #DCE4E8',
              borderRadius: '25px',
              paddingX: '24px',
              paddingY: '12px',
              backgroundColor: item.value === selectedBrokerId ? '#edf1f3' : 'white',
            }}
          >
            <Typography variant="body1">{item.label}</Typography>
            {hasIcon && <img alt="Menu Icon" src={item.icon}></img>}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
