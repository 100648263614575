import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import OutlineCheck from '../../assets/icons/OutlineCheck';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FinishedDocumentsTab = ({ isLoading = false, setActiveTabs, suffix }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    if (params?.fileType === t('finished_tax_return')) {
      setActiveTabs(['finished-docs']);
    }
  }, [params?.fileType]);
  const isActive = params?.fileType === t('finished_tax_return');
  return (
    <Box
      sx={{
        my: '2px',
        background: '#EDF1F3',
        borderRadius: '16px',
        boxShadow: 'none',
        width: '100%',
        padding: '16px 24px',
        fontWeight: '400',
        '&:hover': {
          bgcolor: !isActive ? 'rgba(70, 70, 70,0)' : '#0F5D66',
          color: !isActive ? '#000' : undefined,
        },
        '&:active': { bgcolor: '#0F5D66', color: '#fff' },
        color: isActive ? '#fff' : '#1A1C1E',
        bgcolor: isActive ? '#0F5D66' : '#',
        cursor: 'pointer',
      }}
      variant="contained"
      disabled={!isActive && isLoading}
      onClick={() => {
        setActiveTabs((prev) => {
          if (prev?.includes('finished-docs')) {
            return [];
          }
          return ['finished-docs'];
        });
        navigate(`${suffix}/${t('finished_tax_return')}`, { state: suffix });
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: isActive ? '#fff' : '#EDF1F3',
            overflow: 'visible',
            borderRadius: '200px',
            width: '40px',
            height: '40px',
            '& img': {
              filter: !isActive
                ? 'brightness(0) saturate(100%) invert(27%) sepia(25%) saturate(2578%) hue-rotate(148deg) brightness(89%) contrast(89%)'
                : 'brightness(0) saturate(100%)',
            },
          }}
        >
          <OutlineCheck />
        </Box>

        <Box
          fontWeight={isActive ? 700 : 400}
          whiteSpace={'pre-wrap'}
          sx={{
            wordBreak: 'break-word',
            userSelect: 'none',
          }}
          textAlign="left"
          ml={2}
          px={0}
          width={160}
        >
          {t('finished_tax_return')}
        </Box>
        {/* <Box color={isMenu && isActive ? '#fff' : '#ACB5BB'}>
          {tab?.subTypes?.length > 0 ? (
            <Box display="flex" alignItems="center">
              <ArrowForwardIosIcon
                sx={{
                  transform:
                    activeTabs.includes(tab.id) || findDeepType(tab, params?.fileType, lang)
                      ? 'rotate(90deg)'
                      : 'rotate(0deg)',
                  transition: 'transform 0.2s ease-in-out',
                }}
              />
            </Box>
          ) : (
            !isMenu && (
              <AddIconButton isActive={isActive} onAddButtonClick={onAddButtonClick} tab={tab} isMenu={isMenu} />
            )
          )}
        </Box> */}
      </Box>
    </Box>
  );
};

export default FinishedDocumentsTab;
