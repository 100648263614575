import React from 'react';
import { Controller } from 'react-hook-form';
import CustomTextField from '../../commonComponents/CustomTextField';

const WorkAddressFormElement = ({ name, control, label, type }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <CustomTextField
            variant="outlined"
            color="success"
            type={type}
            label={label}
            error={!!error}
            value={field.value ? field.value : ''}
            onChange={field.onChange}
            fullWidth
            sx={{ marginBottom: 1 }}
            {...field}
          />
        );
      }}
    />
  );
};

export default WorkAddressFormElement;
