import { Box, Typography } from '@mui/material';
import React from 'react';

const SortableHeader = ({ header, itemName, setSortItem, sortIcon }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 1,
        cursor: 'pointer',
      }}
      onClick={() => {
        setSortItem(itemName);
      }}
    >
      <Typography variant="body1" fontWeight={700} sx={{ userSelect: 'none' }}>
        {header}
      </Typography>
      {sortIcon(itemName)}
    </Box>
  );
};

export default SortableHeader;
