/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useCreateTerminForUser } from '../../../../../../api/termins';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import useToastNotification from '../../../../../../hooks/useToastNotification';
import { yupResolver } from '@hookform/resolvers/yup';

export const useAddUserTermins = (id, defaultBrokerValue, handleDrawerClose) => {
  const { t } = useTranslation();
  const { mutate, isSuccess, isLoading, isError } = useCreateTerminForUser();
  const schema = yup
    .object()
    .shape({
      from: yup.date().required(t('From')),
      interest: yup.string().required(t('Interest')),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      from: '',
      broker: defaultBrokerValue,
      interest: '',
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'successAddedTermin',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'errorAddedTermin',
    variant: 'error',
  });

  const onSubmit = (data) => {
    mutate({
      ...data,
      from: new Date(data.from).getTime(),
      user: id,
      interest: data.interest,
    });
    handleDrawerClose();
    reset();
  };
  return {
    handleSubmit,
    control,
    onSubmit,
    isLoading,
    errors,
  };
};
