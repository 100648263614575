import { Button } from '@mui/material';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import React, { useEffect, useState } from 'react';
import { useScroll, useTransform, motion, AnimatePresence, useInView } from 'framer-motion';

const ScrollToTop = ({ heroRef }) => {
  const [view, setView] = useState(false);
  const inView = useInView(heroRef);
  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 0.5], [0.1, 1]);
  useEffect(() => {
    setView(!inView);
  }, [inView]);
  return (
    <AnimatePresence>
      {view && (
        <motion.div
          style={{ scale, position: 'fixed', bottom: '20px', right: '20px' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Button
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              setView(false);
            }}
            variant="contained"
            sx={{
              '&:hover': {
                backgroundColor: '#1565c0ff',
                transform: 'scale(1.1)',
              },
              backgroundColor: '#187D7E',
              height: '65px',
              color: '#ffffff',
              width: '65px',
              padding: '0px',
              opacity: 1,
              transition: 'opacity ease-in-out 1s,transform ease-in-out 0.2s',
              margin: '0px',
              borderRadius: '50%',
            }}
          >
            <ArrowUpward />
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ScrollToTop;
