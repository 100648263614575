/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteTerminSLots } from '../../../../api/termins';
import useTerminSlotsColumns from '../../../../hooks/AllUsers/useTerminSlotsColumns';
import AppointmentSlotsForm from './AppointmentSlotsForm';
import { Box, IconButton, Typography } from '@mui/material';
import CustomDrawer from '../../../modals/CustomDrawer';
import VerifyLoader from '../../../verifyLoader/VerifyLoader';
import TableComponent from '../../../Table/Table';
import CloseIcon from '@mui/icons-material/Close';
import DeleteModal from '../../../modals/DeleteModal';
import useToastNotification from '../../../../hooks/useToastNotification';
import SubmitButton from '../../../commonComponents/SubmitButton';
import { useAddTerminSlots } from '../../../../hooks/useAddTerminSlots';

const AppointmentSlotsTable = ({ slots, isLoadingSlots }) => {
  const { t } = useTranslation();
  const { mutate: deleteSlot, isSuccess, isLoading: deleteLoading, isError } = useDeleteTerminSLots();
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [slotId, setSlotId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const handleOpen = () => {
    setOpenAddDrawer(!openAddDrawer);
  };

  const { handleSubmit, control, onSubmit } = useAddTerminSlots(handleOpen, selectedSlot);
  const handleDeleteModal = (id) => {
    setOpenDelete(!openDelete);
    if (typeof id === 'string') {
      setSlotId(id);
    } else {
      setSlotId(null);
    }
  };
  const handleDelete = () => {
    deleteSlot(slotId);
    setOpenDelete(!openDelete);
  };

  const handleEdit = (slotData) => {
    setSelectedSlot(slotData);
    setOpenAddDrawer(true);
  };

  useToastNotification({
    isShow: isSuccess,
    loading: deleteLoading,
    messageKey: 'SuccessfullyDeletedSlot',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: deleteLoading,
    messageKey: 'ErrorApointmentSlot',
    variant: 'error',
  });

  const columns = useTerminSlotsColumns(handleDeleteModal, handleEdit);

  return (
    <>
      <Box m={5}>
        <Box width="100%">
          {isLoadingSlots ? (
            <Box width="100%" display="flex" justifyContent="center">
              <VerifyLoader />
            </Box>
          ) : (
            slots && (
              <TableComponent hasAboveStuff data={slots} columns={columns}>
                <SubmitButton onClick={handleOpen} buttonText={t('AddNew')} backgroundColor="#31B099" color="inherit" />
              </TableComponent>
            )
          )}
        </Box>
      </Box>
      {/* Add Drawer */}
      <CustomDrawer open={openAddDrawer} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddAppointmentSlots')}
          </Typography>
          <IconButton
            onClick={() => {
              handleOpen();
              setSelectedSlot();
            }}
          >
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <AppointmentSlotsForm handleSubmit={handleSubmit} control={control} onSubmit={onSubmit} />
      </CustomDrawer>

      {/* Delete Modal */}
      <DeleteModal
        handleDeleteModal={handleDeleteModal}
        openDelete={openDelete}
        headingText={t('DeleteAppointmentSlot')}
        bodyText={t('AreYouSureAppointmentSlot')}
        handleDelete={() => handleDelete(slotId)}
      />
    </>
  );
};

export default AppointmentSlotsTable;
