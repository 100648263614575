import { Box } from '@mui/material';
import React from 'react';
import VerticalHeader from '../../components/dashboardComponents/VerticalHeader';
import { Outlet } from 'react-router-dom';
import RequireAuth from '../../components/RequireAuth';
import { useGetAllArchiveTypes } from '../../api/archive';

const SidebarLayout = () => {
  const { data } = useGetAllArchiveTypes();
  return (
    <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} width="100%">
      <VerticalHeader archives={data} />
      <Box width="100%">
        <Outlet />
      </Box>
    </Box>
  );
};

export default RequireAuth(SidebarLayout);
