import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useAddDocumentWidget } from '../../../hooks/useAddDocumentWidget';
import { useTranslation } from 'react-i18next';
import UploadButton from '../../commonComponents/UploadButton';
import SubmitButton from '../../commonComponents/SubmitButton';
import CustomFormInput from '../../commonComponents/CustomFormInput';
import CustomSelect from '../../commonComponents/CustomSelect';

const DocumentWidgetsForm = ({ handleDrawerClose, languages }) => {
  const { t } = useTranslation();
  const [imageName, setImageName] = useState('');
  const [fileName, setFileName] = useState('');
  const [files, setFiles] = useState({
    file: null,
    image: null,
  });

  const { handleSubmit, control, onSubmit } = useAddDocumentWidget(handleDrawerClose, files);

  // Handlers for file change
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageName(file.name);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };
  return (
    <Box component="form" display="flex" flexDirection="column">
      <Box display="flex">
        <Controller
          name="files.image"
          control={control}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <Box display="flex" my={1} flexDirection="column" width="100%">
              <Typography variant="body2" fontSize="12px">
                {t('Image')}
              </Typography>
              <UploadButton
                onFileChange={(e) => {
                  handleImageChange(e);
                  onChange(e);
                  setFiles((prev) => ({ ...prev, image: e.target.files[0] }));
                }}
              />
              {imageName && <Typography>{imageName}</Typography>}
              {error && (
                <Typography variant="caption" color="error">
                  {error.message}
                </Typography>
              )}
            </Box>
          )}
        />
        <Controller
          name="files.file"
          control={control}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <Box display="flex" my={1} flexDirection="column" width="100%">
              <Typography variant="body2" fontSize="12px">
                {t('Files')}
              </Typography>
              <UploadButton
                onFileChange={(e) => {
                  handleFileChange(e);
                  onChange(e);
                  setFiles((prev) => ({ ...prev, file: e.target.files[0] }));
                }}
              />
              {fileName && <Typography>{fileName}</Typography>}
              {error && (
                <Typography variant="caption" color="error">
                  {error.message}
                </Typography>
              )}
            </Box>
          )}
        />
      </Box>
      <CustomFormInput name="subtitle" control={control} label={'Subtitle'} />
      <CustomFormInput name="title" control={control} label={t('title')} />
      <CustomSelect
        control={control}
        name="language"
        label={t('language')}
        data={languages}
        valueField={(item) => item.code}
        displayField={(item) => item.name}
      />
      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default DocumentWidgetsForm;
