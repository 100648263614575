import * as React from 'react';
import 'react-phone-input-2/lib/style.css';
import TabRegister from './tabRegister';
import NavBarRegister from '../navBarRegister/navBarRegister';

export default function RegisterLayout() {
  return (
    <>
      <NavBarRegister />
      <TabRegister />
    </>
  );
}
