/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useCreateLeadTypeLocalization } from '../api/leadTypes';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useToastNotification from './useToastNotification';
import { useEffect } from 'react';
import { isUndefined, omitBy } from 'lodash-es';

export const useEditLeadTypes = (handleDrawerClose, defaultData) => {
  const { t } = useTranslation();
  const { mutate, isSuccess, isLoading, isError } = useCreateLeadTypeLocalization();
  const schema = yup
    .object()
    .shape({
      locals: yup.object().shape({
        eng: yup.string().required(t('required_field', { field: t('english_label') })),
        de: yup.string().required(t('required_field', { field: t('deutsch_label') })),
      }),
    })
    .required();

  const engLocal = defaultData?.locals?.find((local) => local.language === 'eng');
  const deLocal = defaultData?.locals?.find((local) => local.language === 'de');

  const defaultValues = {
    id: defaultData?.id,
    type: defaultData?.type,
    locals: {
      eng: engLocal?.name,
      de: deLocal?.name,
    },
  };

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues.id]);

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessfullyAddedArchiveType',
    variant: 'success',
    cb: () => {
      reset();
      handleDrawerClose();
    },
  });
  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'errorEditingLeadType',
    variant: 'error',
  });

  const onSubmit = async (data) => {
    mutate([
      omitBy({ id: engLocal?.id, name: data?.locals?.eng, language: 'eng' }, isUndefined),
      omitBy({ id: deLocal?.id, name: data?.locals?.de, language: 'de' }, isUndefined),
    ]);
  };
  return {
    control,
    handleSubmit,
    onSubmit,
  };
};
