import { Button } from '@mui/material';
import React from 'react';

const SmallButton = ({ iconUrl, onClick, altText }) => {
  return (
    <Button
      variant="contained"
      sx={{
        bgcolor: '#EDF1F3',
        color: '#000',
        fontWeight: 700,
        textTransform: 'none',
        borderRadius: '15px',
        display: 'flex',
        gap: '10px',
        width: '50px',
        height: '50px',
      }}
      onClick={onClick}
    >
      <img alt={altText} src={iconUrl}></img>
    </Button>
  );
};

export default SmallButton;
