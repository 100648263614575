import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './assets/fonts/Megante/Megante.ttf';

const container = document.getElementById('root');
const root = createRoot(container);
const ThemedApp = () => {
  return (
    // <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
    // </React.StrictMode>
  );
};

root.render(<ThemedApp />);
