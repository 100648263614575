/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useSetFromUserInfo = (property, setValue, value) => {
  const user = useSelector((state) => state.auth.currentUser);
  useEffect(() => {
    if (user?.info?.[property]) {
      setValue((prev) => [...prev, value]);
    }
  }, []);
};

export default useSetFromUserInfo;
