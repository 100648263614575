/* eslint-disable array-callback-return */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ExpandMoreRounded } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SettingsTabs = ({ tabs }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { currentUser } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const finishedTrial = currentUser?.info?.finishedTrial;
  const [active, setActive] = useState(finishedTrial ? 0 : 1);
  const [expanded, setExpanded] = useState(false);

  const navigate = useNavigate();

  const handleClick = (index) => {
    const tab = tabs[index];
    if (tab.navigateTo) {
      navigate(tab.navigateTo);
    } else {
      setActive(index);
    }
  };

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection={isMobile ? 'column' : 'row'}
      overflow="hidden"
      maxWidth="100%"
      minHeight="100vh"
    >
      <Box display="flex" flexDirection="column" alignItems="center" pt={3}>
        {isMobile ? (
          <Accordion
            borderRadius="14px"
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
            sx={{ width: '100%' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreRounded />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>{t('Options')}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                flexDirection: 'column',
                display: 'flex',
                gap: '10px',
              }}
            >
              {tabs?.map((tab, index) => {
                const isActive = index === active;
                const isVisible = finishedTrial ? true : !tab.auth;
                if (isVisible)
                  return (
                    <ListItem key={index}>
                      <ListItemButton
                        onClick={() => handleClick(index)}
                        sx={{
                          display: 'flex',
                          borderRadius: '12px',
                          height: '40px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: isActive ? '#ffffff' : '#718096',
                          bgcolor: isActive && '#004852',
                          '& img': {
                            filter: isActive
                              ? 'brightness(1000)'
                              : 'brightness(0) saturate(100%) invert(53%) sepia(19%) saturate(440%) hue-rotate(176deg) brightness(90%) contrast(88%)',
                          },
                          '&:hover': {
                            bgcolor: '#00485288',
                            color: '#fff',
                            '& img': {
                              filter: 'brightness(1000)',
                            },
                          },
                        }}
                      >
                        <ListItemIcon>
                          <Box component="img" src={tab.icon}></Box>
                        </ListItemIcon>

                        <ListItemText>
                          <Typography
                            sx={{
                              '&': {
                                lineHeight: '18px',
                              },
                            }}
                            maxHeight={40}
                            fontWeight={isActive ? 700 : 400}
                            variant="body1"
                          >
                            {tab.title}
                          </Typography>
                        </ListItemText>
                        <Box color={isActive ? '#fff' : '#ACB5BB'}>
                          <ArrowForwardIosIcon />
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  );
              })}
            </AccordionDetails>
          </Accordion>
        ) : (
          tabs?.map((tab, index) => {
            const isActive = index === active;
            const isVisible = finishedTrial ? true : !tab.auth;
            if (isVisible)
              return (
                <ListItem key={index}>
                  <ListItemButton
                    onClick={() => handleClick(index)}
                    sx={{
                      display: 'flex',
                      borderRadius: '12px',
                      height: '40px',
                      '& .MuiListItemIcon-root': {
                        minWidth: 'auto',
                      },
                      '& .MuiListItemText-root': {
                        marginLeft: 2,
                      },
                      color: isActive ? '#ffffff' : '#718096',
                      bgcolor: isActive && '#004852',
                      '& img': {
                        filter: isActive
                          ? 'brightness(1000)'
                          : 'brightness(0) saturate(100%) invert(53%) sepia(19%) saturate(440%) hue-rotate(176deg) brightness(90%) contrast(88%)',
                      },
                      '&:hover': {
                        bgcolor: '#00485288',
                        color: '#fff',
                        '& img': {
                          filter: 'brightness(1000)',
                        },
                      },
                    }}
                  >
                    <ListItemIcon>
                      <Box component="img" src={tab.icon}></Box>
                    </ListItemIcon>

                    <ListItemText>
                      <Typography
                        sx={{
                          '&': {
                            lineHeight: '18px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          },
                        }}
                        maxHeight={40}
                        fontWeight={isActive ? 700 : 400}
                        variant="body1"
                      >
                        {tab.title}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
          })
        )}
      </Box>
      <Divider orientation="vertical" sx={{ height: '100%' }} />
      <Box width="100%" display="flex" paddingX={3} pt={3}>
        {tabs[active]?.content}
      </Box>
    </Box>
  );
};

export default SettingsTabs;
