import { useMutation, useQuery, useQueryClient } from 'react-query';
import { customInstance } from './client';

const suffix = 'v1/tax-potential';

//GET TAX POTENTIAL
const getTaxPotential = ({ user, archive }) => {
  return customInstance({
    url: `${suffix}/user/archive`,
    method: 'get',
    params: { user, archive },
  });
};

export const useGetTaxPotential = ({ user, archive }) => {
  return useQuery(['potential', user, archive], () => getTaxPotential({ user, archive }), { enabled: !!archive });
};

const createTaxPotential = async (data, trackProgress) => {
  return customInstance({
    url: `${suffix}`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (data) => {
      trackProgress(Math.round((100 * data.loaded) / data.total));
    },
  });
};

export const useCreateTaxPotential = (trackProgress) => {
  const queryClient = useQueryClient();
  return useMutation((data) => createTaxPotential(data, trackProgress), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['potential'] });
    },
  });
};

// We dont need this.
// const updateTaxPotential = (id, data) => {
//   return customInstance({
//     url: `${suffix}/${id}`,
//     method: 'put',
//     data: data,
//     headers: { 'Content-Type': 'multipart/form-data' },
//   });
// };

// export const useUpdateTaxPotential = () => {
//   const queryClient = useQueryClient();
//   return useMutation(({ id, data }) => updateTaxPotential(id, data), {
//     onSuccess: () => {
//       queryClient.invalidateQueries({ queryKey: ['potential'] });
//     },
//   });
// };

const deleteTaxPotential = (id) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'delete',
  });
};

export const useDeleteTaxPotential = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => deleteTaxPotential(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['potential'] });
    },
  });
};
