import { Box } from '@mui/material';
import React from 'react';

const PaymentCard = ({ src }) => {
  return (
    <Box
      component="img"
      src={src}
      width="150px"
      padding={1}
      sx={{ background: 'white', boxShadow: '5', borderRadius: '8px', objectFit: 'contain' }}
    ></Box>
  );
};

export default PaymentCard;
