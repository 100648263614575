/* eslint-disable global-require */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
export const resources = {
  de: {
    translation: {
      ...require('../assets/locales/de/common.json'),
      ...require('../assets/locales/de/login.json'),
      ...require('../assets/locales/de/home.json'),
      ...require('../assets/locales/de/dashboard.json'),
      ...require('../assets/locales/de/adminDashboard.json'),
      ...require('../assets/locales/de/terms&Condition.json'),
      ...require('../assets/locales/de/myDocuments.json'),
    },
  },
  eng: {
    translation: {
      ...require('../assets/locales/eng/common.json'),
      ...require('../assets/locales/eng/login.json'),
      ...require('../assets/locales/eng/home.json'),
      ...require('../assets/locales/eng/dashboard.json'),
      ...require('../assets/locales/eng/adminDashboard.json'),
      ...require('../assets/locales/eng/terms&Condition.json'),
      ...require('../assets/locales/eng/myDocuments.json'),
    },
  },
  dutch: {
    translation: {
      ...require('../assets/locales/dutch/common.json'),
      ...require('../assets/locales/dutch/login.json'),
      ...require('../assets/locales/dutch/home.json'),
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'eng',
    supportedLngs: ['eng', 'de', 'dutch'],
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
