import React from 'react';
import './style.css';
const AppButton = ({ svgPath, topText, bottomText, viewBox, onClick, isMobile }) => {
  return !isMobile ? (
    <button className="appstore-button" onClick={onClick} style={{ width: !svgPath && '200px' }}>
      {svgPath && (
        <svg viewBox={viewBox} aria-hidden="true">
          <path d={svgPath} fill="white" />
        </svg>
      )}
      <span className="button-text">
        <span className="top-text">{topText}</span>
        <span className="bottom-text">{bottomText}</span>
      </span>
    </button>
  ) : (
    <button className="appstore-button" onClick={onClick} style={{ width: '220px' }}>
      {svgPath && (
        <svg viewBox={viewBox} aria-hidden="true">
          <path d={svgPath} fill="white" />
        </svg>
      )}
      <span className="button-text">
        <span className="top-text">{topText}</span>
        <span className="bottom-text">{bottomText}</span>
      </span>
    </button>
  );
};

export default AppButton;
