import i18n from '../../../config/i18n';

export const tags = [
  { value: 'search', label: i18n.t('searchAllFields') },
  { value: 'firstName', label: i18n.t('firstname') },
  { value: 'lastName', label: i18n.t('lastname') },
  { value: 'email', label: i18n.t('email') },
  { value: 'zip', label: i18n.t('zip') },
  { value: 'phoneNumber', label: i18n.t('phone.number') },
];
