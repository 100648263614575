import { Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import VerifyLoader from '../../verifyLoader/VerifyLoader';
import TableComponent from '../../Table/Table';
import SubmitButton from '../../commonComponents/SubmitButton';
import useLeadTypeColumns from '../../../hooks/AllUsers/useLeadTypeColumns';
import CustomDrawer from '../../modals/CustomDrawer';
import LeadTypeForm from './LeadTypesForm';
import CloseIcon from '@mui/icons-material/Close';
import EditLeadTypeForm from './EditLeadTypesForm';

const LeadTypesTable = ({ leadTypes, isLoadingLeadTypes }) => {
  const { t } = useTranslation();
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [selectedLeadType, setSelectedLeadType] = useState(null);

  const handleOpen = () => {
    setOpenAddDrawer(!openAddDrawer);
  };

  const handleOpenEdit = (id, row) => {
    setOpenEditDrawer(!openEditDrawer);
    if (typeof id === 'string') {
      setSelectedLeadType(row);
    } else {
      setSelectedLeadType(null);
    }
  };
  const columns = useLeadTypeColumns(handleOpenEdit);

  return (
    <>
      <Box m={5}>
        {isLoadingLeadTypes ? (
          <Box width="100%" display="flex" justifyContent="center">
            <VerifyLoader />
          </Box>
        ) : (
          leadTypes && (
            <TableComponent hasAboveStuff data={leadTypes} columns={columns}>
              <SubmitButton onClick={handleOpen} buttonText={t('AddNew')} backgroundColor="#31B099" color="inherit" />
            </TableComponent>
          )
        )}
      </Box>

      {/* Add Drawer */}
      <CustomDrawer open={openAddDrawer} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddArchiveType')}
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <LeadTypeForm handleDrawerClose={handleOpen} />
      </CustomDrawer>

      <CustomDrawer open={openEditDrawer} handleOpen={handleOpenEdit}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddArchiveType')}
          </Typography>
          <IconButton onClick={handleOpenEdit}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <EditLeadTypeForm defaultData={selectedLeadType} handleDrawerClose={handleOpenEdit} />
      </CustomDrawer>
    </>
  );
};

export default LeadTypesTable;
