import { Box } from '@mui/material';
import React from 'react';
import InfoField from './InfoField';
import { format } from 'date-fns';

const OldAddress = ({ data }) => {
  return (
    <Box display="flex" flexDirection="row" width="100%" gap="10px">
      <InfoField labelKey="old_address_label" value={data?.info.oldAddress ? data?.info.oldAddress : '/'} />
      <InfoField
        labelKey="lived_from"
        value={data?.info?.livedFrom ? format(new Date(data?.info?.livedFrom), 'dd.MM.yyyy') : '/'}
      />
      <InfoField
        labelKey="lived_to"
        value={data?.info?.livedTo ? format(new Date(data?.info?.livedTo), 'dd.MM.yyyy') : '/'}
      />
    </Box>
  );
};

export default OldAddress;
