import React from 'react';
import RequireAdmin from '../../components/RequireAdmin/RequireAdmin';
import { Box } from '@mui/material';
import VerticalHeader from '../../components/dashboardComponents/VerticalHeader';
import { Outlet } from 'react-router-dom';

const AdminLayout = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <VerticalHeader option="admin" />
      <Box sx={{ overflow: 'hidden', width: '100%' }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default RequireAdmin(AdminLayout);
