import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWidgetLocalization } from '../../../hooks/useWidgetLocalization';
import SubmitButton from '../../commonComponents/SubmitButton';
import CustomFormInput from '../../commonComponents/CustomFormInput';
import CustomSelect from '../../commonComponents/CustomSelect';

const DocumentWidgetsLocalizationForm = ({ handleDrawerClose, selectedWidget, languages }) => {
  const { t } = useTranslation();

  const { handleSubmit, control, onSubmit } = useWidgetLocalization(handleDrawerClose, selectedWidget);

  return (
    <Box component="form" display="flex" flexDirection="column">
      <CustomFormInput name="subtitle" control={control} label={'Subtitle'} />
      <CustomFormInput name="title" control={control} label={t('title')} />
      <CustomSelect
        control={control}
        name="language"
        label={t('language')}
        data={languages}
        valueField={(item) => item.code}
        displayField={(item) => item.name}
      />

      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default DocumentWidgetsLocalizationForm;
