import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomFormInput from '../../commonComponents/CustomFormInput';
import { Box } from '@mui/system';
import SubmitButton from '../../commonComponents/SubmitButton';
import { useLeadTypes } from '../../../hooks/useLeadTypes';

const LeadTypeForm = ({ handleDrawerClose }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, onSubmit } = useLeadTypes(handleDrawerClose);
  return (
    <Box display="flex" flexDirection="column">
      <CustomFormInput name="locals.eng" control={control} label={t('english_label')} />
      <CustomFormInput name="locals.de" control={control} label={t('deutsch_label')} />
      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default LeadTypeForm;
