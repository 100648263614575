import React from 'react';
import { IconButton, Typography } from '@mui/material';

const SubmitButton = ({ onClick, buttonText, color, backgroundColor, marginTop, disabled = false, ...props }) => {
  return (
    <IconButton
      variant="contained"
      component="label"
      color={color || 'inherit'}
      sx={{
        bgcolor: backgroundColor || '#31B099',
        '&:hover': {
          backgroundColor: '#0F5D66',
        },
        textTransform: 'none',
        borderRadius: '15px',
        width: 'fit-content',
        paddingY: '10px',
        paddingX: '25px',
        marginTop: !marginTop ? '10px' : '0',
        ...props.sx,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Typography variant="body1" color="#fff">
        {buttonText}
      </Typography>
    </IconButton>
  );
};

export default SubmitButton;
