/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateStatus, useCreateStatusLocals, useUpdateStatus } from '../api/status';
import useToastNotification from './useToastNotification';
import { useEffect } from 'react';
import { format } from 'date-fns';

export const useAddStatus = (handleDrawerClose, selectedStatus, tableNames) => {
  const { t } = useTranslation();
  const { mutate, isSuccess, isLoading, isError, data: addData } = useCreateStatus();
  const { mutate: addLocals } = useCreateStatusLocals();

  const {
    mutate: editMutate,
    isSuccess: isEditSuccess,
    isLoading: isEditLoading,
    isError: isEditError,
  } = useUpdateStatus();

  const schema = yup.object().shape({
    name: yup.object().shape({
      eng: yup.string().required(t('required_field', { field: t('english_label') })),
      de: yup.string().required(t('required_field', { field: t('deutsch_label') })),
    }),
    color: yup.string().required(t('name.required')),
    validFrom: yup.date().required(t('validFrom.required')),
    validTo: yup.date().required(t('validTo.required')),
    entity: tableNames && yup.string().oneOf(tableNames).required(),
    is_active: yup.boolean().required(),
  });

  const defaultValues = {
    name: {
      eng: selectedStatus?.name?.eng || '',
      de: selectedStatus?.name?.de || '',
    },
    color: selectedStatus?.color || '',
    validFrom: selectedStatus?.validFrom ? format(new Date(selectedStatus.validFrom), 'yyyy-MM-dd') : '',
    validTo: selectedStatus?.validTo ? format(new Date(selectedStatus.validTo), 'yyyy-MM-dd') : '',
    entity: selectedStatus?.entity || '',
    is_active: selectedStatus ? selectedStatus.is_active : true,
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'successfullyAddedStatus',
    variant: 'success',
    cb: () => {
      if (addData) {
        const formVals = getValues();
        addLocals({ language: 'eng', name: formVals?.name?.eng, status: addData?.id });
        reset(defaultValues);
        handleDrawerClose();
      }
    },
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorAddingStatus',
    variant: 'error',
  });

  //EDIT TOASTS
  useToastNotification({
    isShow: isEditSuccess,
    loading: isEditLoading,
    messageKey: 'successfullyEditedStatus',
    variant: 'success',
    cb: () => {
      if (selectedStatus) {
        const formVals = getValues();
        const foundEng = selectedStatus?.locals?.find((local) => local.language === 'eng');
        const foundDe = selectedStatus?.locals?.find((local) => local.language === 'de');
        if (foundDe && foundEng) {
          addLocals([
            { id: foundEng.id, language: 'eng', name: formVals?.name?.eng, status: selectedStatus?.id },
            { id: foundDe.id, language: 'de', name: formVals?.name?.de, status: selectedStatus?.id },
          ]);
        }
        if (foundDe && !foundEng) {
          addLocals([
            { language: 'eng', name: formVals?.name?.eng, status: selectedStatus?.id },
            { id: foundDe.id, language: 'de', name: formVals?.name?.de, status: selectedStatus?.id },
          ]);
        }
        if (!foundDe && foundEng) {
          addLocals([
            { id: foundEng.id, language: 'eng', name: formVals?.name?.eng, status: selectedStatus?.id },
            { language: 'de', name: formVals?.name?.de, status: selectedStatus?.id },
          ]);
        }
        if (!foundDe && !foundEng) {
          addLocals([
            { language: 'eng', name: formVals?.name?.eng, status: selectedStatus?.id },
            { language: 'de', name: formVals?.name?.de, status: selectedStatus?.id },
          ]);
        }
        reset(defaultValues);
        handleDrawerClose();
      }
    },
  });

  useEffect(() => {
    reset(defaultValues);
  }, [selectedStatus]);

  useToastNotification({
    isShow: isEditError,
    loading: isEditLoading,
    messageKey: 'ErrorEditingStatus',
    variant: 'error',
  });

  const onSubmit = (data) => {
    if (selectedStatus && selectedStatus.id) {
      editMutate({
        id: selectedStatus.id,
        data: {
          color: data?.color,
          validFrom: data?.validFrom,
          validTo: data?.validTo,
          entity: data?.entity,
          is_active: data?.is_active,
        },
      });
    } else {
      mutate({
        language: 'de',
        color: data?.color,
        validFrom: data?.validFrom,
        validTo: data?.validTo,
        name: data?.name.de,
        entity: data?.entity,
        is_active: true,
      });
    }
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    isLoading,
    errors,
    reset,
    defaultValues,
  };
};
