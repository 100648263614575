import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from '../../pages/landingPage';
import Auth from '../../pages/auth';
import TermsAndConditions from '../../pages/termsAndConditions';
import NotFoundPage from '../../pages/notFound';
import Unauthorized from '../../pages/unauthorized';
import ReadMore from '../../pages/readMore';
import Impressum from '../../pages/impressum';
import Legal from '../../pages/legal';
import Register from '../../pages/register';
import NewDashboard from '../../pages/newDashboard';
import MyDocuments from '../../pages/myDocuments';
import Settings from '../../pages/settings';
import ChangePassword from '../../pages/changePassword';
import VerifyPass from '../../pages/verifyPass';
import Verify from '../../pages/verify';
import OtpPage from '../../pages/otp';
import SidebarLayout from '../../layout/SidebarLayout/SidebarLayout';
import AdminLayout from '../../layout/AdminLayout/AdminLayout';
import BrokerLayout from '../../layout/BrokerLayout';
import AdminDashboard from '../../pages/AdminDashboard';
import AllUsers from '../../pages/AllUsers';
import FamilyRelationsSettings from '../../components/adminDashboard/familyRelations/FamilyRelationsSettings';
import ArchivesSettingsPage from '../../components/adminDashboard/archives/ArchivesSettingsPage';
import Leads from '../../pages/Leads';
import FileTypeSettings from '../../components/adminDashboard/fileType/FileTypeSettings';
import SingleUser from '../../components/adminDashboard/users/SingleUser';
import ArchiveTypeSettingsPage from '../../components/adminDashboard/archives/ArchiveTypeSettingsPage';
import StatusesPage from '../../components/adminDashboard/statuses/StatusesPage';
import BrokerDashboard from '../../components/brokerDashboard/BrokerDashboard';
import FamilyMembersPage from '../../components/userInfo/FamilyMembersPage';
import BrokerTasks from '../../components/brokerDashboard/BrokerTasks';
import BrokerTaskData from '../../components/brokerDashboard/BrokerTaskData';
import Brokers from '../../pages/Brokers';
import BrokerAppointments from '../../components/brokerDashboard/BrokerAppointments';
import LeadsView from '../../components/adminDashboard/leads/LeadsView';
import Termins from '../../components/adminDashboard/leads/Termins/Termins';
import DocumentWidgets from '../../components/adminDashboard/documentWidgets/DocumentWidgets';
import Articles from '../../components/adminDashboard/articles/articles/Articles';
import ArticleEditor from '../../components/adminDashboard/articles/ArticleEditor/ArticleEditor';
import ArticleLocalization from '../../components/adminDashboard/articles/ArticleLocalization/ArticleLocalization';
import ArticleView from '../../pages/articleView';
import ArticlesView from '../../pages/articles';
import AppointmentsSlots from '../../components/adminDashboard/appointments/appointmentSlots/AppointmentSlots';
import UserAppointments from '../../components/adminDashboard/appointments/userAppointments/UserAppointments';
import LeadAppointments from '../../components/adminDashboard/appointments/leadAppointments/LeadAppointments';
import LeadTypes from '../../components/adminDashboard/leadTypes/LeadTypes';
import LeadPage from '../../pages/LeadPage';
import LeadUpdatePage from '../../pages/LeadUpdatePage';
import ThankYou from '../../pages/ThankYou';
import LeadsPage2 from '../../components/LeadsPage2/LeadsPage2';
import BrokerUsers from '../../pages/BrokerUsers';
import Tasks from '../../pages/Tasks';
import AppointmentsView from '../../components/adminDashboard/appointments/appointmentsView/AppointmentsView';
import BrokerLeads from '../../pages/BrokerLeads';
import TaxProfile from '../../pages/TaxProfile';

const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<LandingPage />} />
      <Route path="auth/:type?/:plan?" element={<Auth />} />
      <Route path="auth/register" element={<Register />} />
      <Route path="auth/otp" element={<OtpPage />} />
      <Route path="tac" element={<TermsAndConditions />} />
      <Route path="legal" element={<Legal />} />
      <Route path="impressum" element={<Impressum />} />
      <Route path="unauthorized" element={<Unauthorized />} />
      <Route path="readmore" element={<ReadMore />} />
      <Route path="verify-password" element={<VerifyPass />} />
      <Route path="verify" element={<Verify />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="changePassword" element={<ChangePassword />} />
      <Route path="campaign" element={<LeadPage />} />
      <Route path="campaign/:leadId" element={<LeadUpdatePage />} />
      <Route path="thank_you" element={<ThankYou />} />
      <Route path="campaign2" element={<LeadsPage2 />} />

      <Route element={<SidebarLayout />}>
        <Route path="dashboard" element={<NewDashboard />} />
        <Route path="my_documents/:archiveType" element={<MyDocuments />} />
        <Route path="my_documents/:archiveType/:fileType" element={<MyDocuments />} />
        <Route path="family-members" element={<FamilyMembersPage />} />
        <Route path="articles" element={<ArticlesView />} />
        <Route path="article/:lang/:id" element={<ArticleView />} />
        <Route path="tax-profile" element={<TaxProfile />} />
        <Route path="settings" element={<Settings />} />
      </Route>

      <Route element={<AdminLayout />}>
        <Route path="admin/dashboard" element={<AdminDashboard />} />
        <Route path="admin/users" element={<AllUsers />} />
        <Route path="admin/leads" element={<Leads />} />
        <Route path="admin/articles" element={<Articles />} />
        <Route path="admin/article-editor" element={<ArticleEditor />} />
        <Route path="admin/article/:lang/:id" element={<ArticleView />} />
        <Route path="admin/article-localization/:lang/:id" element={<ArticleLocalization />} />
        <Route path="admin/leads/:id" element={<LeadsView />} />
        <Route path="admin/termins" element={<Termins />} />
        <Route path="admin/users/:id" element={<SingleUser />} />
        <Route path="admin/tasks" element={<Tasks />} />
        <Route path="admin/tasks/:id" element={<BrokerTaskData />} />
        <Route path="admin/lead-appointments" element={<LeadAppointments />} />
        <Route path="admin/user-appointments" element={<UserAppointments />} />

        {/* Settings */}
        <Route path="admin/settings" element={<Settings />} />
        <Route path="admin/settings/family_relations" element={<FamilyRelationsSettings />} />
        <Route path="admin/settings/file_types" element={<FileTypeSettings />} />
        <Route path="admin/settings/statuses" element={<StatusesPage />} />
        <Route path="admin/settings/brokers" element={<Brokers />} />
        <Route path="admin/settings/archives" element={<ArchivesSettingsPage />} />
        <Route path="admin/settings/archive_types" element={<ArchiveTypeSettingsPage />} />
        <Route path="admin/settings/lead_types" element={<LeadTypes />} />
        <Route path="admin/settings/appointment-slots" element={<AppointmentsSlots />} />
        <Route path="admin/settings/document-widgets" element={<DocumentWidgets />} />
      </Route>

      <Route element={<BrokerLayout />}>
        <Route path="broker/dashboard" element={<BrokerDashboard />} />
        <Route path="broker/settings" element={<Settings />} />
        <Route path="broker/tasks" element={<BrokerTasks />} />
        <Route path="broker/tasks/:id" element={<BrokerTaskData />} />
        <Route path="broker/appointments" element={<BrokerAppointments />} />
        <Route path="broker/appointments/:id" element={<AppointmentsView />} />
        <Route path="broker/leads" element={<Leads />} />
        <Route path="broker/leads" element={<BrokerLeads />} />
        <Route path="broker/leads/:id" element={<LeadsView />} />
        <Route path="broker/customers" element={<BrokerUsers />} />
        <Route path="broker/users/:id" element={<SingleUser />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
