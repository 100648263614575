import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import NavBarRegister from '../components/navBarRegister/navBarRegister';
import ForgotPassLayout from '../components/registrationLayout/forgotPassForm';
import { useTranslation } from 'react-i18next';
import Background from '../components/commonComponents/Background';
const ChangePassword = () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="center" alignItems="center" bgcolor="#1D2735" width="100%" height="100%">
      <NavBarRegister />
      <Background
        src="https://taxofficestorage.blob.core.windows.net/media/loginBackground.svg"
        alt="Login background"
      />

      <Box
        width="100%"
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        zIndex={300}
      >
        <Paper
          sx={{
            zIndex: '1000',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '32px',
            gap: '32px',
            borderRadius: '15px',
            boxShadow: '0px 34px 114px rgba(0, 0, 0, 0.08)',
            width: { xs: '90%', sm: '50%', md: '480px' },
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" width="100%">
            <Typography variant="h4" textAlign="center">
              {t('forgotYourPass')}
            </Typography>
          </Box>
          <Box width="100%" display="flex" flexDirection="column" justifyContent="center" padding={0} gap="32px">
            <ForgotPassLayout />
          </Box>
        </Paper>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="5px"
          paddingY={'30px'}
        >
          <Typography variant="body2" fontWeight={400} color="#fff">
            {t('dontHaveAcc')}
          </Typography>
          <Link to={'/auth/register'} style={{ textDecoration: 'none' }}>
            <Typography variant="body2" fontWeight={400} color="#31B099">
              {t('regHere')}
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
