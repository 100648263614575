import { Box, Typography } from '@mui/material';
import React from 'react';
import { multilineExtractor } from '../../utils';
import { useTranslation } from 'react-i18next';
import { trim } from 'lodash-es';

const ArticleCardContent = ({ title, variant = 'body1', body, gap = '0px', headingSize }) => {
  const { t } = useTranslation();
  const bodyText = multilineExtractor(t(body));
  return (
    <Box display="flex" flexDirection="column" height="100%" gap={gap}>
      <Typography variant={headingSize} paddingBottom={3}>
        {t(title)}
      </Typography>
      {bodyText.map((text) => {
        return (
          <Typography key={text} variant={variant} pl={trim(text).charAt(0) === '*' ? '10px' : '0px'}>
            {text}
          </Typography>
        );
      })}
    </Box>
  );
};

export default ArticleCardContent;
