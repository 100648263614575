import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

const LogoLink = ({ src, alt, to = '/' }) => (
  <Link to={to}>
    <Box
      component="img"
      sx={{
        height: { xs: 35, md: '35px' },
        width: { xs: '170px', md: '290px' },
        objectFit: 'contain',
      }}
      alt={alt}
      src={src}
    />
  </Link>
);

export default LogoLink;
