import Box from '@mui/material/Box';
import React from 'react';
import TableComponent from '../../Table/Table';
import { useState } from 'react';
import { Button, IconButton, Typography } from '@mui/material';
import CustomDrawer from '../../modals/CustomDrawer';
import CloseIcon from '@mui/icons-material/Close';
import { useUpdateManyFileTypes } from '../../../api/fileTypes';
import useFileTypeColumns from '../../../hooks/AllUsers/useFileTypeColumns';
import FileTypeForm from './FileTypeForm';
import EditFileTypeForm from './EditFileTypeForm';
import { useTranslation } from 'react-i18next';
import VerifyLoader from '../../verifyLoader/VerifyLoader';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SubmitButton from '../../commonComponents/SubmitButton';

const FileTypeTable = ({
  fileTypes,
  isLoading,
  isFetching,
  parentType,
  activeTabs,
  setActiveTabs,
  openAddDrawer,
  setOpenAddDrawer,
  archiveTypes,
}) => {
  const { t } = useTranslation();
  const { mutate } = useUpdateManyFileTypes();

  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [editFileTypeId, setEditFileTypeId] = useState(null);

  const handleOpen = () => {
    setOpenAddDrawer(!openAddDrawer);
  };

  const handleOpenEdit = (id) => {
    setOpenEditDrawer(!openEditDrawer);
    if (typeof id === 'string') {
      setEditFileTypeId(id);
    } else {
      setEditFileTypeId(null);
    }
  };

  const columns = useFileTypeColumns(handleOpenEdit, activeTabs);

  const onRowDrop = (draggedRow, droppedRow) => {
    const data = [
      { id: draggedRow?.original.id, order: droppedRow?.index + 1 },
      { id: droppedRow?.original.id, order: draggedRow?.index + 1 },
    ];
    mutate({ data });
  };
  return (
    <>
      <Box m={3}>
        <Box width="100%">
          {isLoading || isFetching ? (
            <Box width="100%" display="flex" justifyContent="center">
              <VerifyLoader />
            </Box>
          ) : (
            <TableComponent hasAboveStuff data={fileTypes || []} columns={columns} isDraggable onRowDrop={onRowDrop}>
              <Box display="flex" gap={1}>
                <SubmitButton onClick={handleOpen} buttonText={t('AddNew')} backgroundColor="#31B099" color="inherit" />
                {activeTabs.length > 0 && (
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: '#31B099',
                      textTransform: 'none',
                      borderRadius: '25px',
                      width: 'fit-content',
                      paddingY: '10px',
                      paddingX: '25px',
                      display: 'flex',
                      gap: '10px',
                    }}
                    onClick={() => {
                      setActiveTabs([]);
                    }}
                  >
                    {t('Reset')}
                    <RestartAltIcon />
                  </Button>
                )}
              </Box>
            </TableComponent>
          )}
        </Box>
      </Box>
      {/* Add Drawer */}
      <CustomDrawer open={openAddDrawer} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddFileType')}
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <FileTypeForm handleDrawerClose={handleOpen} parentType={parentType} archiveTypes={archiveTypes} />
      </CustomDrawer>

      {/* Edit Drawer */}
      <CustomDrawer open={openEditDrawer} handleOpen={handleOpenEdit}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('EditFileType')}
          </Typography>
          <IconButton onClick={handleOpenEdit}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <EditFileTypeForm id={editFileTypeId} handleDrawerClose={handleOpenEdit} />
      </CustomDrawer>
    </>
  );
};

export default FileTypeTable;
