import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllTerminsByLead } from '../../../../api/termins';
import { Box, IconButton, Typography } from '@mui/material';
import CustomDrawer from '../../../modals/CustomDrawer';
import AddNotesForm from '../Notes/AddNotesForm';
import CloseIcon from '@mui/icons-material/Close';
import useJournalColumns from '../../../../hooks/AllUsers/useJournalColumns';
import TableComponent from '../../../Table/Table';
import { handleLimit, handleNextPage, handlePrevPage } from '../../../../utils/paginationFunction';
import TableContainer from '../../../Table/TableContainer';
import { createVcsContent } from '../../../../utils/vcsContent';
import { downloadVcsFile } from '../../../../utils/downloadVcsFile';

const Journal = ({ id }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const { data: allTermins, isLoading, isFetching } = useGetAllTerminsByLead({ id, page, limit });
  const termins = allTermins?.results;
  const [openAddDrawer, setOpenAddDrawer] = useState(false);

  const handleDownload = (data) => {
    const startTime = new Date(data.from);
    const endTime = new Date(startTime.getTime() + 60 * 60 * 1000);

    const eventForVcs = {
      id: data.id,
      dtstamp: new Date(),
      start: data.from,
      end: endTime,
      summary: data.interest,
      description: `Meeting details:
          - Lead: ${data.lead.name}, ${data.lead.email}
          - Address: ${data.lead.address}, ${data.lead.zip}`,
    };
    const vcsContent = createVcsContent(eventForVcs);
    const fileName = `${data.interest}-${data.lead.name}-${new Date(data.from).toISOString()}`;
    downloadVcsFile(vcsContent, `${fileName}.ics`);
  };
  const columns = useJournalColumns(handleDownload);

  const handleOpen = () => {
    setOpenAddDrawer(!openAddDrawer);
  };

  return (
    <>
      <TableContainer>
        <TableComponent
          hasAboveStuff
          data={termins || []}
          columns={columns}
          hasPagination
          hasBoxShadow
          rowsPerPage={allTermins?.limit}
          page={Number.isInteger(allTermins?.currentPage) ? allTermins.currentPage - 1 : 0}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          hasNextPage={allTermins?.hasNextPage}
          hasPreviousPage={allTermins?.hasPreviousPage}
          onPageChange={(lim) => handleLimit(lim, setLimit)}
          setPage={setPage}
          isLoading={isLoading}
          isFetching={isFetching}
          total={allTermins?.total || 0}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h6" fontWeight={700}>
              {t('Journal')}
            </Typography>
          </Box>
        </TableComponent>
      </TableContainer>

      {/* Add Drawer */}
      <CustomDrawer open={openAddDrawer} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddJournal')}
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <AddNotesForm handleDrawerClose={handleOpen} id={id} />
      </CustomDrawer>
    </>
  );
};

export default Journal;
