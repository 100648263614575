import { Divider, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import ArchiveTypeTable from './ArchiveTypeTable';
import { useTranslation } from 'react-i18next';
import { useGetAllIcons } from '../../../api/fileTypes';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useGetAllArchiveTypes } from '../../../api/archive';
import LanguageSelect from '../../selectTranslation/translateSelect';

const ArchiveTypeSettingsPage = () => {
  const { data: icons } = useGetAllIcons();
  const { data: archiveTypes, isLoading, isFetching } = useGetAllArchiveTypes();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center" px={4}>
          <IconButton onClick={() => navigate(-1)} sx={{ marginLeft: -2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" fontWeight={700} px={5} py={2.45} sx={{ marginLeft: -4, flexGrow: 1 }}>
            {t('ArchiveTypesSettings')}
          </Typography>
          <LanguageSelect />
        </Box>

        <Divider />
        <Box p={5}>
          <Box display="flex" flexDirection="column">
            <ArchiveTypeTable
              icons={icons}
              archiveTypes={archiveTypes}
              isLoadingArchiveTypes={isLoading || isFetching}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ArchiveTypeSettingsPage;
