import { Grid, Typography } from '@mui/material';
import React from 'react';
import YesNoInput from '../../YesNoInput';

const Questions = ({ control, t }) => {
  return (
    <Grid container>
      <Grid item md={12} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h7" fontWeight={700}>
          {t('question_one')}
        </Typography>
        <YesNoInput control={control} name="receivedInheritance" padding={'0'} notFullWidth />
      </Grid>
      <Grid item md={12} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h7" fontWeight={700}>
          {t('question_two')}
        </Typography>
        <YesNoInput control={control} name="assets" padding={'0'} notFullWidth />
      </Grid>
      <Grid item md={12} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h7" fontWeight={700}>
          {t('question_three')}
        </Typography>
        <YesNoInput control={control} name="religion" padding={'0'} notFullWidth />
      </Grid>
      <Grid item md={12} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h7" fontWeight={700}>
          {t('question_four')}
        </Typography>
        <YesNoInput control={control} name="taxExtension" padding={'0'} notFullWidth />
      </Grid>
      <Grid item md={12} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h7" fontWeight={700}>
          {t('question_five')}
        </Typography>
        <YesNoInput control={control} name="furtherEducation" padding={'0'} notFullWidth />
      </Grid>
    </Grid>
  );
};

export default Questions;
