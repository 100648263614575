import { Button, SvgIcon } from '@mui/material';
import React from 'react';

const CustomIconButton = ({ label, icon, sx, ...props }) => {
  return (
    <Button
      endIcon={<SvgIcon>{icon}</SvgIcon>}
      sx={{
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '150%',
        textAlign: 'center',
        letterSpacing: '-0.02em',
        width: '240px',
        height: '54px',
        color: '#fff',
        backgroundColor: props.variant !== 'outlined' && '#31B099',
        borderRadius: '14px',
        '&:hover': {
          backgroundColor: '#31B09999',
        },
        display: 'flex',
        gap: '8px',
        ...sx,
      }}
      {...props}
    >
      {label}
    </Button>
  );
};

export default CustomIconButton;
