import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AppButton from './AppButton';
import { useNavigate } from 'react-router-dom';
import useCurrentUser from '../../../hooks/useCurrentUser';

const AppButtons = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: loggedUser } = useCurrentUser();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="center" alignItems="center" gap={2}>
      <AppButton
        isMobile={isMobile}
        svgPath={
          'M18.71 19.5C17.88 20.74 17 21.95 15.66 21.97C14.32 22 13.89 21.18 12.37 21.18C10.84 21.18 10.37 21.95 9.09997 22C7.78997 22.05 6.79997 20.68 5.95997 19.47C4.24997 17 2.93997 12.45 4.69997 9.39C5.56997 7.87 7.12997 6.91 8.81997 6.88C10.1 6.86 11.32 7.75 12.11 7.75C12.89 7.75 14.37 6.68 15.92 6.84C16.57 6.87 18.39 7.1 19.56 8.82C19.47 8.88 17.39 10.1 17.41 12.63C17.44 15.65 20.06 16.66 20.09 16.67C20.06 16.74 19.67 18.11 18.71 19.5ZM13 3.5C13.73 2.67 14.94 2.04 15.94 2C16.07 3.17 15.6 4.35 14.9 5.19C14.21 6.04 13.07 6.7 11.95 6.61C11.8 5.46 12.36 4.26 13 3.5Z'
        }
        viewBox="0 0 24 24"
        topText={t('topTextApple')}
        bottomText={t('bottomTextApple')}
        onClick={() => {
          window.open('https://apps.apple.com/sa/app/digitalesb%C3%BCro-ch/id6474090599', '_blank');
        }}
      />
      <AppButton
        isMobile={isMobile}
        svgPath={
          'M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z'
        }
        viewBox="0 0 512 512"
        topText={t('topTextGoogle')}
        bottomText={t('bottomTextGoogle')}
        onClick={() => {
          window.open(
            'https://play.google.com/store/apps/details?id=com.digitalesbueromobapp&hl=en_US&pli=1',
            '_blank',
          );
        }}
      />
      <AppButton
        isMobile={isMobile}
        topText={t('topTextWeb')}
        bottomText={t('bottomTextWeb')}
        onClick={() => {
          navigate(loggedUser ? '/dashboard' : '/auth/register');
        }}
      />
    </Box>
  );
};

export default AppButtons;
