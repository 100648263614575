import React, { useState } from 'react';
import TableComponent from '../../../../Table/Table';
import Header from './Header';

const UserAppointmentsTable = ({
  users,
  columns,
  allUsers,
  handleNextPage,
  handlePrevPage,
  handleLimit,
  setPage,
  isLoading,
  isFetching,
  filterTag,
  handleChange,
  MenuProps,
  tags,
  control,
  debouncedHandleSearchChange,
  setLimit,
  cursor,
  brokers,
  rowSelection,
  setRowSelection,
}) => {
  const [originalSelectedRows, setOriginalSelectedRows] = useState();
  return (
    <TableComponent
      hasAboveStuff
      data={users}
      columns={columns}
      hasPagination
      rowsPerPage={allUsers?.limit}
      page={allUsers?.currentPage - 1}
      handleNextPage={() => handleNextPage(setPage)}
      handlePrevPage={() => handlePrevPage(setPage)}
      hasNextPage={allUsers?.hasNextPage}
      hasPreviousPage={allUsers?.hasPreviousPage}
      onPageChange={(lim) => handleLimit(lim, setLimit)}
      setPage={setPage}
      isLoading={isLoading}
      isFetching={isFetching}
      total={allUsers?.total}
      cursor={cursor}
      getOriginalSelectedRows={setOriginalSelectedRows}
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
    >
      <Header
        setRowSelection={setRowSelection}
        originalSelectedRows={originalSelectedRows}
        brokers={brokers}
        MenuProps={MenuProps}
        control={control}
        debouncedHandleSearchChange={debouncedHandleSearchChange}
        filterTag={filterTag}
        handleChange={handleChange}
        tags={tags}
      />
    </TableComponent>
  );
};

export default UserAppointmentsTable;
