import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import CustomTextField from '../commonComponents/CustomTextField';
import { useTranslation } from 'react-i18next';
import { useUpdateUser } from '../../hooks/useUpdateUser';
import { useSelector } from 'react-redux';
import ResetPass from './ResetPass';
import CustomFormInput from '../commonComponents/CustomFormInput';
import useSwissGeoDataFromZip from '../../hooks/useSwissGeoDataFromZip';
import CorePhoneInput from '../phoneInput';
import CustomSelect from '../commonComponents/CustomSelect';

const UserForm = () => {
  const [placeOptions, setPlaceOptions] = useState([]);
  const { t } = useTranslation();
  const { currentUser } = useSelector((state) => state.auth);
  const { control, onSubmit, handleSubmit } = useUpdateUser(currentUser);
  const watchZip = useWatch({ control, name: 'zipCode' });
  const zipCode = watchZip || currentUser?.zipCode;
  const { data: geoData } = useSwissGeoDataFromZip(zipCode, zipCode?.length === 4);

  useEffect(() => {
    const options = [];
    geoData?.forEach((d) => {
      d.gem_name?.forEach((name) => {
        const labelName = `${d.plz6_name[0]} (${name})`;
        options.push({ label: labelName, value: labelName });
      });
    });
    setPlaceOptions(options);
  }, [geoData]);

  return (
    <Box>
      <Box
        p={3}
        sx={{
          borderRadius: '15px',
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        }}
      >
        <Typography variant="h6">{t('ProfileInformation')}</Typography>

        <Grid container spacing={1}>
          <Grid item md={6} lg={6}>
            <Typography variant="body2" fontSize="12px">
              {t('email')}
            </Typography>
            <CustomTextField fullWidth value={currentUser?.email ? currentUser?.email : ''} disabled />
          </Grid>
          <Grid item md={6} lg={6}>
            <CustomFormInput name="birthdate" control={control} type="date" label={t('birthdate')} />
          </Grid>
          <Grid item md={6} lg={6}>
            <CustomFormInput name="firstName" control={control} label={t('firstname')} />
          </Grid>
          <Grid item md={6} lg={6}>
            <CustomFormInput name="lastName" control={control} label={t('lastname')} />
          </Grid>
          <Grid item md={4} lg={4}>
            <CustomFormInput name="address" control={control} label={t('Address')} />
          </Grid>
          <Grid item md={4} lg={4}>
            <CustomFormInput name="zipCode" control={control} label={t('zip')} inputProps={{ maxLength: 4 }} />
          </Grid>
          <Grid item md={4} lg={4}>
            <CustomSelect
              control={control}
              name="place"
              label={t('Place')}
              data={placeOptions}
              valueField={(item) => item.value}
              displayField={(item) => item.label}
            />
          </Grid>
          <Grid item lg={12}>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Box display="flex" my={1} flexDirection="column">
                  <Typography variant="body2" fontSize="12px">
                    {t('phone.number')}
                  </Typography>
                  <CorePhoneInput
                    {...field}
                    control={control}
                    inputStyle={{ height: 60, borderRadius: '15px', width: '100%', overflow: 'hidden' }}
                    buttonStyle={{ borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px', overflow: 'hidden' }}
                    inputProps={{
                      required: true,
                      autoFocus: true,
                    }}
                  />
                  {error && (
                    <Typography variant="caption" color="error">
                      {error.message}
                    </Typography>
                  )}
                </Box>
              )}
            />
          </Grid>
        </Grid>
        <Box
          display="flex"
          my={1}
          flexDirection={{ sm: 'column', xs: 'column', md: 'row' }}
          width="100%"
          pt={2}
          gap={2}
        >
          <Button
            variant="contained"
            component="label"
            color="inherit"
            sx={{
              bgcolor: '#31B099',
              ':hover': { bgcolor: '#0F5D66' },
              textTransform: 'none',
              borderRadius: '15px',
              paddingY: '10px',
              paddingX: '25px',
              marginTop: '10px',
              flex: 1,
            }}
            onClick={handleSubmit(onSubmit)}
          >
            <Typography variant="body1" color="#fff">
              {t('saveChanges')}
            </Typography>
          </Button>
          <ResetPass email={currentUser?.email} />
        </Box>
      </Box>
    </Box>
  );
};

export default UserForm;
