import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import UserMenu from './userMenu';
import LanguageSelect from '../selectTranslation/translateSelect';
import { useTranslation } from 'react-i18next';
import { NavHashLink } from 'react-router-hash-link';
import { CloseRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/auth/authActions';

const DrawerComponent = () => {
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  const isAdmin = user.roles?.includes('admin');
  const isBroker = user.roles?.includes('broker');

  return (
    <>
      <Drawer
        PaperProps={{
          sx: { display: 'flex', alignItems: 'flex-start', width: '50%' },
        }}
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box display="flex" p="20px" width="100%" textAlign="right" justifyContent="space-between">
          <LanguageSelect />
          <CloseRounded
            fontSize="large"
            onClick={() => {
              setOpenDrawer(false);
            }}
          />
        </Box>
        <Divider sx={{ width: '100%' }} />
        <List
          sx={{
            width: '100%',
            '& > *': {
              height: '50px',
              borderBottom: '1px solid #00000022',
              width: '100%',
            },
          }}
        >
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavHashLink style={{ color: '#1A1C1E', textDecoration: 'none' }} to="/#how_to_start">
                <Typography variant="body2">{t('how.to.start')}</Typography>
              </NavHashLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavHashLink style={{ textDecoration: 'none' }} to="/#tax_planing">
                <Typography variant="body2" color="#1A1C1E">
                  {t('content.title')}
                </Typography>
              </NavHashLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavHashLink style={{ color: '#1A1C1E', textDecoration: 'none' }} to="/#tips">
                <Typography variant="body2">{t('tips')}</Typography>
              </NavHashLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)} sx={{ paddingBottom: '16px' }}>
            <ListItemText>
              <NavHashLink style={{ color: '#1A1C1E', textDecoration: 'none' }} to="/#pricing">
                <Typography variant="body2">{t('pricing')}</Typography>
              </NavHashLink>
            </ListItemText>
          </ListItem>
          {!accessToken ? (
            <>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link style={{ color: '#1A1C1E', textDecoration: 'none' }} to="/auth/register">
                    <Typography variant="body2">{t('createAcc')}</Typography>
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link style={{ textDecoration: 'none' }} to="/auth/signin">
                    <Typography variant="body2">{t('signin')}</Typography>
                  </Link>
                </ListItemText>
              </ListItem>
            </>
          ) : (
            <>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link
                    style={{ color: '#1A1C1E', textDecoration: 'none' }}
                    to={isBroker ? '/broker/dashboard' : isAdmin ? '/admin/dashboard' : `/dashboard`}
                  >
                    <Typography variant="body2">{t('dashboard')}</Typography>
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={'/'}
                    onClick={() => {
                      dispatch(logout());
                    }}
                  >
                    <Typography variant="body2">{t('logout')}</Typography>
                  </Link>
                </ListItemText>
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
          <MenuIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default DrawerComponent;
