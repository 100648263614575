import React from 'react';
import { useGetAllLeadTypes } from '../../../api/leadTypes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LeadTypesTable from './LeadTypesTable';
import LanguageSelect from '../../selectTranslation/translateSelect';

const LeadTypes = () => {
  const { data: leadTypes, isLoading, isFetching } = useGetAllLeadTypes();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center" px={4}>
          <IconButton onClick={() => navigate(-1)} sx={{ marginLeft: -2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" fontWeight={700} px={5} py={2.45} sx={{ marginLeft: -4, flexGrow: 1 }}>
            {t('ArchiveTypesSettings')}
          </Typography>
          <LanguageSelect />
        </Box>

        <Divider />
        <Box p={5}>
          <Box display="flex" flexDirection="column">
            <LeadTypesTable leadTypes={leadTypes} isLoadingLeadTypes={isLoading || isFetching} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default LeadTypes;
