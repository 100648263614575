import { SvgIcon } from '@mui/material';
import React from 'react';

const Logo = (props) => {
  return (
    <svg
      width={props?.width || '204'}
      height={props?.height || '35'}
      viewBox="0 0 204 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2178_5467)">
        <path
          d="M0.00760632 10.9157C0.00760632 7.84744 0.0123389 4.77819 3.42499e-05 1.70988C-0.000912259 1.36486 0.0823805 1.25899 0.444893 1.26088C3.42923 1.276 6.41452 1.30436 9.39886 1.26088C14.1021 1.19377 17.2142 4.1666 18.1635 8.21515C18.8649 11.2041 18.5355 14.0871 16.8602 16.7187C15.4224 18.9788 13.3212 20.2265 10.6501 20.4941C10.0378 20.5555 9.41779 20.5593 8.80067 20.5612C6.01415 20.5678 3.22763 20.5564 0.441107 20.5735C0.0738619 20.5753 -0.00185877 20.4629 3.42499e-05 20.1216C0.0123389 17.0533 0.00665981 13.9841 0.00665981 10.9148L0.00760632 10.9157ZM2.65594 19.7653C2.82442 19.7653 2.95125 19.7653 3.07713 19.7653C4.74488 19.7653 6.41357 19.7842 8.08132 19.7605C10.7609 19.7237 12.8811 18.6026 14.2128 16.2754C16.0112 13.1314 16.0642 9.83721 14.5668 6.57985C13.1963 3.5985 10.7883 2.07664 7.46609 2.06813C5.99616 2.06435 4.52718 2.06813 3.05726 2.06813C2.9238 2.06813 2.79129 2.06813 2.65594 2.06813V19.7653Z"
          fill="white"
        />
        <path
          d="M113.24 19.3361C113.099 19.6556 112.955 20.0101 112.783 20.3504C112.735 20.443 112.607 20.5517 112.513 20.5555C111.92 20.5744 111.327 20.5649 110.705 20.5649V1.30908H113.359V8.4817C113.497 8.37962 113.564 8.33991 113.618 8.28793C114.663 7.28879 115.945 6.86342 117.356 6.78874C119.294 6.68571 121.05 7.1867 122.532 8.4732C123.805 9.57915 124.443 11.0122 124.635 12.6673C124.793 14.0313 124.673 15.3632 124.126 16.6261C123.204 18.751 121.51 19.9543 119.303 20.4865C117.835 20.84 116.362 20.8258 114.944 20.2691C114.36 20.0394 113.831 19.666 113.239 19.3361H113.24ZM113.364 13.9822C113.364 15.3188 113.355 16.6563 113.373 17.9929C113.376 18.1791 113.463 18.4098 113.593 18.5402C114.667 19.6225 116.001 19.9127 117.452 19.649C119.392 19.2955 120.638 18.0988 121.289 16.2744C121.727 15.0475 121.76 13.7676 121.632 12.4896C121.578 11.9536 121.438 11.4139 121.257 10.9044C120.402 8.5025 118.112 7.58465 115.935 8.04027C115.049 8.22554 114.262 8.59986 113.647 9.28801C113.453 9.50542 113.354 9.73606 113.358 10.0404C113.373 11.3543 113.365 12.6683 113.365 13.9822H113.364Z"
          fill="white"
        />
        <path
          d="M190.691 1.56445H193.279V8.64349C193.465 8.5036 193.57 8.4223 193.678 8.34479C195.89 6.76337 198.302 6.30398 200.882 7.21804C202.681 7.85609 203.637 9.26075 203.913 11.1097C204.013 11.7704 203.993 12.4519 203.996 13.124C204.004 15.4494 203.998 17.7747 203.998 20.0991V20.5632C203.653 20.5632 203.342 20.5632 203.032 20.5632C202.565 20.5632 202.098 20.5434 201.633 20.5698C201.312 20.5887 201.236 20.4649 201.245 20.17C201.263 19.5073 201.252 18.8447 201.251 18.1821C201.247 16.1129 201.28 14.0428 201.218 11.9746C201.198 11.2836 201.028 10.5624 200.767 9.91959C200.059 8.17559 198.511 7.53376 196.773 7.74739C195.586 7.8939 194.541 8.37882 193.695 9.24657C193.416 9.53204 193.275 9.84208 193.277 10.2656C193.295 13.5437 193.288 16.8228 193.288 20.101V20.5367H190.692V1.56445H190.691Z"
          fill="white"
        />
        <path
          d="M40.9251 5.43161C41.4636 5.69534 42.024 5.97041 42.6269 6.26533C42.1991 6.85233 41.6832 7.2966 41.1115 7.67093C40.5398 8.04525 39.9426 8.38176 39.3302 8.75041C39.4192 8.85534 39.4987 8.95175 39.581 9.04533C42.0921 11.8962 41.9161 16.4637 39.1797 19.0641C37.9332 20.2485 36.4055 20.8091 34.7122 20.9849C33.3123 21.1305 31.9361 21.0227 30.6195 20.5302C28.0374 19.5632 26.505 17.6888 26.1302 14.973C25.7383 12.1335 26.63 9.74293 28.9612 7.97908C30.1358 7.09054 31.512 6.69637 32.964 6.59428C34.5068 6.48652 36.0127 6.68124 37.4145 7.38073C37.8186 7.58207 38.2067 7.82784 38.5673 8.09818C38.7907 8.26549 38.9147 8.22957 39.0926 8.05565C39.7675 7.3968 40.2881 6.63398 40.6894 5.78514C40.7339 5.69061 40.7812 5.59797 40.8304 5.50534C40.8399 5.48738 40.8654 5.47698 40.9251 5.43066V5.43161ZM29.1051 14.1081C29.142 14.5023 29.1666 15.0137 29.2433 15.5185C29.424 16.7152 29.7922 17.8447 30.539 18.8221C32.1954 20.9906 35.2744 21.0236 36.8967 18.8694C37.2138 18.4478 37.475 17.9638 37.6681 17.4723C38.3742 15.6697 38.4301 13.8075 38.0524 11.9265C37.8073 10.7062 37.3747 9.55766 36.5531 8.59444C34.8986 6.65477 32.274 6.70865 30.7113 8.71922C29.5064 10.2704 29.1798 12.0947 29.1051 14.1081Z"
          fill="white"
        />
        <path
          d="M138.201 18.8928C138.127 18.9069 138.098 18.9032 138.084 18.9164C136.877 20.0535 135.417 20.5848 133.782 20.6708C131.542 20.789 128.904 19.8371 127.96 17.2773C127.719 16.6251 127.573 15.902 127.562 15.2091C127.518 12.5709 127.552 9.93171 127.545 7.29255C127.545 7.0137 127.609 6.90027 127.911 6.90972C128.587 6.92957 129.264 6.92957 129.94 6.90972C130.239 6.90122 130.308 7.00708 130.306 7.28877C130.295 9.82301 130.284 12.3572 130.311 14.8915C130.317 15.5229 130.393 16.1723 130.558 16.7801C131.205 19.1688 133.293 20.2974 135.679 19.5932C136.487 19.355 137.192 18.9438 137.815 18.3785C138.057 18.1592 138.16 17.922 138.159 17.5893C138.149 14.2062 138.152 10.8231 138.152 7.44001C138.152 7.09027 138.331 6.91539 138.687 6.91539C139.282 6.91539 139.877 6.9239 140.471 6.91161C140.715 6.90594 140.792 6.98912 140.792 7.233C140.787 11.5689 140.787 15.9057 140.792 20.2416C140.792 20.4723 140.741 20.58 140.483 20.5706C140.006 20.5536 139.527 20.5564 139.049 20.5706C138.848 20.5763 138.761 20.495 138.701 20.3144C138.543 19.8352 138.367 19.3616 138.201 18.8947V18.8928Z"
          fill="white"
        />
        <path
          d="M152.934 13.4653C152.892 10.0425 155.268 7.46386 158.69 6.73034C161.242 6.18303 163.631 6.61785 165.731 8.21628C167.266 9.38462 168.058 10.9783 168.244 12.8944C168.683 17.4439 165.418 20.2636 161.952 20.7778C159.097 21.2013 156.613 20.4441 154.633 18.3097C153.424 17.0072 152.918 15.4116 152.934 13.4662V13.4653ZM155.893 13.8103C155.948 14.231 155.997 14.9163 156.133 15.5836C156.421 16.9949 156.973 18.2899 158.031 19.3165C159.473 20.7135 161.466 20.7618 162.979 19.4431C163.567 18.9308 164.005 18.3031 164.349 17.6093C165.142 16.0052 165.423 14.3019 165.246 12.5342C165.114 11.2052 164.747 9.93571 163.977 8.81652C163.198 7.6841 162.137 7.01202 160.742 6.96759C159.25 6.91939 158.125 7.61037 157.298 8.80801C156.313 10.2325 155.953 11.8461 155.895 13.8122L155.893 13.8103Z"
          fill="white"
        />
        <path
          d="M93.469 17.7252C93.5873 17.8056 93.7274 17.901 93.8978 18.0163C93.3706 18.6809 92.7563 19.2083 92.0483 19.6138C89.5789 21.027 87.0167 21.2472 84.4355 19.9929C82.3116 18.9616 81.2647 17.141 80.995 14.8374C80.8322 13.4489 80.9543 12.0905 81.5298 10.8135C82.6287 8.37566 84.5652 7.05324 87.2117 6.78479C89.0914 6.59385 90.833 7.01449 92.3181 8.22158C93.6829 9.33037 94.3673 10.8069 94.4704 12.5509C94.4969 13.0027 94.4534 13.4574 94.4723 13.9101C94.4865 14.2542 94.372 14.3686 94.0123 14.3677C90.8055 14.3544 87.5978 14.3601 84.3911 14.3601C83.8563 14.3601 83.8411 14.3629 83.9046 14.8781C84.2273 17.505 86.0739 19.3539 88.1638 19.7991C89.7417 20.1347 91.1046 19.6072 92.3597 18.7187C92.7535 18.4398 93.0847 18.074 93.47 17.7262L93.469 17.7252ZM87.9017 13.6077C89.1132 13.6077 90.3257 13.601 91.5372 13.6124C91.8221 13.6152 91.9395 13.5216 91.9215 13.2315C91.8865 12.6766 91.9158 12.1132 91.8363 11.565C91.6423 10.2312 91.1321 9.04112 90.0682 8.15069C89.3725 7.56935 88.5689 7.25175 87.6537 7.33304C86.6381 7.42284 85.8241 7.92004 85.1852 8.69516C84.0702 10.0459 83.7843 11.6406 83.844 13.3316C83.8534 13.5973 84.0276 13.6096 84.2302 13.6086C85.454 13.6058 86.6778 13.6067 87.9007 13.6077H87.9017Z"
          fill="white"
        />
        <path
          d="M69.1272 19.8552C67.6762 20.8571 66.0851 21.1001 64.4211 20.9725C63.2881 20.8855 62.1684 20.6974 61.2437 19.9591C59.8239 18.8258 59.3639 17.3181 59.752 15.5987C60.1457 13.8547 61.3346 12.8168 63.0704 12.4519C64.4003 12.1721 65.7529 12.001 67.0846 11.7297C67.5881 11.6267 68.0727 11.4027 68.546 11.189C68.8337 11.0586 69.0183 10.8232 68.9 10.4536C68.7599 10.0141 68.7041 9.53956 68.511 9.12649C68.0283 8.09521 67.1272 7.64999 66.033 7.59044C64.0179 7.47985 62.3994 8.23227 61.2134 9.87608C61.1803 9.92145 61.1405 9.96304 61.0904 10.0216C60.9153 9.91578 60.7487 9.81652 60.5508 9.69742C60.9171 9.1142 61.3696 8.63968 61.8873 8.2266C62.9332 7.39288 64.1485 6.99776 65.4708 6.93254C66.4135 6.88622 67.3562 6.90324 68.2848 7.12443C70.0775 7.55168 71.1385 8.65386 71.4347 10.4735C71.5313 11.0662 71.5796 11.673 71.5833 12.2733C71.5994 14.9011 71.5862 17.5289 71.5966 20.1567C71.5975 20.463 71.5322 20.5972 71.1943 20.5717C70.7883 20.5414 70.3765 20.5405 69.9705 20.5717C69.6534 20.5963 69.4651 20.4894 69.3477 20.204C69.3004 20.0896 69.2152 19.9903 69.1272 19.8542V19.8552ZM68.9644 11.535C67.572 12.3508 66.0671 12.6013 64.6379 13.0748C64.3009 13.1864 63.9715 13.3594 63.68 13.5635C62.4013 14.4625 61.8532 16.2169 62.3227 17.8663C62.6663 19.0744 63.362 19.9837 64.6388 20.2711C65.1367 20.3836 65.679 20.3495 66.1968 20.3146C67.1083 20.2541 67.9488 19.9695 68.67 19.3816C68.8725 19.2162 68.9729 19.0422 68.971 18.7643C68.9587 16.4976 68.9634 14.2309 68.9634 11.9651C68.9634 11.8498 68.9634 11.7354 68.9634 11.5341L68.9644 11.535Z"
          fill="white"
        />
        <path
          d="M96.75 18.888C96.891 18.6819 97.0131 18.5023 97.1447 18.3095C98.4253 19.1895 99.8451 19.6054 101.336 19.8011C102.395 19.94 103.452 19.9278 104.472 19.5487C105.467 19.1791 105.901 18.5486 105.853 17.575C105.832 17.126 105.624 16.78 105.289 16.4974C104.628 15.9407 103.854 15.5975 103.043 15.3253C101.996 14.9737 100.94 14.6504 99.9018 14.2761C99.1267 13.9963 98.4329 13.5662 97.8924 12.9281C96.2597 10.9988 97.0661 8.05247 99.475 7.13368C100.738 6.65255 102.05 6.6327 103.369 6.74802C104.276 6.82742 105.166 7.02403 105.992 7.42199C106.437 7.63561 106.848 7.91636 107.318 8.19237C107.119 8.41262 106.983 8.56291 106.829 8.73211C106.07 8.17252 105.246 7.80198 104.345 7.62522C103.232 7.40686 102.1 7.23766 100.997 7.60631C100.506 7.77079 100.018 8.06287 99.6387 8.41451C98.8739 9.12251 98.8967 10.1349 99.6094 10.8986C100.237 11.5717 101.073 11.8779 101.914 12.1558C103.106 12.5491 104.33 12.8544 105.5 13.2996C106.799 13.794 107.711 14.6976 107.988 16.1259C108.302 17.7537 107.391 19.5118 105.857 20.2085C104.834 20.6726 103.751 20.8503 102.632 20.8607C100.86 20.8768 99.1872 20.5176 97.6586 19.595C97.44 19.4627 97.2374 19.3039 97.033 19.1498C96.9412 19.0808 96.8626 18.9948 96.749 18.8889L96.75 18.888Z"
          fill="white"
        />
        <path
          d="M75.7051 0.499844C76.5437 0.499844 77.3567 0.495118 78.1698 0.508351C78.2323 0.509297 78.3184 0.623673 78.3487 0.702129C78.3799 0.783422 78.3572 0.885509 78.3572 0.978145C78.3572 7.3473 78.3572 13.7164 78.3572 20.0856C78.3572 20.4032 78.201 20.5623 77.8887 20.563C77.1684 20.563 76.449 20.563 75.7051 20.563V0.499844Z"
          fill="white"
        />
        <path
          d="M49.8041 2.0625H52.1903V6.84267H55.4084C55.4084 7.16501 55.4292 7.45236 55.3933 7.73216C55.3847 7.79833 55.1869 7.88435 55.0743 7.88624C54.2811 7.89947 53.4879 7.89285 52.6957 7.89474C52.5462 7.89474 52.3966 7.90987 52.2149 7.91932C52.2064 8.04977 52.1922 8.16036 52.1922 8.2719C52.1922 10.6558 52.1704 13.0398 52.2064 15.4228C52.2187 16.2414 52.3228 17.0675 52.479 17.8719C52.9191 20.1387 54.6389 20.53 56.3104 19.9487C56.866 19.7558 57.3506 19.3626 57.911 19.0356C58.0558 19.1707 58.2564 19.3598 58.5158 19.6037C58.2082 19.8201 57.9432 20.0404 57.6478 20.2086C56.7373 20.7266 55.7302 20.8372 54.7099 20.8079C54.2149 20.7937 53.7075 20.7257 53.2314 20.5905C51.4416 20.0848 50.4761 18.7983 50.0796 17.0638C49.9073 16.3113 49.807 15.5258 49.8022 14.7545C49.7757 10.6388 49.7899 6.52223 49.7899 2.40657C49.7899 2.30354 49.7985 2.20051 49.8051 2.0625H49.8041Z"
          fill="white"
        />
        <path
          d="M187.474 18.1876C187.643 18.3454 187.776 18.4712 187.908 18.594C186.106 20.7729 181.654 21.9705 178.265 19.4268C175.099 17.0514 174.582 12.137 177.17 9.11216C179.884 5.94082 185.012 5.73948 187.556 8.17352C187.437 8.30586 187.317 8.44008 187.178 8.5951C187.018 8.47789 186.881 8.37486 186.741 8.27466C184.348 6.55524 181.669 6.96075 179.853 9.3239C178.922 10.5348 178.37 11.9026 178.272 13.4235C178.124 15.7214 179.352 18.5307 181.715 19.7123C183.019 20.3636 184.307 20.1887 185.542 19.579C186.148 19.2794 186.67 18.8096 187.228 18.4135C187.311 18.354 187.38 18.2755 187.476 18.1876H187.474Z"
          fill="white"
        />
        <path
          d="M39.1496 29.0911C39.3446 28.5514 39.5878 28.0561 39.6957 27.5324C39.851 26.7847 39.532 26.3007 38.8193 25.9926C38.1368 25.6976 37.4109 25.6976 36.6906 25.691C34.347 25.6712 32.0025 25.6957 29.6599 25.6371C29.0617 25.622 28.4323 25.4434 27.8833 25.1967C26.402 24.5302 25.8654 22.8978 26.5696 21.4024C26.8696 20.7653 27.3381 20.2737 27.907 19.8654C28.278 19.5988 28.3935 20.0147 28.65 20.097C28.456 20.3097 28.2695 20.4855 28.1181 20.6878C27.624 21.3504 27.7413 22.0291 28.4323 22.4857C29.1696 22.9715 30.0139 23.102 30.8677 23.1095C33.2112 23.1313 35.5557 23.1076 37.8993 23.1275C38.3829 23.1313 38.8789 23.1937 39.3446 23.3194C41.0975 23.7911 41.8869 25.3139 41.2991 27.0276C40.9925 27.9218 40.4075 28.5911 39.62 29.1034C39.4165 29.2357 39.2594 29.2339 39.1468 29.093L39.1496 29.0911Z"
          fill="white"
        />
        <path
          d="M143.874 6.92008C144.452 6.92008 145 6.90968 145.545 6.92953C145.641 6.93331 145.766 7.04296 145.821 7.13654C146.024 7.48818 146.226 7.84454 146.381 8.21886C146.462 8.41264 146.452 8.64612 146.467 8.86258C146.48 9.0677 146.469 9.27377 146.523 9.51198C147.159 8.51095 147.945 7.70275 149.043 7.24997C150.134 6.80003 151.265 6.6866 152.481 6.88321C152.352 7.49763 152.226 8.09976 152.095 8.72741C150.857 8.31906 149.671 8.45234 148.504 8.8484C147.891 9.05636 147.336 9.36924 146.887 9.84943C146.615 10.1406 146.469 10.4572 146.471 10.8788C146.486 13.9604 146.48 17.0419 146.48 20.1234C146.48 20.5828 146.479 20.5838 146.021 20.5847C145.403 20.5847 144.785 20.5772 144.167 20.5885C143.927 20.5932 143.846 20.5148 143.847 20.2681C143.853 15.9076 143.851 11.5462 143.852 7.18569C143.852 7.11764 143.864 7.04958 143.874 6.92008Z"
          fill="white"
        />
        <path d="M20.7021 6.87744H23.0485V20.5345H20.7021V6.87744Z" fill="white" />
        <path
          d="M44.4834 20.5652V6.86466C45.2387 6.86466 45.9798 6.86182 46.7209 6.87127C46.7692 6.87127 46.8364 6.96674 46.8582 7.03008C46.8828 7.10381 46.8658 7.19077 46.8658 7.27206C46.8658 11.5588 46.861 15.8456 46.8752 20.1323C46.8762 20.4962 46.7721 20.587 46.4247 20.5718C45.7886 20.5444 45.1497 20.5643 44.4843 20.5643L44.4834 20.5652Z"
          fill="white"
        />
        <path
          d="M87.9294 34.4999C86.5995 33.2286 85.3057 31.9931 83.991 30.7359C83.9815 30.8106 83.9645 30.8805 83.9635 30.9514C83.9616 31.9629 83.9474 32.9752 83.9711 33.9857C83.9796 34.344 83.795 34.3506 83.5404 34.3411C83.3085 34.3326 83.0842 34.3875 83.0889 34.0056C83.106 32.2625 83.0965 30.5185 83.0965 28.6743C84.4491 29.9325 85.743 31.1358 87.0747 32.375V28.8671H87.9294V34.5009V34.4999Z"
          fill="white"
        />
        <path
          d="M184.555 28.6865C185.887 29.9267 187.194 31.1461 188.528 32.3881C188.537 32.2917 188.553 32.2085 188.553 32.1244C188.555 31.1253 188.564 30.1261 188.549 29.127C188.544 28.8169 188.721 28.8198 188.928 28.8396C189.121 28.8576 189.395 28.7234 189.393 29.1119C189.384 30.8549 189.388 32.597 189.386 34.3401C189.386 34.3703 189.366 34.4015 189.342 34.4715C188.029 33.2181 186.733 31.9817 185.383 30.6923C185.383 31.0147 185.383 31.2756 185.383 31.5374C185.383 32.3739 185.372 33.2105 185.386 34.0471C185.393 34.427 185.131 34.3278 184.93 34.3354C184.727 34.3429 184.509 34.3873 184.512 34.0433C184.523 32.2889 184.517 30.5345 184.519 28.7801C184.519 28.7602 184.534 28.7404 184.555 28.6855V28.6865Z"
          fill="white"
        />
        <path
          d="M65.874 28.7822C66.67 28.8437 67.4802 28.8295 68.2573 28.9855C69.4499 29.2246 70.1655 30.1926 70.2649 31.3978C70.4153 33.2098 69.0685 34.3385 67.5938 34.3347C67.0922 34.3337 66.5915 34.3347 66.0898 34.3394C65.9119 34.3403 65.8106 34.294 65.8115 34.0842C65.8191 32.3761 65.8182 30.6671 65.821 28.959C65.821 28.9372 65.8352 28.9165 65.8428 28.8947C65.8532 28.8578 65.8636 28.82 65.8731 28.7832L65.874 28.7822ZM66.6823 31.5783C66.6823 31.9384 66.6823 32.2976 66.6823 32.6578C66.6823 33.2464 66.9773 33.5321 67.5673 33.5151C68.5327 33.4877 69.2918 32.8402 69.3827 31.9649C69.5048 30.7937 68.9984 29.9723 67.9781 29.7369C67.6762 29.667 67.3553 29.6831 67.0429 29.6556C66.7789 29.632 66.6662 29.7284 66.6776 30.012C66.6975 30.5338 66.6833 31.0565 66.6833 31.5783H66.6823Z"
          fill="white"
        />
        <path
          d="M136.391 34.2647C135.711 34.4689 135.433 34.3772 135.103 33.8667C134.838 33.4574 134.575 33.0462 134.306 32.6388C134.183 32.4526 133.487 32.3156 133.326 32.4602C133.264 32.5159 133.249 32.6464 133.247 32.7438C133.238 33.1738 133.234 33.6039 133.247 34.0331C133.253 34.259 133.172 34.3365 132.946 34.3431C132.375 34.3601 132.376 34.3687 132.376 33.8185C132.376 32.2966 132.376 30.7748 132.376 29.2529C132.376 29.1291 132.376 29.0043 132.376 28.7954C133.173 28.8427 133.93 28.8342 134.668 28.9485C135.298 29.0459 135.746 29.476 135.875 30.1131C136.014 30.7975 135.935 31.4591 135.386 31.98C135.311 32.0518 135.227 32.1142 135.106 32.2154C135.533 32.895 135.959 33.5746 136.392 34.2628L136.391 34.2647ZM133.242 30.6292H133.243C133.243 30.8731 133.229 31.1189 133.251 31.3608C133.259 31.4516 133.354 31.6085 133.408 31.6076C133.765 31.6038 134.139 31.6227 134.476 31.5263C134.947 31.392 135.163 30.8617 135.031 30.3589C134.878 29.7775 134.367 29.511 133.485 29.6499C132.976 29.7303 133.355 30.1916 133.244 30.4553C133.223 30.5044 133.241 30.5706 133.241 30.6283L133.242 30.6292Z"
          fill="white"
        />
        <path
          d="M147.948 32.2231C148.376 32.9027 148.805 33.5833 149.24 34.2743C148.5 34.4662 148.278 34.383 147.908 33.8083C147.65 33.4075 147.392 33.0067 147.129 32.6106C147.018 32.4443 146.303 32.328 146.172 32.4632C146.119 32.518 146.11 32.6314 146.109 32.7184C146.103 33.1712 146.103 33.6239 146.106 34.0777C146.109 34.434 145.841 34.33 145.655 34.3348C145.474 34.3395 145.239 34.4094 145.24 34.0777C145.247 32.3819 145.246 30.6861 145.248 28.9903C145.248 28.9572 145.264 28.9241 145.286 28.8409C146.083 28.874 146.899 28.7152 147.687 28.9931C148.861 29.4071 149.179 31.0377 148.271 31.9357C148.181 32.0246 148.082 32.1049 147.949 32.224L147.948 32.2231ZM146.105 30.6133C146.105 30.6823 146.105 30.7522 146.105 30.8212C146.105 31.6975 146.164 31.7476 147.043 31.6162C147.573 31.5368 147.915 31.1398 147.909 30.6123C147.904 30.0811 147.559 29.7021 147.01 29.6463C146.792 29.6236 146.565 29.6151 146.35 29.6482C146.259 29.6624 146.13 29.7937 146.118 29.8854C146.085 30.1236 146.106 30.3704 146.105 30.6133Z"
          fill="white"
        />
        <path
          d="M173.698 32.2247C174.137 32.9195 174.562 33.5916 175.027 34.3289C174.667 34.3289 174.38 34.3468 174.098 34.3185C174.009 34.31 173.913 34.1909 173.853 34.1011C173.53 33.618 173.218 33.1274 172.9 32.6425C172.775 32.4525 172.076 32.3164 171.925 32.4629C171.872 32.5159 171.861 32.6302 171.86 32.7172C171.854 33.17 171.854 33.6228 171.856 34.0755C171.857 34.4262 171.594 34.3317 171.405 34.3355C171.221 34.3393 170.991 34.4035 170.993 34.0746C171.001 32.3788 170.999 30.6839 171.002 28.9881C171.002 28.9551 171.019 28.9229 171.055 28.7925C171.794 28.8445 172.574 28.8038 173.306 28.9711C174.845 29.3247 174.774 31.2322 174.021 31.9374C173.928 32.0243 173.832 32.1066 173.699 32.2247H173.698ZM172.402 29.6375C172.316 29.6375 172.189 29.6082 172.087 29.646C171.996 29.6801 171.871 29.7869 171.868 29.8663C171.848 30.3748 171.85 30.8843 171.866 31.3938C171.869 31.4723 171.978 31.6065 172.046 31.6122C172.311 31.633 172.584 31.6472 172.844 31.6018C173.565 31.4761 173.904 30.6764 173.504 30.0733C173.249 29.6876 172.855 29.6215 172.401 29.6385L172.402 29.6375Z"
          fill="white"
        />
        <path
          d="M58.6992 32.216C59.1308 32.9042 59.5586 33.5867 60.0243 34.3287C59.6694 34.3287 59.3816 34.3466 59.0986 34.3183C59.0096 34.3098 58.914 34.1907 58.8535 34.1009C58.5307 33.6178 58.2202 33.1263 57.9003 32.6404C57.7754 32.4495 57.0844 32.3124 56.9216 32.4599C56.8582 32.5176 56.8383 32.6461 56.8365 32.7425C56.827 33.1726 56.8185 33.6027 56.8346 34.0328C56.8469 34.3627 56.6358 34.3438 56.419 34.3381C56.2155 34.3325 55.9893 34.3892 55.9912 34.0479C55.9997 32.3975 55.9912 30.7471 56.0035 29.0967C56.0035 29.0107 56.1256 28.8509 56.1919 28.8509C56.8081 28.849 57.428 28.8386 58.0385 28.902C58.7446 28.9757 59.2699 29.3576 59.4554 30.0646C59.658 30.8388 59.5047 31.5383 58.8601 32.079C58.8165 32.1158 58.773 32.1536 58.6982 32.217L58.6992 32.216ZM56.8355 30.6148C56.8355 30.6148 56.8336 30.6148 56.8317 30.6148C56.8317 30.6724 56.8317 30.731 56.8317 30.7887C56.8317 31.6848 56.9358 31.7708 57.8275 31.6101C58.3234 31.5203 58.6632 31.1318 58.6698 30.6441C58.6774 30.1317 58.348 29.73 57.8237 29.6563C57.5719 29.6203 57.3097 29.6222 57.057 29.6487C56.9756 29.6572 56.8554 29.7943 56.844 29.8841C56.8147 30.1242 56.8336 30.3709 56.8336 30.6148H56.8355Z"
          fill="white"
        />
        <path
          d="M152.69 28.8377V30.9806C153.099 30.5996 153.505 30.2253 153.906 29.8444C154.041 29.7168 154.178 29.5844 154.282 29.4322C154.681 28.8481 155.232 28.7138 155.918 28.8849C155.191 29.6657 154.468 30.4437 153.726 31.2405C154.483 32.2538 155.225 33.2473 155.97 34.2455C155.461 34.4723 154.987 34.3674 154.677 33.9647C154.215 33.3664 153.759 32.7633 153.3 32.1631C153.223 32.0629 153.14 31.9693 153.058 31.871C152.789 32.0166 152.673 32.2018 152.685 32.4977C152.706 33.0081 152.681 33.5205 152.696 34.0309C152.701 34.2549 152.628 34.3353 152.399 34.3438C151.723 34.3674 151.723 34.3759 151.723 33.6991C151.723 32.2463 151.723 30.7934 151.723 29.3415C151.723 29.0062 151.896 28.8386 152.242 28.8386C152.379 28.8386 152.515 28.8386 152.69 28.8386V28.8377Z"
          fill="white"
        />
        <path
          d="M196.488 29.1555C196.334 29.4135 196.21 29.6196 196.13 29.7519C195.569 29.7075 195.061 29.5941 194.57 29.6451C193.55 29.751 192.782 30.7813 192.872 31.8145C192.959 32.8146 193.822 33.5821 194.864 33.585C194.91 33.585 194.957 33.585 195.004 33.585C195.694 33.5746 195.928 33.2475 195.705 32.5849C195.685 32.5216 195.579 32.4601 195.504 32.4469C195.368 32.4242 195.224 32.4232 195.085 32.4393C194.775 32.4771 194.673 32.3486 194.686 32.0451C194.696 31.806 194.744 31.6841 195.02 31.6954C195.495 31.7162 195.972 31.7011 196.473 31.7011C196.488 31.8107 196.511 31.8986 196.511 31.9865C196.514 32.5792 196.505 33.1719 196.517 33.7636C196.52 33.9669 196.457 34.0897 196.261 34.1568C195.374 34.4612 194.481 34.5227 193.6 34.1691C192.575 33.7579 192.056 32.9498 192.011 31.875C191.962 30.6783 192.448 29.7198 193.521 29.1328C194.48 28.6082 195.459 28.6904 196.487 29.1555H196.488Z"
          fill="white"
        />
        <path
          d="M76.5287 28.8672V29.6291C75.9807 29.6291 75.4488 29.6291 74.9168 29.6291C74.6253 29.6291 74.3338 29.6376 74.0422 29.6262C73.8378 29.6187 73.7422 29.6811 73.7498 29.9004C73.7621 30.2255 73.763 30.5516 73.7498 30.8768C73.7394 31.116 73.8274 31.2105 74.0725 31.2058C74.6442 31.1944 75.2159 31.219 75.7867 31.1963C76.1094 31.1831 76.2325 31.2606 76.2372 31.6122C76.2429 31.9932 76.0668 32.0149 75.7706 32.0064C75.1998 31.9894 74.6272 32.0092 74.0564 31.9979C73.8321 31.9932 73.7394 32.064 73.7498 32.2947C73.764 32.6076 73.764 32.9233 73.7498 33.2362C73.7384 33.48 73.8368 33.567 74.0744 33.5642C74.8089 33.5566 75.5444 33.5755 76.2788 33.5566C76.5884 33.5491 76.6451 33.6786 76.6442 33.9498C76.6423 34.2183 76.5865 34.3525 76.2779 34.3487C75.2632 34.3346 74.2486 34.3421 73.2339 34.344C73.0304 34.344 72.879 34.3346 72.8809 34.0538C72.8913 32.3798 72.8866 30.7048 72.8875 29.0307C72.8875 28.9853 72.8989 28.9409 72.9093 28.8672H76.5306H76.5287Z"
          fill="white"
        />
        <path
          d="M138.929 28.8379C139.173 28.8379 139.427 28.8379 139.68 28.8379C140.519 28.8379 141.36 28.8511 142.199 28.8313C142.532 28.8237 142.513 29.0156 142.513 29.233C142.513 29.4495 142.534 29.6432 142.199 29.6347C141.397 29.6139 140.595 29.6281 139.746 29.6281C139.746 30.1121 139.733 30.562 139.759 31.0101C139.763 31.0791 139.946 31.1897 140.049 31.1925C140.619 31.2105 141.191 31.202 141.763 31.202C141.889 31.202 142.016 31.202 142.171 31.202V31.9847H139.775V33.5623C140.017 33.5623 140.258 33.5623 140.498 33.5623C141.105 33.5623 141.712 33.5755 142.317 33.5576C142.618 33.5481 142.627 33.7116 142.619 33.93C142.613 34.1408 142.657 34.3506 142.32 34.3469C141.271 34.3346 140.221 34.3421 139.171 34.3421C139.007 34.3421 138.874 34.3251 138.875 34.103C138.881 32.3939 138.88 30.684 138.883 28.9749C138.883 28.9428 138.903 28.9116 138.928 28.8388L138.929 28.8379Z"
          fill="white"
        />
        <path
          d="M90.9666 28.8381C91.5733 28.8381 92.1658 28.8381 92.7574 28.8381C93.2354 28.8381 93.7152 28.8608 94.1913 28.8305C94.5349 28.8088 94.5652 28.9723 94.5652 29.2436C94.5652 29.5064 94.5254 29.6454 94.2055 29.6359C93.4256 29.6123 92.6447 29.6283 91.8071 29.6283C91.8071 30.117 91.7976 30.5887 91.8203 31.0576C91.8232 31.1105 92.0125 31.1918 92.1175 31.1946C92.6883 31.2079 93.26 31.2013 93.8317 31.2013C93.9575 31.2013 94.0825 31.2013 94.2311 31.2013V31.9905H91.8052C91.8052 32.4859 91.7929 32.9462 91.8203 33.4046C91.8241 33.4633 92.0295 33.5521 92.1431 33.554C92.8662 33.5682 93.5893 33.5739 94.3115 33.5559C94.6097 33.5483 94.6835 33.6533 94.6835 33.9359C94.6835 34.2232 94.6286 34.3546 94.3011 34.349C93.2988 34.331 92.2955 34.3414 91.2922 34.3423C91.0953 34.3423 90.9278 34.3471 90.9287 34.0607C90.9382 32.3743 90.9344 30.6889 90.9363 29.0026C90.9363 28.9686 90.9476 28.9345 90.9647 28.8372L90.9666 28.8381Z"
          fill="white"
        />
        <path
          d="M112.596 28.854H116.139V29.6225H113.426C113.426 30.1121 113.416 30.5715 113.439 31.03C113.442 31.0914 113.615 31.1916 113.711 31.1945C114.293 31.2105 114.877 31.203 115.459 31.203C115.584 31.203 115.709 31.203 115.857 31.203V31.9904H113.427C113.427 32.4829 113.415 32.9422 113.443 33.3988C113.446 33.4593 113.648 33.5529 113.761 33.5548C114.483 33.569 115.205 33.5737 115.927 33.5576C116.208 33.551 116.321 33.6247 116.31 33.9253C116.299 34.1919 116.289 34.3573 115.942 34.3507C114.93 34.3308 113.915 34.3431 112.901 34.3431C112.718 34.3431 112.554 34.3469 112.556 34.0794C112.563 32.3836 112.561 30.6878 112.564 28.992C112.564 28.9589 112.579 28.9258 112.597 28.854H112.596Z"
          fill="white"
        />
        <path
          d="M129.654 28.8387V29.628C129.213 29.628 128.786 29.628 128.36 29.628C127.952 29.628 127.543 29.6148 127.137 29.6394C127.049 29.645 126.904 29.7849 126.897 29.8719C126.867 30.2065 126.893 30.5449 126.881 30.8814C126.872 31.1253 126.974 31.2076 127.213 31.2038C127.83 31.1934 128.449 31.2199 129.066 31.1934C129.429 31.1783 129.381 31.3938 129.385 31.6159C129.389 31.8371 129.407 32.0214 129.072 32.0063C128.456 31.9798 127.836 32.011 127.218 31.9931C126.952 31.9855 126.866 32.0819 126.88 32.3343C126.895 32.6245 126.897 32.9156 126.88 33.2058C126.863 33.4894 126.993 33.566 127.254 33.5631C127.989 33.5537 128.723 33.5726 129.457 33.5546C129.752 33.5471 129.809 33.6728 129.802 33.929C129.797 34.1738 129.793 34.3524 129.459 34.3468C128.434 34.3307 127.408 34.3354 126.382 34.3439C126.122 34.3458 126.036 34.2447 126.038 33.9932C126.045 32.3778 126.041 30.7623 126.043 29.1469C126.043 28.9975 126.025 28.834 126.261 28.835C127.378 28.8387 128.496 28.8369 129.655 28.8369L129.654 28.8387Z"
          fill="white"
        />
        <path
          d="M118.867 28.8861C118.934 28.8606 118.955 28.8474 118.977 28.8455C119.474 28.8058 119.723 29.0307 119.723 29.5204C119.723 30.4609 119.719 31.4024 119.726 32.3429C119.73 33.015 120.214 33.5425 120.847 33.5812C121.582 33.6266 122.172 33.2107 122.23 32.5074C122.293 31.7455 122.262 30.9761 122.27 30.2104C122.274 29.8389 122.277 29.4665 122.27 29.095C122.266 28.9003 122.345 28.8379 122.54 28.8322C123.108 28.8152 123.108 28.8077 123.108 29.3871C123.108 30.3976 123.119 31.409 123.103 32.4195C123.087 33.4734 122.347 34.2826 121.343 34.3771C120.075 34.4972 119.164 33.93 118.9 32.8562C118.889 32.8108 118.869 32.7664 118.869 32.721C118.867 31.4449 118.868 30.1688 118.868 28.8861H118.867Z"
          fill="white"
        />
        <path
          d="M181.605 28.8379C181.605 29.4457 181.605 30.0015 181.605 30.5583C181.605 31.1633 181.609 31.7673 181.603 32.3723C181.596 33.3827 181.041 34.0822 180.054 34.3356C178.874 34.638 177.717 34.0255 177.419 32.93C177.374 32.7645 177.362 32.5868 177.361 32.4148C177.355 31.3334 177.37 30.252 177.35 29.1707C177.343 28.8332 177.512 28.8275 177.759 28.8351C177.999 28.8417 178.212 28.8039 178.204 29.1763C178.182 30.1878 178.188 31.2001 178.2 32.2116C178.21 33.1029 178.757 33.6313 179.594 33.585C180.209 33.551 180.725 33.0472 180.734 32.4328C180.749 31.3863 180.74 30.3399 180.741 29.2926C180.741 28.9895 180.894 28.8382 181.199 28.8389C181.314 28.8389 181.429 28.8389 181.605 28.8389V28.8379Z"
          fill="white"
        />
        <path
          d="M52.2387 28.9088C52.2803 28.8729 52.2879 28.8606 52.2974 28.8587C53.0536 28.7519 53.0612 28.7595 53.0612 29.51C53.0612 30.4278 53.0508 31.3466 53.064 32.2645C53.0754 33.0906 52.7848 33.7523 52.0304 34.1342C51.2382 34.535 50.4346 34.5151 49.6689 34.0397C49.1616 33.7249 48.8417 33.2466 48.8227 32.6662C48.782 31.4128 48.8104 30.1575 48.8104 28.871H49.6519C49.6575 28.9797 49.6689 29.1016 49.6689 29.2236C49.6708 30.235 49.6689 31.2455 49.6698 32.2569C49.6708 33.0349 50.1649 33.5737 50.8842 33.585C51.6556 33.5973 52.2055 33.0764 52.2169 32.2862C52.232 31.2871 52.2207 30.287 52.2226 29.2879C52.2226 29.1517 52.2339 29.0156 52.2387 28.9097V28.9088Z"
          fill="white"
        />
        <path
          d="M103.856 29.1188C103.736 29.391 103.648 29.5914 103.557 29.7965C103.192 29.7303 102.843 29.6245 102.492 29.615C102.201 29.6075 101.882 29.6557 101.622 29.7804C101.184 29.9912 101.175 30.5064 101.595 30.7522C101.913 30.9384 102.271 31.0546 102.608 31.2087C102.862 31.324 103.124 31.4271 103.359 31.5745C103.865 31.8931 104.103 32.3686 104.044 32.9641C103.987 33.5567 103.669 33.9717 103.111 34.2024C102.282 34.5455 100.944 34.4425 100.18 33.9443C100.29 33.7174 100.404 33.484 100.513 33.2599C100.897 33.3611 101.249 33.518 101.607 33.5322C101.988 33.5473 102.402 33.4944 102.754 33.3516C103.323 33.1219 103.352 32.5519 102.838 32.2173C102.578 32.0481 102.278 31.9385 101.994 31.808C101.709 31.6776 101.412 31.5717 101.137 31.4233C100.095 30.8618 100.367 29.6982 100.981 29.2161C101.545 28.7728 102.193 28.7804 102.846 28.8626C103.175 28.9042 103.494 29.0242 103.856 29.1188Z"
          fill="white"
        />
        <path
          d="M168.61 34.2682C167.921 34.4459 167.812 34.3948 167.574 33.8258C167.172 32.8654 167.172 32.855 166.114 32.8682C164.995 32.8824 165.304 32.6839 164.858 33.7095C164.836 33.7625 164.813 33.8163 164.79 33.8693C164.573 34.3825 164.408 34.4525 163.776 34.2597C164.572 32.406 165.366 30.5571 166.189 28.6392C167.01 30.5457 167.803 32.3909 168.61 34.2672V34.2682ZM166.19 30.4919C165.945 31.0893 165.738 31.595 165.51 32.148H166.865C166.64 31.5959 166.434 31.0902 166.19 30.4919Z"
          fill="white"
        />
        <path
          d="M45.9416 31.2955V32.0725C45.3424 32.0725 44.7622 32.0858 44.1839 32.0668C43.8962 32.0574 43.7911 32.1462 43.8015 32.4393C43.8205 32.9734 43.794 33.5084 43.811 34.0424C43.8205 34.3449 43.6406 34.3534 43.4286 34.3421C43.2194 34.3317 42.9629 34.4101 42.9639 34.0472C42.9686 32.3967 42.9677 30.7463 42.9639 29.0968C42.9639 28.9031 43.0443 28.8341 43.2384 28.836C44.1593 28.8426 45.0803 28.8473 46.0012 28.8341C46.3259 28.8293 46.2823 29.0392 46.2795 29.2311C46.2766 29.4173 46.3401 29.6423 46.0088 29.6347C45.3917 29.6215 44.7726 29.6413 44.1555 29.6271C43.9028 29.6215 43.7911 29.6942 43.8044 29.9599C43.8205 30.2841 43.8186 30.6111 43.8044 30.9354C43.794 31.1896 43.8886 31.288 44.1489 31.2794C44.6259 31.2643 45.1049 31.2738 45.5829 31.2766C45.6964 31.2766 45.81 31.288 45.9425 31.2955H45.9416Z"
          fill="white"
        />
        <path
          d="M45.6718 1.19092C46.5625 1.19092 47.2279 1.86016 47.2279 2.75816C47.2279 3.64481 46.5511 4.31216 45.6557 4.30838C44.7726 4.3046 44.1063 3.64859 44.0997 2.77706C44.093 1.85638 44.7518 1.19186 45.6718 1.19186V1.19092Z"
          fill="white"
        />
        <path
          d="M23.4389 2.75249C23.4408 3.63158 22.7801 4.30272 21.9093 4.30839C20.9855 4.31406 20.3097 3.65333 20.3135 2.74682C20.3163 1.85072 20.9779 1.19376 21.8809 1.19093C22.7782 1.18809 23.4379 1.84883 23.4398 2.75249H23.4389Z"
          fill="white"
        />
        <path
          d="M171.693 17.8765C172.554 17.8765 173.203 18.5344 173.2 19.4021C173.198 20.233 172.519 20.9089 171.693 20.906C170.842 20.9032 170.161 20.2264 170.164 19.3832C170.167 18.5249 170.825 17.8755 171.694 17.8765H171.693Z"
          fill="white"
        />
        <path
          d="M158.381 28.853H159.185C159.192 28.9891 159.203 29.1129 159.204 29.2358C159.205 30.5374 159.214 31.8391 159.197 33.1407C159.193 33.4545 159.294 33.5358 159.596 33.5264C160.272 33.5056 160.948 33.533 161.624 33.5141C161.918 33.5056 161.951 33.6483 161.934 33.8761C161.918 34.0945 162.013 34.3525 161.619 34.3459C160.628 34.3298 159.637 34.3402 158.646 34.3412C158.467 34.3412 158.334 34.3213 158.335 34.0812C158.343 32.3731 158.343 30.6641 158.345 28.956C158.345 28.9343 158.36 28.9135 158.382 28.8521L158.381 28.853Z"
          fill="white"
        />
        <path
          d="M107.659 31.9941C107.659 31.3315 107.649 30.6679 107.664 30.0062C107.671 29.7283 107.568 29.629 107.298 29.6461C107.066 29.6602 106.831 29.6366 106.598 29.6508C106.252 29.6706 106.31 29.4296 106.309 29.2273C106.308 29.0345 106.261 28.8294 106.587 28.8341C107.589 28.8483 108.593 28.8492 109.596 28.8341C109.905 28.8294 109.901 29.0033 109.896 29.2131C109.891 29.4324 109.941 29.6772 109.581 29.6527C109.349 29.6366 109.114 29.6621 108.881 29.6479C108.613 29.6309 108.518 29.7226 108.521 30.0043C108.535 31.3182 108.514 32.6321 108.536 33.946C108.542 34.2882 108.417 34.3572 108.114 34.3525C107.822 34.3478 107.629 34.3374 107.649 33.9498C107.682 33.3004 107.658 32.6482 107.658 31.9969L107.659 31.9941Z"
          fill="white"
        />
        <path
          d="M79.2508 28.855H80.097C80.1045 28.9816 80.1169 29.0941 80.1178 29.2057C80.1187 30.7871 80.1055 32.3685 80.1282 33.9499C80.1339 34.3384 79.9512 34.3441 79.6625 34.3498C79.3549 34.3564 79.2385 34.2779 79.2423 33.9414C79.2612 32.3723 79.2508 30.8022 79.2508 29.2321V28.855Z"
          fill="white"
        />
        <path
          d="M136.171 2.25241C136.759 2.25241 137.229 2.72409 137.229 3.31204C137.229 3.9 136.756 4.37357 136.17 4.37168C135.594 4.37073 135.101 3.88109 135.102 3.31015C135.102 2.73733 135.593 2.25146 136.171 2.25146V2.25241Z"
          fill="white"
        />
        <path
          d="M132.518 4.37266C131.939 4.36983 131.459 3.88491 131.461 3.30546C131.463 2.72602 131.946 2.25056 132.53 2.25245C133.092 2.25528 133.562 2.72791 133.572 3.30074C133.582 3.87924 133.095 4.37455 132.518 4.37266Z"
          fill="white"
        />
        <path
          d="M51.7444 28.3937C51.5059 28.3776 51.3431 28.2604 51.327 27.9948C51.3118 27.7528 51.5153 27.5467 51.7576 27.5581C52.0198 27.5704 52.175 27.732 52.176 27.9759C52.1779 28.2216 52.0236 28.3776 51.7434 28.3927L51.7444 28.3937Z"
          fill="white"
        />
        <path
          d="M165.445 28.3938C165.165 28.374 165.024 28.2303 165.032 27.9666C165.039 27.7038 165.193 27.5544 165.455 27.5639C165.712 27.5734 165.859 27.7236 165.854 27.9902C165.849 28.2624 165.686 28.3768 165.445 28.3938Z"
          fill="white"
        />
        <path
          d="M50.68 27.9845C50.6592 28.2492 50.5219 28.3976 50.255 28.3882C49.9938 28.3787 49.848 28.236 49.8537 27.9675C49.8594 27.6981 50.0203 27.5611 50.2749 27.562C50.5399 27.563 50.6677 27.7284 50.68 27.9845Z"
          fill="white"
        />
        <path
          d="M166.932 27.5552C167.191 27.5873 167.337 27.7206 167.333 27.9872C167.327 28.2556 167.175 28.3927 166.914 28.387C166.651 28.3813 166.51 28.2339 166.515 27.9673C166.519 27.697 166.681 27.5788 166.932 27.5552Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2178_5467">
          <rect width="204" height="34" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
