import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetArchiveById, useUpdateArchive } from '../api/archive';
import { useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import useToastNotification from './useToastNotification';
import { useTranslation } from 'react-i18next';
import useCreateArchiveLocals from './useCreateArchiveLocals';

export const useEditArchive = (id, handleDrawerClose, archiveTypes) => {
  const { t } = useTranslation();
  const { data: archive } = useGetArchiveById(id);
  const archiveTypeArr = archiveTypes?.map((arType) => arType.id);
  const { mutate, isSuccess, isLoading, isError } = useUpdateArchive();

  const { mutate: addLocals } = useCreateArchiveLocals();

  const defaultValues = useMemo(() => {
    const de = archive?.data?.locals?.find((locale) => locale.language === 'de')?.name;
    const eng = archive?.data?.locals?.find((locale) => locale.language === 'eng')?.name;
    return {
      name: de,
      locals: {
        eng,
        de,
      },
      type: archive?.data?.type?.id || '',
      validFrom: archive?.data?.validFrom ? format(new Date(archive?.data?.validFrom), 'yyyy-MM-dd') : '',
      validTo: archive?.data?.validTo ? format(new Date(archive?.data?.validTo), 'yyyy-MM-dd') : '',
      is_active: archive?.data?.is_active,
    };
  }, [archive?.data]);

  const editArchiveSchema = yup
    .object()
    .shape({
      validFrom: yup.date().typeError(t('date_required')).required(t('validFrom.required')),
      validTo: yup.date().typeError(t('date_required')).required(t('validTo.required')),
      locals: yup.object().shape({
        eng: yup.string().required(t('required_field', { field: t('english_name') })),
        de: yup.string().required(t('required_field', { field: t('deutsch_name') })),
      }),
      type: archiveTypeArr && yup.string().oneOf(archiveTypeArr).required(t('type.required')),
      is_active: yup.boolean().required(),
    })
    .required();

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: defaultValues,
    resolver: yupResolver(editArchiveSchema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (archive) {
      reset(defaultValues);
    }
  }, [archive, defaultValues, reset]);

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessfullyEditedArchive',
    variant: 'success',
    cb: () => {
      if (archive) {
        const formVals = getValues();
        const foundEng = archive?.data?.locals?.find((local) => local.language === 'eng');
        const foundDe = archive?.data?.locals?.find((local) => local.language === 'de');

        if (foundDe && foundEng) {
          addLocals([
            { id: foundEng.id, language: 'eng', name: formVals?.locals?.eng, archive: archive?.data?.id },
            { id: foundDe.id, language: 'de', name: formVals?.locals?.de, archive: archive?.data?.id },
          ]);
        }
        if (foundDe && !foundEng) {
          addLocals([
            { language: 'eng', name: formVals?.locals?.eng, archive: archive?.data?.id },
            { id: foundDe.id, language: 'de', name: formVals?.locals?.de, archive: archive?.data?.id },
          ]);
        }
        if (!foundDe && foundEng) {
          addLocals([
            { id: foundEng.id, language: 'eng', name: formVals?.locals?.eng, archive: archive?.data?.id },
            { language: 'de', name: formVals?.locals?.de, archive: archive?.data?.id },
          ]);
        }
        if (!foundDe && !foundEng) {
          addLocals([
            { language: 'eng', name: formVals?.locals?.eng, archive: archive?.data?.id },
            { language: 'de', name: formVals?.locals?.de, archive: archive?.data?.id },
          ]);
        }
        reset(defaultValues);
        handleDrawerClose();
      }
    },
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorWhileEditingArchive',
    variant: 'error',
  });

  const onSubmit = async (data) => {
    const { de } = data.locals;
    delete data.locals;
    mutate({
      id,
      data: {
        ...data,
        name: de,
      },
    });
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    isLoading,
    errors,
  };
};
