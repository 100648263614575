/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox } from '@mui/material';
import React from 'react';

const IndeterminateCheckbox = ({ indeterminate, ...rest }) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <Checkbox
      sx={{
        '&.Mui-checked': {
          color: '#0F5D66',
        },
      }}
      ref={ref}
      {...rest}
    />
  );

  // return <input type="checkbox" ref={ref} className={className + ' cursor-pointer'} {...rest} />;
};

export default IndeterminateCheckbox;
