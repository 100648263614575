import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllArticles } from '../../../../api/articles';
import ArticleCard from '../../../dashboardComponents/articleComponents/ArticleCard';
import { useNavigate } from 'react-router-dom';
import SubmitButton from '../../../commonComponents/SubmitButton';
import LanguageSelect from '../../../selectTranslation/translateSelect';

const Articles = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();

  const { data: allArticles } = useGetAllArticles({ lang: language });
  const articles = allArticles?.results;

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center" px={4}>
          <Typography variant="h6" fontWeight={700} px={5} py={2.45} sx={{ marginLeft: -4 }}>
            {t('articles')}
          </Typography>
          <LanguageSelect />
        </Box>
        <Divider />
        <Box p={5}>
          <Box display="flex" alignItems="center" mb={3}>
            <SubmitButton
              onClick={() => navigate('/admin/article-editor')}
              buttonText={t('AddNew')}
              backgroundColor="#31B099"
              color="inherit"
            />
          </Box>
          <Grid container rowSpacing={3}>
            {articles?.map((article, idx) => (
              <Grid item xs={12} lg={4} xl={3} key={idx}>
                <ArticleCard
                  heading={article.title}
                  img={article.article.bannerUrl}
                  archive={article.article.archives[0]?.name}
                  shortDesc={article.shortDescription}
                  id={article.id}
                  articleId={article.article.id}
                  lang={language}
                  isAdmin
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Articles;
