import React from 'react';
import { Controller } from 'react-hook-form';
import CustomTextField from '../../../../../../../../../commonComponents/CustomTextField';
import { Box } from '@mui/material';

const SearchInput = ({ control, debouncedHandleSearchChange }) => {
  return (
    <Controller
      name="search"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Box display="flex" flexDirection="column">
          <CustomTextField
            id="search"
            value={value ? value : ''}
            label="Search"
            onChange={(event) => {
              onChange(event);
              debouncedHandleSearchChange(event.target.value);
            }}
          />
        </Box>
      )}
    />
  );
};

export default SearchInput;
