import { Box, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CustomTextField from './CustomTextField';

const CustomFormInput = ({ name, control, label, type, inputProps, placeholder, labelIcon, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box display="flex" flexDirection="column" width="100%">
          <Typography variant="body2" fontSize="12px" color="#ACB5BB">
            {label}
            {labelIcon}
          </Typography>
          <CustomTextField
            id={name}
            placeholder={placeholder}
            value={value !== null && value !== undefined ? value : ''}
            type={type}
            onChange={onChange}
            sx={{ marginBottom: 1 }}
            inputProps={inputProps}
            disabled={disabled}
          />
          {error && (
            <Typography variant="caption" color="error">
              {error.message}
            </Typography>
          )}
        </Box>
      )}
    />
  );
};

export default CustomFormInput;
