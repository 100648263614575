import { useToast } from './useToast';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useForgotPassword } from '../api/auth';

export const useForgotPass = () => {
  const { toast } = useToast();
  const { mutateAsync } = useForgotPassword();

  const forgotPassSchema = yup
    .object()
    .shape({
      email: yup.string().email().required(),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(forgotPassSchema),
    reValidateMode: 'onChange',
  });

  const navigate = useNavigate();

  const onSubmit = async (vals) => {
    try {
      await mutateAsync(vals);
      navigate('/verify-password', { replace: true, state: { from: 'forgotPassword', email: vals.email } });
    } catch (err) {
      if (err?.response?.status === 404) {
        toast({
          message: 'User Not Found',
          variant: 'error',
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      }
    }
  };

  return {
    handleSubmit,
    onSubmit,
    control,
    errors,
  };
};
