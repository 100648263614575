import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArticleCard from '../components/dashboardComponents/articleComponents/ArticleCard';
import { useGetAllArticles } from '../api/articles';
import CustomModal from '../components/modals/CustomModal';
import TerminForm from '../components/commonComponents/TerminForm/TerminForm';
import CloseIcon from '@mui/icons-material/Close';
import { useGetArchiveByType } from '../api/archive';
import ControlledMultipleCheckbox from '../components/commonComponents/CustomCheckbox';
import CropSquareRoundedIcon from '@mui/icons-material/CropSquareRounded';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import TagSearch from '../components/dashboardComponents/articleComponents/TagSearch';
import SearchTags from '../components/dashboardComponents/articleComponents/SearchTags';

const ArticlesView = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [selectedArchive, setSelectedArchive] = useState(null);
  const [selectAll, setSelectAll] = useState(true);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [tags, setTags] = useState([]);
  const { data: archives } = useGetArchiveByType('articles');

  const allArchives = archives?.data.map((archive) => archive.id) || [];
  const isAllSelected = selectedValues.length === allArchives.length;

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedValues([]);
    } else {
      setSelectedValues(allArchives);
    }
    setSelectAll(!selectAll);
  };

  const handleIndividualChange = (value) => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((id) => id !== value)
      : [...selectedValues, value];

    setSelectedValues(newSelectedValues);
    setSelectAll(newSelectedValues.length === allArchives.length);
  };

  const handleOpen = () => {
    setOpenAddModal(!openAddModal);
  };

  const handleArticleClick = (archive) => {
    setSelectedArchive(archive);
    handleOpen();
  };

  const { data: allArticles } = useGetAllArticles({
    lang: language,
    archives: selectedValues,
    search: tags,
  });

  const articles = allArticles?.results;
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" px={1} py={2.45}>
            <Typography variant="h6" fontWeight={700} px={3}>
              {t('allArticles')}
            </Typography>
          </Box>
          <Divider />
          <Box p={5} display="flex" flexDirection="column">
            {tags.length > 0 && <SearchTags tags={tags} setTags={setTags} />}
            <TagSearch setTags={setTags} />
            <FormGroup sx={{ paddingY: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#0F5D66',
                      '&.Mui-checked': {
                        color: '#0F5D66',
                      },
                      '^.MuiFormControlLabel-label': {
                        color: '#1A1C1E',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '21px',
                        letterSpacing: '-0.02em',
                        textAlign: 'left',
                      },
                    }}
                    icon={<CropSquareRoundedIcon />}
                    checkedIcon={<SquareRoundedIcon />}
                    checked={selectAll || isAllSelected}
                    onChange={handleSelectAllChange}
                  />
                }
                label="Select All"
              />
              <Divider />
              {archives?.data.map((archive) => (
                <React.Fragment key={archive.id}>
                  <ControlledMultipleCheckbox
                    label={`${archive.name} (${archive.articles.length})`}
                    options={[
                      {
                        value: archive.id,
                        checkedValues: selectedValues,
                        onChange: () => handleIndividualChange(archive.id),
                      },
                    ]}
                  />

                  <Divider />
                </React.Fragment>
              ))}
            </FormGroup>

            <Grid container rowSpacing={3}>
              {articles?.map((article, idx) => (
                <Grid item xs={12} lg={4} xl={3} key={idx}>
                  <ArticleCard
                    heading={article.title}
                    img={article.article.bannerUrl}
                    archive={article.article.archives[0]?.name}
                    shortDesc={article.shortDescription}
                    id={article.id}
                    lang={language}
                    onOpenModal={() => handleArticleClick(article.article.archives[0]?.name)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      {/* Add Drawer */}
      <CustomModal open={openAddModal} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddArticles')}
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <TerminForm interest={selectedArchive} handleModalClose={handleOpen} />
      </CustomModal>
    </>
  );
};

export default ArticlesView;
