import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import MenuDropdown from '../../components/adminDashboard/MenuDropdown';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import isISODateValid from '../../utils/isISODateValid';

const useArchiveColumns = (handleOpenEdit) => {
  const { t } = useTranslation();
  const menuItems = [
    {
      label: t('Edit'),
      icon: 'https://taxofficestorage.blob.core.windows.net/media/eye.svg',
      onClick: (id) => handleOpenEdit(id),
    },
  ];
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          #
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.type.name, {
      id: 'type',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('ArchiveType')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row?.locals?.find((locale) => locale.language === 'eng')?.name || '', {
      id: 'locals.eng',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('english_label')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row?.locals?.find((locale) => locale.language === 'de')?.name || '', {
      id: 'locals.de',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('deutsch_label')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.validFrom, {
      id: 'validFrom',
      cell: (info) => (
        <Typography>{isISODateValid(info.getValue()) && format(new Date(info.getValue()), 'dd.MM.yyyy')}</Typography>
      ),
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('ValidFrom')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.validTo, {
      id: 'validTo',
      cell: (info) => (
        <Typography>{isISODateValid(info.getValue()) && format(new Date(info.getValue()), 'dd.MM.yyyy')}</Typography>
      ),
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('ValidTo')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.is_active, {
      id: 'is_active',
      cell: (info) => <Typography>{info.row.original.is_active ? 'Active' : 'Inactive'}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('IsActive')}
        </Typography>
      ),
    }),
    columnHelper.accessor('action', {
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Action')}
        </Typography>
      ),
      cell: (info) => {
        return <MenuDropdown id={info.row.original.id} menuItems={menuItems} />;
      },
      footer: (info) => info.column.id,
    }),
  ];
  return columns;
};

export default useArchiveColumns;
