import { SvgIcon } from '@mui/material';
import React from 'react';

const RoundedBinIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.5 5.97998C18.17 5.64998 14.82 5.47998 11.48 5.47998C9.5 5.47998 7.52 5.57998 5.54 5.77998L3.5 5.97998"
          stroke="inherit"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 4.97L9.22 3.66C9.38 2.71 9.5 2 11.19 2H13.81C15.5 2 15.63 2.75 15.78 3.67L16 4.97"
          stroke="inherit"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.3504 9.14001L18.7004 19.21C18.5904 20.78 18.5004 22 15.7104 22H9.29039C6.50039 22 6.41039 20.78 6.30039 19.21L5.65039 9.14001"
          stroke="inherit"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8301 16.5H14.1601"
          stroke="inherit"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M10 12.5H15" stroke="inherit" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  );
};

const RoundedBinIconFilled = () => {
  return (
    <svg width="50" height="50" viewBox="22 0 90 95" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        style={{
          transform: 'scale(1.5)',
        }}
        d="M64.2735 15.1138C60.8523 14.7738 57.431 14.5188 53.9885 14.3275V14.3062L53.521 11.5437C53.2023 9.58875 52.7348 6.65625 47.7623 6.65625H42.1948C37.2435 6.65625 36.776 9.46125 36.436 11.5225L35.9898 14.2425C34.0135 14.37 32.0373 14.4975 30.061 14.6888L25.726 15.1138C24.8335 15.1988 24.196 15.985 24.281 16.8563C24.366 17.7275 25.131 18.365 26.0235 18.28L30.3585 17.855C41.4935 16.75 52.7135 17.175 63.976 18.3013C64.0398 18.3013 64.0823 18.3013 64.146 18.3013C64.9535 18.3013 65.6548 17.685 65.7398 16.8563C65.8035 15.985 65.166 15.1988 64.2735 15.1138Z"
        fill="white"
      />
      <path
        style={{
          transform: 'scale(1.5)',
        }}
        d="M60.3635 21.2975C59.8535 20.7663 59.1522 20.4688 58.4297 20.4688H31.5697C30.8472 20.4688 30.1247 20.7663 29.636 21.2975C29.1472 21.8288 28.871 22.5512 28.9135 23.295L30.231 45.0975C30.4647 48.3275 30.7622 52.365 38.1785 52.365H51.821C59.2372 52.365 59.5347 48.3488 59.7685 45.0975L61.086 23.3163C61.1285 22.5513 60.8522 21.8288 60.3635 21.2975ZM48.5272 41.7188H41.451C40.5797 41.7188 39.8572 40.9962 39.8572 40.125C39.8572 39.2538 40.5797 38.5312 41.451 38.5312H48.5272C49.3985 38.5312 50.121 39.2538 50.121 40.125C50.121 40.9962 49.3985 41.7188 48.5272 41.7188ZM50.3122 33.2188H39.6872C38.816 33.2188 38.0935 32.4962 38.0935 31.625C38.0935 30.7538 38.816 30.0312 39.6872 30.0312H50.3122C51.1835 30.0312 51.906 30.7538 51.906 31.625C51.906 32.4962 51.1835 33.2188 50.3122 33.2188Z"
        fill="white"
      />
    </svg>
  );
};

export { RoundedBinIcon, RoundedBinIconFilled };
