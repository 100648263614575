import React from 'react';
import Box from '@mui/material/Box';

const AboveTable = ({ children }) => {
  return (
    <Box m={2} display="flex" justifyContent="space-between">
      {children}
    </Box>
  );
};

export default AboveTable;
