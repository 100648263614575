import { Box, Container } from '@mui/system';
import React, { useRef } from 'react';
import NavBarHome from '../components/navBar/navBarHome';
import Hero from '../components/homeComponent/Hero';
import ContentComponent from '../components/homeComponent/contentComponent';
import PaymentMethod from '../components/homeComponent/PaymentMethod';
import Footer from '../components/homeComponent/Footer';
import ScrollToTop from '../components/homeComponent/ScrollToTop';
import SubPlan from '../components/subscriptionPlan/subscriptionPlan';
import Map from '../components/homeComponent/Map/Map';

const LandingPage = () => {
  const heroRef = useRef(null);
  return (
    <Box>
      <Box flexGrow={1}>
        <NavBarHome />
      </Box>
      <Container maxWidth="100%" fixed disableGutters sx={{ paddingTop: 5 }}>
        <Hero heroRef={heroRef} />
        <ContentComponent />
        <SubPlan />
        <PaymentMethod />
      </Container>
      <ScrollToTop heroRef={heroRef} />
      <Map />
      <Footer />
    </Box>
  );
};

export default LandingPage;
