import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Card, CardContent, CardMedia } from '@mui/material';
import image from '../../assets/banners/taxyear-bg.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const InfoCard = ({ status, statusColor }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        m: 1,
        mx: { xs: 0, sm: 1 },
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        border: '0.5px solid #DCE4E8',
        borderRadius: '24px',
        '&:hover': {
          boxShadow: '0px 2px 4px -5px rgba(0, 0, 0, 0.01)',
        },
      }}
    >
      {status && (
        <Box
          height="60px"
          width="100%"
          sx={{ backgroundColor: statusColor, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {status}
        </Box>
      )}
      <CardMedia
        component="img"
        height="150px"
        image={image}
        sx={{
          objectFit: 'cover',
          width: '100%',
        }}
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 1,
          padding: '15px',
          gap: '10px',
        }}
      >
        <Typography sx={{ my: 2 }} variant="subtitle1">
          {t('tax_year', { year: new Date().getFullYear() - 1 })}
        </Typography>
        <Typography gutterBottom variant="h6" fontWeight={900} color="#0F5D66">
          {t('my_documents')}
        </Typography>
        <Typography sx={{ my: 2, color: '#6C7278' }} variant="body1">
          {t('description')}
        </Typography>
        <Box display="flex">
          <Button
            variant="contained"
            sx={{
              bgcolor: '#0F5D66',
              color: '#ffffff',
              textTransform: 'none',
              borderRadius: '15px',
              borderColor: '#DCE4E8',
              fontWeight: '700',
              padding: '8px 12px',
              '&:hover': {
                border: '0.5px solid',
                color: 'rgba(15, 93, 102,0.9)',
                bgcolor: 'rgba(255, 255, 255,0.5)',
              },
              flex: 1,
              height: 60,
            }}
            onClick={() => {
              navigate('/my_documents/taxyear');
            }}
          >
            {t('get_started')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default InfoCard;
