import React from 'react';
import FilledCheckIcon from '../../../assets/icons/FilledCheckIcon';
import { Box, Typography } from '@mui/material';

const CheckItem = ({ label }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '8px', alignItems: 'center' }}>
      <FilledCheckIcon />
      <Typography variant="body1" color="textPrimary">
        {label}
      </Typography>
    </Box>
  );
};

export default CheckItem;
