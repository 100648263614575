import { useMutation, useQuery, useQueryClient } from 'react-query';
import { customInstance } from './client';

const suffix = '/files';

//GET FileTypes

const getAllFileTypes = () => {
  return customInstance({
    url: `${suffix}/file-types`,
    method: 'get',
  });
};

export const useGetAllFileTypes = () => {
  return useQuery(['all-file-types'], async () => {
    const data = await getAllFileTypes();
    return data;
  });
};

//GET FileType By Id

const getFileTypeById = (id) => {
  return customInstance({
    url: `${suffix}/file-types/${id}`,
    method: 'get',
  });
};

export const useGetFileTypeById = (id) => {
  return useQuery(['file-type', id], () => getFileTypeById(id), { enabled: !!id });
};

//GET Icons
const getAllIcons = () => {
  return customInstance({
    url: `${suffix}/icons`,
    method: 'get',
  });
};
export const useGetAllIcons = () => {
  return useQuery(['all-icons'], async () => {
    const data = await getAllIcons();
    return data;
  });
};

//ADD FileType

const createFileType = async (data) => {
  return customInstance({
    url: `${suffix}/file-type`,
    method: 'post',
    data,
  });
};

export const useCreateFileType = () => {
  const queryClient = useQueryClient();
  return useMutation(({ data }) => createFileType(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['all-file-types'] });
      queryClient.invalidateQueries({ queryKey: ['file', 'type', 'children'] });
    },
  });
};

//PUT FileType

const updateFileType = async (id, data) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'put',
    data,
  });
};

export const useUpdateFileType = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateFileType(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['all-file-types'] });
      queryClient.invalidateQueries({ queryKey: ['file', 'type', 'children'] });
    },
  });
};

//PUT Update Many File Types

const updateManyFileTypes = async (data) => {
  return customInstance({
    url: `${suffix}`,
    method: 'put',
    data,
  });
};

export const useUpdateManyFileTypes = () => {
  const queryClient = useQueryClient();
  return useMutation(({ data }) => updateManyFileTypes(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['all-file-types'] });
      queryClient.invalidateQueries({ queryKey: ['file', 'type', 'children'] });
    },
  });
};
