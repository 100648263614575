import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import NavBarRegister from '../components/navBarRegister/navBarRegister';
import VerifyPassForm from '../components/registrationLayout/verifyPassForm';
import { useTranslation } from 'react-i18next';
import Background from '../components/commonComponents/Background';
const ChangePassword = () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="center" alignItems="center" bgcolor="#1D2735" width="100%" height="100%">
      <NavBarRegister />
      <Background
        src="https://taxofficestorage.blob.core.windows.net/media/loginBackground.svg"
        alt="Login background"
      />

      <Box
        width="100%"
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        zIndex={300}
      >
        <Paper
          sx={{
            zIndex: '1000',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '32px',
            borderRadius: '15px',
            boxShadow: '0px 34px 114px rgba(0, 0, 0, 0.08)',
            width: { xs: '90%', sm: '50%', md: '480px' },
          }}
        >
          <Typography variant="h6" fontWeight={700} textAlign="center">
            {t('otpCodeMsg')}
          </Typography>
          <Box width="100%" display="flex" flexDirection="column" justifyContent="center" gap="32px">
            <VerifyPassForm />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default ChangePassword;
