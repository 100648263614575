import React, { useState } from 'react';
import { Divider, IconButton, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import FileTypeTable from './FileTypeTable';
import { useGetAllFileTypes } from '../../../api/fileTypes';
import DocumentTab from '../../DocumentTab';
import useFileTypeChildren from '../../../hooks/useFileTypeChildren';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useGetAllArchiveTypes } from '../../../api/archive';
import LanguageSelect from '../../selectTranslation/translateSelect';

const FileTypeSettings = () => {
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [activeTabs, setActiveTabs] = useState([]);
  const [parentId, setParentId] = useState(null);
  const { t, i18n } = useTranslation();
  const { data, isLoading, isFetching } = useGetAllFileTypes();
  const { data: fileTypeChildren } = useFileTypeChildren(
    activeTabs[activeTabs.length - 1],
    !!activeTabs[activeTabs.length - 1],
  );
  const navigate = useNavigate();
  const { data: archiveTypes } = useGetAllArchiveTypes();

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center" px={4}>
          <IconButton onClick={() => navigate(-1)} sx={{ marginLeft: -2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" fontWeight={700} px={5} py={2.45} sx={{ marginLeft: -4, flexGrow: 1 }}>
            {t('FileTypeSettings')}
          </Typography>
          <LanguageSelect />
        </Box>
        <Divider />
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
          <Box my={5} width={!isMobile ? '20%' : '100%'}>
            {data?.tree.map((tab, index) => (
              <DocumentTab
                key={index}
                lang={i18n.language}
                tab={tab}
                isLoading={isLoading}
                activeTabs={activeTabs}
                setActiveTabs={setActiveTabs}
                isActive={activeTabs?.includes(tab.id)}
                onAddButtonClick={(param) => {
                  setParentId(param.id);
                  setOpenAddDrawer(true);
                }}
              />
            ))}
          </Box>
          <Box my={isMobile ? 2 : 5} width={!isMobile ? '80%' : '100%'}>
            <FileTypeTable
              fileTypes={activeTabs.length > 0 ? fileTypeChildren : data?.tree}
              isLoading={isLoading}
              isFetching={isFetching}
              parentType={parentId ? parentId : activeTabs.length > 0 && activeTabs[activeTabs.length - 1]}
              activeTabs={activeTabs}
              setActiveTabs={setActiveTabs}
              openAddDrawer={openAddDrawer}
              setOpenAddDrawer={setOpenAddDrawer}
              archiveTypes={archiveTypes}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FileTypeSettings;
