/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useCreateLead } from '../../../../api/leads';
import { useEffect } from 'react';

const useComponentForm = () => {
  const { t } = useTranslation();
  const { mutate, isSuccess, isLoading, data, isError } = useCreateLead();
  const defaultValues = {
    name: '',
    phoneNumber: '',
    email: '',
  };

  const formSchema = yup.object().shape({
    name: yup.string().required(t('required_field', { field: t('name') })),
    phoneNumber: yup.string().required(t('required_field', { field: t('phone.number') })),
    email: yup
      .string()
      .email()
      .required(t('required_field', { field: t('email') })),
  });

  const form = useForm({ defaultValues, resolver: yupResolver(formSchema) });

  const onSubmit = (data) => {
    mutate(data);
  };
  useEffect(() => {
    if (isSuccess && !isLoading && data) {
      window.location.href = `/campaign/${data?.id}`;
    }
  }, [isSuccess, isLoading]);
  return { ...form, onSubmit, isLoading, isSuccess, isError };
};

export default useComponentForm;
