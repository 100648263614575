import { Box, FormHelperText, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import CustomButton from '../commonComponents/CustomButton';

const YesNoInput = ({ question, name, control, padding, notFullWidth }) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Box sx={{ width: !notFullWidth ? '100%' : 'fit-content' }}>
          <Typography variant="body1" sx={{ mb: '12px', textAlign: 'center', fontWeight: 700 }}>
            {question}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: '24px', px: padding ? '0' : '24px' }}>
            <CustomButton
              fullWidth
              variant={field.value === true ? 'contained' : 'outlined'}
              onClick={() => {
                field.onChange(true);
              }}
            >
              {t('Yes')}
            </CustomButton>
            <CustomButton
              fullWidth
              variant={field.value === false ? 'contained' : 'outlined'}
              onClick={() => {
                field.onChange(false);
              }}
            >
              {t('No')}
            </CustomButton>
          </Box>
          <FormHelperText error={!!fieldState.error}>{fieldState?.error?.message}</FormHelperText>
        </Box>
      )}
    />
  );
};

export default YesNoInput;
