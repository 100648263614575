import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import { format } from 'date-fns';

const UpcomingAppointment = ({ termins }) => {
  return termins.map((termin, index) => (
    <Paper key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
      <Box bgcolor="#f4f4f7" p={3} display="flex" alignItems="center">
        <Typography variant="h6">{termin.from && format(new Date(termin.from), 'HH:mm')}</Typography>
        <RemoveIcon />
        <Typography variant="h6">{termin.to && format(new Date(termin.to), 'HH:mm')}</Typography>
      </Box>
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" px={3}>
        <Typography variant="h6">{termin.lead?.name}</Typography>
        <Typography variant="body2">{termin.to && format(new Date(termin.to), 'dd.MM.yyyy')}</Typography>
      </Box>
    </Paper>
  ));
};

export default UpcomingAppointment;
