/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SelectIcon = ({ icons, onClick, errorMessage, value }) => {
  const { t } = useTranslation();
  const [selectedIcon, setSelectedIcon] = useState(
    icons?.find((icon) => icon.url === value || icon.name === value)?.name,
  );

  useEffect(() => {
    setSelectedIcon(icons?.find((icon) => icon.url === value || icon.name === value)?.name);
  }, [value]);

  return (
    <Box display="flex" my={1} flexDirection="column" width="100%">
      <Typography variant="body2" fontSize="12px">
        {t('Icon')}
      </Typography>
      <Grid container spacing={1}>
        {icons?.map((item) => (
          <Grid key={item.url} item>
            <Box
              sx={{
                border: selectedIcon === item.name ? '2px solid #31B099' : 'none',
                borderRadius: '15px',
                paddingX: 1,
                paddingTop: 1,
              }}
              onClick={() => {
                setSelectedIcon(item.name);
                onClick(item);
              }}
            >
              <img
                src={item.url}
                alt={item.name}
                style={{
                  width: '44px',
                }}
              ></img>
            </Box>
          </Grid>
        ))}
      </Grid>
      {errorMessage && (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default SelectIcon;
