import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import CustomFormInput from '../../../commonComponents/CustomFormInput';
import CustomDateInput from '../../../CustomDateInput';
import BigSubmitButton from '../../../commonComponents/BigSubmitButton';
import YesNoInput from '../../../YesNoInput';
import { Alert, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useComponentForm from './hooks/useComponentForm';

const Form = () => {
  const { t } = useTranslation();
  const { control, handleSubmit, onSubmit, isLoading, isSuccess } = useComponentForm();
  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      justifyContent="space-evenly"
      alignItems="center"
      gap="20px"
      marginY="24px"
    >
      <CustomFormInput name="name" control={control} label={t('YFirstName')} />
      <CustomFormInput name="phoneNumber" control={control} label={t('YMobileNumber')} />
      <CustomFormInput name="email" control={control} label={t('YEmailAddress')} />
      <CustomFormInput name="zip" control={control} label={t('YZip')} inputProps={{ inputMode: 'numeric' }} />
      <CustomFormInput name="address" control={control} label={t('YAddress')} />

      <CustomDateInput name="birthdate" control={control} label={t('YBirthdate')} />

      <YesNoInput question={t('what_is_your_status')} control={control} name="married" />
      <YesNoInput question={t('do_you_have_house')} control={control} name="estate" />
      <BigSubmitButton
        onClick={handleSubmit(onSubmit)}
        buttonText={t('Submit')}
        color="inherit"
        disabled={isSuccess && !isLoading}
      />
      {isSuccess && !isLoading && (
        <>
          <Alert severity="success">{t('form_submited_successfuly')}</Alert>
          <Typography variant="body2">
            {t('check_out_our_site')}: <Link to="/">digitalesbuero.ch</Link>{' '}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default Form;
