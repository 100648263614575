import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useUpdateUserData } from '../api/user';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserData } from '../store/auth/authActions';
import useToastNotification from './useToastNotification';
import { format } from 'date-fns';

export const useUpdateUser = (currentUser) => {
  const [defaultValuesLoaded, setDefaultValuesLoaded] = useState(false);
  let defaultValues = {};
  const { mutate, isLoading, isSuccess, isError } = useUpdateUserData();
  const dispatch = useDispatch();

  const updateSchema = yup
    .object()
    .shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      zipCode: yup.string().min(4).max(4).required(),
      place: yup.string().required(),
      phoneNumber: yup.string().required(),
      address: yup.string().required(),
      birthdate: yup.date().required(),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: defaultValuesLoaded ? defaultValues : {},
    resolver: yupResolver(updateSchema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (currentUser) {
      const defaultValues = {
        firstName: currentUser?.firstName || '',
        lastName: currentUser?.lastName || '',
        zipCode: currentUser?.zipCode || '',
        place: currentUser?.place || '',
        phoneNumber: currentUser?.phoneNumber || '',
        address: currentUser?.address || '',
        birthdate: currentUser?.birthdate ? format(new Date(currentUser.birthdate), 'yyyy-MM-dd') : '',
      };
      reset(defaultValues);
      setDefaultValuesLoaded(true);
    }
  }, [currentUser, reset]);

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessfullyUpdatedUser',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorUpdatingUser',
    variant: 'error',
  });

  const onSubmit = async (data) => {
    const formattedData = {
      ...data,
      birthdate: data.birthdate.toISOString(),
    };
    dispatch(updateUserData(formattedData));
    mutate(formattedData);
  };

  return {
    handleSubmit,
    onSubmit,
    control,
    isLoading,
    defaultValuesLoaded,
    errors,
  };
};
