import { Switch } from '@mui/material';
import { styled } from '@mui/styles';

export const CustomSwitch = styled(Switch)({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#31B099',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#004852',
      },
    },

    color: '#004852',
    '& + .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#31B099',
    },
  },
});
