/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useCreateTermins, useGetAllTerminSlots } from '../../../api/termins';
import { Box, Grid } from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomFormInput from '../CustomFormInput';
import SubmitButton from '../SubmitButton';
import TerminSlotComponent from './TerminSlotComponent';
import useToastNotification from '../../../hooks/useToastNotification';
import { useTranslation } from 'react-i18next';

const TerminForm = ({ interest, handleModalClose }) => {
  const { t } = useTranslation();
  const { data: slots } = useGetAllTerminSlots();
  console.log(slots);
  const { mutate, isSuccess, isLoading, isError } = useCreateTermins();
  const [selectedSlot, setSelectedSlot] = useState();

  const schema = yup.object().shape({
    date: yup.string().required(),
    from: yup.object().shape({
      hour: yup.number().min(0).max(23).required(),
      minute: yup.number().min(0).max(60).required(),
    }),
    to: yup.object().shape({
      hour: yup.number().min(0).max(23).required(),
      minute: yup.number().min(0).max(60).required(),
    }),
  });

  const { handleSubmit, control, reset, setValue } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      date: '',
      from: {
        hour: '',
        minute: '',
      },
      to: {
        hour: '',
        minute: '',
      },
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    const newDate = new Date(data.date);

    const newData = {
      from: newDate.setHours(data.from.hour, data.from.minute, 0, 0),
      to: newDate.setHours(data.to.hour, data.to.minute, 0, 0),
      interest: interest,
    };
    mutate(newData);
    reset();
    setSelectedSlot(undefined);
    handleModalClose();
  };

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'TerminSuccess',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'TerminError',
    variant: 'error',
  });

  return (
    <Box display="flex" flexDirection="column" gap="20px" component="form">
      <CustomFormInput name="date" type="date" control={control} label={'Date'} />
      <Grid container columnSpacing={3} rowSpacing={3}>
        {slots?.map((slot, idx) => (
          <Grid item xs={12} lg={6} xl={6} key={idx}>
            <TerminSlotComponent
              slot={slot}
              selectedSlot={selectedSlot}
              setSelectedSlot={setSelectedSlot}
              setValue={setValue}
            />
          </Grid>
        ))}
      </Grid>

      <SubmitButton
        onClick={handleSubmit(onSubmit)}
        buttonText={t('MakeAnappointment')}
        backgroundColor="#31B099"
        color="inherit"
      />
    </Box>
  );
};

export default TerminForm;
