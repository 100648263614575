import React from 'react';
import { Button, Typography } from '@mui/material';

const BigSubmitButton = ({ onClick, buttonText, color, backgroundColor, ...props }) => {
  return (
    <Button
      variant="contained"
      component="label"
      color={color || 'inherit'}
      sx={{
        bgcolor: backgroundColor || '#0F5D66',
        '&:hover': {
          backgroundColor: '#31B099',
        },
        textTransform: 'none',
        borderRadius: '15px',
        paddingY: '13px',
        width: '100%',
        marginTop: '10px',
        ...props.sx,
      }}
      onClick={onClick}
      {...props}
    >
      <Typography variant="body1" color="#fff">
        {buttonText}
      </Typography>
    </Button>
  );
};

export default BigSubmitButton;
