import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import useChangeEffect from '../../../../hooks/useChangeEffect.js';
import { debounce } from 'lodash-es';
import { Box, Divider, Typography } from '@mui/material';
import { tags } from '../../leads/tags.js';
import { useGetAllUsersTermins } from '../../../../api/termins.js';
import useUserTerminsColumns from '../../../../hooks/AllUsers/useUserTerminsColumns.js';
import { handleLimit, handleNextPage, handlePrevPage } from '../../../../utils/paginationFunction.js';
import LanguageSelect from '../../../selectTranslation/translateSelect.js';
import UserAppointmentsTable from './components/UserAppointmentsTable.jsx';
import { useNavigate } from 'react-router-dom';
import { useGetAllBrokers } from '../../../../api/user.js';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UserAppointments = () => {
  const { t } = useTranslation();

  const [keyword, setKeyword] = useState('');
  const [filterTag, setFilterTag] = useState(['search']);
  const [filterTagData, setFilterTagData] = useState({});
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const navigation = useNavigate();
  const [rowSelection, setRowSelection] = useState({});
  const { data: brokers } = useGetAllBrokers();

  const { data: allUsers, isLoading, isFetching } = useGetAllUsersTermins({ page, limit, ...filterTagData });
  const termins = allUsers?.results;

  const { control } = useForm({
    mode: 'onSubmit',

    reValidateMode: 'onChange',
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterTag(typeof value === 'string' ? value.split(',') : value);
  };

  const updateFilterTagData = () => {
    setFilterTagData({ [filterTag]: keyword });
  };

  useChangeEffect(() => {
    updateFilterTagData();
  }, [filterTag, keyword]);

  const debouncedHandleSearchChange = debounce((value) => {
    setKeyword(value);
    setPage(0);
  }, 300);

  const columns = useUserTerminsColumns({ brokers });
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center" px={4}>
            <Typography variant="h6" fontWeight={700} px={5} py={2.45} sx={{ marginLeft: -4 }}>
              {t('User Appointments')}
            </Typography>
            <LanguageSelect />
          </Box>
          <Divider />

          <Box m={5}>
            {termins && (
              <UserAppointmentsTable
                MenuProps={MenuProps}
                allUsers={allUsers}
                columns={columns}
                control={control}
                debouncedHandleSearchChange={debouncedHandleSearchChange}
                filterTag={filterTag}
                handleChange={handleChange}
                handleLimit={handleLimit}
                handleNextPage={handleNextPage}
                handlePrevPage={handlePrevPage}
                isFetching={isFetching}
                isLoading={isLoading}
                setPage={setPage}
                tags={tags}
                users={termins}
                setLimit={setLimit}
                cursor="pointer"
                setRowSelection={setRowSelection}
                rowSelection={rowSelection}
                brokers={brokers?.results}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserAppointments;
