import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import CustomTextField from '../../../../commonComponents/CustomTextField';

const InfoField = ({ labelKey, value, disabled = true }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" my={1} flexDirection="column" width="100%">
      <Typography variant="body2" fontSize="12px">
        {t(labelKey)}
      </Typography>
      <CustomTextField value={value || '/'} disabled={disabled} />
    </Box>
  );
};

export default InfoField;
