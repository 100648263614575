import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetAllBrokers } from '../../../../../../api/user';
import { Box } from '@mui/system';
import CustomFormInput from '../../../../../commonComponents/CustomFormInput';
import CustomSelect from '../../../../../commonComponents/CustomSelect';
import SubmitButton from '../../../../../commonComponents/SubmitButton';
import { useAddUserTermins } from '../hooks/useAddUserTermin';

const TerminsForm = ({ handleDrawerClose, id }) => {
  const { t } = useTranslation();

  const user = useSelector((state) => state.auth);
  const isAdmin = user.roles?.includes('admin');
  const defaultBrokerValue = isAdmin ? '' : user?.currentUser?.id;

  const { handleSubmit, control, onSubmit } = useAddUserTermins(id, defaultBrokerValue, handleDrawerClose);

  const { data: brokers } = useGetAllBrokers(undefined, isAdmin);
  return (
    <>
      <Box display="flex" gap="20px">
        <CustomFormInput name="from" type="datetime-local" control={control} label={t('From')} />
        <CustomFormInput name="interest" label={t('Interest')} control={control} />
      </Box>
      {isAdmin && (
        <CustomSelect
          control={control}
          name="broker"
          label={t('Broker')}
          data={brokers?.results}
          valueField={(item) => item.id}
          displayField={(item) => `${item.firstName} ${item.lastName}`}
        />
      )}
      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </>
  );
};

export default TerminsForm;
