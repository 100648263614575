import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog } from '@mui/material';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';

const ColorPicker = ({ onColorChange, initialColor }) => {
  const [color, setColor] = useState(initialColor || { r: 255, g: 255, b: 255, a: 1 });
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (initialColor) {
      setColor(initialColor);
    }
  }, [initialColor]);

  const { t } = useTranslation();

  const handleChangeComplete = (color) => {
    setColor(color.rgb);
    onColorChange(color.hex);
  };

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box display="flex" alignItems="center" gap="15px">
      <Box
        style={{
          width: '25px',
          height: '25px',
          backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
          border: '1px solid #ccdae2',
          borderRadius: '15px',
        }}
      ></Box>
      <Button
        variant="contained"
        component="label"
        color="inherit"
        sx={{
          bgcolor: '#f4f4f7',
          textTransform: 'none',
          borderRadius: '15px',
          width: 'fit-content',
          paddingY: '10px',
          paddingX: '25px',
        }}
        onClick={handleClickOpen}
      >
        {t('PickColor')}
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <ChromePicker color={color} onChangeComplete={handleChangeComplete} />
      </Dialog>
    </Box>
  );
};

export default ColorPicker;
