import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DocumentCard from './DocumentCard';
import { useTranslation } from 'react-i18next';
import { useCreateFile } from '../../hooks/useOwnFiles';
import UploadFileButton from '../commonComponents/UploadFileButton/UploadFileButton';
import { useForm } from 'react-hook-form';

const DocumentManager = ({ taskStatus, files, fileType, archives, archive, ...props }) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState();
  const [isDisabled, setIsDisabled] = useState();
  const { mutate: createFile, isLoading, isSuccess } = useCreateFile(setProgress);
  const { i18n } = useTranslation();
  const { register, control } = useForm();

  const lang = i18n.language;

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setProgress(null);
    }
  }, [isLoading, isSuccess]);

  const handleUploadSubmit = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', fileType);
    formData.append('archiveId', archive);
    createFile(formData);
  };

  useEffect(() => {
    if (taskStatus) {
      if (taskStatus === 'finished_documents') {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(false);
    }
  }, [taskStatus]);

  const isFinishedDocuments = fileType === t('finished_tax_return');
  return (
    <Box width="100%" {...props}>
      {!isFinishedDocuments && (
        <Box display="flex" width="fit-content" gap="15px">
          <UploadFileButton
            onSubmit={handleUploadSubmit}
            progress={isLoading ? progress : undefined}
            register={register}
            control={control}
            props
            disabled={isDisabled}
          />
        </Box>
      )}
      <Box
        sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, flexWrap: 'wrap', gap: '20px' }}
        my="20px"
      >
        {isFinishedDocuments ? (
          files ? (
            <DocumentCard
              key={files?.id}
              url={files?.file?.url}
              originalName={files?.file?.name}
              id={files?.id}
              uploadedAt={files?.updatedAt}
              showEditOptions={false}
              showImg
            />
          ) : (
            <Typography variant="h6">{t('noFinishedDocumentsMessage')}</Typography>
          )
        ) : files?.length > 0 && isFinishedDocuments ? (
          files.map((file) => (
            <DocumentCard
              key={file?.id}
              url={file?.file?.url}
              originalName={file?.file?.name}
              id={file?.id}
              uploadedAt={file?.updatedAt}
              showEditOptions={false}
              showImg
            />
          ))
        ) : (
          files
            ?.filter((file) => file?.archive?.id === archive && file?.fileType?.[`name_${lang}`] === fileType)
            ?.map((file) => <DocumentCard key={file.id} {...file} showImg />)
        )}
      </Box>
    </Box>
  );
};

export default DocumentManager;
