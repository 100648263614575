import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './style.css';

const Counter = ({ targetNumber, type, icon }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < targetNumber) {
        setCount(count + 1);
      } else {
        clearInterval(interval);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [count, targetNumber]);

  return (
    <Box
      className="card-container"
      style={{
        border: '1px solid #323232',
        padding: '1rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          placeItems: 'center',
          bgcolor: '#004852',
          overflow: 'visible',
          borderRadius: '200px',
          width: '40px',
          height: '40px',
          '& img': {
            filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)',
          },
        }}
      >
        <img alt="Tab Icon" src={icon}></img>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">{type}</Typography>
        <Typography variant="h5">{count.toLocaleString()}</Typography>
      </Box>
    </Box>
  );
};

export default Counter;
