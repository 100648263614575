import React from 'react';
import { Box } from '@mui/material';

const Background = ({ src, alt }) => (
  <Box
    component="img"
    sx={{
      position: 'absolute',
      left: 0,
      right: 0,
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      zIndex: '0',
    }}
    alt={alt}
    src={src}
  />
);

export default Background;
