import { Avatar, Box, Divider, Drawer, IconButton, List, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import VListItem from './VListItem';
import { snakeCase } from 'lodash-es';
import verticalHeaderOptions from '../../config/verticalHeaderOptions';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/auth/authActions';
import { useTranslation } from 'react-i18next';
import findLocalsOrUseFirst from '../../utils/findLocalsOrUseFirst';

const VerticalHeader = ({ option = 'dashboard', archives }) => {
  const { i18n } = useTranslation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.currentUser);
  const { t } = useTranslation();
  const path = pathname?.split('/').filter((e) => e);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleDropdown = (label) => {
    if (openDropdown === label) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(label);
    }
  };

  const isActive = (label, navigateTo, isDropdownOption = false) => {
    const currentPath = snakeCase(path[path.length - 1]);
    const currentLabel = snakeCase(label);

    if (isDropdownOption) {
      return navigateTo.endsWith(currentPath);
    }

    if (currentLabel === 'archive' && !isDropdownOption) {
      return path.includes(currentLabel);
    }

    // Additional check for archives
    if (label?.startsWith('my_documents/')) {
      const archiveName = label.split('/')[1];
      return currentPath === snakeCase(archiveName);
    }

    return currentPath === currentLabel;
  };

  const listArr = verticalHeaderOptions?.[option]?.topArr;
  const bottomArr = verticalHeaderOptions?.[option]?.bottomArr;
  const link = verticalHeaderOptions?.[option]?.link;

  const userActive = useSelector((state) => state.auth);
  const isAdmin = userActive.roles?.includes('admin');
  const isBroker = userActive.roles?.includes('broker');
  return (
    <>
      {isMobile ? (
        <>
          <Box
            overflow="hidden"
            display="flex"
            bgcolor="#fff"
            zIndex={1000}
            px={1}
            width="100%"
            top="0"
            position="sticky"
            justifyContent="space-between"
            boxShadow="0px -3px 5px 5px #11111144"
          >
            <Link to={link}>
              <Box
                component="img"
                margin="15px"
                sx={{
                  height: { xs: '33px' },
                  width: { xs: '180px' },
                  objectFit: 'contain',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                alt="Your logo."
                src={'https://taxofficestorage.blob.core.windows.net/media/greenLogo.svg'}
              ></Box>
            </Link>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              sx={{ display: { lg: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Drawer
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', lg: 'none' },
              width: '260px',
              flexShrink: 0,

              '& .MuiDrawer-paper': {
                width: '260px',
                bgcolor: '#F4F4F7',
                boxSizing: 'border-box',
              },
            }}
            variant="temporary"
            anchor="right"
          >
            <Box display="flex" alignItems="center" justifyContent="space-between" pl={2} pr={1} py={1.5}>
              <Box display="flex" alignItems="center" gap="10px">
                <Avatar />
                {user?.firstName}
              </Box>
              <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
                <Close />
              </IconButton>
            </Box>
            <Divider />
            <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
              {listArr && (
                <List>
                  {listArr.map((post, i) => {
                    if (post.dropdownoptions) {
                      return (
                        <React.Fragment key={i}>
                          <VListItem
                            handleClose={handleDrawerToggle}
                            isActive={isActive(post?.navigateTo)}
                            label={t(post?.label)}
                            onClick={() => toggleDropdown(post.label)}
                            {...post}
                          />
                          {openDropdown === post.label &&
                            post.dropdownoptions.map((dropdownOption, j) => (
                              <VListItem
                                handleClose={handleDrawerToggle}
                                key={j}
                                isActive={isActive(dropdownOption?.label, dropdownOption?.navigateTo, true)}
                                label={t(dropdownOption?.label)}
                                style={{ marginLeft: 10 }}
                                {...dropdownOption}
                              />
                            ))}
                        </React.Fragment>
                      );
                    }

                    return (
                      <VListItem
                        handleClose={handleDrawerToggle}
                        key={i}
                        isActive={isActive(post?.label)}
                        label={t(post?.label)}
                        {...post}
                      />
                    );
                  })}
                  {archives
                    ?.filter((arch) => arch.name !== 'articles')
                    ?.map((archive, idx) => {
                      return (
                        <VListItem
                          handleClose={handleDrawerToggle}
                          key={idx}
                          isActive={isActive(`my_documents/${archive?.name}`)}
                          label={findLocalsOrUseFirst(archive?.locals, i18n.language)?.name}
                          imgSrc={archive.icon}
                          navigateTo={`my_documents/${archive.name}`}
                          auth
                        />
                      );
                    })}
                </List>
              )}

              {bottomArr && (
                <List>
                  <Divider />
                  {bottomArr.map((post, i) => (
                    <VListItem
                      handleClose={handleDrawerToggle}
                      key={i}
                      isActive={isActive(post?.label)}
                      label={t(post?.label)}
                      {...post}
                    />
                  ))}
                  <VListItem
                    onClick={() => {
                      dispatch(logout());
                    }}
                    label="Logout"
                    imgSrc="https://taxofficestorage.blob.core.windows.net/media/logout.svg"
                  />
                </List>
              )}
            </Box>
          </Drawer>
        </>
      ) : (
        <Drawer
          sx={{
            width: '260px',
            overflow: 'hidden',
            flexShrink: 0,
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              width: '260px',
              overflow: 'hidden',
              bgcolor: '#F4F4F7',
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Link to={isAdmin ? '/admin/dashboard' : isBroker ? '/broker/dashboard' : link}>
            <Box
              component="img"
              margin="20px"
              sx={{
                height: { xs: '28px', md: '35px' },
                width: { xs: '168px', md: '205px' },
                objectFit: 'contain',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              alt="Your logo."
              src={'https://taxofficestorage.blob.core.windows.net/media/greenLogo.svg'}
            ></Box>
          </Link>
          <Divider />
          <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
            {listArr && (
              <List>
                {listArr.map((post, i) => {
                  if (post.dropdownoptions) {
                    return (
                      <React.Fragment key={i}>
                        <VListItem
                          handleClose={handleDrawerToggle}
                          isActive={isActive(post?.navigateTo)}
                          label={t(post?.label)}
                          onClick={() => toggleDropdown(post.label)}
                          {...post}
                        />
                        {openDropdown === post.label &&
                          post.dropdownoptions.map((dropdownOption, j) => (
                            <VListItem
                              handleClose={handleDrawerToggle}
                              key={j}
                              isActive={isActive(dropdownOption?.label, dropdownOption?.navigateTo, true)}
                              label={t(dropdownOption?.label)}
                              style={{ marginLeft: 10 }}
                              {...dropdownOption}
                            />
                          ))}
                      </React.Fragment>
                    );
                  }
                  return (
                    <VListItem
                      handleClose={handleDrawerToggle}
                      key={i}
                      isActive={isActive(post?.label)}
                      label={t(post?.label)}
                      {...post}
                    />
                  );
                })}
                {archives
                  ?.filter((arch) => arch.name !== 'articles')
                  ?.map((archive, idx) => {
                    return (
                      <VListItem
                        handleClose={handleDrawerToggle}
                        key={idx}
                        isActive={isActive(`my_documents/${archive?.name}`)}
                        label={findLocalsOrUseFirst(archive?.locals, i18n.language)?.name}
                        imgSrc={archive.icon}
                        navigateTo={`my_documents/${archive.name}`}
                        auth
                      />
                    );
                  })}
              </List>
            )}
            {bottomArr && (
              <List>
                <Divider />
                {bottomArr.map((post, i) => (
                  <VListItem
                    handleClose={handleDrawerToggle}
                    key={i}
                    isActive={isActive(post?.label)}
                    label={t(post?.label)}
                    {...post}
                  />
                ))}
                <VListItem
                  onClick={() => {
                    dispatch(logout());
                  }}
                  label="Logout"
                  imgSrc="https://taxofficestorage.blob.core.windows.net/media/logout.svg"
                />
              </List>
            )}
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default VerticalHeader;
