import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { truncate } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import isISODateValid from '../../utils/isISODateValid';
import { format } from 'date-fns';
import MenuDropdown from '../../components/adminDashboard/MenuDropdown';

const useFinishedDocumentsColumns = (handleDeleteModal) => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper();
  const menuItems = [
    {
      label: t('Delete'),
      icon: 'https://taxofficestorage.blob.core.windows.net/media/trash.svg',
      onClick: (row) => handleDeleteModal(row.id),
    },
  ];
  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          #
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row?.file?.name, {
      id: 'fileName',
      cell: (info) => <Typography>{truncate(info.getValue(), { length: 20 })}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('filename')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row?.archive?.name, {
      id: 'archive',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('taxyear')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row?.createdAt, {
      id: 'createdAt',
      cell: (info) => (
        <Typography>{isISODateValid(info.getValue()) && format(new Date(info.getValue()), 'dd.MM.yyyy')}</Typography>
      ),
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Uploaded At')}
        </Typography>
      ),
    }),
    columnHelper.accessor('action', {
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Action')}
        </Typography>
      ),
      cell: (info) => {
        return (
          <MenuDropdown
            id={info?.row?.original?.id}
            menuItems={menuItems.map((item) => ({ ...item, onClick: () => item.onClick(info.row.original) }))}
          />
        );
      },
      footer: (info) => info.column.id,
    }),
  ];
  return columns;
};

export default useFinishedDocumentsColumns;
