/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import { useCreateArchiveTypeLocal, useGetArchiveTypeById, useUpdateArchiveType } from '../api/archive';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import * as yup from 'yup';
import useToastNotification from './useToastNotification';

export const useEditArchiveType = (id, handleDrawerClose) => {
  const { data } = useGetArchiveTypeById(id);
  const { mutate, isSuccess, isLoading, isError } = useUpdateArchiveType();
  const { mutate: addLocals } = useCreateArchiveTypeLocal();

  const localsDe = data?.data?.locals?.find((local) => local.language === 'de')?.name;
  const localsEng = data?.data?.locals?.find((local) => local.language === 'eng')?.name;

  const defaultValues = {
    name_eng: localsEng || '',
    name_de: localsDe || '',
    is_active: data?.data?.is_active,
    icon: data?.data?.icon || '',
  };

  const editArchiveTypeSchema = yup
    .object()
    .shape({
      name_eng: yup.string().required(),
      name_de: yup.string().required(),
      is_active: yup.boolean(),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: 'onSubmit',

    defaultValues,
    resolver: yupResolver(editArchiveTypeSchema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    reset(defaultValues);
  }, [data?.data?.id, reset]);

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessEditArchiveType',
    variant: 'success',
    cb: () => {
      if (data) {
        const formVals = getValues();
        const foundEng = data?.data?.locals?.find((local) => local.language === 'eng');
        const foundDe = data?.data?.locals?.find((local) => local.language === 'de');
        if (foundDe && foundEng) {
          addLocals([
            { id: foundEng.id, language: 'eng', name: formVals?.name_eng, archiveType: data?.data?.id },
            { id: foundDe.id, language: 'de', name: formVals?.name_de, archiveType: data?.data?.id },
          ]);
        }
        if (foundDe && !foundEng) {
          addLocals([
            { language: 'eng', name: formVals?.name_eng, archiveType: data?.data?.id },
            { id: foundDe.id, language: 'de', name: formVals?.name_de, archiveType: data?.data?.id },
          ]);
        }
        if (!foundDe && foundEng) {
          addLocals([
            { id: foundEng.id, language: 'eng', name: formVals?.name_eng, archiveType: data?.data?.id },
            { language: 'de', name: formVals?.name_de, archiveType: data?.data?.id },
          ]);
        }
        if (!foundDe && !foundEng) {
          addLocals([
            { language: 'eng', name: formVals?.name_eng, archiveType: data?.data?.id },
            { language: 'de', name: formVals?.name_de, archiveType: data?.data?.id },
          ]);
        }
        reset(defaultValues);
        handleDrawerClose();
      }
    },
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorWhileEditingArchiveType',
    variant: 'error',
  });

  const onSubmit = async (data) => {
    const editData = {
      name: data.name_de,
      language: 'de',
      icon: data.icon,
      is_active: data.is_active,
    };
    mutate({ id, data: editData });
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    isLoading,
    errors,
  };
};
