/* eslint-disable react-hooks/exhaustive-deps */
import { useCreateTerminSlots, useUpdateTerminSLots } from '../api/termins';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import useToastNotification from './useToastNotification';
import { useTranslation } from 'react-i18next';
import useCreateTerminSlotLocals from './useCreateTerminSlotLocals';

export const useAddTerminSlots = (handleDrawerClose, selectedSlot) => {
  const { t } = useTranslation();
  const { mutate, isSuccess, isLoading, isError, data: addData } = useCreateTerminSlots();
  const { mutate: addLocals } = useCreateTerminSlotLocals();
  const {
    mutate: editMutate,
    isSuccess: isEditSuccess,
    isLoading: isEditLoading,
    isError: isEditError,
  } = useUpdateTerminSLots();

  const schema = yup.object().shape({
    label: yup.object().shape({
      eng: yup.string().required(t('required_field', { field: t('english_label') })),
      de: yup.string().required(t('required_field', { field: t('deutsch_label') })),
    }),
    from: yup.object().shape({
      hour: yup
        .number()
        .typeError(t('number_required', { field: t('hour') }))
        .min(0, 'Hour must be at least 0')
        .max(23, 'Hour cannot be greater than 23')
        .required(t('required_field', { field: t('hour') }))
        .integer('Hour must be an integer'),
      minute: yup
        .number()
        .typeError(t('number_required', { field: t('minute') }))
        .min(0, 'Minute must be at least 0')
        .max(59, 'Minute cannot be greater than 59')
        .required(t('required_field', { field: t('minute') }))
        .integer('Minute must be an integer'),
    }),
    to: yup.object({
      hour: yup
        .number()
        .typeError(t('number_required', { field: t('hour') }))
        .min(0, 'Hour must be at least 0')
        .max(23, 'Hour cannot be greater than 23')
        .required(t('required_field', { field: t('hour') }))
        .integer('Hour must be an integer'),
      minute: yup
        .number()
        .typeError(t('number_required', { field: t('minute') }))
        .min(0, 'Minute must be at least 0')
        .max(59, 'Minute cannot be greater than 59')
        .required(t('required_field', { field: t('minute') }))
        .integer('Minute must be an integer'),
    }),
  });

  const defaultValues = {
    label: {
      eng: selectedSlot?.label?.eng || '',
      de: selectedSlot?.label?.de || '',
    },
    from: {
      hour: selectedSlot?.from?.hour ?? null,
      minute: selectedSlot?.from?.minute ?? null,
    },
    to: {
      hour: selectedSlot?.to?.hour ?? null,
      minute: selectedSlot?.to?.minute ?? null,
    },
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'successaddedTerminSlots',
    variant: 'success',
    cb: () => {
      if (addData) {
        const formVals = getValues();
        addLocals({ language: 'eng', label: formVals?.label?.eng, terminSlot: addData?.id });
        reset(defaultValues);
        handleDrawerClose();
      }
    },
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'erroraddedTerminSlots',
    variant: 'error',
  });

  //EDIT TOASTS
  useToastNotification({
    isShow: isEditSuccess,
    loading: isEditLoading,
    messageKey: 'successEditTerminSlots',
    variant: 'success',
    cb: () => {
      if (selectedSlot) {
        const formVals = getValues();
        const foundEng = selectedSlot?.locals?.find((local) => local.language === 'eng');
        const foundDe = selectedSlot?.locals?.find((local) => local.language === 'de');
        if (foundDe && foundEng) {
          addLocals([
            { id: foundEng.id, language: 'eng', label: formVals?.label?.eng, terminSlot: selectedSlot?.id },
            { id: foundDe.id, language: 'de', label: formVals?.label?.de, terminSlot: selectedSlot?.id },
          ]);
        }
        if (foundDe && !foundEng) {
          addLocals([
            { language: 'eng', label: formVals?.label?.eng, terminSlot: selectedSlot?.id },
            { id: foundDe.id, language: 'de', label: formVals?.label?.de, terminSlot: selectedSlot?.id },
          ]);
        }
        if (!foundDe && foundEng) {
          addLocals([
            { id: foundEng.id, language: 'eng', label: formVals?.label?.eng, terminSlot: selectedSlot?.id },
            { language: 'de', label: formVals?.label?.de, terminSlot: selectedSlot?.id },
          ]);
        }
        if (!foundDe && !foundEng) {
          addLocals([
            { language: 'eng', label: formVals?.label?.eng, terminSlot: selectedSlot?.id },
            { language: 'de', label: formVals?.label?.de, terminSlot: selectedSlot?.id },
          ]);
        }
        reset(defaultValues);
        handleDrawerClose();
      }
    },
  });

  useEffect(() => {
    reset(defaultValues);
  }, [selectedSlot]);

  useToastNotification({
    isShow: isEditError,
    loading: isEditLoading,
    messageKey: 'errorEditTerminSlots',
    variant: 'error',
  });

  const onSubmit = (data) => {
    if (selectedSlot && selectedSlot.id) {
      editMutate({ id: selectedSlot.id, data: { from: data?.from, to: data?.to } });
    } else {
      mutate({ language: 'de', from: data?.from, to: data.to, label: data.label?.de });
    }
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    isLoading,
    errors,
    reset,
    defaultValues,
  };
};
