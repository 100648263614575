/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import useUserInfo from '../../hooks/useUserInfo';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useTranslation } from 'react-i18next';
import TransportFormElement from './formComponents/TransportFormElement';
import WorkAddressFormElement from './formComponents/WorkAddressFormElement';
import { useEffect, useState } from 'react';
import CategorySelect from './formComponents/CategorySelect';
import { Controller } from 'react-hook-form';
import StatusSelect from './formComponents/StatusSelect';
import { useNavigate } from 'react-router-dom';
import YesNoInput from '../YesNoInput';
import CustomFormInput from '../commonComponents/CustomFormInput';
import Questions from './Questions/Questions';

const UserInfoForm = () => {
  const navigate = useNavigate();
  const { info, isLoading } = useCurrentUser();
  const [taxValues, setTaxValues] = useState([]);
  const { handleSubmit, onSubmit, control, setValue, watch } = useUserInfo(info);
  const moved = watch('moved');

  const { t } = useTranslation();

  useEffect(() => {
    const taxKeys = {
      estate: false,
      insurance: false,
      receivedAlimente: false,
      payedAlimente: false,
      credit: false,
      bonds: false,
    };

    Object.keys(taxKeys).forEach((key) => {
      const foundKey = taxValues.find((tv) => tv.value === key);
      if (foundKey) {
        setValue(key, true);
      } else {
        setValue(key, false);
      }
    });
  }, [taxValues]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box flex={1}>
      <Box
        gap="20px"
        component="form"
        display="flex"
        flexDirection="column"
        justifyContent="start"
        flexWrap="wrap"
        alignItems="start"
        marginY={2}
        paddingX={3}
        paddingY={2}
        sx={{
          borderRadius: '15px',
          overflow: 'hidden',
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        }}
      >
        <Box display="flex" flexDirection="column" width="100%" my={1}>
          <Typography variant="h7" fontWeight={700} mb="12px">
            {t('family_status')}
          </Typography>
          <Controller
            name="familyStatus"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <StatusSelect error={error} value={field.value} onChange={field.onChange} />
            )}
          />
          <Divider sx={{ mt: 5, mb: 2 }} />
          <Typography variant="h7" fontWeight={700} mb="12px">
            {t('select_the_categories_that_apply_to_you')}
          </Typography>
          <CategorySelect taxValues={taxValues} setTaxValues={setTaxValues} />
          <Divider sx={{ mt: 5, mb: 2 }} />
          <Grid container spacing={1}>
            <Grid item md={4} lg={4} mb={2}>
              <Typography variant="h7" fontWeight={700} mb="12px">
                {t('have_you_moved_last_year')}
              </Typography>
              <YesNoInput control={control} name="moved" padding={'0'} />
            </Grid>
            {moved && (
              <>
                <Grid item md={12} lg={12}>
                  <WorkAddressFormElement name="oldAddress" control={control} label={t('old_address')} type="text" />
                </Grid>
                <Grid item md={6} lg={6}>
                  <CustomFormInput name="livedFrom" control={control} type="date" label={t('lived_from')} />
                </Grid>
                <Grid item md={6} lg={6}>
                  <CustomFormInput name="livedTo" control={control} type="date" label={t('lived_to')} />
                </Grid>
              </>
            )}
          </Grid>
          <Divider sx={{ mt: 5, mb: 2 }} />
          <Typography variant="h7" fontWeight={700}>
            {t('mans_means_of_transport_to_work')}
          </Typography>
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <Box display="flex" flexDirection="column" flexGrow={1} flexWrap="wrap" mt={3} width="100%">
              <Box display="flex" flexDirection="column" alignItems="baseline" gap="12px">
                <WorkAddressFormElement
                  name="manWorkAddress"
                  control={control}
                  label={t('ManWorkAddress')}
                  type="text"
                />

                <TransportFormElement name="manTransport" control={control} />
              </Box>
              <Divider sx={{ mt: 5, mb: 2 }} />
              <Typography variant="h7" fontWeight={700}>
                {t('womans_means_of_transport_to_work')}
              </Typography>
              <Box display="flex" flexDirection="column" flexGrow={1} flexWrap="wrap" mt={3} width="100%">
                <Box display="flex" flexDirection="column" alignItems="baseline" gap="12px" width="100%">
                  <WorkAddressFormElement
                    name="wifeWorkAddress"
                    control={control}
                    label={t('WifeWorkAddress')}
                    type="text"
                  />

                  <TransportFormElement name="wifeTransport" control={control} />
                </Box>
              </Box>
              <Divider sx={{ mt: 5, mb: 2 }} />
            </Box>
          </Box>
          <Questions control={control} t={t} />
          <Box display="flex" width="100%" justifyContent="end" alignItems="start">
            <Button
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              sx={{
                mt: 3,
                bgcolor: '#0F5D66',
                borderRadius: '14px',
                padding: '12px 24px',
                width: 'fit-content',
                flex: 1,
                '&:hover': {
                  bgcolor: '#187D7E',
                },
              }}
            >
              {t('saveChanges')}
            </Button>
          </Box>
          <Box display="flex" width="100%" justifyContent="end" alignItems="start" gap={2}>
            <Button
              variant="contained"
              onClick={() => {
                navigate('/family-members');
              }}
              sx={{
                mt: 2,
                bgcolor: '#23978D',
                borderRadius: '14px',
                padding: '12px 24px',
                width: 'fit-content',
                flex: 1,
                '&:hover': {
                  bgcolor: '#31B099',
                },
              }}
            >
              {t('add_family')}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                navigate('/my_documents/taxyear');
              }}
              sx={{
                mt: 2,
                bgcolor: '#23978D',
                borderRadius: '14px',
                padding: '12px 24px',
                width: 'fit-content',
                flex: 1,
                '&:hover': {
                  bgcolor: '#31B099',
                },
              }}
            >
              {t('continue_tax_documents')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserInfoForm;
