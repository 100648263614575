import { useMutation, useQueryClient } from 'react-query';
import { createArchiveLocalization } from '../api/archive';

const useCreateArchiveLocals = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createArchiveLocalization(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['archives'] });
    },
  });
};

export default useCreateArchiveLocals;
