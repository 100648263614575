export const createVcsContent = (event) => {
  return (
    `BEGIN:VCALENDAR\r\n` +
    `VERSION:2.0\r\n` +
    `PRODID:-//Your Company//Your Product//EN\r\n` +
    `BEGIN:VEVENT\r\n` +
    `UID:${event.id}@digitalesbuero.ch\r\n` +
    `DTSTAMP:${formatDateToIcs(event.dtstamp)}\r\n` +
    `DTSTART:${formatDateToIcs(event.start)}\r\n` +
    `DTEND:${formatDateToIcs(event.end)}\r\n` + // Add this line
    `SUMMARY:${escapeText(event.summary)}\r\n` +
    `DESCRIPTION:${escapeText(event.description)}\r\n` +
    `END:VEVENT\r\n` +
    `END:VCALENDAR`
  );
};

const formatDateToIcs = (dateInput) => {
  const date = new Date(dateInput);
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date');
  }
  return date.toISOString().replace(/-|:|\.\d+Z$/g, '') + 'Z';
};

const escapeText = (text) => {
  return text
    .replace(/\\/g, '\\\\') // Escape backslashes
    .replace(/;/g, '\\;') // Escape semicolons
    .replace(/,/g, '\\,') // Escape commas
    .replace(/\n/g, '\\n'); // Escape newlines
};
