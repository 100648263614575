import { Container, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import NavBarRegister from '../../src/components/navBarRegister/navBarRegister';
import { useTranslation } from 'react-i18next';
import { multilineExtractor } from '../utils';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';

const ReadMore = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const bodyText = multilineExtractor(t('content.second.readmore'));
  return (
    <Box>
      <NavBarRegister />
      <Container maxWidth="xxl" fixed disableGutters sx={{ paddingY: 3, paddingLeft: 3 }}>
        <Typography variant={'h2'} color={'#037A68'} textAlign={'left'} paddingBottom={3}>
          {t('content.second.title')}
        </Typography>
        {bodyText.map((text) => (
          <Typography color={'black'} fontSize={'1.5rem'} lineHeight={'2.5rem'} textAlign={'left'}>
            {text}
          </Typography>
        ))}
        <Button onClick={() => navigate(-1)} sx={{ width: '200px', marginY: 2 }} variant="outlined" color="success">
          <ArrowBackIcon /> {t('Go.Back')}
        </Button>
      </Container>
    </Box>
  );
};
export default ReadMore;
