import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToast } from './useToast';
import { useSignupBroker, useSignupUser } from '../api/auth';
import { differenceInYears } from 'date-fns';

export const useRegister = (source, handleDrawerClose) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { mutateAsync, reset, isLoading } = useSignupUser();
  const { mutate } = useSignupBroker();

  const registerSchema = yup
    .object()
    .shape({
      firstName: yup.string().required(`${t('name.required')}`),
      lastName: yup.string().required(`${t('lastName.required')}`),
      phoneNumber: yup
        .string()
        .min(11, `${t('phone.invalid')}`)
        .matches(/^[\d]+$/, `${t('phone.invalid')}`)
        .required(),
      zipCode: yup
        .string()
        .min(4, `${t('zipCode.required')}`)
        .required(),
      address: yup
        .string()
        .required(`${t('address.required')}`)
        .min(6, `${t('address.min')}`),
      place: yup.string().required(`PlaceRequired`),
      email: yup
        .string()
        .email(`${t('email.invalid')}`)
        .required(`${t('email.required')}`),
      password: yup
        .string()
        .min(8, `${t('password.min.value')}`)
        .required(),
      passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], t('PasswordsMatch')),
      birthdate: yup
        .string()
        .required('DOB is required')
        .test('DOB', 'You must be adult', (value) => {
          return differenceInYears(new Date(), new Date(value)) >= 18;
        }),
      promoCode: yup.string(),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    watch,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      zipCode: '',
      address: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      place: '',
      promoCode: '',
    },
    resolver: yupResolver(registerSchema),
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      if (source === 'AddUserForm') {
        mutate(data);
        handleDrawerClose();
      } else {
        await mutateAsync(data);
        navigate('/auth', {
          replace: true,
          state: { from: 'register', email: data.email, password: data.password },
        });
      }
      reset();
      toast({
        message: t('Successfully.registered'),
        variant: 'success',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
      });
    } catch (err) {
      reset();
      clearErrors();
      switch (err?.response?.status) {
        case 400:
          toast({
            message: t('bad_request'),
            variant: 'error',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
          break;
        case 401:
          toast({
            message: t('unauthorized'),
            variant: 'warning',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
          break;
        case 403:
          toast({
            message: t('forbidden'),
            variant: 'error',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
          break;
        case 404:
          toast({
            message: t('not_found'),
            variant: 'info',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
          break;
        case 409:
          toast({
            message: t('user', { user: data.email }),
            variant: 'error',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
          break;
        case 500:
          toast({
            message: t('server_error'),
            variant: 'error',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
          break;
        case 503:
          toast({
            message: t('service_unavailable'),
            variant: 'warning',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
          break;
        // Add more cases as needed
        default:
          toast({
            message: t('unexpected_error'),
            variant: 'error',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          });
          break;
      }
    }
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    errors,
    buttonDisabled:
      !!errors.firstName ||
      !!errors.lastName ||
      !!errors.phoneNumber ||
      !!errors.zipCode ||
      !!errors.address ||
      !!errors.email ||
      !!errors.password,
    loading: isLoading,
    watch,
  };
};
