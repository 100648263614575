import { Button } from '@mui/material';
import React from 'react';

const iconSource = {
  language: 'icons/lang',
  edit: 'media/edit-2',
  delete: 'media/trash',
  view: 'media/eye',
  download: 'media/arrow-down',
};

const DocumentIconButton = ({ onClick, iconType }) => {
  return (
    <Button
      variant="contained"
      sx={{
        bgcolor: '#EDF1F3',
        color: '#000',
        fontWeight: 700,
        textTransform: 'none',
        borderRadius: '25px',
        width: 'fit-content',
        display: 'flex',
        gap: '10px',
        transition: 'all 100ms ease-in',
        '&:hover img': {
          filter: 'brightness(1000)',
          transition: 'all 100ms ease-out',
        },
      }}
      onClick={onClick}
    >
      <img alt="Menu Icon" src={`https://taxofficestorage.blob.core.windows.net/${iconSource?.[iconType]}.svg`}></img>
    </Button>
  );
};

export default DocumentIconButton;
