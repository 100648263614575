import React from 'react';
import TableComponent from '../../../Table/Table';
import useColumns from './hooks/useColumns';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TableContainer from '../../../Table/TableContainer';

const UserTasks = ({ tasks, isLoading, isFetching, brokers, statuses }) => {
  const columns = useColumns({ brokers, statuses });
  const { t } = useTranslation();
  return (
    <TableContainer>
      <TableComponent
        hasBoxShadow
        hasAboveStuff
        data={tasks || []}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h6" fontWeight={700}>
            {t('Tasks')}
          </Typography>
        </Box>
      </TableComponent>
    </TableContainer>
  );
};

export default UserTasks;
