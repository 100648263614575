import React, { useState } from 'react';
import Title from './components/Title';
import Filters from './components/Filters';
import AssignBroker from '../../../../../AssignBroker';
import { useAssingBroker } from '../../../../../../api/leads';
import useToastNotification from '../../../../../../hooks/useToastNotification';
import { useTranslation } from 'react-i18next';

const Header = ({
  filterTag,
  handleChange,
  MenuProps,
  tags,
  control,
  debouncedHandleSearchChange,
  brokers,
  setRowSelection,
  originalSelectedRows,
}) => {
  const [selectedBroker, setSelectedBroker] = useState();
  const { t } = useTranslation();
  const { mutate, isSuccess, isLoading } = useAssingBroker();
  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'successfuly_updated_leads_broker',
    cb: () => {
      setSelectedBroker(undefined);
      setRowSelection({});
    },
    variant: 'success',
  });
  return (
    <>
      <Title title={t('Leads')} />

      {originalSelectedRows && originalSelectedRows?.length !== 0 ? (
        <AssignBroker
          MenuProps={MenuProps}
          brokers={brokers}
          selectedBroker={selectedBroker}
          setSelectedBroker={setSelectedBroker}
          onMutate={() => {
            if (selectedBroker) {
              mutate({ data: { brokerId: selectedBroker, users: originalSelectedRows?.map((row) => row?.id) } });
            }
          }}
          isLoading={isLoading}
          isBrokerSelected={!!selectedBroker}
        />
      ) : (
        <Filters
          MenuProps={MenuProps}
          control={control}
          debouncedHandleSearchChange={debouncedHandleSearchChange}
          filterTag={filterTag}
          handleChange={handleChange}
          tags={tags}
        />
      )}
    </>
  );
};

export default Header;
