import React from 'react';
import DocumentWidgetsTable from './DocumentWidgetsTable';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetAllDocumentWidgets } from '../../../api/documentWidgets';
import { useGetAllLanguages } from '../../../api/articles';
import LanguageSelect from '../../selectTranslation/translateSelect';

const DocumentWidgets = () => {
  const { t } = useTranslation();
  const { data } = useGetAllDocumentWidgets();
  const { data: languages } = useGetAllLanguages();

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center" px={4}>
          <Typography variant="h6" fontWeight={700} px={5} py={2.45} sx={{ marginLeft: -4, flexGrow: 1 }}>
            {t('DocumentWidgetsHeader')}
          </Typography>
          <LanguageSelect />
        </Box>
        <Divider />
        <Box p={5}>
          <Box display="flex" flexDirection="column">
            <DocumentWidgetsTable data={data} languages={languages} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DocumentWidgets;
