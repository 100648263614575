import { FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BrokerSelect = ({ brokers, selectedBroker, handleChange, MenuProps }) => {
  const { t } = useTranslation();
  return (
    <FormControl sx={{ width: 200 }}>
      <InputLabel id="demo-multiple-checkbox-label">{t('broker')}</InputLabel>
      <Select
        labelid="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        value={selectedBroker || ''}
        onChange={(e) => handleChange(e.target.value)}
        input={<OutlinedInput label="Tag" sx={{ border: '1px solid #DCE4E8', borderRadius: '15px' }} />}
        renderValue={(selectedValue) => {
          const selectedBroker = brokers?.find((broker) => broker?.id === selectedValue);
          const firstName = selectedBroker?.firstName;
          const lastName = selectedBroker?.lastName;
          return `${firstName} ${lastName}`;
        }}
        MenuProps={MenuProps}
      >
        {brokers?.map((broker) => (
          <MenuItem key={broker?.id} value={broker?.id}>
            <ListItemText primary={broker?.firstName + ' ' + broker?.lastName} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BrokerSelect;
