import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import SvgBackground from '../../../assets/backgrounds/leads-page-bg.svg';
import Logo from '../../../assets/Logo';
import { useNavigate } from 'react-router-dom';

const PageBackground = ({ children }) => {
  const navigate = useNavigate();
  const width1200 = useMediaQuery('(min-width:1200px)');
  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: '#F4F4F7', zIndex: -1 }}>
      <Box
        sx={{
          backgroundImage: `url(${SvgBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover', // This stretches the background to fill the container
          minHeight: '85vh',
          width: '100%',
          backgroundPosition: 'center bottom', // Adjust the position as needed
          position: 'fixed',
          zIndex: 1,
        }}
      />
      <Box
        sx={{ position: 'fixed', marginLeft: '60px', zIndex: 1, cursor: 'pointer' }}
        onClick={() => {
          navigate('/');
        }}
      >
        <Logo width={width1200 ? 250 : 150} height={width1200 ? 150 : 75} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingY: { xs: '0px', md: '64px' },
          flexDirection: 'column',
          height: { xs: '100%' },
        }}
      >
        {/* <LanguageDropdown /> */}
        {children}
      </Box>
    </Box>
  );
};

export default PageBackground;
