import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import LeadFormInput from './LeadFormInput';
import LeadCheckerList from './LeadCheckerList';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../../selectTranslation/translateSelect';

const FormContainer = ({ control, handleSubmit, onSubmit, isLoading, isError }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        bgcolor: 'white',
        zIndex: '1001',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '32px',
        gap: '32px',
        borderRadius: { md: '15px' },
        boxShadow: '0px 34px 114px rgba(0, 0, 0, 0.08)',
        width: { xs: '100%', md: '480px' },
        flex: { xs: 1 },
      }}
    >
      <LeadFormInput
        control={control}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        isLoading={isLoading}
        isError={isError}
      />

      <Typography fontWeight="700" color="#094554" align="center">
        {t('TaxProcess')}
      </Typography>

      <LeadCheckerList />
      <Typography sx={{ color: '#31B099', cursor: 'pointer', userSelect: 'none' }}>
        <Link
          href="/impressum"
          target="_blank"
          sx={{
            textDecoration: 'none',
            color: '#31B099',
            ':hover': { textDecoration: 'underline' },
          }}
        >
          {t('Impressum')}
        </Link>
        {' / '}
        <Link
          href="/legal"
          target="_blank"
          sx={{
            textDecoration: 'none',
            color: '#31B099',
            ':hover': { textDecoration: 'underline' },
          }}
        >
          {t('Legal')}
        </Link>
      </Typography>
      <LanguageSelect />
    </Box>
  );
};

export default FormContainer;
