import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TaxSavingButton from './components/TaxSavingButton';

const TaxPotential = ({ name, title, image, file, onOpenDrawer, finishedDocuments }) => {
  const { t } = useTranslation();
  const handleDownload = () => {
    if (window) {
      window.location.href = file;
    }
  };

  const handleButtonClick = () => {
    const widgetData = { name, title, image, file };
    onOpenDrawer(widgetData);
  };

  const handleDownloadFinishedDocs = () => {
    if (window) {
      window.location.href = finishedDocuments;
    }
  };

  return (
    <Card
      sx={{
        m: 1,
        mx: { xs: 0, sm: 1 },
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        border: '0.5px solid #DCE4E8',
        borderRadius: '24px',
        '&:hover': {
          boxShadow: '0px 2px 4px -5px rgba(0, 0, 0, 0.01)',
        },
      }}
    >
      <CardMedia
        component="img"
        height="200px"
        image={image}
        sx={{
          objectFit: 'cover',
          width: '100%',
        }}
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 1,
          padding: '15px',
          gap: '10px',
        }}
      >
        <Box>
          <Typography sx={{ my: 2 }} variant="subtitle1">
            {t('exclusive_customer_benefit')}
          </Typography>
          <Typography sx={{ my: 2 }} gutterBottom variant="h6" fontWeight={900} color="#0F5D66">
            {t('your_tax_saving_potential')}
          </Typography>
        </Box>
        <Box display="flex" gap="10px" width="100%" flexDirection="column">
          <TaxSavingButton
            variant="outlined"
            textKey="finishedDocuments"
            onClick={handleDownloadFinishedDocs}
            isPresent={!!finishedDocuments}
            t={t}
          />
          <TaxSavingButton
            variant="outlined"
            textKey="savingsPotential"
            onClick={handleDownload}
            isPresent={!!file}
            t={t}
          />
          <TaxSavingButton
            variant="contained"
            textKey="MakeAnappointment"
            onClick={handleButtonClick}
            isPresent={true}
            t={t}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default TaxPotential;
