import React from 'react';
import { Box, Typography } from '@mui/material';

const HeaderLogo = ({ title, subtitle }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        py: { xs: '24px', md: '1px' },
        px: { xs: '10px', md: '1px' },
      }}
    >
      <Typography sx={{ color: '#ffffff', fontWeight: '700' }}>{subtitle}</Typography>
      <Typography textAlign="center" color="white" variant="h6" fontWeight="bold">
        {title}
      </Typography>
    </Box>
  );
};

export default HeaderLogo;
