import React from 'react';
import ArchiveTabs from '../ArchiveTabs/ArchiveTabs';
import { Box, Divider } from '@mui/material';

const TopTabs = ({ tabs, activeTab }) => {
  return (
    <>
      <Box display="flex" px={3}>
        {tabs.map((tab, idx) => (
          <ArchiveTabs key={idx} onClick={tab.onClick} label={tab.label} activeTab={activeTab} />
        ))}
      </Box>
      <Divider />
    </>
  );
};

export default TopTabs;
