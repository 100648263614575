import * as yup from 'yup';
import { useCreateFileType } from '../api/fileTypes';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import useToastNotification from './useToastNotification';

export const useAddFileType = (handleDrawerClose, parentType) => {
  const { t } = useTranslation();
  const { mutate, isSuccess, isLoading, isError } = useCreateFileType();

  const addFileTypeSchema = yup.object().shape({
    name_eng: yup.string().required(t('NameEnglishRequired')),
    name_de: yup.string().required(t('NameDeutschRequired')),
    description_eng: yup.string().required(t('NameEnglishRequired')),
    description_de: yup.string().required(t('NameEnglishRequired')),
    icon: parentType ? yup.string() : yup.string().required(t('IconRequired')),
    order: yup.number().required(),
    is_active: yup.boolean().required(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      name_eng: '',
      name_de: '',
      description_de: '',
      description_eng: '',
      icon: '',
      order: 0,
      is_active: true,
      archiveType: '',
    },
    resolver: yupResolver(addFileTypeSchema),
    reValidateMode: 'onChange',
  });

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessfullyAddedFileType',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorAddingFileType',
    variant: 'error',
  });

  const onSubmit = async (data) => {
    if (parentType) {
      delete data.archiveType;
      mutate({ data: { ...data, parentType } });
    } else {
      mutate({ data });
    }
    reset();
    handleDrawerClose();
  };
  return {
    handleSubmit,
    control,
    onSubmit,
    isLoading,
    errors,
  };
};
