/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '../../hooks/useToast';
import useUrlQueryParams from '../../hooks/useUrlQueryParams';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/auth/authActions';
import { signInUser } from '../../api/auth';

export default function OtpForm() {
  const user = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const urlParams = useUrlQueryParams();
  const redirect = urlParams.get('redirect');
  const navigate = useNavigate();
  const otpSchema = yup.object({
    otp: yup.string().min(6, t('otpError')).max(6).required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      otp: '',
    },
    resolver: yupResolver(otpSchema),
  });
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(30);

  const onSubmit = (data) => {
    const newData = { email: state.email, otp: data.otp };
    dispatch(login(newData));
  };

  useEffect(() => {
    if (user.accessToken) {
      if (user.currentUser?.roles.includes('admin')) {
        navigate('/admin/dashboard');
      } else if (user.currentUser?.roles.includes('broker')) {
        navigate('/broker/dashboard');
      } else {
        if (redirect) {
          navigate(redirect, { replace: true });
        } else {
          navigate(`/dashboard`, { replace: true });
        }
      }
    }
  }, [navigate, redirect, user.accessToken, user.currentUser?.roles]);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timer]);

  useEffect(() => {
    if (user.error) {
      toast({
        message: t('wrongOtp'),
        variant: 'error',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
      });
    }
  }, [user.error]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          width="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-evenly"
          gap="20px"
        >
          <Controller
            name="otp"
            control={control}
            rules={{ validate: (value) => value.length === 6 }}
            render={({ field, fieldState }) => (
              <Box>
                <MuiOtpInput
                  sx={{ gap: 1 }}
                  errors={errors.otp}
                  {...field}
                  TextFieldsProps={{
                    inputProps: {
                      inputMode: 'numeric',
                    },
                  }}
                  length={6}
                />
                {errors?.otp && (
                  <Typography variant="caption" align="center" color="error">
                    {errors?.otp?.message}
                  </Typography>
                )}
              </Box>
            )}
          />

          <Button
            variant="contained"
            sx={{
              bgcolor: '#0F5D66',
              '&:hover': {
                backgroundColor: '#187D7E',
              },
              textTransform: 'none',
              borderRadius: '15px',
              paddingY: '12px',
              paddingX: '25px',
              color: 'white',
              width: '100%',
            }}
            type="submit"
            disabled={isSubmitting || loading}
          >
            {t('login')}
          </Button>
        </Box>
      </form>
      <Link
        component="button"
        type="button"
        onClick={() => {
          if (timer === 0) {
            const data = { email: state?.email, password: state?.password };
            signInUser(data);
            setTimer(30);
            toast({
              message: t('ResendOtpSuccess'),
              variant: 'success',
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
          }
        }}
        disabled={!(timer === 0)}
        sx={{
          textDecoration: 'none',
          color: '#0F5D66',
          '&:disabled': {
            color: 'gray',
            cursor: 'not-allowed',
          },
        }}
      >
        {t('resendOtpCode')}
        {!(timer === 0) && (
          <>
            <br />
            {timer}
          </>
        )}
      </Link>
    </>
  );
}
