import { useEffect, useState } from 'react';

const DotProgress = () => {
  const [dots, setDots] = useState(['.']);
  useEffect(() => {
    const intervalId = setInterval(() => {
      dots.length <= 3 && setDots((dots) => ['.', ...dots]);
    }, 700);
    dots.length > 3 && setDots(() => ['.']);
    return () => {
      clearInterval(intervalId);
    };
  }, [dots]);
  return <span>{dots?.map((dot) => `${dot} `)}</span>;
};

export default DotProgress;
