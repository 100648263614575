import { Breadcrumbs, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllBrokers, useGetFilesByUserId, useGetUserById } from '../../../api/user';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import UserFiles from './UserFiles';
import { useGetFamilyMemberByUserId } from '../../../api/familyMembers';
import FamilyMemberForUser from './FamilyMemberForUser';
import { useGetStatusByEntity } from '../../../api/status';
import StatusMenu from '../../StatusMenu';
import { useGetTaxPotential } from '../../../api/taxPotential';
import TaxPotential from './TaxPotential/TaxPotential';
import { useGetAllArchives } from '../../../api/archive';
import FinishedDocuments from './FinishedDocuments/FinishedDocuments';
import { useGetFinishedDocuments } from '../../../api/finishedDocuments';
import { trim } from 'lodash-es';
import { useGetTaskByNameAndArchive, useGetTaskByUserId } from '../../../api/tasks';
import { useGetAllFileTypes } from '../../../api/fileTypes';
import UserNotes from './Notes/UserNotes';
import { useUserTermins } from '../../../api/termins';
import Journal from './Journal';
import UserTasks from './UserTasks';
import PersonalInfo from './PersonalInfo';

const SingleUser = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const [archiveTaxPotential, setArchiveTaxPotential] = useState();
  const [archiveFinishedDocuments, setArchiveFinishedDocuments] = useState();
  const [archiveTask, setArchiveTask] = useState();

  const { data: familyMembers } = useGetFamilyMemberByUserId(id);
  const { data: statuses } = useGetStatusByEntity('users');
  const { data: taskStatuses } = useGetStatusByEntity('tasks');
  const { data: userData } = useGetUserById(id);
  const { data: files } = useGetFilesByUserId(id);
  const { data: archives } = useGetAllArchives();
  const { data: allFileTypes } = useGetAllFileTypes();
  const { data: task } = useGetTaskByNameAndArchive(id, archiveTask);
  const { data: taxPotential } = useGetTaxPotential({ user: id, archive: archiveTaxPotential });
  const { data: tasks, isLoading: isTasksLoading, isFetching: isTasksFetching } = useGetTaskByUserId(id);
  const { data: finishedDocuments } = useGetFinishedDocuments({
    user: id,
    archive: archiveFinishedDocuments,
    enabled: !!archiveFinishedDocuments,
  });
  const { data: brokers } = useGetAllBrokers();

  const { data: userTermins, isLoading: isLoadingTermins, isFetching: isFetchingTermins } = useUserTermins(id);

  useEffect(() => {
    setArchiveTaxPotential(archives?.find((archive) => trim(archive?.name) === (currentYear - 1).toString())?.id);
    setArchiveFinishedDocuments(archives?.find((archive) => trim(archive?.name) === (currentYear - 1).toString())?.id);
  }, [archives, currentYear]);

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Typography textAlign={{ xs: 'center', sm: 'left' }} variant="h6" fontWeight={700} px={5} py={2.45}>
          {userData?.data.firstName} {userData?.data.lastName}
        </Typography>
        <Divider />
        <Box display="flex" flexDirection="column" gap="32px" m={3}>
          <Box display="flex" justifyContent="space-between">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <Link onClick={() => navigate(-1)} style={{ textDecoration: 'none' }}>
                <Typography variant="body1" color="#1A1C1E">
                  {t('Users')}
                </Typography>
              </Link>
              <Typography variant="body1" color="#1A1C1E">
                {userData?.data.firstName}
              </Typography>
            </Breadcrumbs>
            <StatusMenu data={userData?.data} statuses={statuses} i18n={i18n} />
          </Box>

          <Box display="flex" flexDirection="row" gap="20px">
            <Box display="flex" flexDirection="column" gap="32px" width="50%">
              <FamilyMemberForUser data={familyMembers} />
              <PersonalInfo data={userData?.data} />
            </Box>
            <Box display="flex" width="50%" flexDirection="column" gap="32px">
              <UserFiles
                username={`${userData?.data?.firstName} ${userData?.data?.lastName}`}
                files={files?.data}
                email={userData?.data?.email}
                statuses={taskStatuses}
                userId={id}
                task={task}
                taxYear={archiveTask}
                setTaxYear={setArchiveTask}
                archives={archives}
                allFileTypes={allFileTypes}
              />
              <FinishedDocuments
                finishedDocuments={finishedDocuments}
                archives={archives}
                user={id}
                taxYear={archiveFinishedDocuments}
                setTaxYear={setArchiveFinishedDocuments}
              />
              <TaxPotential
                taxPotential={taxPotential}
                archives={archives}
                user={id}
                taxYear={archiveTaxPotential}
                setTaxYear={setArchiveTaxPotential}
              />
            </Box>
          </Box>
          <UserNotes id={id} />
          <Journal id={id} data={userTermins} isLoading={isLoadingTermins} isFetching={isFetchingTermins} />
          <UserTasks
            tasks={tasks}
            brokers={brokers}
            statuses={taskStatuses}
            isLoading={isTasksLoading}
            isFetching={isTasksFetching}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SingleUser;
