import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import TableComponent from '../../Table/Table';
import useArchiveTypeColumns from '../../../hooks/AllUsers/useArchiveTypeColumns';
import { useState } from 'react';
import CustomDrawer from '../../modals/CustomDrawer';
import CloseIcon from '@mui/icons-material/Close';
import ArchiveTypeSettings from './ArchiveTypeSettings';
import { useTranslation } from 'react-i18next';
import VerifyLoader from '../../verifyLoader/VerifyLoader';
import EditArchiveTypeForm from './EditArchiveTypeForm';
import SubmitButton from '../../commonComponents/SubmitButton';

const ArchiveTypeTable = ({ icons, archiveTypes, isLoadingArchiveTypes }) => {
  const { t } = useTranslation();

  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [editArchiveTypeId, setEditArchiveTypeId] = useState(null);

  const handleOpen = () => {
    setOpenAddDrawer(!openAddDrawer);
  };

  const handleOpenEdit = (id) => {
    setOpenEditDrawer(!openEditDrawer);
    if (typeof id === 'string') {
      setEditArchiveTypeId(id);
    } else {
      setEditArchiveTypeId(null);
    }
  };
  const columns = useArchiveTypeColumns(handleOpenEdit);

  return (
    <>
      <Box m={5}>
        {isLoadingArchiveTypes ? (
          <Box width="100%" display="flex" justifyContent="center">
            <VerifyLoader />
          </Box>
        ) : (
          archiveTypes && (
            <TableComponent hasAboveStuff data={archiveTypes} columns={columns}>
              <SubmitButton onClick={handleOpen} buttonText={t('AddNew')} backgroundColor="#31B099" color="inherit" />
            </TableComponent>
          )
        )}
      </Box>
      {/* Add Drawer */}
      <CustomDrawer open={openAddDrawer} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddArchiveType')}
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <ArchiveTypeSettings handleDrawerClose={handleOpen} icons={icons} />
      </CustomDrawer>

      {/* Edit Drawer */}
      <CustomDrawer open={openEditDrawer} handleOpen={handleOpenEdit}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('EditArchiveType')}
          </Typography>
          <IconButton onClick={handleOpenEdit}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <EditArchiveTypeForm id={editArchiveTypeId} handleDrawerClose={handleOpenEdit} icons={icons} />
      </CustomDrawer>
    </>
  );
};

export default ArchiveTypeTable;
