/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TableComponent from '../../../Table/Table';
import { useTranslation } from 'react-i18next';
import useTaxPotentialColumns from '../../../../hooks/AllUsers/useTaxPotentialColumns';
import { useForm } from 'react-hook-form';
import { trim } from 'lodash-es';
import { useCreateTaxPotential, useDeleteTaxPotential } from '../../../../api/taxPotential';
import UploadFileButton from '../../../commonComponents/UploadFileButton/UploadFileButton';
import DeleteModal from '../../../modals/DeleteModal';
import useToastNotification from '../../../../hooks/useToastNotification';
import TaxYearSelector from '../../../commonComponents/TaxYearSelector';
import TableContainer from '../../../Table/TableContainer';

const TaxPotential = ({ taxPotential, archives, user, taxYear, setTaxYear }) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const { register, control } = useForm();
  const [progress, setProgress] = useState();
  const [taxPotentialId, setTaxPotentialId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  const { mutate, isLoading, isSuccess, isError } = useCreateTaxPotential(setProgress);

  const {
    mutate: deleteTaxPotential,
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
    isError: isErrorDelete,
  } = useDeleteTaxPotential();

  const handleDeleteModal = (id) => {
    setOpenDelete(!openDelete);
    if (typeof id === 'string') {
      setTaxPotentialId(id);
    } else {
      setTaxPotentialId(null);
    }
  };
  const handleDelete = () => {
    deleteTaxPotential(taxPotentialId);
    setOpenDelete(!openDelete);
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setProgress(null);
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    setTaxYear(archives?.find((archive) => trim(archive?.name) === (currentYear - 1).toString())?.id);
  }, [archives]);

  const handleUploadSubmit = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user', user);
    formData.append('archive', taxYear);

    mutate(formData);
  };

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessfullyAddedTaxPotential',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorAddingTaxPotential',
    variant: 'error',
  });

  useToastNotification({
    isShow: isSuccessDelete,
    loading: isLoadingDelete,
    messageKey: 'SuccessfullyDeletedTaxPotential',
    variant: 'success',
  });

  useToastNotification({
    isShow: isErrorDelete,
    loading: isLoadingDelete,
    messageKey: 'ErrorDeleteingTaxPotential',
    variant: 'error',
  });

  const columns = useTaxPotentialColumns(handleDeleteModal);

  return (
    <>
      <TableContainer>
        <TableComponent
          hasBoxShadow
          hasAboveStuff
          data={Array.isArray(taxPotential) ? taxPotential : [taxPotential]}
          columns={columns}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" px={1} width="100%">
            <Typography variant="h6">{t('taxPotential')}</Typography>
            {taxYear && <TaxYearSelector taxYear={taxYear} setTaxYear={setTaxYear} archives={archives} />}

            <UploadFileButton
              onSubmit={handleUploadSubmit}
              progress={isLoading ? progress : undefined}
              register={register}
              control={control}
              props
            />
          </Box>
        </TableComponent>
      </TableContainer>

      {/* Delete Modal */}
      <DeleteModal
        handleDeleteModal={handleDeleteModal}
        openDelete={openDelete}
        headingText={t('DeleteTaxPotentinal')}
        bodyText={t('AreYouSureDeleteTaxPotential')}
        handleDelete={() => handleDelete(taxPotentialId)}
      />
    </>
  );
};

export default TaxPotential;
