import { useEffect, useRef } from 'react'; // Import useRef
import { useTranslation } from 'react-i18next';
import { useToast } from './useToast';

const useToastNotification = ({ isShow, loading, messageKey, variant, cb }) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const hasShownRef = useRef(false);

  useEffect(() => {
    if (isShow && !loading && !hasShownRef.current) {
      toast({
        variant: variant,
        message: t(messageKey),
      });

      if (cb) {
        cb();
      }

      hasShownRef.current = true;
    }

    if (!isShow) {
      hasShownRef.current = false;
    }
  }, [isShow, loading, messageKey, variant, cb, t, toast]);
};

export default useToastNotification;
