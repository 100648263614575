import { useState } from 'react';
import useToastNotification from '../../../../../hooks/useToastNotification';
import Title from './Title';
import Filters from './Filter';
import AssignBroker from '../../../../AssignBroker';
import { useAssingBrokerBulk } from '../../../../../api/tasks';

const Header = ({
  filterTag,
  handleChange,
  MenuProps,
  tags,
  control,
  debouncedHandleSearchChange,
  brokers,
  setRowSelection,
  originalSelectedRows,
}) => {
  const [selectedBroker, setSelectedBroker] = useState();
  const { mutate, isSuccess, isLoading } = useAssingBrokerBulk();
  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'successfuly_updated_tasks_broker',
    cb: () => {
      setSelectedBroker(undefined);
      setRowSelection({});
    },
    variant: 'success',
  });
  return (
    <>
      <Title />

      {originalSelectedRows && originalSelectedRows?.length !== 0 ? (
        <AssignBroker
          MenuProps={MenuProps}
          brokers={brokers}
          selectedBroker={selectedBroker}
          setSelectedBroker={setSelectedBroker}
          isBrokerSelected={selectedBroker?.length}
          onMutate={() => {
            if (selectedBroker.length) {
              mutate({ data: { brokerId: selectedBroker, taskId: originalSelectedRows?.map((row) => row?.id) } });
            }
          }}
        />
      ) : (
        <Filters
          MenuProps={MenuProps}
          control={control}
          debouncedHandleSearchChange={debouncedHandleSearchChange}
          filterTag={filterTag}
          handleChange={handleChange}
          tags={tags}
        />
      )}
    </>
  );
};

export default Header;
