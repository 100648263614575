import { useMutation, useQuery, useQueryClient } from 'react-query';
import { customInstance } from './client';
import useChangeEffect from '../hooks/useChangeEffect';

const suffix = '/articles';
const suffix2 = '/article-localizations';

const getAllArticlesByLang = (params) => {
  return customInstance({
    url: `${suffix}/${params.lang}`,
    method: 'get',
    params,
  });
};

export const useGetAllArticles = (params) => {
  const query = useQuery(['articles'], () => getAllArticlesByLang(params));
  useChangeEffect(() => {
    if (params) {
      query.refetch();
    }
  }, [JSON.stringify(params)]);

  return query;
};

const createArticle = (data) => {
  return customInstance({
    url: `${suffix}`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const useCreateArticle = () => {
  const queryClient = useQueryClient();
  return useMutation(({ data }) => createArticle(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['articles'] });
    },
  });
};

const getArticlesById = (lang, id) => {
  return customInstance({
    url: `${suffix}/${lang}/${id}`,
    method: 'get',
  });
};

export const useGetArticlesById = (lang, id) => {
  const query = useQuery(['article'], () => getArticlesById(lang, id));
  return query;
};

const getLanguages = () => {
  return customInstance({
    url: `/languages`,
    method: 'get',
  });
};

export const useGetAllLanguages = () => {
  const query = useQuery(['languages'], () => getLanguages());
  return query;
};

const createArticleLocalization = (data) => {
  return customInstance({
    url: `${suffix2}`,
    method: 'post',
    data,
  });
};

export const useCreateArticleLocalization = () => {
  const queryClient = useQueryClient();
  return useMutation(({ data }) => createArticleLocalization(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['article-localizations'] });
    },
  });
};
