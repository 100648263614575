import Box from '@mui/material/Box';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useArchiveType } from '../../../hooks/useArchiveType';
import SelectIcon from '../../SelectIcon';
import SubmitButton from '../../commonComponents/SubmitButton';
import CustomFormInput from '../../commonComponents/CustomFormInput';

const ArchiveTypeSettings = ({ handleDrawerClose, icons }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, onSubmit } = useArchiveType(handleDrawerClose);
  return (
    <Box display="flex" flexDirection="column">
      <CustomFormInput name="name_eng" control={control} label={t('english_label')} />
      <CustomFormInput name="name_de" control={control} label={t('deutsch_label')} />
      <Controller
        name="icon"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <SelectIcon icons={icons} onClick={(item) => onChange(item.url)} />
        )}
      />
      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default ArchiveTypeSettings;
