import React from 'react';
import Box from '@mui/material/Box';
import LoginLayout from './loginForm';
import { Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LanguageSelect from '../selectTranslation/translateSelect';
import Background from '../commonComponents/Background';

export default function BasicTabs() {
  const { t } = useTranslation();
  const today = new Date();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" bgcolor="#1D2735" width="100%" height="100%">
      <Background
        src="https://taxofficestorage.blob.core.windows.net/media/loginBackground.svg"
        alt="Login background"
      />

      <Box
        width="100%"
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        zIndex={300}
      >
        <Paper
          sx={{
            zIndex: '1000',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '32px',
            gap: '32px',
            borderRadius: '15px',
            boxShadow: '0px 34px 114px rgba(0, 0, 0, 0.08)',
            width: { xs: '90%', sm: '50%', md: '480px' },
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" width="100%">
            <Typography variant="h4" textAlign="center">
              {t('letsLogin')}
            </Typography>
          </Box>
          <Box width="100%" display="flex" flexDirection="column" justifyContent="center" padding={0} gap="32px">
            <LoginLayout />
          </Box>
        </Paper>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="5px"
          paddingY={'30px'}
        >
          <Typography variant="body2" fontWeight={400} color="#fff">
            {t('dontHaveAcc')}
          </Typography>
          <Link to={'/auth/register'} style={{ textDecoration: 'none' }}>
            <Typography variant="body2" fontWeight={400} color="#31B099">
              {t('regHere')}
            </Typography>
          </Link>
        </Box>
        <Box position={'relative'} bottom={0} paddingBottom={1}>
          <Typography variant="subtitle1" color="#fff" textAlign="center">
            &copy; {today.getFullYear()} Digitalesbüro.ch -{' '}
            <a
              href="/impressum"
              target="_blank"
              rel="noopener noreferrer"
              style={{ borderBottom: '1px solid', textDecoration: 'none', color: '#31B099' }}
            >
              {t('Impressum')}
            </a>
          </Typography>
        </Box>
        <LanguageSelect />
      </Box>
    </Box>
  );
}
