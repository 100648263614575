import { useQuery } from 'react-query';
import { axiosClient, customInstance } from '../api/client';
const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL : 'http://localhost:5001';

export const getOwnFiles = (fileType) => {
  return customInstance({
    url: `/files/${fileType}`,
    method: 'get',
  });
};

export const getFileTypes = ({ ...params }) => {
  return customInstance({
    url: `/files/file-types`,
    method: 'get',
    params: { ...params },
  });
};

export const getFileTypeChildren = (parentId) =>
  customInstance({
    url: `/files/file-types/childrens/${parentId}`,
    method: 'get',
  });

export const getFileTypesByArchiveType = (archiveType) =>
  customInstance({
    url: `/files/file-types/archive-type/${archiveType}`,
    method: 'get',
  });

export const deleteFile = (fileId) => {
  return customInstance({
    url: `/v1/users/upload/${fileId}`,
    method: 'delete',
  });
};

export const createFile = (data, trackProgress) => {
  return axiosClient.post(`${BASE_URL}/v1/users/upload`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (data) => {
      trackProgress(Math.round((100 * data.loaded) / data.total));
    },
  });
};

const getFilesByArchiveID = (userId, archiveId) => {
  return customInstance({
    url: `/files/${userId}/${archiveId}`,
    method: 'get',
  });
};

export const useGetFilesByArchiveId = (userId, archiveId) => {
  return useQuery(['files', userId, archiveId], () => getFilesByArchiveID(userId, archiveId), {
    enabled: !!userId && !!archiveId,
  });
};
