import React from 'react';
import SelectFilterField from './components/SelectFilterField';
import SearchInput from './components/SearchInput';
import ExportButton from './components/ExportButton';
import { Box } from '@mui/material';
import useExportLeads from '../../../../../../../../hooks/useExportLeads';

const Filters = ({ filterTag, handleChange, MenuProps, tags, control, debouncedHandleSearchChange }) => {
  const { mutate } = useExportLeads();
  return (
    <Box display="flex" gap="20px" alignItems="center">
      <SelectFilterField MenuProps={MenuProps} filterTag={filterTag} handleChange={handleChange} tags={tags} />
      <SearchInput control={control} debouncedHandleSearchChange={debouncedHandleSearchChange} />
      <ExportButton
        onClick={() => {
          mutate({
            data: [
              {
                containertypeid: 'neo_db_lead_100',
                contenttype: 'application/json',
                containerdata: {
                  externalId: '2676ce26-522c-4df1-baac-b8e23a39751c',
                  topic: 'Test #7638 27.10 14:10',
                  createdOn: '2024-02-08T15:26:12.937Z',
                  description: 'Steuer Lead',
                  status: 'Neu',
                  potentialCustomer: {
                    firstname: 'user',
                    lastname: '',
                    gender: '',
                    birthdate: '1994-11-10',
                    nationality: '',
                    language: '',
                    mobilephone: null,
                    mainphone: '123123123',
                    email: 'mirsat2@dobi.mk',
                    npa: '6339',
                    city: '',
                    street: 's.Labunishta',
                    houseNumber: '',
                  },
                  'containerdata--encoding': '',
                  remote_uid: '',
                },
              },
            ],
          });
        }}
      />
    </Box>
  );
};

export default Filters;
