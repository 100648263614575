import React from 'react';
import BrokerSelect from './components/BrokerSelect';
import { Box, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AssignBroker = ({
  brokers,
  MenuProps,
  onMutate,
  selectedBroker,
  setSelectedBroker,
  isLoading,
  isBrokerSelected,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <BrokerSelect
        MenuProps={MenuProps}
        selectedBroker={selectedBroker}
        brokers={brokers}
        handleChange={setSelectedBroker}
      />
      <Button
        onClick={() => {
          if (onMutate) {
            onMutate();
          }
        }}
        variant="contained"
        sx={{
          height: '100%',
          borderRadius: 4,
          backgroundColor: '#0F5D66',
          padding: '20px 40px',
          '&:hover,&:focus': { backgroundColor: '#31B099' },
        }}
        disabled={isLoading || !isBrokerSelected}
      >
        {isLoading ? <CircularProgress size={24} color="inherit" /> : t('assign')}
      </Button>
    </Box>
  );
};

export default AssignBroker;
