import React from 'react';
import { Box, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import PaymentCard from './Cards/PaymentCard';

const PaymentMethod = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      justifyContent="space-evenly"
      borderRadius="16px"
      marginTop="30px"
      marginBottom="30px"
      marginLeft="15px"
      alignContent="center"
      gap={2}
      sx={{
        background: 'white',
      }}
    >
      <Typography variant="h5" fontWeight={700}>
        {t('PaymentOptions')}
      </Typography>
      <Box width="100%" display="flex" flexWrap="wrap" gap={3}>
        <PaymentCard src={'https://taxofficestorage.blob.core.windows.net/media/Twint.png'} />
        <PaymentCard src={'https://taxofficestorage.blob.core.windows.net/media/Rechnung.png'} />
        <PaymentCard src={'https://taxofficestorage.blob.core.windows.net/media/Barzahlung.png'} />
      </Box>
    </Box>
  );
};

export default PaymentMethod;
