import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6" textAlign="center" color="#1C2634">
        {t('one_more_step')}
      </Typography>
      <Typography variant="body1" fontWeight={700} textAlign="center" color="#1C2634">
        {t('fill_the_form')}
      </Typography>
    </>
  );
};

export default FormHeader;
