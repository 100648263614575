/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useCreateArticle } from '../api/articles';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import useToastNotification from './useToastNotification';

export const useAddArticle = (files, setImageName, setEditorState, EditorState) => {
  const { t } = useTranslation();
  const { mutate, isSuccess, isLoading, isError } = useCreateArticle();
  const navigate = useNavigate();

  const schema = yup
    .object()
    .shape({
      title: yup.string().required(t('titleRequired')),
      shortDescription: yup.string().required(t('shortDescriptionRequired')),
      text: yup.string().required(t('textRequired')),
      language: yup.string().required(t('languageRequired')),
      banner: yup.mixed().required(t('bannerRequired')),
      archive: yup.string().required(t('archiveRequired')),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      title: '',
      shortDescription: '',
      text: '',
      language: '',
      archive: '',
      banner: null,
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'successAddedArticle',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'errorAddingArticle',
    variant: 'error',
  });

  const onSubmit = (data) => {
    const formData = new FormData();

    formData.append('title', data.title);
    formData.append('shortDescription', data.shortDescription);
    formData.append('banner', files.image);
    formData.append('archives', data.archive);
    formData.append('language', data.language);
    formData.append('text', data.text);

    mutate({ data: formData });
    reset();
    setImageName('');
    setEditorState(EditorState.createEmpty());
    navigate(-1);
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    isLoading,
    errors,
    register,
  };
};
