import { useNavigate, useParams } from 'react-router-dom';
import { useGetByTaskId, useUpdateTaskStatus } from '../../api/tasks';
import { useTranslation } from 'react-i18next';
import { Box, Breadcrumbs, Divider, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';
import FamilyMemberForUser from '../adminDashboard/users/FamilyMemberForUser';
import PersonalInfo from '../adminDashboard/users/PersonalInfo';
import { useGetFilesByArchiveId } from '../../services/files';
import BrokerUserFiles from './BrokerUserFiles';
import { useGetStatusByEntity } from '../../api/status';
import StatusMenu from '../StatusMenu';

const BrokerTaskData = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { data } = useGetByTaskId(id);
  const { data: files } = useGetFilesByArchiveId(data?.user?.id, data?.archive?.id);
  const { data: statuses } = useGetStatusByEntity('tasks');
  const { mutate } = useUpdateTaskStatus();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} minHeight="100vh" width="100%">
      <Typography textAlign={{ xs: 'center', sm: 'left' }} variant="h6" fontWeight={700} px={5} py={2.45}>
        {data?.user?.firstName} {data?.user?.lastName}
      </Typography>
      <Divider />
      <Box display="flex" flexDirection="column" gap="32px" m={3}>
        <Box display="flex" justifyContent="space-between">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link onClick={() => navigate(-1)} style={{ textDecoration: 'none' }}>
              <Typography variant="body1" color="#1A1C1E">
                {t('Tasks')}
              </Typography>
            </Link>
            <Typography variant="body1" color="#1A1C1E">
              {data?.user?.firstName}
            </Typography>
          </Breadcrumbs>
          <StatusMenu data={data} statuses={statuses} mutate={mutate} i18n={i18n} />
        </Box>
        <Box display="flex" flexDirection="row" gap="20px">
          <Box display="flex" flexDirection="column" gap="32px" width="60%">
            <FamilyMemberForUser data={data?.user?.familyMembers} />
            <PersonalInfo data={data?.user} />
          </Box>
          <Box display="flex" width="40%">
            <BrokerUserFiles username={`${data?.user?.firstName} ${data?.user?.lastName}`} files={files} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BrokerTaskData;
