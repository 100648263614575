import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import React from 'react';

const AppointmentContainer = ({ title, children }) => {
  return (
    <Card elevation={2} sx={{ borderRadius: '15px', m: 5 }}>
      <CardContent>
        <Typography variant="h6" fontWeight={900}>
          {title}
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Box display="flex" flexDirection="column" gap="20px">
          {children}
        </Box>
      </CardContent>
    </Card>
  );
};

export default AppointmentContainer;
