import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetArticlesById } from '../api/articles';
import { Box, Divider, Typography } from '@mui/material';

const ArticleView = () => {
  const { lang, id } = useParams();
  const { data } = useGetArticlesById(lang, id);

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" px={1} py={2.45}>
          <Typography variant="h6" fontWeight={700} px={3}>
            {data?.title}
          </Typography>
        </Box>
        <Divider />
        <Box px={3}>
          <Box display="flex" flexDirection="column" dangerouslySetInnerHTML={createMarkup(data?.text)}></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ArticleView;
