import { SvgIcon } from '@mui/material';
import React from 'react';

const FilledCheckIcon = (props) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 16C6.54167 15.9792 5.20833 15.625 4 14.9375C2.79167 14.2292 1.8125 13.25 1.0625 12C0.354167 10.7292 0 9.39583 0 8C0 6.60417 0.354167 5.27083 1.0625 4C1.8125 2.75 2.79167 1.77083 4 1.0625C5.20833 0.375 6.54167 0.0208333 8 0C9.45833 0.0208333 10.7917 0.375 12 1.0625C13.2083 1.77083 14.1875 2.75 14.9375 4C15.6458 5.27083 16 6.60417 16 8C16 9.39583 15.6458 10.7292 14.9375 12C14.1875 13.25 13.2083 14.2292 12 14.9375C10.7917 15.625 9.45833 15.9792 8 16ZM11.5312 6.53125C11.8229 6.17708 11.8229 5.82292 11.5312 5.46875C11.1771 5.17708 10.8229 5.17708 10.4688 5.46875L7 8.9375L5.53125 7.46875C5.17708 7.17708 4.82292 7.17708 4.46875 7.46875C4.17708 7.82292 4.17708 8.17708 4.46875 8.53125L6.46875 10.5312C6.82292 10.8229 7.17708 10.8229 7.53125 10.5312L11.5312 6.53125Z"
        fill={props?.fill || '#23978D'}
      />
    </SvgIcon>
  );
};

export default FilledCheckIcon;
