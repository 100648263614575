import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import ArticleLocalizationForm from './ArticleLocalizationForm';
import { useTranslation } from 'react-i18next';

const ArticleLocalization = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" px={1} py={2.45}>
          <Typography variant="h6" fontWeight={700} px={3}>
            {t('articleLocalization')}
          </Typography>
        </Box>
        <Divider />
        <Box p={5}>
          <Box display="flex" flexDirection="column">
            <ArticleLocalizationForm />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ArticleLocalization;
