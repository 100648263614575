import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import Footer from '../components/homeComponent/Footer';
import NavBarHome from '../components/navBar/navBarHome';
import { useTranslation } from 'react-i18next';

const Impressum = () => {
  const { t } = useTranslation();

  return (
    <>
      <NavBarHome />
      <Box display="flex" justifyContent="center">
        <>
          <Container maxWidth="xxl" fixed disableGutters sx={{ paddingY: 10, paddingX: 3 }}>
            <Typography variant="h4" color={'#037A68'} textAlign={'center'} py="30px">
              {t('Impressum')}
            </Typography>
            <Typography variant="h6">Kontakt-Adresse</Typography>
            <Typography>
              Digitalesb&uuml;ro.ch GmbH
              <br />
              Dorfstrasse 10
              <br />
              8310 Grafstal
              <br />
              Schweiz
            </Typography>
            <Typography>
              E-Mail:
              <br />
              info@digitalesbuero.ch
            </Typography>
            <br />
            <br />
            <Typography>
              <strong>
                <i>Vertretungsberechtigte Person(en)</i>
              </strong>
            </Typography>
            Ilir Zekiri, Gesch&auml;fsf&uuml;hrer
            <br />
            <br />
            <Typography>
              <strong>
                <i>Handelsregister-Eintrag</i>
              </strong>
            </Typography>
            Eingetragener Firmenname:
            <br />
            <strong>Digitalesb&uuml;ro.ch GmbH</strong>
            <br />
            <br />
            Unternehmens-Nr (UID):
            <br />
            <strong>CHE-411.947.532</strong>
            <br />
            <br />
            <div>
              Mehrwertsteuer-Nummer
              <br />
              <strong>CHE-411.947.532 MWST</strong>
              <br />
              <br />
              <br />
              <Typography variant="h6">Haftungsausschluss</Typography>
              Der Autor &uuml;bernimmt keinerlei Gew&auml;hr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit,
              Aktualit&auml;t, Zuverl&auml;ssigkeit und Vollst&auml;ndigkeit der Informationen.
            </div>
            <Typography>
              Haftungsanspr&uuml;che gegen den Autor wegen Sch&auml;den materieller oder immaterieller Art, welche aus
              dem Zugriff oder der Nutzung bzw. Nichtnutzung der ver&ouml;ffentlichten Informationen, durch Missbrauch
              der Verbindung oder durch technische St&ouml;rungen entstanden sind, werden ausgeschlossen.
            </Typography>
            <Typography>
              Alle Angebote sind unverbindlich. Der Autor beh&auml;lt es sich ausdr&uuml;cklich vor, Teile der Seiten
              oder das gesamte Angebot ohne besondere Ank&uuml;ndigung zu ver&auml;ndern, zu erg&auml;nzen, zu
              l&ouml;schen oder die Ver&ouml;ffentlichung zeitweise oder endg&uuml;ltig einzustellen.
            </Typography>
            <br />
            <br />
            <Typography variant="h6">Haftungsausschluss f&uuml;r Links</Typography>
            <Typography>
              Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Es wird
              jegliche Verantwortung f&uuml;r solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten
              erfolgen auf eigene Gefahr des jeweiligen Nutzers.
            </Typography>
            <br />
            <br />
            <Typography variant="h6">Urheberrechte</Typography>
            <Typography>
              Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf dieser Website,
              geh&ouml;ren ausschliesslich <strong>der Firma Digitalesb&uuml;ro.ch GmbH</strong> oder den speziell
              genannten Rechteinhabern. F&uuml;r die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung des
              Urheberrechtstr&auml;gers im Voraus einzuholen.
            </Typography>
            <br />
            Quelle:{' '}
            <a href="https://dev.swissanwalt.ch" target="_blank" rel="noopener noreferrer">
              SwissAnwalt
            </a>
          </Container>
        </>
      </Box>
      <Footer />
    </>
  );
};

export default Impressum;
