import { useMutation, useQuery, useQueryClient } from 'react-query';
import { customInstance } from './client';
import useChangeEffect from '../hooks/useChangeEffect';
import axios from 'axios';

const suffix = '/leads';

export const getAllLeads = (params) => {
  return customInstance({
    url: `${suffix}`,
    method: 'get',
    params,
  });
};

export const useGetAllLeads = (params) => {
  const query = useQuery(['leads'], () => getAllLeads(params));

  useChangeEffect(() => {
    if (params) {
      query.refetch();
    }
  }, [JSON.stringify(params)]);

  return query;
};

export const deleteLead = async (id) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'delete',
  });
};

export const useDeleteLead = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id }) => deleteLead(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });
    },
  });
};

const getLeadbyId = (id) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'get',
  });
};

export const useGetLeadById = (id) => {
  return useQuery(['leads', id], () => getLeadbyId(id), { enabled: !!id });
};

const getNotesByLeadId = (params) => {
  return customInstance({
    url: `lead-notes/${params?.id}`,
    method: 'get',
    params,
  });
};

export const useGetNotesByLead = (params) => {
  const query = useQuery(['lead-notes'], () => getNotesByLeadId(params));

  useChangeEffect(() => {
    if (params) {
      query.refetch();
    }
  }, [JSON.stringify(params)]);

  return query;
};

const createLead = (data) =>
  customInstance({
    url: suffix,
    method: 'post',
    data,
  });

export const useCreateLead = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createLead(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });
    },
  });
};

const updateLead = (id, data) => {
  return customInstance({
    url: `${suffix}/update/${id}`,
    method: 'put',
    data,
  });
};

const assingBroker = (data) => customInstance({ url: `${suffix}/assign-broker`, method: 'put', data });

export const useAssingBroker = () => {
  const queryClient = useQueryClient();
  return useMutation(({ data }) => assingBroker(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });
    },
  });
};

export const useUpdateLead = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateLead(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });
    },
  });
};

export const exportLeadsToNeoSana = (data) => {
  const token = btoa(`${process.env.REACT_APP_NEOSANA_USER}:${process.env.REACT_APP_NEOSANA_USER_PASSWORD}`);
  return axios.post(`https://sor.neo.swiss:553/sda/in/addrecord`, data, {
    headers: { Authorization: `Basic ${token}` },
  });
};

const getLeadsByBroker = () => (params) => {
  return customInstance({
    url: `${suffix}/broker/leads`,
    method: 'get',
    params,
  });
};

export const useGetAllLeadsByBroker = (params) => {
  const query = useQuery(['leads'], async () => {
    const data = await getLeadsByBroker()(params);
    return data;
  });

  useChangeEffect(() => {
    if (params) {
      query.refetch();
    }
  }, [JSON.stringify(params)]);

  return query;
};
