import React, { useState } from 'react';
import { useGetAllFamilyRelations } from '../../../api/familyMembers';
import Box from '@mui/material/Box';
import TableComponent from '../../Table/Table';
import useFamilyRelationsColumns from '../../../hooks/AllUsers/useFamilyRelationsColumns';
import { IconButton, Typography } from '@mui/material';
import CustomDrawer from '../../modals/CustomDrawer';
import FamilyRelationsForm from './FamilyRelationsForm';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import VerifyLoader from '../../verifyLoader/VerifyLoader';
import SubmitButton from '../../commonComponents/SubmitButton';

const FamilyRelationsTable = ({ languages }) => {
  const { t } = useTranslation();
  const { data: famRelations, isLoading, isFetching } = useGetAllFamilyRelations();
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [selectedRelation, setSelectedRelation] = useState(null);

  const handleEdit = (slotData) => {
    setSelectedRelation(slotData);
    setOpenAddDrawer(true);
  };

  const columns = useFamilyRelationsColumns(handleEdit);

  const handleOpen = () => {
    setOpenAddDrawer(!openAddDrawer);
    setSelectedRelation();
  };

  return (
    <>
      <Box m={5}>
        {isLoading || isFetching ? (
          <Box width="100%" display="flex" justifyContent="center">
            <VerifyLoader />
          </Box>
        ) : (
          famRelations && (
            <TableComponent hasAboveStuff data={famRelations} columns={columns}>
              <SubmitButton onClick={handleOpen} buttonText={t('AddNew')} backgroundColor="#31B099" color="inherit" />
            </TableComponent>
          )
        )}
      </Box>
      <CustomDrawer open={openAddDrawer} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color="#037A68" textAlign="left" paddingBottom={3}>
            {t('AddFamilyRelation')}
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <FamilyRelationsForm handleDrawerClose={handleOpen} languages={languages} selectedRelation={selectedRelation} />
      </CustomDrawer>
    </>
  );
};

export default FamilyRelationsTable;
