import React from 'react';
import { Box, Button } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const TransportFormElement = ({ name, control }) => {
  const { t } = useTranslation();
  const transportButtons = [
    {
      label: t('car'),
      value: 'car',
      titleHover: t('Car'),
    },
    {
      label: t('Bike'),
      value: 'bike',
      titleHover: t('Bike'),
    },
    {
      label: t('P.T'),
      value: 'publicTransport',
      titleHover: t('Pubic Transport'),
    },
  ];
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Box display="flex" gap="10px">
          {transportButtons.map((btn) => (
            <Button
              key={btn.value}
              sx={{
                padding: '12px 24px',
                outline: '1px solid #DCE4E8',
                borderRadius: '14px',
                color: 'rgb(0,0,0)',
                '&:disabled': {
                  outline: '2px solid #31B099',
                  color: '#fff',
                  backgroundColor: '#31B099',
                },
                '&:focus; &:hover': {
                  outline: '2px solid #31B099',
                  color: '#31B099',
                },
              }}
              title={btn.titleHover}
              variant="outlined"
              onClick={() => onChange(btn.value)}
              disabled={value === btn.value}
            >
              {btn.label}
            </Button>
          ))}
        </Box>
      )}
    />
  );
};

export default TransportFormElement;
