// InfoIconButton component
import React from 'react';
import { IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const InfoIconButton = ({ onMouseEnter, onMouseLeave, isActive }) => {
  return (
    <IconButton
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        color: isActive ? '#fff' : '#ACB5BB',
        ':hover': {
          bgcolor: isActive ? '#fff' : '#EDF1F3',
          color: isActive ? '#000' : '#468289',
        },
      }}
    >
      <InfoIcon />
    </IconButton>
  );
};

export default InfoIconButton;
