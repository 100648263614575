import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/auth/authActions';
import LanguageSelect from '../selectTranslation/translateSelect';

const DashboardOptions = ({ roles }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const user = useSelector((state) => state.auth);
  const isAdmin = user.roles?.includes('admin');
  const isBroker = user.roles?.includes('broker');
  return (
    <>
      {/* {roles && roles?.includes('admin') && (
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
          onClick={() => navigate('/dashboard')}
        >
          <AdminPanelSettingsIcon fontSize="large" />
        </IconButton>
      )} */}
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
        gap={{ xs: 3, sm: 5 }}
        paddingLeft={2}
        flexGrow={2}
      >
        <Link
          style={{ color: '#1A1C1E', textDecoration: 'none' }}
          to={isBroker ? '/broker/dashboard' : isAdmin ? '/admin/dashboard' : `/dashboard`}
          state="dashboard"
        >
          <Typography variant="body2">{t('dashboard')}</Typography>
        </Link>
        <Link
          style={{ color: '#1A1C1E', textDecoration: 'none' }}
          to={'/'}
          onClick={() => {
            dispatch(logout());
          }}
        >
          <Typography variant="body2" color="#1A1C1E">
            {t('logout')}
          </Typography>
        </Link>
        {!isMobile && <LanguageSelect />}
      </Box>
    </>
  );
};

export default DashboardOptions;
