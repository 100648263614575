import { Button } from '@mui/material';
import React from 'react';

const CustomButton = ({ children, variant = 'contained', fullWidth = false, ...rest }) => {
  const isContained = variant === 'contained';
  return (
    <Button
      sx={{
        padding: '12px 24px',
        outline: isContained ? '1px solid #31B099' : '1px solid #DCE4E8',
        borderRadius: '14px',
        color: isContained ? '#fff' : 'rgb(0,0,0)',
        backgroundColor: isContained ? '#31B099' : '#',
        '&:focus; &:hover': {
          color: '#DCE4E8',
          backgroundColor: '#31B099',
        },
        width: fullWidth ? '100%' : 'auto',
      }}
      variant="outlined"
      {...rest}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
