import React from 'react';
import PageBackground from '../LeadPage/PageBackground';
import LeadForm from './LeadForm';
import { Helmet } from 'react-helmet';

const LeadPage2 = () => {
  window.dataLayer.push({
    event: 'pageview',
  });
  return (
    <>
      <Helmet>
        <script>fbq('track', 'Lead');</script>
      </Helmet>
      <PageBackground>
        <LeadForm />
      </PageBackground>
    </>
  );
};

export default LeadPage2;
