import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckItem from './CheckItem';

const LeadCheckerList = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <CheckItem label={t('Personalsupport')} />
      <CheckItem label={t('100Takeover')} />
      <CheckItem label={t('CostEffectiveTaxes')} />
    </Box>
  );
};

export default LeadCheckerList;
