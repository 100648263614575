/* eslint-disable jsx-a11y/iframe-has-title */
import { useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const Map = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        overflow: 'hidden',
        padding: '0',
        margin: 'auto',
        width: '100%',
        position: 'relative',
      }}
    >
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10906.118009450016!2d9.410583957326894!3d47.444542403217845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479b1e9a1f322c91%3A0x637a675ca7f866cb!2sRorschacher%20Str.%20304%2C%209016%20St.%20Gallen%2C%20Switzerland!5e0!3m2!1sen!2smk!4v1711101692474!5m2!1sen!2smk"
        width="100%"
        height={isMobile ? '200' : '500'}
        style={{ border: '0' }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </Box>
  );
};

export default Map;
