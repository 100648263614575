import Drawer from '@mui/material/Drawer';
import React from 'react';

const CustomDrawer = ({ open, handleOpen, children }) => {
  return (
    <Drawer
      open={open}
      onClose={handleOpen}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        flexShrink: 0,

        '& .MuiDrawer-paper': {
          padding: '20px',
          width: '30%',
          bgcolor: '#F4F4F7',
          boxSizing: 'border-box',
        },
      }}
      variant="temporary"
      anchor="right"
    >
      {children}
    </Drawer>
  );
};

export default CustomDrawer;
