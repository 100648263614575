import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';

const AddIconButton = ({ onAddButtonClick, isActive, tab, isMenu }) => {
  return (
    <IconButton
      onClick={() => {
        if (onAddButtonClick) {
          onAddButtonClick(tab);
        }
      }}
      sx={{
        color: isMenu && isActive ? '#fff' : '#ACB5BB',
        ':hover': {
          bgcolor: isMenu && isActive ? '#fff' : '#EDF1F3',
          color: isMenu && isActive ? '#000' : '#468289',
        },
      }}
    >
      <AddIcon />
    </IconButton>
  );
};

export default AddIconButton;
