import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddArticle } from '../../../../hooks/useAddArticle';
import draftToHtml from 'draftjs-to-html';
import { Box, Paper, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import UploadButton from '../../../commonComponents/UploadButton';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SubmitButton from '../../../commonComponents/SubmitButton';
import CustomFormInput from '../../../commonComponents/CustomFormInput';
import CustomSelect from '../../../commonComponents/CustomSelect';

const ArticleEditorForm = ({ languages, archives }) => {
  const { t } = useTranslation();
  const [imageName, setImageName] = useState('');
  const [files, setFiles] = useState(null);
  const [contentState, setContentState] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { handleSubmit, control, onSubmit } = useAddArticle(files, setImageName, setEditorState, EditorState);

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const rawContent = convertToRaw(newEditorState.getCurrentContent());
    setContentState(draftToHtml(rawContent));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageName(file.name);
    }
  };
  return (
    <Box component="form" display="flex" flexDirection="column">
      <CustomFormInput name="title" control={control} label={t('title')} />
      <CustomFormInput name="shortDescription" control={control} label={t('shortDescription')} />
      <Controller
        name="banner"
        control={control}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <Box display="flex" my={1} flexDirection="column" width="100%">
            <Typography variant="body2" fontSize="12px">
              {t('banner')}
            </Typography>
            <UploadButton
              onFileChange={(e) => {
                handleImageChange(e);
                onChange(e);
                setFiles((prev) => ({ ...prev, image: e.target.files[0] }));
              }}
            />
            {imageName && <Typography>{imageName}</Typography>}
            {error && (
              <Typography variant="caption" color="error">
                {error.message}
              </Typography>
            )}
          </Box>
        )}
      />
      <CustomSelect
        control={control}
        name="archive"
        label={t('archive')}
        data={archives?.data}
        valueField={(item) => item.id}
        displayField={(item) => item.name}
      />
      <CustomSelect
        control={control}
        name="language"
        label={t('language')}
        data={languages}
        valueField={(item) => item.code}
        displayField={(item) => item.name}
      />
      <Controller
        name="text"
        control={control}
        render={({ field: { onChange } }) => (
          <Paper>
            <Editor
              editorState={editorState}
              onEditorStateChange={(newEditorState) => {
                handleEditorStateChange(newEditorState);
                onChange(contentState);
              }}
            />
          </Paper>
        )}
      />

      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default ArticleEditorForm;
