import i18n from '../../../config/i18n';

export const tags = [
  { value: 'search', label: i18n.t('searchAllFields') },
  { value: 'name', label: i18n.t('name') },
  { value: 'archive', label: i18n.t('archive') },
  { value: 'status', label: i18n.t('status') },
  { value: 'zip', label: i18n.t('zip') },
  { value: 'language', label: i18n.t('language') },
  { value: 'referal', label: i18n.t('referral') },
];
