import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import MenuDropdown from '../../components/adminDashboard/MenuDropdown';
import isISODateValid from '../../utils/isISODateValid';
import findLocalsOrUseFirst from '../../utils/findLocalsOrUseFirst';

const useLeadTerminsColumns = (handleEdit) => {
  const { t, i18n } = useTranslation();
  const menuItems = [
    {
      label: 'Edit',
      icon: 'https://taxofficestorage.blob.core.windows.net/media/edit-2.svg',
      onClick: (row) => {
        handleEdit(row);
      },
    },
  ];
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          #
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.lead?.name, {
      id: 'name',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('name')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.lead?.zip, {
      id: 'zip',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('zip')}
        </Typography>
      ),
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor((row) => row.interest, {
      id: 'interest',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('interest')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row?.lead?.status, {
      id: 'status',
      cell: (info) => {
        const localizedStatus = findLocalsOrUseFirst(info.getValue()?.locals, i18n.language);
        return <Typography>{localizedStatus?.name || info.getValue()?.name}</Typography>;
      },
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Status')}
        </Typography>
      ),
    }),

    columnHelper.accessor((row) => row.from, {
      id: 'createdAt',
      cell: (info) => (
        <Typography>{isISODateValid(info.getValue()) && format(new Date(info.getValue()), 'dd.MM.yyyy')}</Typography>
      ),
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Date')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => ({ from: row.from, to: row.to }), {
      id: 'createdTime',
      cell: (info) => {
        const { from } = info.getValue();
        return (
          <Typography>{`${isISODateValid(from) && format(new Date(from), 'hh:mm')}
          `}</Typography>
        );
      },
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('fromTime')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.broker?.firstName, {
      id: 'broker',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('referral')}
        </Typography>
      ),
    }),
    columnHelper.accessor('action', {
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Action')}
        </Typography>
      ),
      cell: (info) => {
        return (
          <MenuDropdown
            id={info.row.original.id}
            menuItems={menuItems.map((item) => ({ ...item, onClick: () => item.onClick(info.row.original) }))}
          />
        );
      },
      footer: (info) => info.column.id,
    }),
  ];
  return columns;
};
export default useLeadTerminsColumns;
