import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useFamilyMember } from '../../hooks/useFamilyMember';
import { useGetAllFamilyRelations } from '../../api/familyMembers';
import CustomFormInput from '../commonComponents/CustomFormInput';
import SubmitButton from '../commonComponents/SubmitButton';
import CustomSelect from '../commonComponents/CustomSelect';
import findLocalsOrUseFirst from '../../utils/findLocalsOrUseFirst';

const FamilyMembers = ({ handleDrawerClose }) => {
  const { t, i18n } = useTranslation();
  const { onSubmit, handleSubmit, control } = useFamilyMember(handleDrawerClose);
  const { data } = useGetAllFamilyRelations();

  const filteredFamilyRelations = data?.map((item) => {
    const localData = findLocalsOrUseFirst(item.locals, i18n.language);
    return {
      id: item.id,
      localData: localData,
    };
  });
  return (
    <Box display="flex" flexDirection="column" component="form" gap="30px" width="100%">
      <CustomFormInput name="firstName" control={control} label={t('firstname')} />
      <CustomFormInput name="lastName" control={control} label={t('lastname')} />
      <CustomSelect
        control={control}
        name="familyRelation"
        label={t('FamilyRelation')}
        data={filteredFamilyRelations}
        valueField={(item) => item.id}
        displayField={(item) => item.localData?.name}
      />
      <CustomFormInput name="birthDate" type={'date'} control={control} label={t('birthdate')} />
      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} color="inherit" />
    </Box>
  );
};

export default FamilyMembers;
