import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useToast } from './useToast';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useResetPassToken } from '../api/auth';
import { useState } from 'react';

export const useResetPass = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [disabled, setDisabled] = useState(false);
  const { state } = useLocation();
  const { mutateAsync } = useResetPassToken();

  const resetPasswordSchema = yup
    .object()
    .shape({
      otp: yup.string().required(),
      password: yup
        .string()
        .min(8, `${t('password.min.value')}`)
        .required(),
      passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], t('PasswordsMatch')),
    })
    .required();

  const { handleSubmit, control, watch } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      otp: '',
      password: '',
      passwordConfirmation: '',
    },
    resolver: yupResolver(resetPasswordSchema),
    reValidateMode: 'onChange',
  });

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setDisabled(() => true);

    try {
      const password = data.password;
      const otp = data.otp;
      const email = state.email;
      await mutateAsync({ email, password, otp });
      toast({
        variant: 'success',
        message: 'Password Successfully Changed',
      });
      navigate('/auth', { replace: true });
    } catch (error) {
      if (error?.response?.status === 400) {
        toast({
          variant: 'error',
          message: 'Wrong Otp Code Provided',
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      }
    }
  };

  return {
    handleSubmit,
    onSubmit,
    control,
    disabled,
    watch,
  };
};
