import { axiosClient, customInstance } from './client';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import useChangeEffect from '../hooks/useChangeEffect';

const suffix = '/v1/users';

export const getAllUsers = (params) => {
  return customInstance({
    url: `${suffix}`,
    method: 'get',
    params,
  });
};

export const updateUserData = async (data) => {
  return customInstance({
    url: `${suffix}/updateUser`,
    method: 'put',
    data,
  });
};

export const assignUserBroker = async (data) => {
  return customInstance({
    url: `${suffix}/assign-broker`,
    method: 'put',
    data,
  });
};

export const useAssignUserBroker = () => {
  const queryClient = useQueryClient();
  return useMutation(({ data }) => assignUserBroker(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export const useUpdateUserData = () => {
  return useMutation((data) => updateUserData(data));
};

export const getUserById = async (id) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'get',
  });
};

export const updateUserInfo = async (data) => {
  return customInstance({
    url: `${suffix}/info`,
    method: 'put',
    data,
  });
};

export const updateUser = async (data) => {
  return axiosClient.put(`${suffix}/info`, data);
};

export const deleteFile = async (fileId) => {
  return customInstance({
    url: `${suffix}/upload/${fileId}`,
    method: 'delete',
  });
};

export const uploadFile = async (fileType, formData) => {
  return customInstance({
    url: `${suffix}/upload/${fileType}`,
    method: 'post',
    data: formData,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export const useGetAllUsers = (params) => {
  const query = useQuery(['users'], () => getAllUsers(params), { select: (data) => data?.data });

  useChangeEffect(() => {
    if (params) {
      query.refetch();
    }
  }, [JSON.stringify(params)]);

  return query;
};

export const useGetCurrentUser = (id) => {
  return useQuery('current-user', async () => {
    const { data } = await getUserById(id);
    return data;
  });
};

export const useUpdateUserInfo = () => {
  return useMutation({
    mutationFn: (data) => updateUserInfo(data),
  });
};

export const useGetUserById = (id) => {
  return useQuery(['user', id], () => getUserById(id), { enabled: !!id });
};

export const useDeleteFile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => deleteFile(id),
    onSuccess: () => queryClient.invalidateQueries(['current-user']),
  });
};

const getFilesByUserId = async (id) => {
  return customInstance({
    url: `${suffix}/files/${id}`,
    method: 'get',
  });
};

export const useGetFilesByUserId = (id) => {
  return useQuery(['files', id], () => getFilesByUserId(id), { enabled: !!id });
};

//GET Brokers
const getAllBrokers = (params) => {
  return customInstance({
    url: `${suffix}/brokers`,
    method: 'get',
    params,
  });
};

export const useGetAllBrokers = (params, enabled = true) => {
  const query = useQuery(['brokers'], () => getAllBrokers(params), { select: (data) => data?.data, enabled });

  useChangeEffect(() => {
    if (params) {
      query.refetch();
    }
  }, [JSON.stringify(params)]);

  return query;
};

const updateUserStatus = (userId, statusId) => {
  return customInstance({
    url: `${suffix}/updateStatus/${userId}`,
    method: 'put',
    data: { statusId },
  });
};

export const useUpdateUserStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, statusId }) => updateUserStatus(id, statusId), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      queryClient.invalidateQueries('user');
    },
  });
};

export const getBrokerUsers = (brokerId, params) =>
  customInstance({ url: `${suffix}/broker/${brokerId}`, method: 'GET', params });

export const useBrokerUsers = (brokerId, params) =>
  useQuery(['customer'], () => getBrokerUsers(brokerId, params), { enabled: !!brokerId });
