import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAddArchive } from '../../../hooks/useAddArchive';
import SubmitButton from '../../commonComponents/SubmitButton';
import CustomFormInput from '../../commonComponents/CustomFormInput';
import CustomSelect from '../../commonComponents/CustomSelect';

const ArchiveSettings = ({ handleDrawerClose, data }) => {
  const { handleSubmit, control, onSubmit } = useAddArchive(handleDrawerClose, data);

  const { t, i18n } = useTranslation();
  return (
    <Box component="form" display="flex" flexDirection="column">
      <CustomSelect
        control={control}
        name="archiveTypeId"
        label={t('ArchiveType')}
        data={data}
        valueField={(item) => item.id}
        displayField={(item) => {
          const foundLocal = item?.locals?.find((local) => local.language === i18n.language);
          return foundLocal?.name || item?.name;
        }}
      />
      <CustomFormInput name="locals.de" control={control} label={t('deutsch_label')} />
      <CustomFormInput name="locals.eng" control={control} label={t('english_label')} />
      <CustomFormInput name="validFrom" type="date" control={control} label={t('ValidFrom')} />
      <CustomFormInput name="validTo" type="date" control={control} label={t('ValidTo')} />
      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default ArchiveSettings;
