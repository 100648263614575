import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import isISODateValid from '../../../../../utils/isISODateValid';

const useColumns = () => {
  const { t } = useTranslation();

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => <Typography variant="body1">#</Typography>,
    }),
    columnHelper.accessor((row) => row?.createdAt, {
      id: 'createdAt',
      cell: (info) => <span>{isISODateValid(info.getValue()) && format(new Date(info.getValue()), 'dd.MM.yyyy')}</span>,
      header: () => <Typography variant="body1">{t('created_at')}</Typography>,
    }),
    columnHelper.accessor((row) => row?.from, {
      id: 'date',
      cell: (info) => <span>{isISODateValid(info.getValue()) && format(new Date(info.getValue()), 'dd.MM.yyyy')}</span>,
      header: () => <Typography variant="body1">{t('date')}</Typography>,
    }),
    columnHelper.accessor((row) => row?.from, {
      id: 'time',
      cell: (info) => <span>{isISODateValid(info.getValue()) && format(new Date(info.getValue()), 'HH:mm')}</span>,
      header: () => <Typography variant="body1">{t('time')}</Typography>,
    }),
    columnHelper.accessor((row) => row?.interest, {
      id: 'interest',
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => <Typography variant="body1">{t('interest')}</Typography>,
    }),
  ];
  return columns;
};
export default useColumns;
