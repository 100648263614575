import { useMutation, useQuery, useQueryClient } from 'react-query';
import { customInstance } from './client';

const suffix = 'v1/finished-documents';

//GET TAX POTENTIAL
const getFinishedDocuments = ({ user, archive }) => {
  return customInstance({
    url: `${suffix}`,
    method: 'get',
    params: { user, archive },
  });
};

export const useGetFinishedDocuments = ({ user, archive, enabled }) => {
  return useQuery(['finishedDocuments', user, archive], () => getFinishedDocuments({ user, archive }), {
    enabled,
  });
};

const createFinishedDocument = async (data, trackProgress) => {
  return customInstance({
    url: `${suffix}`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (data) => {
      trackProgress(Math.round((100 * data.loaded) / data.total));
    },
  });
};

export const useCreateFinishedDocument = (trackProgress) => {
  const queryClient = useQueryClient();
  return useMutation((data) => createFinishedDocument(data, trackProgress), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['finishedDocuments'] });
    },
  });
};

const deleteFinishedDocument = (id) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'delete',
  });
};

export const useDeleteFinishedDocument = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => deleteFinishedDocument(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['finishedDocuments'] });
    },
  });
};
