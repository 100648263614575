/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useUpdateDocumentWidget } from '../api/documentWidgets';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import useToastNotification from './useToastNotification';

export const useEditDocumentWidget = (handleDrawerClose, selectedWidget) => {
  const { t } = useTranslation();
  const {
    mutate: editMutate,
    isSuccess: isEditSuccess,
    isLoading: isEditLoading,
    isError: isEditError,
  } = useUpdateDocumentWidget();

  const schema = yup
    .object()
    .shape({
      subtitle: yup.string().required(t('NameRequired')),
      title: yup.string().required(t('TitleRequired')),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      defaultValues: {
        subtitle: selectedWidget?.subtitle || '',
        title: selectedWidget?.title || '',
      },
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    reset({
      subtitle: selectedWidget?.subtitle || '',
      title: selectedWidget?.title || '',
    });
  }, [selectedWidget, reset]);

  //EDIT TOASTS

  useToastNotification({
    isShow: isEditSuccess,
    loading: isEditLoading,
    messageKey: 'SuccessEditDocumentWidget',
    variant: 'success',
  });

  useToastNotification({
    isShow: isEditError,
    loading: isEditLoading,
    messageKey: 'ErrorEditDocumentWidget',
    variant: 'error',
  });

  const onSubmit = (data) => {
    editMutate({ id: selectedWidget.id, data });

    reset();
    handleDrawerClose();
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    errors,
    register,
  };
};
