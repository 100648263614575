import { Chip, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import findLocalsOrUseFirst from '../../utils/findLocalsOrUseFirst';

const getTextColor = (bgColor) => {
  if (!bgColor) return 'black';

  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  return brightness > 128 ? '#364c5c' : '#fff';
};

const useBrokerTaskColumns = () => {
  const { t, i18n } = useTranslation();
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          #
        </Typography>
      ),
    }),
    columnHelper.accessor(
      (row) => ({
        firstName: row.user?.firstName,
        lastName: row.user?.lastName,
      }),
      {
        id: 'user',
        cell: (info) => (
          <Typography>
            {info.getValue().firstName} {info.getValue().lastName}
          </Typography>
        ),
        header: () => (
          <Typography variant="body1" fontWeight={700}>
            {t('Username')}
          </Typography>
        ),
      },
    ),
    columnHelper.accessor((row) => row.archive?.name, {
      id: 'archive',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('archive')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.status, {
      id: 'status',
      cell: (info) => {
        const bgColor = info.getValue()?.color;
        const textColor = getTextColor(bgColor);

        // Using findLocalsOrUseFirst to get the localized status name
        const localizedStatus = findLocalsOrUseFirst(info.getValue()?.locals, i18n.language);

        return (
          <Chip label={localizedStatus?.name || info.getValue()?.name} sx={{ bgcolor: bgColor, color: textColor }} />
        );
      },
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Status')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.createdAt, {
      id: 'createdAt',
      cell: (info) => <Typography>{format(new Date(info.getValue()), 'dd.MM.yyyy')}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Created At')}
        </Typography>
      ),
    }),
  ];
  return columns;
};
export default useBrokerTaskColumns;
