import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetTerminById } from '../../../../api/termins';
import { useTranslation } from 'react-i18next';
import { Box, Breadcrumbs, Divider, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';
import Notes from '../../leads/Notes';
import Journal from '../../leads/Journal';

const AppointmentsView = () => {
  const { id } = useParams();
  const { data } = useGetTerminById(id);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Typography textAlign={{ xs: 'center', sm: 'left' }} variant="h6" fontWeight={700} px={5} py={2.45}>
          {data?.lead?.name}
        </Typography>
        <Divider />
        <Box display="flex" flexDirection="column" gap="32px" m={3}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link onClick={() => navigate(-1)} style={{ textDecoration: 'none' }}>
              <Typography variant="body1" color="#1A1C1E">
                {t('Leads')}
              </Typography>
            </Link>
            <Typography variant="body1" color="#1A1C1E">
              {data?.lead?.name}
            </Typography>
          </Breadcrumbs>
          <Box>
            <Notes id={id} />
          </Box>
          <Box>
            <Journal id={id} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AppointmentsView;
