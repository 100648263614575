import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  verified: null,
  email: null,
  files: [],
  firstName: null,
  lastName: null,
  zipCode: null,
  phoneNumber: null,
  address: null,
  id: null,
  createdAt: null,
  updatedAt: null,
  info: {
    household: '',
    estate: false,
    insurance: false,
    receivedAlimente: false,
    payedAlimente: false,
    creadit: false,
    bonds: false,
    manWorkAddress: '',
    wifeWorkAddress: '',
  },
};

const currentUser = createSlice({
  name: 'current-user',
  initialState,
  reducers: {
    whoAmi: (state, action) => state,
    setCurrentUser: (state, action) => {
      const { roles, ...rest } = action.payload;
      return {
        ...state,
        ...rest,
      };
    },
    clearUser: (state) => {
      localStorage.removeItem('_u');
      return {
        verified: null,
        email: null,
        files: [],
        firstName: null,
        lastName: null,
        zipCode: null,
        phoneNumber: null,
        address: null,
        id: null,
        createdAt: null,
        updatedAt: null,
        info: {
          household: '',
          estate: false,
          insurance: false,
          receivedAlimente: false,
          payedAlimente: false,
          creadit: false,
          bonds: false,
          manWorkAddress: '',
          wifeWorkAddress: '',
        },
      };
    },
  },
});

export const { whoAmi, setCurrentUser, clearUser } = currentUser.actions;

export default currentUser.reducer;
