import { Box } from "@mui/system";
import React from "react";
import NavBarRegister from "../../src/components/navBarRegister/navBarRegister";
import NotFound from "../components/notFound/NotFound";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box>
      <NavBarRegister/>

      <NotFound />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          onClick={() => navigate(-1)}
          sx={{ width: "200px" }}
          variant="outlined"
          color="success"
        >
          <ArrowBackIcon/> {t('Go.Back')}
        </Button>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
