import React from 'react';
import { Alert, Box } from '@mui/material';
import CustomFormInput from '../../commonComponents/CustomFormInput';
import BigSubmitButton from '../../commonComponents/BigSubmitButton';
import { useTranslation } from 'react-i18next';

const LeadFormInput = ({ control, handleSubmit, onSubmit, isLoading, isError }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" width="100%" flexDirection="column" justifyContent="space-evenly" gap="20px">
      <CustomFormInput name="name" control={control} label={t('YFirstName')} />
      <CustomFormInput name="phoneNumber" control={control} label={t('YMobileNumber')} />
      <CustomFormInput name="email" control={control} label={t('YEmailAddress')} />
      <BigSubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Submit')} color="inherit" disabled={isLoading} />
      {isError && !isLoading && <Alert severity="error">{t('server_error')}</Alert>}
    </Box>
  );
};

export default LeadFormInput;
