import { Box } from '@mui/material';
import { useGetTasksByBrokerId } from '../../api/tasks';
import TableComponent from '../Table/Table';
import { useSelector } from 'react-redux';
import useBrokerTaskColumns from '../../hooks/AllUsers/useBrokerTaskColumns';
import { useNavigate } from 'react-router-dom';
import VerifyLoader from '../verifyLoader/VerifyLoader';

const BrokerTasksTable = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const { data, isLoading, isFetching } = useGetTasksByBrokerId(currentUser?.id);
  const navigate = useNavigate();

  const columns = useBrokerTaskColumns();

  const handleRowClick = (row) => {
    navigate(`/broker/tasks/${row?.id}`);
  };
  return (
    <Box m={5}>
      <Box width="100%">
        {isLoading || isFetching ? (
          <Box width="100%" display="flex" justifyContent="center">
            <VerifyLoader />
          </Box>
        ) : (
          data && <TableComponent data={data} columns={columns} onRowClick={handleRowClick} cursor="pointer" />
        )}
      </Box>
    </Box>
  );
};

export default BrokerTasksTable;
