import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Link, Menu } from '@mui/material';
import Box from '@mui/material/Box';

const languages = [
  {
    value: 'eng',
    label: (
      <img
        height={22}
        width={22}
        src={'https://em-content.zobj.net/thumbs/160/twitter/322/flag-united-kingdom_1f1ec-1f1e7.png'}
        alt="Eng"
      />
    ),
  },
  {
    value: 'de',
    label: (
      <img
        height={22}
        width={22}
        src={'https://em-content.zobj.net/thumbs/320/twitter/322/flag-germany_1f1e9-1f1ea.png'}
        alt="De"
      />
    ),
  },
  // {
  //   value: "dutch",
  //   label: "Dutch",
  // },
];

export default function LanguageSelect() {
  const localLang = localStorage.getItem('i18nextLng');
  const [lng, setLng] = React.useState(
    languages.find((lng) => lng.value === localLang) ?? {
      value: 'eng',
      label: 'English',
    },
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();

  const handleChange = (value) => {
    setLng({ value });
    handleClose();
  };

  React.useEffect(() => {
    i18next.changeLanguage(lng.value);
  }, [lng]);

  return (
    <Box marginRight={1} display="flex" alignItems="center">
      <Link
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
          display: 'grid',
          placeItems: 'center',
        }}
        onClick={handleClick}
      >
        {languages.find((lng) => lng.value === localLang)?.label}
      </Link>
      <Menu
        sx={{
          color: 'white',
        }}
        labelid="demo-select-small"
        id="demo-select-small"
        value={lng.value}
        label={t('language')}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {languages.map(({ value, label }, idx) => (
          <MenuItem onClick={() => handleChange(value)} key={idx} id={idx}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
