import { useTranslation } from 'react-i18next';
import { useAddNotes } from './hooks/useAddNote';
import CustomFormInput from '../../../../commonComponents/CustomFormInput';
import SubmitButton from '../../../../commonComponents/SubmitButton';
import { Box } from '@mui/system';

const NotesForm = ({ id, handleDrawerClose }) => {
  const { t } = useTranslation();
  const { handleSubmit, control, onSubmit } = useAddNotes(id, handleDrawerClose);
  return (
    <Box component="form" display="flex" flexDirection="column">
      <CustomFormInput name="note" control={control} label={t('Notes')} />
      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default NotesForm;
