import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomPieChart from '../components/dashboardComponents/pieChart/CustomPieChart';
import { useGetFileCount, useGetUserCount, useGetTaskCount, useGetTopTen, useGetFileTypeCount } from '../api/stats';
import CustomBarChart from '../components/dashboardComponents/barChart/CustomBarChart';
import CounterContainer from '../components/dashboardComponents/counter/CounterContainer';
import LanguageSelect from '../components/selectTranslation/translateSelect';
import Counter from '../components/dashboardComponents/counter/Counter';
import useTimeOfDay from '../utils/useTimeOfTheDay';

const AdminDashboard = () => {
  const { t } = useTranslation();
  const data = useSelector((state) => state?.auth?.currentUser);
  const { data: filesCount } = useGetFileCount();
  const { data: tasksCount } = useGetTaskCount();
  const { data: usersCount } = useGetUserCount();
  const { data: topten } = useGetTopTen();
  const { data: fileTypeCount } = useGetFileTypeCount();
  const timeOfDay = useTimeOfDay();

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" px={2}>
        <Typography variant="h6" fontWeight={700} px={5} py={2}>
          {t('dashboard')}
        </Typography>
        <LanguageSelect />
      </Box>

      <Divider />
      <Typography variant="h4" fontWeight={900} px={5} pt={3} mb={3}>
        {t('Greeting', { timeOfDay: t(timeOfDay) })}, {data?.firstName} {data?.lastName}
      </Typography>

      <CounterContainer>
        <Counter
          targetNumber={usersCount}
          type={t('UsersAdmin')}
          icon="https://taxofficestorage.blob.core.windows.net/media/user.svg"
        />
        <Counter
          targetNumber={filesCount}
          type={t('Files')}
          icon="https://taxofficestorage.blob.core.windows.net/media/folder.svg"
        />
        <Counter
          targetNumber={tasksCount}
          type={t('Tasks')}
          icon="https://taxofficestorage.blob.core.windows.net/media/relations.svg"
        />
      </CounterContainer>
      <Box m={5} display="flex" justifyContent="space-between" gap={'20px'}>
        <CustomPieChart data={fileTypeCount} title={t('Uploaded_FPFT')} />
        <CustomBarChart data={topten} title={t('Top10Users')} />
      </Box>
    </Box>
  );
};

export default AdminDashboard;
