/* eslint-disable react-hooks/exhaustive-deps */
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import DocumentCard from '../../dashboardComponents/DocumentCard';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { downloadFilesAsZip } from '../../../features/ZipDownloadUtil';
import { format } from 'date-fns';
import { trim } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import StatusMenu from '../../StatusMenu';
import { useUpdateTaskStatus } from '../../../api/tasks';
import SmallButton from '../../commonComponents/SmallButton';
import TableContainer from '../../Table/TableContainer';

const UserFiles = ({ username, files, email, statuses, task, taxYear, setTaxYear, archives, allFileTypes }) => {
  const { t, i18n } = useTranslation();
  const currentYear = new Date().getFullYear();
  const fileTypes = allFileTypes?.raw;
  const [, setFileType] = useState();
  const [selectedFileType, setSelectedFileType] = useState('');
  const { mutate } = useUpdateTaskStatus();

  useEffect(() => {
    setTaxYear(archives?.find((archive) => trim(archive?.name) === (currentYear - 1).toString())?.id);
  }, [archives]);

  useEffect(() => {
    setFileType(fileTypes);
  }, [fileTypes]);

  const handleDownload = () => {
    let filteredFiles = files.filter((file) => file?.archive?.id === taxYear);

    if (selectedFileType && selectedFileType !== '') {
      filteredFiles = filteredFiles.filter((file) => file?.fileType?.id === selectedFileType);
    }

    const fileUrls = filteredFiles.map((file) => file.url);
    downloadFilesAsZip(fileUrls, username, format(new Date(), 'dd.MM.yyyy'));
  };

  const handleChange = (e) => {
    setTaxYear(e.target.value);
  };

  const handleChangeFileType = (e) => {
    setSelectedFileType(e.target.value);
  };

  const handleMailClick = () => {
    const emailSubject = process.env.REACT_APP_EMAIL_SUBJECT;
    const emailBody = process.env.REACT_APP_EMAIL_BODY;

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(
      emailBody,
    )}`;
    window.open(mailtoLink, '_blank');
  };

  return (
    <TableContainer sx={{ p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{t('UploadedDocuments')}</Typography>
        <StatusMenu data={task} statuses={statuses} mutate={mutate} i18n={i18n} />
      </Box>

      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" gap="16px">
        <Box display="flex" gap="16px">
          <FormControl>
            <Typography variant="body2">{t('archive')}</Typography>

            {taxYear && (
              <Select
                labelid="taxyearLabel"
                value={taxYear}
                onChange={handleChange}
                sx={{
                  'label + &': {
                    marginTop: 2,
                  },
                  borderRadius: '15px',
                  width: '100px',
                  height: '54px',
                }}
              >
                {archives?.map((tax, i) => (
                  <MenuItem key={i} value={tax?.id}>
                    {tax?.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
          <FormControl>
            <Typography variant="body2">{t('FileType')}</Typography>
            <Select
              labelId="filetypeLabel"
              value={selectedFileType}
              onChange={handleChangeFileType}
              displayEmpty
              sx={{
                'label + &': {
                  marginTop: 2,
                },
                borderRadius: '15px',
                width: '200px',
                height: '54px',
              }}
            >
              <MenuItem value="">{t('AllFileTypes')}</MenuItem>
              {fileTypes?.map((file, i) => (
                <MenuItem key={i} value={file?.id}>
                  {file?.[`name_${i18n.language}`]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box display="flex" gap="16px" mt={2.5}>
          <SmallButton
            iconUrl="https://taxofficestorage.blob.core.windows.net/media/mail.svg"
            onClick={handleMailClick}
            altText="Mail Icon"
          />
          <SmallButton
            iconUrl="https://taxofficestorage.blob.core.windows.net/media/arrow-down.svg"
            onClick={handleDownload}
            altText="Download Icon"
          />
        </Box>
      </Box>
      {files?.length > 0 &&
        files?.map((file) => {
          if (
            file?.archive?.id === taxYear &&
            (!selectedFileType || selectedFileType === '' || file?.fileType?.id === selectedFileType)
          ) {
            return (
              <Box key={file.id} width="100%">
                <DocumentCard {...file} />
              </Box>
            );
          }
          return null;
        })}
    </TableContainer>
  );
};

export default UserFiles;
