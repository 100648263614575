import { useMutation, useQuery, useQueryClient } from 'react-query';
import { customInstance } from './client';
import useChangeEffect from '../hooks/useChangeEffect';

const suffix = 'v1/tasks';

//GET Tasks
const getAllTasks = (params) => {
  return customInstance({
    url: `${suffix}`,
    method: 'get',
    params,
  });
};

export const useGetAllTasks = (params) => {
  const query = useQuery(['tasks'], () => getAllTasks(params));

  useChangeEffect(() => {
    if (params) {
      query.refetch();
    }
  }, [JSON.stringify(params)]);

  return query;
};

//PUT Assign Broker

const assignBroker = (id, data) => {
  return customInstance({
    url: `${suffix}/assign/${id}`,
    method: 'put',
    data: data,
  });
};

export const useAssignBrokerToTask = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => assignBroker(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    },
  });
};

//PUT Assign Broker Bulk

const assingBrokerBulk = (data) => customInstance({ url: `${suffix}/assign-broker`, method: 'put', data });

export const useAssingBrokerBulk = () => {
  const queryClient = useQueryClient();
  return useMutation(({ data }) => assingBrokerBulk(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    },
  });
};

//GET By Broker ID
const getTasksByBrokerId = (id) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'get',
  });
};

export const useGetTasksByBrokerId = (id) => {
  return useQuery(['tasks', id], () => getTasksByBrokerId(id), { enabled: !!id });
};

//GET By Task ID
const getByTaskId = (id) => {
  return customInstance({
    url: `${suffix}/task/${id}`,
    method: 'get',
  });
};

export const useGetByTaskId = (id) => {
  return useQuery(['task', id], () => getByTaskId(id), { enabled: !!id });
};

//PUT Update Status
const updateStatus = (taskId, statusId) => {
  return customInstance({
    url: `${suffix}/${taskId}`,
    method: 'put',
    data: { statusId },
  });
};

export const useUpdateTaskStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, statusId }) => updateStatus(id, statusId), {
    onSuccess: () => {
      queryClient.invalidateQueries('tasks');
      queryClient.invalidateQueries('task');
    },
  });
};

export const getTaskByArchive = (name) =>
  customInstance({
    url: `v1/tasks/user/${name}`,
    method: 'get',
  });

export const useTaskByArchive = (name) => useQuery(['tasks', name], () => getTaskByArchive(name));

export const getTaskByNameAndArchive = (userId, archiveId) =>
  customInstance({
    url: `v1/tasks/${userId}/${archiveId}`,
    method: 'get',
  });
export const useGetTaskByNameAndArchive = (userId, archiveId) =>
  useQuery(['tasks', 'task', userId, archiveId], () => getTaskByNameAndArchive(userId, archiveId), {
    enabled: !!archiveId,
  });

const getTaskByUserId = async (userId) => {
  return customInstance({
    url: `v1/tasks/by/user/${userId}`,
    method: 'get',
  });
};

export const useGetTaskByUserId = (userId) => useQuery(['tasks', 'task', userId], () => getTaskByUserId(userId));
