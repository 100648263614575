import { useMutation, useQuery, useQueryClient } from 'react-query';
import { customInstance } from './client';

const suffix = '/leads-type';
const suffix2 = '/leads-type-localizations';

//GET LEAD TYPES
const getAllLeadTypes = () => {
  return customInstance({
    url: `${suffix}`,
    method: 'get',
  });
};

export const useGetAllLeadTypes = () => {
  return useQuery(['lead-types'], async () => {
    const data = await getAllLeadTypes();
    return data;
  });
};

//CREATE LEAD TYPE
const createLeadType = async (data) => {
  return customInstance({
    url: `${suffix}`,
    method: 'post',
    data,
  });
};

export const useCreateLeadType = () => {
  const queryClient = useQueryClient();
  return useMutation(({ data }) => createLeadType(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lead-types'] });
    },
  });
};

//PUT LEAD TYPE
const updateLeadType = (id, data) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'put',
    data: data,
  });
};

export const useUpdateLeadType = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateLeadType(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lead-types'] });
    },
  });
};

//CREATE LEAD TYPE LOCALIZATION
const createLeadTypeLocalization = async (data) => {
  return customInstance({
    url: `${suffix2}`,
    method: 'post',
    data,
  });
};

export const useCreateLeadTypeLocalization = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createLeadTypeLocalization(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lead-types'] });
    },
  });
};
