import React, { useState } from 'react';
import { Box, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import CustomTextField from '../../commonComponents/CustomTextField';

const TagSearch = ({ setTags }) => {
  const { t } = useTranslation();
  const [input, setInput] = useState('');

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <CustomTextField
        fullWidth
        value={input}
        placeholder={t('searchAllFields')}
        variant="outlined"
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && input) {
            setTags((prev) => [...prev, input]);
            setInput('');
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default TagSearch;
