import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import MenuDropdown from '../../components/adminDashboard/MenuDropdown';

const useFileTypeColumns = (handleOpenEdit, activeTabs) => {
  const menuItems = [
    {
      label: 'Edit',
      icon: 'https://taxofficestorage.blob.core.windows.net/media/edit-2.svg',
      onClick: (id) => handleOpenEdit(id),
    },
  ];
  const columnHelper = createColumnHelper();
  const { t } = useTranslation();
  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          #
        </Typography>
      ),
    }),

    columnHelper.accessor((row) => row.name_eng, {
      id: 'name_eng',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('name_eng')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.name_de, {
      id: 'name_de',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('NameDeutsch')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.archiveType?.name, {
      id: 'archiveName',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Art')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.name_eng, {
      id: 'name_eng',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('name_eng')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.name_de, {
      id: 'name_de',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('NameDeutsch')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.archiveType?.name, {
      id: 'archiveName',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Art')}
        </Typography>
      ),
    }),
    activeTabs.length === 0 &&
      columnHelper.accessor((row) => row.icon, {
        id: 'icon',
        cell: (info) => info.getValue() && <img alt="FileTypeIcon" src={info.getValue()}></img>,
        header: () => (
          <Typography variant="body1" fontWeight={700}>
            {t('Icon')}
          </Typography>
        ),
      }),
    columnHelper.accessor((row) => row.is_active, {
      id: 'is_active',
      cell: (info) => <Typography>{info.row.original.is_active ? 'Active' : 'Inactive'}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('IsActive')}
        </Typography>
      ),
    }),
    columnHelper.accessor('action', {
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Action')}
        </Typography>
      ),
      cell: (info) => {
        return <MenuDropdown id={info.row.original.id} menuItems={menuItems} />;
      },
      footer: (info) => info.column.id,
    }),
  ].filter((h) => h);
  return columns;
};
export default useFileTypeColumns;
