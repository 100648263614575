export const multilineExtractor = (text) => {
  return text.replace(/\\n/g, 'nbsp;').split('\n');
};

export const scrollToRef = (e, ref) => {
  e.preventDefault();
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
  ref.current.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'start',
  });
};
