import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ExportButton = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      sx={{
        bgcolor: '#fff',
        color: '#000',
        fontWeight: 700,
        textTransform: 'none',
        borderRadius: '25px',
        width: 'fit-content',
        paddingY: '16px',
        paddingX: '25px',
        display: 'flex',
        gap: '10px',
      }}
      {...props}
    >
      <img alt="Menu Icon" src={'https://taxofficestorage.blob.core.windows.net/media/export.svg'}></img>
      <Typography fontWeight={700}>{t('Export')}</Typography>
    </Button>
  );
};

export default ExportButton;
