/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';

const useChangeEffect = (cb, depArray) => {
  const firstUpdate = useRef(true);

  useEffect(() => {
    firstUpdate.current = false;
  }, []);

  useEffect(() => {
    if (!firstUpdate.current) {
      cb();
    }
  }, depArray);
};

export default useChangeEffect;
