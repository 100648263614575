import React, { useState } from 'react';
import { useAssignTerminsBroker } from '../../../../../api/termins';
import useToastNotification from '../../../../../hooks/useToastNotification';
import AssignBroker from '../../../../AssignBroker';
import Filters from '../../../leads/LeadsTable/components/Header/components/Filters';
import Title from '../../../leads/LeadsTable/components/Header/components/Title';
import { useTranslation } from 'react-i18next';

const Header = ({
  filterTag,
  handleChange,
  MenuProps,
  tags,
  control,
  debouncedHandleSearchChange,
  brokers,
  setRowSelection,
  originalSelectedRows,
}) => {
  const [selectedBroker, setSelectedBroker] = useState();
  const { t } = useTranslation();
  const { mutate, isSuccess, isLoading, isError } = useAssignTerminsBroker();
  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessfullyUpdatedTermins',
    cb: () => {
      setSelectedBroker(undefined);
      setRowSelection({});
    },
    variant: 'success',
  });
  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorUpdatedTermins',
    variant: 'error',
    cb: () => {
      setSelectedBroker(undefined);
      setRowSelection({});
    },
  });
  return (
    <>
      <Title title={t('Termins')} />

      {originalSelectedRows && originalSelectedRows?.length !== 0 ? (
        <AssignBroker
          MenuProps={MenuProps}
          brokers={brokers}
          selectedBroker={selectedBroker}
          setSelectedBroker={setSelectedBroker}
          onMutate={() => {
            if (selectedBroker) {
              mutate({ data: { brokerId: selectedBroker, termins: originalSelectedRows?.map((row) => row?.id) } });
            }
          }}
          isLoading={isLoading}
          isBrokerSelected={!!selectedBroker}
        />
      ) : (
        <Filters
          MenuProps={MenuProps}
          control={control}
          debouncedHandleSearchChange={debouncedHandleSearchChange}
          filterTag={filterTag}
          handleChange={handleChange}
          tags={tags}
        />
      )}
    </>
  );
};

export default Header;
