import { useMutation, useQuery, useQueryClient } from 'react-query';
import { customInstance } from './client';
import useChangeEffect from '../hooks/useChangeEffect';

const suffix = '/termins';
const suffix2 = '/termin-slots';

export const getAllTermins = (params) => {
  return customInstance({
    url: `${suffix}`,
    method: 'get',
    params,
  });
};

export const getUserTermins = (userId) => customInstance({ url: `${suffix}/user/${userId}` });

export const useUserTermins = (userId) =>
  useQuery(['termins', userId], () => getUserTermins(userId), { enabled: !!userId });

export const useGetAllTermins = (params) => {
  const query = useQuery(['termins'], () => getAllTermins(params));

  useChangeEffect(() => {
    if (params) {
      query.refetch();
    }
  }, [JSON.stringify(params)]);

  return query;
};

const getAllTerminsByBroker = (params) => {
  return customInstance({
    url: `${suffix}/broker/${params?.id}`,
    method: 'get',
    params,
  });
};

export const useGetAllTerminsByBroker = (params) => {
  const query = useQuery(['termins'], () => getAllTerminsByBroker(params));

  useChangeEffect(() => {
    if (params) {
      query.refetch();
    }
  }, [JSON.stringify(params)]);

  return query;
};

const createTermin = async (data) => {
  return customInstance({
    url: `${suffix}`,
    method: 'post',
    data,
  });
};

export const useCreateTermins = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createTermin(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['termins'] });
    },
  });
};

const createTerminForUser = async (data) => {
  return customInstance({
    url: `${suffix}/create-termin-for-user`,
    method: 'post',
    data,
  });
};

export const useCreateTerminForUser = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createTerminForUser(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['termins'] });
    },
  });
};

const getTerminsByLead = (params) => {
  return customInstance({
    url: `${suffix}/lead/${params?.id}`,
    method: 'get',
    params,
  });
};

export const useGetAllTerminsByLead = (params) => {
  const query = useQuery(['termins'], () => getTerminsByLead(params));

  useChangeEffect(() => {
    if (params) {
      query.refetch();
    }
  }, [JSON.stringify(params)]);

  return query;
};

//TERMIN SLOTS

//GET TERMIN SLOTS
const getTerminSlots = () => {
  return customInstance({
    url: `${suffix2}`,
    method: 'get',
  });
};

export const useGetAllTerminSlots = () => {
  const query = useQuery(['termin-slots'], () => getTerminSlots());
  return query;
};

//POST TERMIN SLOTS
const createTerminSlots = (data) => {
  return customInstance({
    url: `${suffix2}`,
    method: 'post',
    data,
  });
};

export const useCreateTerminSlots = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createTerminSlots(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['termin-slots'] });
    },
  });
};

//PUT TERMIN SLOTS
const updateTerminSlots = (id, data) => {
  return customInstance({
    url: `${suffix2}/${id}`,
    method: 'put',
    data: data,
  });
};

export const useUpdateTerminSLots = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateTerminSlots(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['termin-slots'] });
    },
  });
};

//DELETE TERMIN SLOTS
const deleteTerminSlots = (id) => {
  return customInstance({
    url: `${suffix2}/${id}`,
    method: 'delete',
  });
};
export const useDeleteTerminSLots = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => deleteTerminSlots(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['termin-slots'] });
    },
  });
};

export const getAllLeadTermins = (params) => {
  return customInstance({
    url: `${suffix}/leads-termins`,
    method: 'get',
    params,
  });
};

export const useGetAllLeadsTermins = (params) => {
  const query = useQuery(['termins'], () => getAllLeadTermins(params));

  useChangeEffect(() => {
    if (params) {
      query.refetch();
    }
  }, [JSON.stringify(params)]);

  return query;
};

export const getAllUsersTermins = (params) => {
  return customInstance({
    url: `${suffix}/users-termins`,
    method: 'get',
    params,
  });
};

export const useGetAllUsersTermins = (params) => {
  const query = useQuery(['termins'], () => getAllUsersTermins(params));

  useChangeEffect(() => {
    if (params) {
      query.refetch();
    }
  }, [JSON.stringify(params)]);

  return query;
};

export const createTerminSlotsLocalization = (data) =>
  customInstance({ url: '/termin-slot-locals', method: 'post', data });

const getTerminById = (id) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'get',
  });
};

export const useGetTerminById = (id) => {
  return useQuery(['termins', id], () => getTerminById(id), { enabled: !!id });
};

const updateAppointment = (id, data) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'put',
    data: data,
  });
};

export const useUpdateAppointment = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateAppointment(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['termins'] });
      queryClient.invalidateQueries({ queryKey: ['termin'] });
    },
  });
};

const assingBroker = (data) =>
  customInstance({ url: `${suffix}/user-appointments/assign-broker`, method: 'put', data });

export const useAssignTerminsBroker = () => {
  const queryClient = useQueryClient();
  return useMutation(({ data }) => assingBroker(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['termins'] });
      queryClient.invalidateQueries({ queryKey: ['termin'] });
    },
  });
};
