import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import isISODateValid from '../../utils/isISODateValid';
import { format } from 'date-fns';
import MenuDropdown from '../../components/adminDashboard/MenuDropdown';

const useJournalColumns = (handleDownload) => {
  const { t } = useTranslation();
  const menuItems = [
    {
      label: t('Export'),
      icon: 'https://taxofficestorage.blob.core.windows.net/media/export.svg',
      onClick: (row) => handleDownload(row),
    },
  ];
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          #
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row?.createdAt, {
      id: 'createdAt',
      cell: (info) => (
        <Typography>
          {isISODateValid(info.getValue()) && format(new Date(info.getValue()), 'dd.MM.yyyy hh:mm')}
        </Typography>
      ),
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('createdAt')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row?.from, {
      id: 'from',
      cell: (info) => (
        <Typography>
          {isISODateValid(info.getValue()) && format(new Date(info.getValue()), 'dd.MM.yyyy hh:mm')}
        </Typography>
      ),
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('date')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row?.interest, {
      id: 'interest',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('interest')}
        </Typography>
      ),
    }),
    columnHelper.accessor(
      (row) => {
        const firstName = row?.broker?.firstName ?? '';
        const lastName = row?.broker?.lastName ?? '';
        return `${firstName} ${lastName}`;
      },
      {
        id: 'broker',
        cell: (info) => <Typography>{info.getValue()}</Typography>,
        header: () => (
          <Typography variant="body1" fontWeight={700}>
            Broker
          </Typography>
        ),
      },
    ),
    columnHelper.accessor('action', {
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Action')}
        </Typography>
      ),
      cell: (info) => {
        return (
          <MenuDropdown
            id={info?.row?.original?.id}
            menuItems={menuItems.map((item) => ({ ...item, onClick: () => item.onClick(info.row.original) }))}
          />
        );
      },
      footer: (info) => info.column.id,
    }),
  ];
  return columns;
};

export default useJournalColumns;
