import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsTabs from '../components/settingsComponents/SettingsTabs';
import UserForm from '../components/dashboardComponents/UserForm';
import { useSelector } from 'react-redux';

const Settings = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const tabs = [
    {
      title: t('profile'),
      content: <UserForm />,
      icon: 'https://taxofficestorage.blob.core.windows.net/media/user.svg',
      auth: true,
    },
    currentUser?.roles.includes('admin') && {
      icon: 'https://taxofficestorage.blob.core.windows.net/media/relations.svg',
      title: t('Family Relations'),
      navigateTo: 'family_relations',
      auth: true,
    },
    currentUser?.roles.includes('admin') && {
      icon: 'https://taxofficestorage.blob.core.windows.net/media/archive.svg',
      title: t('Archives'),
      navigateTo: 'archives',
      auth: true,
    },
    currentUser?.roles.includes('admin') && {
      icon: 'https://taxofficestorage.blob.core.windows.net/media/archive-types.svg',
      title: t('Archive Types'),
      navigateTo: 'archive_types',
      auth: true,
    },
    currentUser?.roles.includes('admin') && {
      icon: 'https://taxofficestorage.blob.core.windows.net/media/people.svg',
      title: t('Lead Types'),
      navigateTo: 'lead_types',
      auth: true,
    },
    currentUser?.roles.includes('admin') && {
      icon: 'https://taxofficestorage.blob.core.windows.net/media/folder.svg',
      title: t('File Types'),
      navigateTo: 'file_types',
      auth: true,
    },
    currentUser?.roles.includes('admin') && {
      icon: 'https://taxofficestorage.blob.core.windows.net/media/status.svg',
      title: t('Statuses'),
      navigateTo: 'statuses',
      auth: true,
    },
    currentUser?.roles.includes('admin') && {
      icon: 'https://taxofficestorage.blob.core.windows.net/media/brokers.png',
      title: t('Brokers'),
      navigateTo: 'brokers',
      auth: true,
    },
    currentUser?.roles.includes('admin') && {
      icon: 'https://taxofficestorage.blob.core.windows.net/media/calendar.svg',
      title: t('AppointmentSlots'),
      navigateTo: 'appointment-slots',
      auth: true,
    },
    currentUser?.roles.includes('admin') && {
      icon: 'https://taxofficestorage.blob.core.windows.net/media/document-widgets.svg',
      title: t('DocumentWidgets'),
      navigateTo: 'document-widgets',
      auth: true,
    },
  ].filter(Boolean);

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Typography display="flex" justifyContent="space-between" variant="h6" fontWeight={700} px={5} py={2.45}>
          {t('settings')}
        </Typography>
        <Divider />
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
          <SettingsTabs tabs={tabs} />
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
