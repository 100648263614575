import { Box, Button, FormHelperText, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useResetPass } from '../../hooks/useResetPass';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import CustomTextField from '../commonComponents/CustomTextField';

const VerifyPassForm = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const { handleSubmit, onSubmit, control, watch } = useResetPass();

  const navigate = useNavigate();

  const pass = watch('password');
  const pass1 = watch('passwordConfirmation');

  return (
    <Box pt={10} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Box component="form" onSubmit={handleSubmit(onSubmit)} display="flex" flexDirection="column" gap="30px">
        <Controller
          name="otp"
          control={control}
          render={({ field, fieldState }) => (
            <Box textAlign="center" maxWidth={500}>
              <MuiOtpInput sx={{ gap: 1 }} {...field} length={6} onComplete={handleSubmit(onSubmit)} />
              {fieldState.invalid ? <FormHelperText error>{t('otpInvalid')}</FormHelperText> : null}
            </Box>
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <CustomTextField
                label={t('Password')}
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                color="success"
                error={!!error}
                {...field}
                InputProps={{
                  endAdornment: (
                    <IconButton onMouseDown={() => setShowPassword(true)} onMouseUp={() => setShowPassword(false)}>
                      <VisibilityOutlinedIcon />
                    </IconButton>
                  ),
                }}
              />
              {error && (
                <Typography variant="caption" color="error">
                  {error.message}
                </Typography>
              )}
            </>
          )}
        />
        <Controller
          name="passwordConfirmation"
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <CustomTextField
                  label={t('passwordConfirmation')}
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  color="success"
                  error={!!error}
                  {...field}
                  InputProps={{
                    endAdornment:
                      !error && pass === pass1 ? (
                        <IconButton>
                          <CheckCircleOutlineIcon color="success" />
                        </IconButton>
                      ) : (
                        pass1.length > 0 &&
                        error && (
                          <IconButton>
                            <CloseIcon color="error" />
                          </IconButton>
                        )
                      ),
                  }}
                />
                {error && (
                  <Typography variant="caption" color="error">
                    {error ? error.message : t('passwordsDoNotMatch')}
                  </Typography>
                )}
              </>
            );
          }}
        />

        <Button
          variant="contained"
          sx={{
            bgcolor: '#187D7E',
            '&:hover': {
              backgroundColor: '#0F5D66',
            },
            textTransform: 'none',
            borderRadius: '15px',
            paddingY: '12px',
            paddingX: '25px',
            color: 'white',
            width: '100%',
          }}
          type="submit"
        >
          {t('Confirm')}
        </Button>
      </Box>
      <Box display="flex" w="100%" paddingTop={3} justifyContent="center">
        <Button onClick={() => navigate('/auth')}>
          <Typography variant="button" sx={{ borderBottom: '1px solid #1A1C1E' }}>
            {t('Go.Back')}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default VerifyPassForm;
