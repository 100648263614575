import React from 'react';
import ButtonPicker from '../../ButtonPicker';
import { useTranslation } from 'react-i18next';
import useSetFromUserInfo from '../../../hooks/useSetFromUserInfo';

const CategorySelect = ({ taxValues, setTaxValues }) => {
  const { t } = useTranslation();

  const taxOptions = [
    {
      label: t('estate'),
      value: 'estate',
    },
    {
      label: t('insurance'),
      value: 'insurance',
    },
    {
      label: t('receivedAlimente'),
      value: 'receivedAlimente',
    },
    {
      label: t('payedAlimente'),
      value: 'payedAlimente',
    },
    {
      label: t('credit'),
      value: 'credit',
    },
    {
      label: t('bonds'),
      value: 'bonds',
    },
  ];

  useSetFromUserInfo('estate', setTaxValues, {
    label: t('estate'),
    value: 'estate',
  });

  useSetFromUserInfo('insurance', setTaxValues, {
    label: t('insurance'),
    value: 'insurance',
  });

  useSetFromUserInfo('receivedAlimente', setTaxValues, {
    label: t('receivedAlimente'),
    value: 'receivedAlimente',
  });

  useSetFromUserInfo('payedAlimente', setTaxValues, {
    label: t('payedAlimente'),
    value: 'payedAlimente',
  });

  useSetFromUserInfo('credit', setTaxValues, {
    label: t('credit'),
    value: 'credit',
  });

  useSetFromUserInfo('bonds', setTaxValues, {
    label: t('bonds'),
    value: 'bonds',
  });

  return <ButtonPicker options={taxOptions} isMulti value={taxValues} onChange={setTaxValues} />;
};

export default CategorySelect;
