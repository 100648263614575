const findDeepType = (options, path, lang) => {
  let result = null;

  const search = (options) => {
    if (options[`name_${lang}`] === path) {
      result = options;
      return true;
    }
    if (options.subTypes?.length) {
      for (const sub of options.subTypes) {
        if (search(sub)) {
          return true;
        }
      }
    }
    return false;
  };

  search(options);
  return result;
};

export default findDeepType;
