import { Box, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import MenuDropdown from '../../components/adminDashboard/MenuDropdown';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const useStatusColumns = (handleEdit) => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper();
  const menuItems = [
    {
      label: t('Edit'),
      icon: 'https://taxofficestorage.blob.core.windows.net/media/edit-2.svg',
      onClick: (row) => {
        const nameEng = row.locals?.find((val) => val.language === 'eng');
        const nameDe = row.locals?.find((val) => val.language === 'de');
        handleEdit({ ...row, name: { eng: nameEng?.name, de: nameDe?.name } });
      },
    },
  ];
  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          #
        </Typography>
      ),
    }),

    columnHelper.accessor((row) => row.entity, {
      id: 'entity',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Entity')}
        </Typography>
      ),
    }),

    columnHelper.accessor((row) => row.locals, {
      id: 'locals.eng',
      cell: (info) => {
        const label = info.getValue()?.find((val) => val.language === 'eng');
        return <Typography>{label ? label?.name : t('missing')}</Typography>;
      },
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('English')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.locals, {
      id: 'locals.de',
      cell: (info) => {
        const label = info.getValue()?.find((val) => val.language === 'de');
        return <Typography>{label ? label?.name : t('missing')}</Typography>;
      },
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Deutsch')}
        </Typography>
      ),
    }),

    columnHelper.accessor((row) => row.color, {
      id: 'color',
      cell: (info) => (
        <Box width="20px" height="20px" borderRadius="100%" border="1px solid #ccdae2" bgcolor={info.getValue}></Box>
      ),
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Color')}
        </Typography>
      ),
    }),

    columnHelper.accessor((row) => row.validFrom, {
      id: 'validFrom',
      cell: (info) => <Typography>{format(new Date(info.getValue()), 'dd.MM.yyyy')}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('ValidFrom')}
        </Typography>
      ),
    }),

    columnHelper.accessor((row) => row.validTo, {
      id: 'validTo',
      cell: (info) => <Typography>{format(new Date(info.getValue()), 'dd.MM.yyyy')}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('ValidTo')}
        </Typography>
      ),
    }),

    columnHelper.accessor((row) => row.is_active, {
      id: 'is_active',
      cell: (info) => <Typography>{info.row.original.is_active ? 'Active' : 'Inactive'}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('IsActive')}
        </Typography>
      ),
    }),

    columnHelper.accessor('action', {
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Action')}
        </Typography>
      ),
      cell: (info) => {
        return (
          <MenuDropdown
            id={info.row.original.id}
            menuItems={menuItems.map((item) => ({ ...item, onClick: () => item.onClick(info.row.original) }))}
          />
        );
      },
      footer: (info) => info.column.id,
    }),
  ];
  return columns;
};

export default useStatusColumns;
