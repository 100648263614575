import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Check from '@mui/icons-material/CheckCircle';
import Minus from '@mui/icons-material/RemoveCircle';
import Cancel from '@mui/icons-material/Cancel';
import GetStartedButton from '../homeComponent/GetStartedButton';
import useCurrentUser from '../../hooks/useCurrentUser';

export default function CardSub({ planName, options, price, img }) {
  const { id: loggedUser } = useCurrentUser();
  const { t } = useTranslation();
  const iconSelector = (icon) => {
    switch (icon) {
      case 'remove':
        return <Cancel sx={{ color: '#BB2320' }} />;
      case 'minus':
        return <Minus sx={{ color: '#959595' }} />;
      default:
        return <Check sx={{ color: '#23978D' }} />;
    }
  };
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: '95%',
          height: '95%',
          m: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-around',
          borderRadius: '16px',
          padding: '32px',
        }}
      >
        <Box component="img" src={img}></Box>
        <Typography variant="h5">{t(planName)}</Typography>
        <Typography variant="body1">{t('price.text')}</Typography>
        <Typography variant="h6" color="#23978D">
          {price}
        </Typography>
        <Box display="flex" gap="10px" py="10px" flexDirection="column">
          {options?.map(({ name, icon }, i) => (
            <Typography key={i} variant="body1" display="flex" alignItems="center" gap="8px">
              {iconSelector(icon)}
              {t(name)}
            </Typography>
          ))}
        </Box>
        <GetStartedButton fullWidth destination={loggedUser ? '/dashboard' : '/auth/register'} />
      </Paper>
    </>
  );
}
