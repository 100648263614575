import Box from '@mui/material/Box';
import React from 'react';
import { useFamilyRelations } from '../../../hooks/useFamilyRelaotions';
import { useTranslation } from 'react-i18next';
import CustomFormInput from '../../commonComponents/CustomFormInput';
import SubmitButton from '../../commonComponents/SubmitButton';
import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import { CustomSwitch } from '../../commonComponents/CustomSwitch';

const FamilyRelationsForm = ({ handleDrawerClose, selectedRelation }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, onSubmit } = useFamilyRelations(handleDrawerClose, selectedRelation);
  return (
    <Box display="flex" flexDirection="column">
      <Typography>{t('localization')}</Typography>
      <CustomFormInput name="name.eng" control={control} label={t('FamilyRelationName English')} />
      <CustomFormInput name="name.de" control={control} label={t('FamilyRelationName Deutsch')} />
      {selectedRelation && (
        <Controller
          name="is_active"
          control={control}
          render={({ field }) => (
            <Box display="flex" alignContent="center">
              <Typography>{t('Inactive')}</Typography>
              <CustomSwitch checked={field.value || false} onChange={() => field.onChange(!field.value)} {...field} />
              <Typography>{t('Active')}</Typography>
            </Box>
          )}
        />
      )}
      <SubmitButton
        onClick={handleSubmit(onSubmit)}
        buttonText={t('AddFamilyRelation')}
        backgroundColor="#31B099"
        color="inherit"
      />
    </Box>
  );
};

export default FamilyRelationsForm;
