import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RequireAuth from '../RequireAuth';

const RequireAdmin = (ChildComponent) => {
  const ComposedComponent = (props) => {
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.auth.currentUser);

    useEffect(() => {
      if (Array.isArray(currentUser?.roles)) {
        if (!currentUser?.roles.includes('admin')) {
          navigate('/dashboard');
        }
      }
    }, [currentUser, navigate]);

    return <ChildComponent {...props} />;
  };
  return RequireAuth(ComposedComponent);
};

export default RequireAdmin;
