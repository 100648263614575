/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form';
import {
  useCreateFamilyRelation,
  useCreateFamilyRelationLocalization,
  useUpdateFamilyRelation,
  useUpdateFamilyRelationLocalization,
} from '../api/familyMembers';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import useToastNotification from './useToastNotification';
import { useEffect, useState } from 'react';

export const useFamilyRelations = (handleDrawerClose, selectedRelation) => {
  const { t } = useTranslation();
  const [submittedData, setSubmittedData] = useState(null);
  const { mutate, isSuccess, isLoading, isError, data: addData } = useCreateFamilyRelation();
  const { mutate: mutateLocalization } = useCreateFamilyRelationLocalization();
  const {
    mutate: editMutate,
    isSuccess: isEditSuccess,
    isLoading: isEditLoading,
    isError: isEditError,
    data: editData,
  } = useUpdateFamilyRelation();
  const { mutate: editLocalization } = useUpdateFamilyRelationLocalization();

  const addFamilyRelationSchema = yup
    .object()
    .shape({
      name: yup.object().shape({
        eng: yup.string().required(t('name.required')),
        de: yup.string().required(t('name.required')),
      }),
      is_active: yup.boolean(),
    })
    .required();

  const defaultValues = {
    name: { eng: selectedRelation?.name?.eng || '', de: selectedRelation?.name?.de || '' },
    is_active: selectedRelation ? selectedRelation.is_active : true,
  };
  const { handleSubmit, control, reset, getValues } = useForm({
    defaultValues,
    resolver: yupResolver(addFamilyRelationSchema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    reset(defaultValues);
  }, [selectedRelation, reset]);

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'SuccessFamilyRelation',
    variant: 'success',
    cb: () => {
      if (addData) {
        mutateLocalization({ familyRelation: addData.id, name: submittedData?.name?.de, language: 'de' });
        reset(defaultValues);
        handleDrawerClose();
      }
    },
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'ErrorFamilyRelation',
    variant: 'error',
  });

  //EDIT TOASTS
  useToastNotification({
    isShow: isEditSuccess,
    loading: isEditLoading,
    messageKey: 'successEditFamilyRelation',
    variant: 'success',
    cb: () => {
      if (selectedRelation) {
        const formVals = getValues();
        const foundEng = selectedRelation?.locals?.find((local) => local.language === 'eng');
        const foundDe = selectedRelation?.locals?.find((local) => local.language === 'de');
        if (foundDe && foundEng) {
          mutateLocalization([
            { id: foundEng.id, language: 'eng', name: formVals?.name?.eng, familyRelation: selectedRelation?.id },
            { id: foundDe.id, language: 'de', name: formVals?.name?.de, familyRelation: selectedRelation?.id },
          ]);
        }
        if (foundDe && !foundEng) {
          mutateLocalization([
            { language: 'eng', name: formVals?.name?.eng, familyRelation: selectedRelation?.id },
            { id: foundDe.id, language: 'de', name: formVals?.name?.de, familyRelation: selectedRelation?.id },
          ]);
        }
        if (!foundDe && foundEng) {
          mutateLocalization([
            { id: foundEng.id, language: 'eng', name: formVals?.name?.eng, familyRelation: selectedRelation?.id },
            { language: 'de', name: formVals?.name?.de, familyRelation: selectedRelation?.id },
          ]);
        }
        if (!foundDe && !foundEng) {
          mutateLocalization([
            { language: 'eng', name: formVals?.name?.eng, familyRelation: selectedRelation?.id },
            { language: 'de', name: formVals?.name?.de, familyRelation: selectedRelation?.id },
          ]);
        }
        reset(defaultValues);
        handleDrawerClose();
      }
    },
  });

  useEffect(() => {
    reset(defaultValues);
  }, [selectedRelation]);

  useToastNotification({
    isShow: isEditError,
    loading: isEditLoading,
    messageKey: 'ErrorEditFamilyRelation',
    variant: 'error',
  });

  const onSubmit = async (data) => {
    setSubmittedData(data);
    if (selectedRelation && selectedRelation.id) {
      editMutate({ id: selectedRelation.id, data: { is_active: data?.is_active } });
    } else {
      mutate({ ...data, name: data.name.eng, language: 'eng' });
    }
  };
  return {
    control,
    handleSubmit,
    onSubmit,
  };
};
