import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetAllTerminsByBroker } from '../../api/termins';
import { Controller } from 'react-hook-form';
import useChangeEffect from '../../hooks/useChangeEffect';
import { debounce } from 'lodash-es';
import useTerminsColumns from '../../hooks/AllUsers/useLeadTerminsColumns';
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import TableComponent from '../Table/Table';
import { tags } from '../adminDashboard/users/tags';
import CustomTextField from '../commonComponents/CustomTextField';
import { handleLimit, handleNextPage, handlePrevPage } from '../../utils/paginationFunction';
import CustomDrawer from '../modals/CustomDrawer';
import CloseIcon from '@mui/icons-material/Close';
import AppointmentForm from './AppointmentForm';
import { useEditAppointment } from '../../hooks/useEditAppointment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BrokerAppointments = () => {
  const { t } = useTranslation();
  const { currentUser } = useSelector((state) => state.auth);
  const [keyword, setKeyword] = useState('');
  const [filterTag, setFilterTag] = useState(['search']);
  const [filterTagData, setFilterTagData] = useState({});
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const {
    data: allTermins,
    isLoading,
    isFetching,
  } = useGetAllTerminsByBroker({ id: currentUser?.id, page, limit, ...filterTagData });

  const termins = allTermins?.results;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterTag(typeof value === 'string' ? value.split(',') : value);
  };

  const updateFilterTagData = () => {
    const dynamicFilterTag = filterTag;
    const dynamicKeyword = keyword;

    setFilterTagData({ [dynamicFilterTag]: dynamicKeyword });
  };

  useChangeEffect(() => {
    updateFilterTagData();
  }, [filterTag, keyword]);

  const debouncedHandleSearchChange = debounce((value) => {
    setKeyword(value);
    setPage(0);
  }, 300);

  const handleOpen = () => {
    setOpenDrawer(!openDrawer);
  };

  const { handleSubmit, control, onSubmit } = useEditAppointment(currentUser?.id, handleOpen, selectedAppointment);

  const handleEdit = (row) => {
    setSelectedAppointment(row);
    setOpenDrawer(true);
  };
  const columns = useTerminsColumns(handleEdit);
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
        <Box display="flex" flexDirection="column" width="100%">
          <Typography variant="h6" fontWeight={700} px={5} py={2.45}>
            {t('AllAppointments')}
          </Typography>
          <Divider />

          <Box m={5}>
            {termins && (
              <TableComponent
                hasAboveStuff
                data={termins}
                columns={columns}
                hasPagination
                rowsPerPage={allTermins?.limit}
                page={allTermins?.currentPage - 1}
                handleNextPage={() => handleNextPage(setPage)}
                handlePrevPage={() => handlePrevPage(setPage)}
                hasNextPage={allTermins?.hasNextPage}
                hasPreviousPage={allTermins?.hasPreviousPage}
                onPageChange={(lim) => handleLimit(lim, setLimit)}
                setPage={setPage}
                isLoading={isLoading}
                isFetching={isFetching}
                total={allTermins?.total}
              >
                <Box display="flex" alignItems="center">
                  <Typography variant="h6" fontWeight={700}>
                    {t('Appointments')}
                  </Typography>
                </Box>
                <Box display="flex" gap="20px" alignItems="center">
                  <FormControl sx={{ width: 200 }}>
                    <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      value={filterTag}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" sx={{ border: '1px solid #DCE4E8', borderRadius: '15px' }} />}
                      renderValue={(selected) => {
                        const selectedTag = tags.find((tag) => tag.value === selected[0]);
                        return selectedTag ? selectedTag.label : '';
                      }}
                      MenuProps={MenuProps}
                    >
                      {tags?.map((tag) => (
                        <MenuItem key={tag.value} value={tag.value}>
                          <ListItemText primary={tag.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Controller
                    name="search"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Box display="flex" flexDirection="column">
                        <CustomTextField
                          id="search"
                          value={value ? value : ''}
                          label="Search"
                          onChange={(event) => {
                            onChange(event);
                            debouncedHandleSearchChange(event.target.value);
                          }}
                        />
                      </Box>
                    )}
                  />

                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: '#fff',
                      color: '#000',
                      fontWeight: 700,
                      textTransform: 'none',
                      borderRadius: '25px',
                      width: 'fit-content',
                      paddingY: '16px',
                      paddingX: '25px',
                      display: 'flex',
                      gap: '10px',
                    }}
                  >
                    <img alt="Menu Icon" src={'https://taxofficestorage.blob.core.windows.net/media/export.svg'}></img>
                    <Typography fontWeight={700}>{t('Export')}</Typography>
                  </Button>
                </Box>
              </TableComponent>
            )}
          </Box>
        </Box>
      </Box>

      {/* Add Drawer */}
      <CustomDrawer open={openDrawer} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('EditAppointment')}
          </Typography>
          <IconButton
            onClick={() => {
              handleOpen();
              setSelectedAppointment();
            }}
          >
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <AppointmentForm handleSubmit={handleSubmit} control={control} onSubmit={onSubmit} t={t} />
      </CustomDrawer>
    </>
  );
};

export default BrokerAppointments;
