import { useQuery } from 'react-query';
import { getFileTypeChildren } from '../services/files';

const useFileTypeChildren = (parentId, enabled = true) =>
  useQuery(['file', 'type', 'children', parentId], () => getFileTypeChildren(parentId), {
    refetchOnWindowFocus: false,
    enabled,
  });

export default useFileTypeChildren;
