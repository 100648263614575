import { Box } from '@mui/material';
import React from 'react';
import VerticalHeader from '../../components/dashboardComponents/VerticalHeader';
import { Outlet } from 'react-router-dom';
import RequireBroker from '../../components/RequireBroker';

const BrokerLayout = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <VerticalHeader option="broker" />
      <Box sx={{ overflow: 'hidden', width: '100%' }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default RequireBroker(BrokerLayout);
