/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { snakeCase } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const VListItem = ({ imgSrc, handleClose, auth, label, isActive = false, navigateTo, ...props }) => {
  const { t } = useTranslation();
  const { currentUser } = useSelector((state) => state.auth);
  const finishedTrial = currentUser?.info?.finishedTrial;
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (finishedTrial) {
      setIsVisible(true);
    } else {
      setIsVisible(!auth);
    }
  }, [finishedTrial, auth]);
  return (
    <ListItem {...props} sx={{ padding: 1 }}>
      <ListItemButton
        onClick={() => {
          if (navigateTo) {
            navigate(`/${navigateTo}`, { state: snakeCase(label) });
            handleClose();
          }
        }}
        sx={{
          display: 'flex',
          borderRadius: '12px',
          height: '40px',
          color: isActive ? '#ffffff' : '#718096',
          bgcolor: isActive && '#004852',
          '& img': {
            filter: isActive
              ? 'brightness(1000)'
              : 'brightness(0) saturate(100%) invert(53%) sepia(19%) saturate(440%) hue-rotate(176deg) brightness(90%) contrast(88%)',
          },
          '&:hover': {
            bgcolor: '#00485288',
            color: '#fff',
            '& img': {
              filter: 'brightness(1000)',
            },
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: '40px' }}>
          <Box component="img" src={imgSrc}></Box>
        </ListItemIcon>
        <ListItemText>
          <Typography
            sx={{
              '&': {
                lineHeight: '18px',
              },
            }}
            maxHeight={40}
            fontWeight={isActive ? 700 : 400}
            variant="body1"
          >
            {t(label)}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default VListItem;
