import { Button, Typography } from '@mui/material';
import React from 'react';
import { useForgotPass } from '../../hooks/useForgotPass';
import { useTranslation } from 'react-i18next';
const ResetPass = ({ email }) => {
  const { handleSubmit, onSubmit } = useForgotPass();
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      component="label"
      color="inherit"
      sx={{
        bgcolor: '#852C18',
        ':hover': { bgcolor: '#6E190E' },
        textTransform: 'none',
        borderRadius: '15px',
        paddingY: '10px',
        paddingX: '25px',
        marginTop: '10px',
        flex: 1,
      }}
      onClick={() => {
        handleSubmit(onSubmit({ email: email }));
      }}
    >
      <Typography variant="body1" color="#fff">
        {t('resetPassword')}
      </Typography>
    </Button>
  );
};

export default ResetPass;
