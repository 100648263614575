import React, { useState } from 'react';
import { useGetAllTableNames } from '../../../api/status';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import ColorPicker from '../../commonComponents/ColorPicker';
import SubmitButton from '../../commonComponents/SubmitButton';
import CustomFormInput from '../../commonComponents/CustomFormInput';
import CustomSelect from '../../commonComponents/CustomSelect';
import { CustomSwitch } from '../../commonComponents/CustomSwitch';

const StatusForm = ({ handleSubmit, control, onSubmit, selectedStatus }) => {
  const initialColor = selectedStatus?.color || '#FFFFFF';
  const { t } = useTranslation();
  const [selectedColor, setSelectedColor] = useState(initialColor);
  const { data } = useGetAllTableNames();

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  return (
    <Box component="form" display="flex" flexDirection="column">
      <CustomSelect
        control={control}
        name="entity"
        label={t('Entity')}
        data={data}
        valueField={(item) => item}
        displayField={(item) => item}
      />
      <Box py={2}>
        <Typography>{t('localization')}</Typography>
        <CustomFormInput name="name.de" control={control} label={t('StatusNameDe')} />
        <CustomFormInput name="name.eng" control={control} label={t('StatusNameEng')} />
      </Box>
      <CustomFormInput name="validFrom" type="date" control={control} label={t('ValidFrom')} />
      <CustomFormInput name="validTo" type="date" control={control} label={t('ValidTo')} />
      <Controller
        name="color"
        control={control}
        render={({ field }) => (
          <ColorPicker
            initialColor={selectedColor}
            onColorChange={(color) => {
              handleColorChange(color);
              field.onChange(color);
            }}
          />
        )}
      />
      {selectedStatus && (
        <Controller
          name="is_active"
          control={control}
          render={({ field }) => (
            <Box display="flex" alignContent="center">
              <Typography>{t('Inactive')}</Typography>
              <CustomSwitch checked={field.value || false} onChange={() => field.onChange(!field.value)} {...field} />
              <Typography>{t('Active')}</Typography>
            </Box>
          )}
        />
      )}

      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default StatusForm;
