import { MenuItem, Select } from '@mui/material';
import React, { useCallback } from 'react';

const TaxYearSelector = ({ taxYear, setTaxYear, archives }) => {
  const handleChange = useCallback(
    (e) => {
      setTaxYear(e.target.value);
    },
    [setTaxYear],
  );

  return (
    <Select
      labelId="taxYearLabel"
      value={taxYear}
      onChange={handleChange}
      sx={{
        borderRadius: '15px',
        width: '100px',
        height: '54px',
      }}
    >
      {archives?.map((archive, index) => (
        <MenuItem key={index} value={archive?.id}>
          {archive?.name}
        </MenuItem>
      ))}
    </Select>
  );
};
export default TaxYearSelector;
