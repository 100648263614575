import { Box } from '@mui/material';
import React from 'react';

const TableContainer = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 34px 184px -10px #0000000F',
        border: '2px solid #DCE4E8',
        borderRadius: '15px',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default TableContainer;
