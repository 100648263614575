import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const PhoneInput = ({ onChange, value, name, error }) => {
  const handleInputChange = (event) => {
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length <= 18) {
      onChange(onlyNums);
    }
  };

  return (
    <TextField
      name={name}
      variant="outlined"
      value={value}
      onChange={handleInputChange}
      error={!!error}
      helperText={error?.message}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img alt="Menu Icon" src={'https://taxofficestorage.blob.core.windows.net/media/phone.svg'}></img>
          </InputAdornment>
        ),
      }}
      inputProps={{
        type: 'tel',
        pattern: '[0-9]*',
        inputMode: 'numeric',
        maxLength: 18,
      }}
    />
  );
};

export default PhoneInput;
