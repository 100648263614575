import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RequireAuth = (ChildComponent) => {
  const ComposedComponent = (props) => {
    const navigate = useNavigate();
    const { accessToken } = useSelector((state) => state.auth);
    const { pathname } = useLocation();

    useEffect(() => {
      if (!accessToken) {
        navigate(`/?redirect=${encodeURIComponent(pathname)}`);
      }
    }, [accessToken, navigate, pathname]);

    return <ChildComponent {...props} />;
  };
  return ComposedComponent;
};

export default RequireAuth;
