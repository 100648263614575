import { useMutation, useQuery, useQueryClient } from 'react-query';
import { customInstance } from './client';
import useChangeEffect from '../hooks/useChangeEffect';

const suffix = '/lead-notes';
const suffix2 = '/user-notes';

const createNote = async (data) => {
  return customInstance({
    url: `${suffix}`,
    method: 'post',
    data,
  });
};

export const useCreateNote = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createNote(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lead-notes'] });
    },
  });
};

const createUserNote = async (data) => {
  return customInstance({
    url: `${suffix2}`,
    method: 'post',
    data,
  });
};

export const useCreateUserNote = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createUserNote(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user-notes'] });
    },
  });
};

const getNotesByUserId = (params) => {
  return customInstance({
    url: `${suffix2}/${params?.id}`,
    method: 'get',
    params,
  });
};

export const useGetNotesByUser = (params) => {
  const query = useQuery(['user-notes'], () => getNotesByUserId(params));

  useChangeEffect(() => {
    if (params) {
      query.refetch();
    }
  }, [JSON.stringify(params)]);

  return query;
};
