import { createColumnHelper } from '@tanstack/react-table';
import MenuDropdown from '../../components/adminDashboard/MenuDropdown';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useArchiveTypeColumns = (handleOpenEdit) => {
  const { t, i18n } = useTranslation();
  const menuItems = [
    {
      label: t('Edit'),
      icon: 'https://taxofficestorage.blob.core.windows.net/media/edit-2.svg',
      onClick: (id) => handleOpenEdit(id),
    },
  ];
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((_row, i) => i + 1, {
      id: 'index',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          #
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row?.locals?.find((local) => local.language === i18n.language)?.name || row?.name, {
      id: 'name',
      cell: (info) => <Typography>{info.getValue()}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('name')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.is_active, {
      id: 'is_active',
      cell: (info) => <Typography>{info.row.original.is_active ? 'Active' : 'Inactive'}</Typography>,
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('IsActive')}
        </Typography>
      ),
    }),
    columnHelper.accessor((row) => row.icon, {
      id: 'icon',
      cell: (info) => (
        <img
          src={info.getValue()}
          alt={info.getValue()}
          style={{
            width: '30px',
          }}
        ></img>
      ),
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Icon')}
        </Typography>
      ),
    }),
    columnHelper.accessor('action', {
      header: () => (
        <Typography variant="body1" fontWeight={700}>
          {t('Action')}
        </Typography>
      ),
      cell: (info) => {
        return <MenuDropdown id={info.row.original.id} menuItems={menuItems} />;
      },
      footer: (info) => info.column.id,
    }),
  ];
  return columns;
};
export default useArchiveTypeColumns;
