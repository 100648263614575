import { Typography } from '@mui/material';
import React from 'react';
import TableComponent from '../../Table/Table';
import useFamilyMemberForUserColumn from '../../../hooks/AllUsers/useFamilyMemberForUserColumn';
import { useTranslation } from 'react-i18next';
import TableContainer from '../../Table/TableContainer';

const FamilyMemberForUser = ({ data }) => {
  const { t } = useTranslation();
  const columns = useFamilyMemberForUserColumn();

  return (
    <TableContainer>
      {data && (
        <TableComponent hasBoxShadow hasAboveStuff data={data} columns={columns}>
          <Typography variant="h6">{t('FamilyMembers')}</Typography>
        </TableComponent>
      )}
    </TableContainer>
  );
};

export default FamilyMemberForUser;
