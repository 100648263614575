import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import LanguageSelect from '../../selectTranslation/translateSelect';
import Form from './Form/Form';
import HeaderLogo from '../../LeadsForms/MainLeadForms/HeaderLogo';
import LeadCheckerList from '../../LeadsForms/MainLeadForms/LeadCheckerList';
import { Link } from 'react-router-dom';

const LeadForm = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: '#037A68',
        paddingTop: '32px',
        paddingBottom: '3px',
        gap: '22px',
        borderRadius: { md: '15px' },
        boxShadow: '0px 34px 114px rgba(0, 0, 0, 0.08)',
        width: { xs: '100%', md: '480px' },
        minHeight: { xs: '100vh', md: '100%' },
        zIndex: 2,
      }}
    >
      <HeaderLogo title={t('TaxReturn')} />
      <Box
        sx={{
          bgcolor: 'white',
          zIndex: '1001',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '32px',
          gap: '32px',
          borderRadius: { md: '15px' },
          boxShadow: '0px 34px 114px rgba(0, 0, 0, 0.08)',
          width: { xs: '100%', md: '480px' },
          flex: { xs: 1 },
        }}
      >
        <Form />
        <Typography fontWeight="700" color="#094554" align="center">
          {t('TaxProcess')}
        </Typography>

        <LeadCheckerList />
        <Typography sx={{ color: '#31B099', cursor: 'pointer', userSelect: 'none' }}>
          <Link
            href="/impressum"
            target="_blank"
            sx={{
              textDecoration: 'none',
              color: '#31B099',
              ':hover': { textDecoration: 'underline' },
            }}
          >
            {t('Impressum')}
          </Link>
          {' / '}
          <Link
            href="/legal"
            target="_blank"
            sx={{
              textDecoration: 'none',
              color: '#31B099',
              ':hover': { textDecoration: 'underline' },
            }}
          >
            {t('Legal')}
          </Link>
        </Typography>
        <LanguageSelect />
      </Box>
    </Box>
  );
};

export default LeadForm;
