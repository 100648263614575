import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useEditFamMember, useGetAllFamilyRelations, useGetFamilyMemberById } from '../api/familyMembers';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import useToastNotification from './useToastNotification';

export const useEditFamilyMember = (id, handleDrawerClose) => {
  const { mutate, isSuccess, isLoading, isError } = useEditFamMember();
  const { data: familyMember } = useGetFamilyMemberById(id);
  const { data: famRel } = useGetAllFamilyRelations();
  const [defaultValuesLoaded, setDefaultValuesLoaded] = useState(false);
  let defaultValues = {};
  const famRelationArr = famRel?.map((fam) => fam.id);
  const editFamilyRelationSchema = yup
    .object()
    .shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      birthDate: yup.date().required(),
      familyRelation: famRelationArr && yup.string().oneOf(famRelationArr).required(),
    })
    .required();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValuesLoaded ? defaultValues : {},
    resolver: yupResolver(editFamilyRelationSchema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (familyMember) {
      const defaultValues = {
        firstName: familyMember?.firstName || '',
        lastName: familyMember?.lastName || '',
        birthDate: familyMember?.birthDate ? format(new Date(familyMember?.birthDate), 'yyyy-MM-dd') : '',
        familyRelation: familyMember?.familyRelation?.id || '',
      };
      reset(defaultValues);
      setDefaultValuesLoaded(true);
    }
  }, [familyMember, reset]);

  useEffect(() => {
    if (familyMember) {
      setDefaultValuesLoaded(true);
    }
  }, [familyMember]);

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'successEditFamilyMember',
    variant: 'success',
  });

  useToastNotification({
    isShow: isError,
    loading: isLoading,
    messageKey: 'errorEditFamilyMember',
    variant: 'error',
  });

  const onSubmit = async (data) => {
    mutate({ id, data });
    reset();
    handleDrawerClose();
  };

  return {
    handleSubmit,
    onSubmit,
    control,
    errors,
    defaultValuesLoaded,
  };
};
