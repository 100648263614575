import React, { useEffect, useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Box, TablePagination } from '@mui/material';
import AboveTable from './AboveTable';
import DraggableRow from './DraggableRow';
import useChangeEffect from '../../hooks/useChangeEffect';

const TableComponent = ({
  data,
  children,
  columns,
  hasAboveStuff,
  hasPagination,
  page = 0,
  hasBoxShadow,
  rowsPerPage = 5,
  isDraggable = false,
  onRowDrop = (draggerdRow, droppedRow) => {},
  autoReorder = false,
  hasNextPage,
  hasPreviousPage,
  handleNextPage,
  handlePrevPage,
  onPageChange,
  setPage,
  isLoading,
  isFetching,
  total,
  onRowClick = () => {},
  cursor = '',
  hasIndexRow = true,
  getOriginalSelectedRows,
  rowSelection,
  setRowSelection,
}) => {
  const [tableData, setTableData] = useState(data);
  useChangeEffect(() => {
    setTableData(data);
  }, [data]);

  const reorderRow = (draggedRowIndex, targetRowIndex) => {
    tableData.splice(targetRowIndex, 0, tableData.splice(draggedRowIndex, 1)[0]);
    setTableData([...tableData]);
  };

  const table = useReactTable({
    data: tableData,
    columns,

    state: {
      rowSelection: rowSelection || {},
    },
    enableMultiRowSelection: true,
    enableRowSelection: true,
    onRowSelectionChange: (selection) => {
      if (setRowSelection) {
        setRowSelection(selection);
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row?.userId,
  });
  const getRowById = (id) => {
    return table.getRow(id);
  };

  useEffect(() => {
    if (getOriginalSelectedRows) {
      getOriginalSelectedRows(table.getSelectedRowModel()?.rows?.map((row) => row.original));
    }
  }, [table.getSelectedRowModel()?.rows]);

  return (
    <Box
      sx={{
        borderRadius: '15px',
        overflow: 'hidden',
        overflowX: 'auto',
        boxShadow: !hasBoxShadow ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : undefined,
      }}
    >
      {hasAboveStuff && <AboveTable>{children}</AboveTable>}
      <TableContainer>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {isDraggable && (
                  <TableCell
                    sx={{
                      background: '#EDF1F3',
                      color: '#6C7278',
                      fontWeight: 400,
                      padding: 1,
                    }}
                  >
                    {' '}
                  </TableCell>
                )}
                {headerGroup.headers.map((header, idx) => (
                  <TableCell
                    key={header.id}
                    sx={{
                      background: '#EDF1F3',
                      color: '#6C7278',
                      fontWeight: 400,
                      minWidth: hasIndexRow && idx === 0 ? 0 : '200px',
                      padding: 1,
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <DraggableRow
                hasIndexRow={hasIndexRow}
                key={row.id}
                row={row}
                reorderRow={autoReorder && reorderRow}
                isDraggable={isDraggable}
                onRowDrop={onRowDrop}
                onRowClick={onRowClick}
                cursor={cursor}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {hasPagination && (
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => onPageChange(+e.target.value)}
          backIconButtonProps={{ disabled: !hasPreviousPage || isLoading || isFetching }}
          nextIconButtonProps={{ disabled: !hasNextPage || isLoading || isFetching }}
        />
      )}
    </Box>
  );
};

export default TableComponent;
