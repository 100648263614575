import { Box } from '@mui/system';
import React from 'react';
import CustomFormInput from '../../commonComponents/CustomFormInput';
import SubmitButton from '../../commonComponents/SubmitButton';

const AppointmentForm = ({ control, handleSubmit, onSubmit, t }) => {
  return (
    <Box>
      <CustomFormInput name="from" type="datetime-local" control={control} label={t('From')} />
      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default AppointmentForm;
