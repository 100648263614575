import { Box, TableCell, TableRow } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import { flexRender } from '@tanstack/react-table';

const DraggableRow = ({ row, reorderRow, isDraggable, onRowDrop, onRowClick, cursor, hasIndexRow }) => {
  const [, dropRef] = useDrop({
    accept: 'row',
    drop: (draggedRow) => {
      onRowDrop(draggedRow, row);
      if (reorderRow) {
        reorderRow(draggedRow.index, row.index);
      }
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: 'row',
  });

  return (
    <TableRow
      ref={previewRef}
      sx={{
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: '#fff',
        opacity: isDragging ? 0.5 : 1,
        cursor: cursor,
      }}
    >
      {isDraggable && (
        <TableCell ref={dropRef} sx={{ fontWeight: '600', fontSize: '18px', padding: 1 }}>
          <Box ref={dragRef} sx={{ cursor: isDragging ? 'grabbing' : 'grab' }}>
            <img src="https://taxofficestorage.blob.core.windows.net/icons/burgermenu.svg" alt="Dndmenu"></img>
          </Box>
        </TableCell>
      )}
      {row.getVisibleCells().map((cell, idx) => (
        <TableCell
          key={cell.id}
          sx={{ fontWeight: '600', fontSize: '18px', minWidth: hasIndexRow && idx === 0 ? 0 : '200px', padding: 1 }}
          onClick={() => {
            if (cell.id !== `${cell.row.id}_select`) {
              onRowClick(row.original);
            }
          }}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default DraggableRow;
