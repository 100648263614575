import React from 'react';
import FamilyMemberTable from './FamilyMemberTable';
import { Box, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FamilyMembersPage = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Typography textAlign={{ xs: 'center', sm: 'left' }} variant="h6" fontWeight={700} px={5} py={2.45}>
          {t('FamilyMembers')}
        </Typography>

        <Divider />
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} padding={5}>
          <FamilyMemberTable />
        </Box>
      </Box>
    </Box>
  );
};

export default FamilyMembersPage;
