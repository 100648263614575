import { Box, Typography } from '@mui/material';
import React from 'react';

const ArchiveTabs = ({ label, onClick, activeTab }) => {
  const isActive = activeTab === label;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: 2,
        px: 3,
        borderBottom: isActive ? '3px solid #31B099' : '3px solid transparent',
        backgroundColor: isActive ? '#E1F0EF' : '#fff',
        borderRadius: '5px 5px 0 0',
        boxShadow: isActive ? '0px -4px 10px rgba(0, 0, 0, 0.1)' : 'none',
        '&:hover': {
          backgroundColor: isActive ? '#E1F0EF' : '#f9f9f9',
          cursor: 'pointer',
          borderBottom: '3px solid #31B099',
        },
      }}
      onClick={() => onClick && onClick()}
    >
      <Typography
        sx={{
          color: isActive ? '#31B099' : '#A2A6AA',
          fontSize: 16,
          fontWeight: isActive ? '600' : '500',
          textAlign: 'center',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default ArchiveTabs;
