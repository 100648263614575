import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import DrawerComponent from './DrawerComponent';
import LanguageSelect from '../selectTranslation/translateSelect';
import DashboardOptions from './DashboardOptions';
import { useTranslation } from 'react-i18next';
import { NavHashLink } from 'react-router-hash-link';

export default function NavBarHome() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { accessToken, roles } = useAuth();

  const [navHide, setNavHide] = React.useState(false);
  const [lastScroll, setLastScroll] = React.useState(window.scrollY);

  React.useEffect(() => {
    const changeNavbarColor = () => {
      if (lastScroll < window.scrollY) {
        setNavHide(true);
      } else {
        setNavHide(false);
      }
      setLastScroll(() => window.scrollY);
    };
    window.addEventListener('scroll', changeNavbarColor);
    return () => {
      window.removeEventListener('scroll', changeNavbarColor);
    };
  }, [lastScroll]);

  const navHidden = {
    transform: 'translateY(-100px)',
    boxShadow: 'none',
  };

  return (
    <Box
      position="fixed"
      maxWidth="100vw"
      minWidth="100vw"
      sx={{
        background: '#fff',
        paddingY: '10px',
        boxShadow: 'none',
        zIndex: 1000,
        borderBottom: '2px solid rgba(0,0,0,0.05)',
        transition: 'all 0.5s linear',
        maxWidth: '100%',
      }}
      style={navHide ? (!isMobile ? navHidden : {}) : {}}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '100%',
        }}
      >
        <NavHashLink to={'/#hero'}>
          <Box
            component="img"
            sx={{
              height: { xs: '28px', md: '35px' },
              width: { xs: '168px', md: '205px' },
              objectFit: 'contain',
            }}
            alt="Your logo."
            src={'https://taxofficestorage.blob.core.windows.net/media/greenLogo.svg'}
          ></Box>
        </NavHashLink>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <Box display="flex" flexDirection="row" gap={5} alignItems="flex-start" paddingLeft={10} flexGrow={2}>
            <NavHashLink style={{ color: '#1A1C1E', textDecoration: 'none' }} to="/#how_to_start">
              <Typography variant="body2">{t('how.to.start')}</Typography>
            </NavHashLink>
            <NavHashLink style={{ textDecoration: 'none' }} to="/#tax_planing">
              <Typography variant="body2" color="#1A1C1E">
                {t('content.title')}
              </Typography>
            </NavHashLink>
            <NavHashLink style={{ color: '#1A1C1E', textDecoration: 'none' }} to="/#tips">
              <Typography variant="body2">{t('tips')}</Typography>
            </NavHashLink>
            <NavHashLink style={{ color: '#1A1C1E', textDecoration: 'none' }} to="/#pricing">
              <Typography variant="body2">{t('pricing')}</Typography>
            </NavHashLink>
          </Box>
        )}
        {!accessToken ? (
          <>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems="center" gap="1rem">
              <Link style={{ color: '#1A1C1E', textDecoration: 'none' }} to={'/auth/register'}>
                <Button sx={{ textTransform: 'none' }} color="inherit">
                  <Typography variant="body2">{t('createAcc')}</Typography>
                </Button>
              </Link>
              <Link style={{ textDecoration: 'none' }} to={'/auth/login'}>
                <Button
                  sx={{
                    float: 'left',
                    fontSize: '17px',
                    color: '#1A1C1E',
                    fontWeight: '700',
                    textTransform: 'none',
                    border: '1px solid #DCE4E8',
                    borderRadius: '15px',
                    padding: '12px 40px',
                  }}
                  variant="outlined"
                >
                  <Typography variant="body2">{t('signin')}</Typography>
                </Button>
              </Link>
              <LanguageSelect />
            </Box>
          </>
        ) : (
          !isMobile && <DashboardOptions roles={roles} />
        )}
      </Toolbar>
    </Box>
  );
}
