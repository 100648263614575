import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useTimeOfDay = () => {
  const { t } = useTranslation();
  const [timeOfDay, setTimeOfDay] = useState('');

  useEffect(() => {
    const updateTimeOfDay = () => {
      const now = new Date();
      let hours = now.getHours();
      if (hours < 12) {
        setTimeOfDay(t('Morning'));
      } else if (hours < 18) {
        setTimeOfDay(t('Afternoon'));
      } else {
        setTimeOfDay(t('Evening'));
      }
    };

    updateTimeOfDay();
    const interval = setInterval(() => {
      updateTimeOfDay();
    }, 3.6e6); // 3.6e6 represents one hour in milliseconds

    return () => clearInterval(interval);
  }, [t]);

  return timeOfDay;
};

export default useTimeOfDay;
