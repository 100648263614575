import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, MenuItem, Select, Typography } from '@mui/material';
import TabsComponent from '../components/myDocumentsComponents/DocumentTabs';
import { useParams } from 'react-router-dom';
import useOwnFiles, { useFileTypesByArchiveType } from '../hooks/useOwnFiles';
import { useGetAllArchiveTypes, useGetArchiveByType } from '../api/archive';
import trim from 'lodash-es/trim';
import { useGetFinishedDocuments } from '../api/finishedDocuments';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGetStatusByEntity } from '../api/status';
import { useTaskByArchive, useUpdateTaskStatus } from '../api/tasks';
import useToastNotification from '../hooks/useToastNotification';
import findLocalsOrUseFirst from '../utils/findLocalsOrUseFirst';

const MyDocuments = () => {
  const { t, i18n } = useTranslation();
  const { currentUser } = useSelector((state) => state.auth);
  const activeTab = useSelector((state) => state.dashboard.activeTab);
  const [archive, setArchive] = useState('');
  const [selectedArchiveType, setSelectedArchiveType] = useState(null);
  const param = useParams();
  const { data: tabs } = useFileTypesByArchiveType(param.archiveType);
  const { data: files, refetch, isFetching } = useOwnFiles(param.fileType);
  const { data: archives } = useGetArchiveByType(param?.archiveType);
  const { data: archiveTypes } = useGetAllArchiveTypes();
  const { data: statuses } = useGetStatusByEntity('tasks');
  const finishedStatus = statuses?.find((status) => status.name === 'finished_uploading_documents');
  const suffix = '/my_documents/' + param.archiveType;
  const currentYear = new Date().getFullYear();
  const { data: finishedDocuments } = useGetFinishedDocuments({
    user: currentUser?.id,
    archive: archive,
    enabled: param?.fileType === t('finished_tax_return'),
  });

  const selectedArchive = archives?.data?.find((arch) => arch.id === archive);
  const { data: task } = useTaskByArchive(selectedArchive?.name);
  const { mutate, isLoading, isSuccess } = useUpdateTaskStatus();

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (archives?.data?.length) {
      setArchive(
        archives.data.find((archive) => trim(archive?.name) === (currentYear - 1).toString())?.id ||
          archives.data[0].id ||
          '',
      );
    } else {
      setArchive('');
    }
  }, [archives?.data, currentYear]);

  useEffect(() => {
    if (param?.archiveType) {
      setSelectedArchiveType(archiveTypes?.find((archiveType) => archiveType.name === param?.archiveType));
    }
  }, [param?.archiveType, archiveTypes]);

  useEffect(() => {
    if (archives?.data?.length) {
      setArchive(
        archives.data.find((archive) => trim(archive?.name) === activeTab.toString())?.id || archives.data[0].id || '',
      );
    } else {
      setArchive('');
    }
  }, [archives?.data, activeTab]);

  useEffect(() => {
    if (param.fileType) {
      refetch();
    }
  }, [param?.fileType, refetch]);

  useEffect(() => {
    if (task) {
      if (task?.status?.name === 'finished_uploading_documents' || task?.status?.name === 'finished_documents') {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  }, [task]);

  const onSubmitFinish = () => {
    mutate({ id: task?.id, statusId: finishedStatus?.id });
    setIsDisabled(true);
  };

  useToastNotification({
    isShow: isSuccess,
    loading: isLoading,
    messageKey: 'succChangedTheStatusOfTask',
    variant: 'success',
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Box
          display="flex"
          flexDirection={{ md: 'row', xs: 'column' }}
          alignItems="center"
          justifyContent="space-between"
          px={5}
        >
          <Box display="flex" alignItems="center" gap="16px">
            <Typography textAlign={{ xs: 'center', sm: 'left' }} variant="h6" fontWeight={700} py={2.45}>
              {findLocalsOrUseFirst(selectedArchiveType?.locals, i18n.language)?.name}
            </Typography>
            <Select
              value={archive}
              onChange={(e) => setArchive(e.target.value)}
              sx={{ borderRadius: '15px', minWidth: '200px', height: '54px' }}
            >
              {archives?.data.map((arch, i) => (
                <MenuItem key={i} value={arch?.id}>
                  {arch?.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={{
                bgcolor: '#fff',
                color: '#000',
                fontWeight: 700,
                textTransform: 'none',
                borderRadius: '25px',
                width: '400px',
                paddingY: '15px',
                paddingX: '25px',
                display: 'flex',
                gap: '10px',
                my: 1,
              }}
              onClick={onSubmitFinish}
              disabled={isDisabled}
            >
              {t('finish_uploading_documents')}
            </Button>
          </Box>
        </Box>
        <Divider />
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
          {tabs && (
            <TabsComponent
              taskStatus={task?.status?.name}
              suffix={suffix}
              archives={archives}
              isLoading={isFetching}
              files={param?.fileType === t('finished_tax_return') ? finishedDocuments : files}
              tabs={tabs?.tree}
              archive={archive}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MyDocuments;
