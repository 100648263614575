import { Box, Chip } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const SearchTags = ({ tags, setTags }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2, gap: '10px' }}>
      {tags.map((tag, idx) => (
        <Chip
          key={idx}
          label={tag}
          onDelete={() => setTags((prev) => prev.filter((p) => p !== tag))}
          deleteIcon={<CloseIcon style={{ width: 12, height: 12 }} />}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#EDF1F3',
            borderRadius: '5px',
            fontSize: '14px',
            color: '#6C7278',
            padding: '6px',
            gap: '10px',
            fontWeight: 700,
          }}
        />
      ))}
    </Box>
  );
};

export default SearchTags;
