import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ArticleCard = ({ heading, img, shortDesc, archive, id, lang, onOpenModal, isAdmin, articleId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    const articleData = { heading, img, shortDesc, id, lang };
    onOpenModal(articleData);
  };

  const handleLocalizationClick = () => {
    navigate(`/admin/article-localization/${lang}/${articleId}`);
  };
  return (
    <Card
      sx={{
        m: 1,
        mx: { xs: 0, sm: 1 },
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        border: '0.5px solid #DCE4E8',
        borderRadius: '24px',
        '&:hover': {
          boxShadow: '0px 2px 4px -5px rgba(0, 0, 0, 0.01)',
        },
      }}
    >
      <CardMedia
        component="img"
        height="200px"
        image={img}
        sx={{
          objectFit: 'cover',
          width: '100%',
        }}
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 1,
          padding: '15px',
          gap: '10px',
        }}
      >
        <Typography variant="subtitle1">{archive}</Typography>
        <Typography gutterBottom variant="h6" fontWeight={900} color="#0F5D66">
          {heading}
        </Typography>
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          {shortDesc}
        </Typography>
        <Box display="flex" gap="10px">
          {isAdmin ? (
            <Button
              variant="contained"
              sx={{
                bgcolor: '#0F5D66',
                color: '#fff',
                textTransform: 'none',
                borderRadius: '15px',
                padding: '8px 12px',
                fontWeight: '700',
                '&:hover': {
                  color: 'rgba(255, 255, 255,0.7)',
                  bgcolor: 'rgba(15, 93, 102,0.5)',
                },
                flex: 1,
              }}
              onClick={handleLocalizationClick}
            >
              {t('Localization')}
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                bgcolor: '#0F5D66',
                color: '#fff',
                textTransform: 'none',
                borderRadius: '15px',
                padding: '8px 12px',
                fontWeight: '700',
                '&:hover': {
                  color: 'rgba(255, 255, 255,0.7)',
                  bgcolor: 'rgba(15, 93, 102,0.5)',
                },
                flex: 1,
              }}
              onClick={handleButtonClick}
            >
              {t('MakeAnappointment')}
            </Button>
          )}
          <Button
            variant="contained"
            sx={{
              bgcolor: '#fff',
              color: '#0F5D66',
              textTransform: 'none',
              borderRadius: '15px',
              fontWeight: '700',
              padding: '8px 12px',
              '&:hover': {
                color: 'rgba(15, 93, 102,0.5)',
                bgcolor: 'rgba(255, 255, 255,0.3)',
              },
              flex: 1,
            }}
            onClick={() => navigate(isAdmin ? `/admin/article/${lang}/${id}` : `/article/${lang}/${id}`)}
          >
            {t('ContinueReading')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ArticleCard;
