import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { debounce } from 'lodash-es';
import useChangeEffect from '../hooks/useChangeEffect.js';
import { Box, Divider, Typography } from '@mui/material';
import { useGetAllLeadsByBroker } from '../api/leads.js';
import useLeadsColumns from '../hooks/AllUsers/useLeadsColumns.js';
import { tags } from '../components/adminDashboard/leads/tags.js';

import { handleLimit, handleNextPage, handlePrevPage } from '../utils/paginationFunction.js';
import LeadsTable from '../components/adminDashboard/leads/LeadsTable/LeadsTable.jsx';
import LanguageSelect from '../components/selectTranslation/translateSelect.js';
import { useGetAllBrokers } from '../api/user.js';
import { useGetStatusByEntity } from '../api/status.js';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BrokerLeads = () => {
  const { t } = useTranslation();
  const { currentUser } = useSelector((state) => state.auth);
  const navigation = useNavigate();
  const [rowSelection, setRowSelection] = useState({});
  const [keyword, setKeyword] = useState('');
  const [filterTag, setFilterTag] = useState(['search']);
  const [filterTagData, setFilterTagData] = useState({});

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);

  const { data: brokers } = useGetAllBrokers();
  const { data: statuses } = useGetStatusByEntity('lead');
  const [columns, sortBy] = useLeadsColumns({
    brokers,
    statuses,
  });
  const { data: allUsers, isLoading, isFetching } = useGetAllLeadsByBroker({ page, limit, ...filterTagData, sortBy });
  const users = allUsers?.results;

  const { control } = useForm({
    mode: 'onSubmit',

    reValidateMode: 'onChange',
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterTag(typeof value === 'string' ? value.split(',') : value);
  };

  const updateFilterTagData = () => {
    const dynamicFilterTag = filterTag;
    const dynamicKeyword = keyword;

    setFilterTagData({ [dynamicFilterTag]: dynamicKeyword });
  };

  useChangeEffect(() => {
    updateFilterTagData();
  }, [filterTag, keyword]);

  const debouncedHandleSearchChange = debounce((value) => {
    setKeyword(value);
    setPage(0);
  }, 300);

  const handleRowClick = (row) => {
    if (row?.id) {
      navigation(row?.id);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center" px={4}>
          <Typography variant="h6" fontWeight={700} px={5} py={2.45} sx={{ marginLeft: -4 }}>
            {t('allLeads')}
          </Typography>
          <LanguageSelect />
        </Box>
        <Divider />

        <Box m={5}>
          {users && (
            <LeadsTable
              MenuProps={MenuProps}
              allUsers={allUsers}
              columns={columns}
              control={control}
              debouncedHandleSearchChange={debouncedHandleSearchChange}
              filterTag={filterTag}
              handleChange={handleChange}
              handleLimit={handleLimit}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
              isFetching={isFetching}
              isLoading={isLoading}
              setPage={setPage}
              tags={tags}
              users={users}
              setLimit={setLimit}
              onRowClick={handleRowClick}
              cursor="pointer"
              setRowSelection={setRowSelection}
              rowSelection={rowSelection}
              brokers={brokers?.results}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BrokerLeads;
