import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import TableContainer from '../../../Table/TableContainer';
import InfoField from './components/InfoField';
import AddressInfo from './components/AddressInfo';
import ContactInfo from './components/ContactInfo';
import WorkAndTransportInfo from './components/WorkAndTransportInfo';
import OldAddress from './components/OldAddress';
import Questions from './components/Questions';

const PersonalInfo = ({ data }) => {
  const { t } = useTranslation();
  return (
    <TableContainer sx={{ p: 2 }}>
      <Typography variant="h6">{t('personalInfo')}</Typography>
      <Box display="flex" flexDirection="column">
        <ContactInfo data={data} />
        <AddressInfo data={data} />
        <WorkAndTransportInfo data={data} />
        {data?.info.moved && <OldAddress data={data} />}
        <InfoField labelKey="promoCode" value={data?.promoCode ? data?.promoCode : '/'} />
        <Questions data={data} />
      </Box>
    </TableContainer>
  );
};

export default PersonalInfo;
