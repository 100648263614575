/* eslint-disable no-unused-vars */
import { Box, IconButton, Menu, Typography } from '@mui/material';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import CustomIconButton from '../commonComponents/CustomIconButton';
import SouthRoundedIcon from '@mui/icons-material/SouthRounded';
import { RoundedBinIcon } from '../../assets/icons/RoundedBinIcon';
import Modal from '@mui/material/Modal';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import CircularProgress from '@mui/material/CircularProgress';
import { truncate } from 'lodash-es';
import { useDeleteFile } from '../../hooks/useOwnFiles';
import DeleteModal from '../modals/DeleteModal';
import isISODateValid from '../../utils/isISODateValid';
import DocumentIconButton from './DocumentIconButton';
import { useTranslation } from 'react-i18next';

const DocumentCard = ({
  id,
  originalName,
  uploadedAt,
  url,
  fileUrl,
  showImg,
  deleteWidget,
  onEdit,
  onLocalization,
  fileType,
  showEditOptions = true,
}) => {
  const downloadUrl = fileUrl || url;
  const { t, i18n } = useTranslation();
  const [anchorElement, setAnchorElement] = useState(null);
  const [isPortait, setIsPortait] = useState(null);
  const [drawerAnchor, setDrawerAnchor] = useState(null);
  const openDrawer = Boolean(drawerAnchor);
  const handleLoadSuccess = async (pdfObject) => {
    const page = await pdfObject.getPage(1);
    const height = page?._pageInfo?.view[2];
    const width = page?._pageInfo?.view[3];
    if (width > height) {
      setIsPortait(false);
    } else {
      setIsPortait(true);
    }
  };
  const handleClick = (event) => {
    setDrawerAnchor(event.currentTarget);
  };
  const handleCloseDrawer = () => {
    setDrawerAnchor(null);
  };

  ////this worker is only for the preview
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  //// this plugins are for the full preview of the pdfs
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  ///checks the extension of the url
  const checkFileType = url?.substring(url.lastIndexOf('.'));

  const { mutate: deleteFile } = useDeleteFile();

  // eslint-disable-next-line no-unused-vars

  const handleClose = () => {
    setAnchorElement(null);
  };

  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleDelete = () => {
    handleCloseDeleteModal();
    fileUrl ? deleteWidget(id) : deleteFile(id);
  };

  const handleOpenModal = () => {
    handleCloseDrawer();
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenDeleteModal = () => {
    handleCloseDrawer();
    setOpenDelete(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDelete(false);
  };

  const handleDownload = () => {
    handleClose();
    handleCloseDrawer();
    if (window) {
      window.location.href = downloadUrl;
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        borderRadius="16px"
        border="#DCE4E8 2px solid"
        width={showImg ? '270px' : '100%'}
        height={showImg ? '480px' : 'auto'}
        overflow="hidden"
      >
        {showImg && (
          <Box height="280px" onClick={handleOpenModal}>
            {checkFileType === '.pdf' ? (
              <Box
                position="relative"
                overflow="hidden"
                display="flex"
                width="100%"
                height="280px"
                justifyContent="center"
                alignItems="center"
              >
                <Document
                  file={url}
                  onLoadSuccess={handleLoadSuccess}
                  loading={<CircularProgress sx={{ alignSelf: 'center', justifySelf: 'center' }} color="success" />}
                >
                  {isPortait !== true ? (
                    <>
                      <Box
                        position="absolute"
                        top="50%"
                        left="50%"
                        sx={{ transform: 'translate(-50%,-50%)' }}
                        zIndex={1}
                      >
                        <Page
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                          height={280}
                          pageIndex={0}
                          renderMode="canvas"
                        />
                      </Box>
                      <Box sx={{ filter: 'blur(3px)' }}>
                        <Page
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                          width={300}
                          scale={1}
                          pageIndex={0}
                          renderMode="canvas"
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        position="absolute"
                        top="50%"
                        left="50%"
                        sx={{ transform: 'translate(-50%,-50%)' }}
                        zIndex={1}
                      >
                        <Page
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                          width={300}
                          scale={1}
                          pageIndex={0}
                          renderMode="canvas"
                        />
                      </Box>
                      <Box sx={{ filter: 'blur(3px)' }}>
                        <Page
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                          height={280}
                          pageIndex={0}
                          renderMode="canvas"
                        />
                      </Box>
                    </>
                  )}
                </Document>
              </Box>
            ) : (
              <Box height="280px" width="100%" display="flex" justifyContent="center">
                <Box component="img" width="100%" sx={{ objectFit: 'cover' }} src={url} />
              </Box>
            )}
          </Box>
        )}

        <Box
          display="flex"
          p={3}
          flexDirection={showImg ? 'column' : 'row'}
          width="100%"
          justifyContent="space-between"
          alignContent="space-between"
          height={showImg ? '200px' : 'auto'}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: 'Plus Jakarta Sans',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '150%',
                letterSpacing: '-0.02em',
              }}
            >
              {truncate(originalName, { length: 20 })}
            </Typography>

            <Typography variant="body2" fontWeight={400} color="#6C7278">
              {isISODateValid(uploadedAt) ? moment(uploadedAt).format('dd.MM.yyyy') : uploadedAt}
              {' - '}
              {fileType && fileType[`name_${i18n?.language}`]}
            </Typography>
          </Box>
          {showImg ? (
            <IconButton
              sx={{
                width: showImg ? '100%' : 'auto',
                fontFamily: 'Manrope',
                fontStyle: 'normal',
                fontWeight: 600,
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '16px',
                lineHeight: '150%',
                borderRadius: '16px',
                backgroundColor: openDrawer ? '#EDF1F3' : '#FFFFFF',
                border: '#DCE4E8 1px solid',
                p: '10px 10px 10px 16px',
              }}
              id="basic-button"
              aria-controls={openDrawer ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openDrawer ? 'true' : undefined}
              onClick={handleClick}
            >
              {t('options')} <ExpandMoreIcon />
            </IconButton>
          ) : (
            <Box display="flex" gap="16px">
              {fileUrl ? (
                <>
                  <DocumentIconButton iconType="language" onClick={onLocalization} />
                  <DocumentIconButton iconType="edit" onClick={onEdit} />
                  <DocumentIconButton iconType="delete" onClick={handleOpenDeleteModal} />
                  <DocumentIconButton iconType="view" onClick={handleOpenModal} />
                  <DocumentIconButton iconType="download" onClick={handleDownload} />
                </>
              ) : (
                <>
                  <DocumentIconButton iconType="view" onClick={handleOpenModal} />
                  <DocumentIconButton iconType="download" onClick={handleDownload} />
                </>
              )}
            </Box>
          )}
        </Box>

        <Menu
          sx={{
            ml: '-70px',
            mt: '10px',
            overflow: 'hidden',
            borderRadius: '15px',
            '& div': {
              borderRadius: '15px',
            },
            '& ul': {
              p: 0,
              borderRadius: '15px',
            },
          }}
          id="basic-menu"
          anchorEl={drawerAnchor}
          open={openDrawer}
          onClose={handleCloseDrawer}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Box flexDirection="column" gap="15px" p="15px" display="flex">
            <CustomIconButton label="Download" onClick={handleDownload} icon={<SouthRoundedIcon />} />
            {showEditOptions && (
              <CustomIconButton
                icon={<RoundedBinIcon />}
                label="Delete"
                variant="outlined"
                onClick={handleOpenDeleteModal}
                sx={{
                  color: '#000',
                  borderColor: '#DCE4E8',
                  '&:hover': {
                    color: '#000',
                    borderColor: '#DCE4E8',
                  },
                }}
              />
            )}
          </Box>
        </Menu>
      </Box>
      <Modal
        open={open}
        onClose={handleCloseModal}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            width: '90%',
            height: '90%',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            justifyContent: 'center',
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{ height: 50, width: 50, color: 'white', position: 'absolute', top: 0, right: 0 }}
          >
            <CloseIcon sx={{ height: 35, width: 35 }} />
          </IconButton>
          {checkFileType === '.pdf' ? (
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
              <Box width="100%" height="100%" display="flex" justifyContent="center">
                <Viewer fileUrl={url} defaultScale={1} plugins={[defaultLayoutPluginInstance]}></Viewer>
              </Box>
            </Worker>
          ) : (
            <Box height="inherit" display="flex" justifyContent="center" alignItems="center">
              <Box component="img" width="100%" maxHeight="100%" height="fit-content" src={url} alt="img"></Box>
            </Box>
          )}
        </Box>
      </Modal>
      <DeleteModal
        handleDeleteModal={handleCloseDeleteModal}
        openDelete={openDelete}
        headingText={'Delete File'}
        bodyText={'pdf.card'}
        handleDelete={() => handleDelete()}
      />
    </>
  );
};

export default DocumentCard;
