import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import HeaderLogo from './MainLeadForms/HeaderLogo';
import FormContainer from './MainLeadForms/FormContainer';
import useComponentForm from './MainLeadForms/hooks/useComponentForm';

const LeadForm = () => {
  const { t } = useTranslation();
  const { handleSubmit, control, onSubmit, isLoading, isError } = useComponentForm();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: '#037A68',
        paddingTop: '32px',
        paddingBottom: '3px',
        gap: '22px',
        borderRadius: { md: '15px' },
        boxShadow: '0px 34px 114px rgba(0, 0, 0, 0.08)',
        width: { xs: '100%', md: '480px' },
        minHeight: { xs: '100vh', md: '100%' },
        zIndex: 2,
      }}
    >
      <HeaderLogo title={t('register_for_your_tax_return_here')} />
      <FormContainer
        control={control}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        onSubmit={onSubmit}
        t={t}
        isError={isError}
      />
    </Box>
  );
};

export default LeadForm;
