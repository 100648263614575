import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEditDocumentWidget } from '../../../hooks/useEditDocumentWidget';
import SubmitButton from '../../commonComponents/SubmitButton';
import CustomFormInput from '../../commonComponents/CustomFormInput';

const DocumentWidgetsEditForm = ({ handleDrawerClose, selectedWidget }) => {
  const { t } = useTranslation();

  const { handleSubmit, control, onSubmit } = useEditDocumentWidget(handleDrawerClose, selectedWidget);

  return (
    <Box component="form" display="flex" flexDirection="column">
      <CustomFormInput name="subtitle" control={control} label={'Subtitle'} />
      <CustomFormInput name="title" control={control} label={'Title'} />
      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default DocumentWidgetsEditForm;
