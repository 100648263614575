import { FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SelectFilterField = ({ filterTag, handleChange, MenuProps, tags }) => {
  const { t } = useTranslation();
  return (
    <FormControl sx={{ width: 200 }}>
      <InputLabel id="demo-multiple-checkbox-label">{t('tag')}</InputLabel>
      <Select
        labelid="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        value={filterTag}
        onChange={handleChange}
        input={<OutlinedInput label="Tag" sx={{ border: '1px solid #DCE4E8', borderRadius: '15px' }} />}
        renderValue={(selected) => {
          const selectedTag = tags.find((tag) => tag.value === selected[0]);
          return selectedTag ? selectedTag.label : '';
        }}
        MenuProps={MenuProps}
      >
        {tags?.map((tag) => (
          <MenuItem key={tag.value} value={tag.value}>
            <ListItemText primary={tag.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectFilterField;
