import Box from '@mui/material/Box';
import React from 'react';
import TableComponent from '../../Table/Table';
import { IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import CustomDrawer from '../../modals/CustomDrawer';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import VerifyLoader from '../../verifyLoader/VerifyLoader';
import StatusForm from './StatusForm';
import useStatusColumns from '../../../hooks/AllUsers/useStatusColumns';
import SubmitButton from '../../commonComponents/SubmitButton';
import { useAddStatus } from '../../../hooks/useAddStatus';

const StatusesTable = ({ data, isLoading, tableNames }) => {
  const { t } = useTranslation();
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleOpen = () => {
    setOpenAddDrawer(!openAddDrawer);
  };
  const { handleSubmit, control, onSubmit } = useAddStatus(handleOpen, selectedStatus, tableNames);

  const handleEdit = (statusData) => {
    setSelectedStatus(statusData);
    setOpenAddDrawer(true);
  };

  const columns = useStatusColumns(handleEdit);
  return (
    <>
      <Box m={5}>
        <Box width="100%">
          {isLoading ? (
            <Box width="100%" display="flex" justifyContent="center">
              <VerifyLoader />
            </Box>
          ) : (
            data && (
              <TableComponent hasAboveStuff data={data} columns={columns}>
                <SubmitButton onClick={handleOpen} buttonText={t('AddNew')} backgroundColor="#31B099" color="inherit" />
              </TableComponent>
            )
          )}
        </Box>
      </Box>
      {/* Add Drawer */}
      <CustomDrawer open={openAddDrawer} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddStatuses')}
          </Typography>
          <IconButton
            onClick={() => {
              handleOpen();
              setSelectedStatus();
            }}
          >
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <StatusForm
          handleSubmit={handleSubmit}
          control={control}
          onSubmit={onSubmit}
          tableNames={tableNames}
          selectedStatus={selectedStatus}
        />
      </CustomDrawer>
    </>
  );
};

export default StatusesTable;
