import React, { useState } from 'react';
import TableComponent from '../../../Table/Table';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomDrawer from '../../../modals/CustomDrawer';
import CloseIcon from '@mui/icons-material/Close';
import { handleLimit, handleNextPage, handlePrevPage } from '../../../../utils/paginationFunction';
import SubmitButton from '../../../commonComponents/SubmitButton';
import { useGetNotesByUser } from '../../../../api/notes';
import useUserNotesColumns from '../../../../hooks/AllUsers/useUserNotesColumns';
import NotesForm from './NotesForm/NotesForm';
import TableContainer from '../../../Table/TableContainer';

const UserNotes = ({ id }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const columns = useUserNotesColumns();
  const { data: allNotes, isLoading, isFetching } = useGetNotesByUser({ id, page, limit });
  const notes = allNotes?.results;
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const handleOpen = () => {
    setOpenAddDrawer(!openAddDrawer);
  };

  return (
    <>
      <TableContainer>
        <TableComponent
          hasAboveStuff
          data={notes || []}
          columns={columns}
          hasPagination
          hasBoxShadow
          rowsPerPage={allNotes?.limit}
          page={Number.isInteger(allNotes?.currentPage) ? allNotes.currentPage - 1 : 0}
          handleNextPage={() => handleNextPage(setPage)}
          handlePrevPage={() => handlePrevPage(setPage)}
          hasNextPage={allNotes?.hasNextPage}
          hasPreviousPage={allNotes?.hasPreviousPage}
          onPageChange={(lim) => handleLimit(lim, setLimit)}
          setPage={setPage}
          isLoading={isLoading}
          isFetching={isFetching}
          total={allNotes?.total || 0}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h6" fontWeight={700}>
              {t('Notes')}
            </Typography>
          </Box>
          <Box display="flex" gap="20px" alignItems="center">
            <SubmitButton onClick={handleOpen} buttonText={t('AddNew')} backgroundColor="#31B099" color="inherit" />
          </Box>
        </TableComponent>
      </TableContainer>

      {/* Add Drawer */}
      <CustomDrawer open={openAddDrawer} handleOpen={handleOpen}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h5" color={'#037A68'} textAlign={'left'} paddingBottom={3}>
            {t('AddNotes')}
          </Typography>
          <IconButton onClick={handleOpen}>
            <CloseIcon sx={{ fontSize: '28px' }} />
          </IconButton>
        </Box>
        <NotesForm handleDrawerClose={handleOpen} id={id} />
      </CustomDrawer>
    </>
  );
};

export default UserNotes;
