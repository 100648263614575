import { TextField } from '@mui/material';
import styled from '@emotion/styled';

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    border: '1px solid #DCE4E8',
    borderRadius: '15px',
  },

  '& MuiOutlinedInput-root': {
    border: '1px solid #0F5D66',
  },
});

export default CustomTextField;
