/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useCreateLead } from '../../../../../api/leads';
import { useNavigate } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import useToastNotification from '../../../../../hooks/useToastNotification';
import chDateFormatToJsDateFormat from '../../../../../utils/chDateFormatToJsDateFormat';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

const useComponentForm = () => {
  const { mutate, isSuccess, isLoading, isError } = useCreateLead();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const defaultValues = {
    name: '',
    phoneNumber: '',
    email: '',
    zip: '',
    kanton: '',
    address: '',
    birthdate: '',
    married: false,
    estate: false,
  };
  const formSchema = yup.object().shape({
    name: yup.string().required(t('required_field', { field: t('name') })),
    phoneNumber: yup.string().required(t('required_field', { field: t('phone.number') })),
    email: yup
      .string()
      .email()
      .required(t('required_field', { field: t('email') })),
    zip: yup.string().required(t('required_field', { field: t('zip') })),
    address: yup.string().required(t('required_field', { field: t('Address') })),
    birthdate: yup
      .string()
      .max(10, t('invalid_date'))
      .min(10, t('invalid_date'))
      .required(t('required_field', { field: t('birthdate') })),
    married: yup.boolean().required(t('required_field', { field: t('married') })),
    estate: yup.boolean().required(t('required_field', { field: t('estate') })),
  });

  const form = useForm({ defaultValues, resolver: yupResolver(formSchema) });

  const date = useWatch({ control: form.control, name: 'birthdate' });

  useEffect(() => {
    if (date?.length === 10) {
      chDateFormatToJsDateFormat({
        chFormat: date,
        onInvalidDate: () => {
          form.setError('birthdate', { type: 'custom', message: t('invalid_date') });
        },
        onValidDate: () => {
          form.setError('birthdate', undefined);
        },
      });
    }
  }, [date]);

  const onSubmit = (data) => {
    mutate({ data: { ...data, birthdate: chDateFormatToJsDateFormat({ chFormat: data.birthdate }) } });
  };

  useToastNotification({
    isShow: isSuccess && !isLoading,
    messageKey: 'thank_you_for_your_submision',
    variant: 'success',
    cb: () => {
      navigate('/thank_you');
    },
  });

  useToastNotification({
    isShow: isError && !isLoading,
    messageKey: 'server_error',
    variant: 'error',
  });

  return {
    ...form,
    onSubmit,
    isLoading,
    isSuccess,
  };
};

export default useComponentForm;
