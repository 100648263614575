import { Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { IconButton, Typography } from '@mui/material';
import { useLogin } from '../../hooks/useLogin';
import { useTranslation } from 'react-i18next';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { VisibilityOffOutlined } from '@mui/icons-material';
import CustomTextField from '../commonComponents/CustomTextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomFormInput from '../commonComponents/CustomFormInput';
import useUrlQueryParams from '../../hooks/useUrlQueryParams';

export default function LoginLayout() {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { handleSubmit, onSubmit, control, buttonDisabled, loading } = useLogin();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const urlParams = useUrlQueryParams();
  const redirect = urlParams.get('redirect');

  useEffect(() => {
    if (auth.accessToken) {
      if (auth.currentUser?.roles.includes('admin')) {
        navigate('/admin/dashboard');
      } else if (auth.currentUser?.roles.includes('broker')) {
        navigate('/broker/dashboard');
      } else {
        if (redirect) {
          navigate(redirect, { replace: true });
        } else {
          navigate(`/dashboard`, { replace: true });
        }
      }
    }
  }, [navigate, redirect, auth.accessToken, auth.currentUser?.roles]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" width="100%" flexDirection="column" justifyContent="space-evenly" gap="20px">
        <CustomFormInput name="email" control={control} placeholder={t('email')} />
        <Controller
          control={control}
          name="password"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <CustomTextField
                color="success"
                id="password"
                placeholder={t('password')}
                type={showPassword ? 'text' : 'password'}
                error={error}
                value={value}
                onChange={onChange}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlinedIcon />}
                    </IconButton>
                  ),
                }}
              />
              {error && (
                <Typography variant="caption" color="error">
                  {error.message}
                </Typography>
              )}
            </>
          )}
        />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Link to={'/changePassword'}>
            <Typography variant="body2" fontWeight="700" color="#0F5D66">
              {t('forgotPass')}
            </Typography>
          </Link>
        </Box>
        <Button
          variant="contained"
          sx={{
            bgcolor: '#0F5D66',
            '&:hover': {
              backgroundColor: '#187D7E',
            },
            textTransform: 'none',
            borderRadius: '15px',
            paddingY: '12px',
            paddingX: '25px',
            color: 'white',
            width: '100%',
          }}
          type="submit"
          disabled={buttonDisabled || loading}
        >
          {t('signin')}
        </Button>
        <Box
          onClick={() => navigate('/')}
          sx={{
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <ArrowBackIcon />
          <Typography variant="body2" color="#1A1C1E">
            {t('goBack')}
          </Typography>
        </Box>
      </Box>
    </form>
  );
}
