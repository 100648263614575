import { Divider, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import StatusesTable from './StatusesTable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useGetAllStatuses, useGetAllTableNames } from '../../../api/status';
import LanguageSelect from '../../selectTranslation/translateSelect';

const StatusesPage = () => {
  const { data, isLoading, isFetching } = useGetAllStatuses();
  const { data: tableNames } = useGetAllTableNames();

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} minHeight="100vh">
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center" px={4}>
          <IconButton onClick={() => navigate(-1)} sx={{ marginLeft: -2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" fontWeight={700} px={5} py={2.45} sx={{ marginLeft: -4, flexGrow: 1 }}>
            {t('Statuses')}
          </Typography>
          <LanguageSelect />
        </Box>
        <Divider />
        <Box p={5}>
          <Box display="flex" flexDirection="column">
            <StatusesTable tableNames={tableNames} data={data} isLoading={isLoading || isFetching} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StatusesPage;
