/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { signInUser } from '../api/auth';

import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useToast } from './useToast';
import useUrlQueryParams from './useUrlQueryParams';
import { useDispatch } from 'react-redux';
import { setUser } from '../store/auth/authActions';

export const useLogin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const urlParams = useUrlQueryParams();
  const redirect = urlParams.get('redirect');
  const { toast } = useToast();
  const signinSchema = yup
    .object()
    .shape({
      email: yup
        .string()
        .email(`${t('email.valid')}`)
        .required(),
      password: yup
        .string()
        .min(1, `${t('password.required')}`)
        .min(8, `${t('password.min.value')}`)
        .required(),
    })
    .required();

  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: localStorage.getItem('e') ?? '',
      password: localStorage.getItem('p') ?? '',
    },
    resolver: yupResolver(signinSchema),
    reValidateMode: 'onChange',
  });

  const navigate = useNavigate();

  const onSubmit = async (vals) => {
    setLoading(true);
    try {
      const response = await signInUser(vals);
      if (response) {
        setLoading(false);
        if (response.data?.accessToken) {
          dispatch(setUser(response.data));
        } else {
          if (redirect) {
            navigate(`/auth/otp?redirect=${encodeURIComponent(redirect)}`, {
              state: { email: vals.email, password: vals.password },
            });
          } else {
            navigate(`/auth/otp`, { state: { email: vals.email, password: vals.password } });
          }
        }
      }
      toast({
        message: t('Toast201'),
        variant: 'success',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
      });
    } catch (err) {
      toast({
        message: t('wrongCredentials'),
        variant: 'error',
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    buttonDisabled: !!errors.email || !!errors.password,
    loading,
  };
};
