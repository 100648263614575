import i18n from '../../../config/i18n';

export const tags = [
  { value: 'search', label: i18n.t('searchAllFields') },
  { value: 'firstName', label: i18n.t('firstname') },
  { value: 'lastName', label: i18n.t('lastname') },
  { value: 'email', label: i18n.t('email') },
  { value: 'phoneNumber', label: i18n.t('phone.number') },
  { value: 'zipCode', label: i18n.t('zip') },
  { value: 'address', label: i18n.t('address') },
  { value: 'place', label: i18n.t('place') },
  { value: 'status', label: i18n.t('status') },
  { value: 'promoCode', label: i18n.t('promoCode') },
  { value: 'broker', label: i18n.t('Brokers') },
];
