import React, { useState } from 'react';
import { useGetAllLanguages } from '../../../../api/articles';
import { useTranslation } from 'react-i18next';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Box, Paper } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Editor } from 'react-draft-wysiwyg';
import { useAddArticleLocalization } from '../../../../hooks/useAddArticleLocalization';
import SubmitButton from '../../../commonComponents/SubmitButton';
import CustomFormInput from '../../../commonComponents/CustomFormInput';
import CustomSelect from '../../../commonComponents/CustomSelect';

const ArticleLocalizationForm = () => {
  const { data: languages } = useGetAllLanguages();

  const { t } = useTranslation();

  const [contentState, setContentState] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { handleSubmit, control, onSubmit } = useAddArticleLocalization(setEditorState, EditorState);

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const rawContent = convertToRaw(newEditorState.getCurrentContent());
    setContentState(draftToHtml(rawContent));
  };

  return (
    <Box component="form" display="flex" flexDirection="column">
      <CustomFormInput name="title" control={control} label={t('title')} />
      <CustomFormInput name="shortDescription" control={control} label={t('shortDescription')} />
      <CustomSelect
        control={control}
        name="language"
        label={t('language')}
        data={languages}
        valueField={(item) => item.code}
        displayField={(item) => item.name}
      />
      <Controller
        name="text"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Paper>
            <Editor
              editorState={editorState}
              onEditorStateChange={(newEditorState) => {
                handleEditorStateChange(newEditorState);
                onChange(contentState);
              }}
            />
          </Paper>
        )}
      />

      <SubmitButton onClick={handleSubmit(onSubmit)} buttonText={t('Add')} backgroundColor="#31B099" color="inherit" />
    </Box>
  );
};

export default ArticleLocalizationForm;
