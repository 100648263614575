import { Box } from '@mui/material';
import React from 'react';
import QuestionsField from './QuestionsField';

const Questions = ({ data }) => {
  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <QuestionsField labelKey="Estate" status={data?.info.estate} />
      <QuestionsField labelKey="Insurance" status={data?.info.insurance} />
      <QuestionsField labelKey="Bonds" status={data?.info.bonds} />
      <QuestionsField labelKey="Credit" status={data?.info.credit} />
      <QuestionsField labelKey="ReceivedAlimente" status={data?.info.receivedAlimente} />
      <QuestionsField labelKey="PayedAlimente" status={data?.info.payedAlimente} />
      <QuestionsField labelKey="question_one" status={data?.info.receivedInheritance} />
      <QuestionsField labelKey="question_two" status={data?.info.undistributedInheritance} />
      <QuestionsField labelKey="question_three" status={data?.info.acquireAssets} />
      <QuestionsField labelKey="question_four" status={data?.info.giveAssets} />
      <QuestionsField labelKey="question_five" status={data?.info.partnershipInvolvement} />
    </Box>
  );
};

export default Questions;
