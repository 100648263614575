import { useMutation, useQuery, useQueryClient } from 'react-query';
import { customInstance } from './client';

const suffix = '/v1/status';
const suffix2 = '/status-localizations';

//GET Statuses
const getAllStatuses = () => {
  return customInstance({
    url: `${suffix}`,
    method: 'get',
  });
};

export const useGetAllStatuses = () => {
  return useQuery(['status'], async () => {
    const data = await getAllStatuses();
    return data;
  });
};

//POST Status
const createStatus = async (data) => {
  return customInstance({
    url: `${suffix}`,
    method: 'post',
    data,
  });
};

export const useCreateStatus = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createStatus(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['status'] });
    },
  });
};

//GET Table Names
const getAllTableNames = () => {
  return customInstance({
    url: '/v1/table-names',
    method: 'get',
  });
};

export const useGetAllTableNames = () => {
  return useQuery(['table-names'], async () => {
    const data = await getAllTableNames();
    return data;
  });
};

//GET Status by ID
const getStatusById = (id) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'get',
  });
};

export const useGetStatusById = (id) => {
  return useQuery(['status', id], () => getStatusById(id), { enabled: !!id });
};

//PUT Status
const updateStatus = (id, data) => {
  return customInstance({
    url: `${suffix}/${id}`,
    method: 'put',
    data: data,
  });
};

export const useUpdateStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id, data }) => updateStatus(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['status'] });
    },
  });
};

//Get Statuses by Entity

const getByEntity = (entity) => {
  return customInstance({
    url: `${suffix}/entity/${entity}`,
    method: 'get',
  });
};

export const useGetStatusByEntity = (entity) => {
  return useQuery(['status', entity], () => getByEntity(entity), { enabled: !!entity });
};

//POST Status Localization
const createStatusLocalization = (data) => customInstance({ url: suffix2, method: 'post', data });

export const useCreateStatusLocals = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createStatusLocalization(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['status'] });
    },
  });
};
