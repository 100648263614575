import Box from '@mui/material/Box';
import React from 'react';

const NotFound = () => {
  return (
    <Box width="100%" height="70vh" display="flex" justifyContent="center" alignItems="center">
      <Box
        component="img"
        width="50%"
        src={'https://taxofficestorage.blob.core.windows.net/media/404NotFound.jpg'}
        backgroundSize="contain"
      ></Box>
    </Box>
  );
};

export default NotFound;
