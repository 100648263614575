import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../selectTranslation/translateSelect';
import CounterContainer from '../dashboardComponents/counter/CounterContainer';
import {
  useGetBrokerCompletedTerminCount,
  useGetBrokerTaskCount,
  useGetBrokerTerminCount,
  useGetNextTerminForBroker,
} from '../../api/stats';
import Counter from '../dashboardComponents/counter/Counter';
import UpcomingAppointment from '../dashboardComponents/upcomingAppointment/UpcomingAppointment';
import AppointmentContainer from '../dashboardComponents/upcomingAppointment/AppointmentContainer';
import useTimeOfDay from '../../utils/useTimeOfTheDay';

const BrokerDashboard = () => {
  const { t } = useTranslation();

  const data = useSelector((state) => state?.auth?.currentUser);
  const { data: tasksCount } = useGetBrokerTaskCount(data?.id);
  const { data: terminsCount } = useGetBrokerTerminCount(data?.id);
  const { data: completedTerminsCount } = useGetBrokerCompletedTerminCount(data?.id);
  const { data: nextTermin } = useGetNextTerminForBroker(data?.id);
  const timeOfDay = useTimeOfDay();

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" px={5} py={2.45}>
        <Typography variant="h6" fontWeight="bold">
          {t('dashboard')}
        </Typography>
        <LanguageSelect />
      </Box>

      <Divider />
      <Typography variant="h4" fontWeight={900} px={5} pt={3} mb={3}>
        {t('Greeting', { timeOfDay: t(timeOfDay) })}, {data?.firstName} {data?.lastName}
      </Typography>

      <CounterContainer>
        <Counter
          targetNumber={tasksCount}
          type={t('Tasks')}
          icon="https://taxofficestorage.blob.core.windows.net/media/task-square.svg"
        />
        <Counter
          targetNumber={terminsCount}
          type={t('Appointments')}
          icon="https://taxofficestorage.blob.core.windows.net/media/task-square.svg"
        />
        <Counter
          targetNumber={completedTerminsCount}
          type={t('CompletedTermins')}
          icon="https://taxofficestorage.blob.core.windows.net/media/task-square.svg"
        />
      </CounterContainer>
      <AppointmentContainer title={'Next Appointment'}>
        {nextTermin ? (
          <UpcomingAppointment termins={nextTermin} />
        ) : (
          <>
            <Typography variant="h6" my={2} fontWeight={900}>
              {t('InspiringMessageForAppoiments')}
            </Typography>
          </>
        )}
      </AppointmentContainer>
    </Box>
  );
};

export default BrokerDashboard;
